import { FundAccountParticipantCost } from 'model/fund-account';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum FundAccountParticipantCostActionTypes {
    PARTICIPANT_COST_REQUEST = '@@fundAccount/PARTICIPANT_COST_REQUEST',
    PARTICIPANT_COST_SUCCESS = '@@fundAccount/PARTICIPANT_COST_SUCCESS',
    PARTICIPANT_COST_ERROR = '@@fundAccount/PARTICIPANT_COST_ERROR',
    PARTICIPANT_COST_RESET_STATE = '@@fundAccount/PARTICIPANT_COST_RESET_STATE'
}

export interface FundAccountParticipantCostState extends BaseStoreState {
    readonly participantCostBySeries?: FundAccountParticipantCost;
    readonly participantCostConsolidated?: FundAccountParticipantCost;
}
