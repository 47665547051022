import clsx from 'clsx';
import AccountList from 'components/account-list/account-list';
import EmptyFundWarning from 'components/empty-fund-warning/empty-fund-warning';
import Loading from 'components/loading/loading';
import _ from 'lodash';
import { DatePeriod } from 'model/enums/date-period';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ShareholderShareReportType } from 'model/enums/shareholder-share-report-type';
import { defaultAccountFilterRequest, ShareholderAccountHistory, ShareholderAccountHistoryRequest } from 'model/shareholder-account';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import { useRootDispatch, useShareholderAccountHistory } from 'reducer/hooks';
import { shareholderAccountHistoryRequest, shareholderAccountHistoryResetState } from 'reducer/shareholder-account/history/actions';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { v4 as uuidV4 } from 'uuid';
import AccountFilterButtons from './components/account-filter-buttons/account-filter-buttons';
import AccountFilterModal from './components/account-filter-modal/account-filter-modal';
import AccountTimeLine from './components/account-time-line';

import LocalDateFormatter, { LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import './account.scss';

interface AnchorAccountChildrenHistory extends ShareholderAccountHistory {
    id: string;
}

export interface AnchorMonthHistory {
    monthId: string;
    monthName: string;
    children: AnchorAccountChildrenHistory[];
}

const I18N_PREFIX = 'account';

const Account = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<AnchorMonthHistory[]>([]);
    const [activeTab, setActiveTab] = useState<ShareholderShareReportType>(ShareholderShareReportType.PURCHASE);
    const [accountData, setAccountData] = useState<ShareholderAccountHistory[] | undefined>(undefined);
    const [filterActive, setFilterActive] = useState<ShareholderAccountHistoryRequest>(defaultAccountFilterRequest);
    const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false);

    const dispatch = useRootDispatch();

    const { accounts, status: searchStatus } = useShareholderAccountHistory();
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const isLoading = searchStatus === HttpRequestStatus.ONGOING;

    useEffect(() => {
        if (!selectedFundAndSeries?.fundId) return;

        dispatch(
            shareholderAccountHistoryRequest({
                ...filterActive,
                fundId: selectedFundAndSeries.fundId,
                seriesId: selectedFundAndSeries.seriesId,
                personId: selectedFundAndSeries.personId
            })
        );
    }, [dispatch, filterActive, selectedFundAndSeries]);

    useEffect(() => {
        return () => {
            dispatch(shareholderAccountHistoryResetState());
        };
    }, [dispatch]);

    const filterAccounts = useCallback(
        (reportType: ShareholderShareReportType) => {
            return accounts?.filter(({ type }) => type === reportType);
        },
        [accounts]
    );

    useEffect(() => {
        setAccountData(filterAccounts(activeTab));
    }, [activeTab, filterAccounts]);

    useEffect(() => {
        const monthArr = new Array<AnchorMonthHistory>();
        const sortedAccounts = _.sortBy(accounts, ['date']);

        let monthId = '';

        sortedAccounts.forEach((_value, index) => {
            const date = sortedAccounts[index]?.date;

            const slicedDate = date ? date.slice(0, 7) : null;

            const nextMonthId = moment(slicedDate, 'YYYY-MM').format('MM/YYYY');

            if (nextMonthId !== monthId) {
                monthId = nextMonthId;
                monthArr.push({
                    monthId,
                    monthName: moment(monthId, 'MM/YYYY')
                        .locale('pt-BR')
                        .format('MMMM YYYY')
                        .toUpperCase(),
                    children: []
                });
            }

            const _accountInfo = { ...sortedAccounts[index], id: uuidV4() };

            monthArr[monthArr.length - 1].children.push(_accountInfo);
        });

        setData(monthArr.filter(x => x.children.length !== 0));
    }, [accounts]);

    const handleChangeFilterActive = (value: Partial<ShareholderAccountHistoryRequest>) => {
        setFilterActive({ ...filterActive, ...value });
    };

    if (!selectedFundAndSeries?.fundId) {
        return (
            <div className="account__items">
                <EmptyFundWarning />
            </div>
        );
    }

    const isCustomPeríodsFilterActive: boolean = !!filterActive.startDate || !!filterActive.endDate;

    return (
        <div className="account__items">
            <Container>
                <div className="account__items--timeline">{isLoading ? <Loading /> : <AccountTimeLine data={data} filterActive={filterActive} />}</div>
                <Row>
                    <Col>
                        <div className="account__items--bar">
                            <div style={{ display: 'flex' }}>
                                <div
                                    onClick={() => setActiveTab(ShareholderShareReportType.PURCHASE)}
                                    className={clsx('account__items--bar-contribution', { active: activeTab === ShareholderShareReportType.PURCHASE })}
                                    data-cy="account-tab#purchase"
                                >
                                    {t('account.contribution')}
                                </div>
                                <div onClick={() => setActiveTab(ShareholderShareReportType.SALE)} className={clsx('account__items--bar-rescue', { active: activeTab === ShareholderShareReportType.SALE })} data-cy="account-tab#redemption">
                                    {t('account.rescue')}
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AccountFilterButtons
                                    label={t(`${I18N_PREFIX}.filters.THREE_MONTHS`).toUpperCase()}
                                    isActive={filterActive.datePeriod === DatePeriod.THREE_MONTHS}
                                    onClick={() => handleChangeFilterActive({ datePeriod: DatePeriod.THREE_MONTHS, startDate: undefined, endDate: undefined })}
                                />
                                <AccountFilterButtons
                                    label={t(`${I18N_PREFIX}.filters.SIX_MONTHS`).toUpperCase()}
                                    isActive={filterActive.datePeriod === DatePeriod.SIX_MONTHS}
                                    onClick={() => handleChangeFilterActive({ datePeriod: DatePeriod.SIX_MONTHS, startDate: undefined, endDate: undefined })}
                                />
                                <AccountFilterButtons
                                    label={t(`${I18N_PREFIX}.filters.TWELVE_MONTHS`).toUpperCase()}
                                    isActive={filterActive.datePeriod === DatePeriod.TWELVE_MONTHS}
                                    onClick={() => handleChangeFilterActive({ datePeriod: DatePeriod.TWELVE_MONTHS, startDate: undefined, endDate: undefined })}
                                />
                                <AccountFilterButtons
                                    label={
                                        isCustomPeríodsFilterActive ? (
                                            <Trans
                                                t={t}
                                                i18nKey={`${I18N_PREFIX}.filters.active-period`}
                                                components={{
                                                    startDate: <LocalDateFormatter value={filterActive.startDate} type={LocalDateFormatType.LONG_FULL_DATE} />,
                                                    endDate: <LocalDateFormatter value={filterActive.endDate} type={LocalDateFormatType.LONG_FULL_DATE} />
                                                }}
                                                values={{ dash: !!filterActive.startDate && !!filterActive.endDate ? '-' : '' }}
                                            />
                                        ) : (
                                            t(`${I18N_PREFIX}.filters.period`).toUpperCase()
                                        )
                                    }
                                    isActive={!!filterActive.startDate || !!filterActive.endDate}
                                    onClick={() => setIsOpenFilterModal(true)}
                                />
                                {isOpenFilterModal && <AccountFilterModal onClose={() => setIsOpenFilterModal(false)} handleChangeFilterActive={handleChangeFilterActive} filterActive={filterActive} />}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AccountList data={accountData} filterActive={filterActive} activeTab={activeTab} isLoading={isLoading} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Account;
