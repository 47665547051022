import ActionButton from 'components/buttons/action-button/action-button';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import SimpleDonutChart from 'components/chart/donut-chart/simple-donut-chart';
import { formatDecimal } from 'components/fomatter/decimal/decimal-formatter';
import PercentageFormatter, { formatIntegerPercentage, formatPercentage } from 'components/fomatter/percentage/percentage-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { FundResourcesSource } from 'model/fund';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { fundResourcesSourceRequest } from 'reducer/fund/resources-source/actions';
import { useFundResourcesSourceState, useRootDispatch } from 'reducer/hooks';
import './resources-source-card.scss';

interface ResourcesSourceCardProps {
    fundId: string | null | undefined;
}

export const ResourcesSourceCard = (props: ResourcesSourceCardProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { status, source } = useFundResourcesSourceState();

    const isLoading = status !== HttpRequestStatus.ERROR && status !== HttpRequestStatus.SUCCESS;
    const hasError = status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (!props.fundId) return;

        dispatch(fundResourcesSourceRequest(props.fundId));
    }, [dispatch, props.fundId]);

    if (isLoading) {
        return (
            <div className="items--card">
                <div className="items--card-title">
                    <div>{t('debenture-cards.series-position.title')}</div>
                </div>
                <div className="items--card--loading">
                    <Loading />
                </div>
            </div>
        );
    }

    const handleTryAgain = () => {
        if (!props.fundId) return;

        dispatch(fundResourcesSourceRequest(props.fundId));
    };

    if (hasError || !source) {
        return (
            <div className="items--card">
                <div className="items--card-title">
                    <div>{t('debenture-cards.series-position.title')}</div>
                </div>
                <div className="items--card--error">
                    <div className="anchor-header--error-message">{t('debenture-cards.resources-source.data-recovery-exception')}</div>
                    <ActionButton label={t('global.try-again')} onClick={handleTryAgain} />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="items--card-title">{t('debenture-cards.resources-source.title')}</div>
            <div className="items--card-content-horizontal">
                <div className="items--card-graph">
                    <ResourcesGraph source={source} />
                </div>
                <div className="items--card-content-vertical">
                    <div className="items--card-subtitles-vertical">
                        <div className="items--card-subtitle">
                            <div>
                                <span className="items--card-label-two">{t('debenture-cards.resources-source.leverage')}</span>
                                <span className="items--card-value-three">{` ${formatDecimal(source.leverage)}X`}</span>
                            </div>
                        </div>
                        <ResourcesSubtitleItem label="debenture-cards.resources-source.participant" capture={source.stakeholder} color="#5991bc" />
                        <ResourcesSubtitleItem label="debenture-cards.resources-source.anchor" capture={source.shareholder} color="#64b5f7" />
                    </div>
                </div>
            </div>
        </>
    );
};

interface ResourcesGraphProps {
    source: FundResourcesSource;
}

const ResourcesGraph = (props: ResourcesGraphProps) => {
    const { t } = useTranslation();

    const customizeUngroupedToolTip = (id: string, value: number, color: unknown) => {
        return `
            <div class="tooltip--block">
                <div class="tooltip--block-line">
                    <div class="tooltip--block-color" style="background-color:${color};"></div>
                    <b class="tooltip--block-text-one">
                        ${t(`debenture-cards.resources-source.${id === 'anchor' ? 'anchor' : 'participant'}`)}
                    </b>
                </div>
                <hr />
                <span class="tooltip--block-text-two">Percentual: ${formatPercentage(value)}</span>
            </div>
        `;
    };

    return (
        <div>
            <UngroupedChartToolTip className="resources" customize={customizeUngroupedToolTip}>
                {tooltip => (
                    <SimpleDonutChart
                        data={[props.source]}
                        className="resources"
                        config={{
                            height: 190,
                            donutWidth: 45,
                            donutLabelPositionAdjust: 1,
                            labelFormat: value => formatIntegerPercentage(value),
                            categoriesKeys: ['shareholder', 'stakeholder']
                        }}
                        tooltipOptions={tooltip}
                    />
                )}
            </UngroupedChartToolTip>
        </div>
    );
};

interface ResourcesSubtitleItemProps {
    label: string;
    capture: number;
    color: string;
}

const ResourcesSubtitleItem = (props: ResourcesSubtitleItemProps) => {
    const { t } = useTranslation();
    return (
        <div className="items--card-subtitle">
            <div className="items--card-graph-label">
                <div className="items--card-box" style={{ background: props.color }} />
                <div>{t(props.label)}</div>
            </div>
            <div className="items--card-value-focus" style={{ marginLeft: 17 }}>
                <PercentageFormatter value={props.capture} />
            </div>
        </div>
    );
};

export default ResourcesSourceCard;
