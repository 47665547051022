import React from 'react';
import { useIsMobile } from '../../../../shared/responsive/media-query';

export interface StepsContextProps {
    current: StepsEnum;
    previous: StepsEnum;
    toNext: () => void;
    toPrevious: () => void;
    activeIndex: () => number;
    shouldHideStepper: () => boolean;
    toStep: (step: StepsEnum) => void;
    isMobile: boolean;
    isCompleted: (step: StepsEnum) => boolean;
}

export enum StepsEnum {
    WELCOME = 'WELCOME',
    PASSWORD_AND_CONFIRM = 'PASSWORD_AND_CONFIRM',
    PASSWORD_ONLY = 'PASSWORD_ONLY',
    CONFIRM_ONLY = 'CONFIRM_ONLY',
    CONTACT = 'CONTACT',
    CONTACT_INFORMATIVE = 'CONTACT_INFORMATIVE',
    CONTACT_PHONE = 'CONTACT_PHONE',
    TERMS_OF_USE = 'TERMS_OF_USE'
}

export const StepsContext = React.createContext<StepsContextProps>({} as StepsContextProps);

interface StepsSequence {
    previous: StepsEnum;
    stepperIndex: number;
    next: StepsEnum;
}

const WELCOME_STEP_INDEX = -1; // NO PROGRESS
const PASSWORD_STEP_INDEX = 0;
const CONTACT_STEP_INDEX = 1;
const COMPLETION_STEP_INDEX = 2;

const WEB_STEPS_SEQUENCE: Record<StepsEnum, StepsSequence> = {
    WELCOME: {
        stepperIndex: WELCOME_STEP_INDEX,
        previous: StepsEnum.WELCOME,
        next: StepsEnum.PASSWORD_AND_CONFIRM
    },
    PASSWORD_AND_CONFIRM: {
        stepperIndex: PASSWORD_STEP_INDEX,
        previous: StepsEnum.WELCOME,
        next: StepsEnum.CONTACT
    },
    PASSWORD_ONLY: {
        stepperIndex: PASSWORD_STEP_INDEX,
        previous: StepsEnum.WELCOME,
        next: StepsEnum.CONFIRM_ONLY
    },
    CONFIRM_ONLY: {
        stepperIndex: PASSWORD_STEP_INDEX,
        previous: StepsEnum.PASSWORD_ONLY,
        next: StepsEnum.CONTACT
    },
    CONTACT: {
        stepperIndex: CONTACT_STEP_INDEX,
        previous: StepsEnum.PASSWORD_AND_CONFIRM,
        next: StepsEnum.TERMS_OF_USE
    },
    CONTACT_INFORMATIVE: {
        stepperIndex: CONTACT_STEP_INDEX,
        previous: StepsEnum.PASSWORD_AND_CONFIRM,
        next: StepsEnum.TERMS_OF_USE
    },
    CONTACT_PHONE: {
        stepperIndex: CONTACT_STEP_INDEX,
        previous: StepsEnum.PASSWORD_AND_CONFIRM,
        next: StepsEnum.TERMS_OF_USE
    },
    TERMS_OF_USE: {
        stepperIndex: COMPLETION_STEP_INDEX,
        previous: StepsEnum.CONTACT,
        next: StepsEnum.TERMS_OF_USE
    }
};

const RESPONSIVE_STEPS_SEQUENCE: Record<StepsEnum, StepsSequence> = {
    WELCOME: {
        stepperIndex: WELCOME_STEP_INDEX,
        previous: StepsEnum.WELCOME,
        next: StepsEnum.PASSWORD_ONLY
    },
    PASSWORD_AND_CONFIRM: {
        stepperIndex: PASSWORD_STEP_INDEX,
        previous: StepsEnum.WELCOME,
        next: StepsEnum.CONTACT
    },
    PASSWORD_ONLY: {
        stepperIndex: PASSWORD_STEP_INDEX,
        previous: StepsEnum.WELCOME,
        next: StepsEnum.CONFIRM_ONLY
    },
    CONFIRM_ONLY: {
        stepperIndex: PASSWORD_STEP_INDEX,
        previous: StepsEnum.PASSWORD_ONLY,
        next: StepsEnum.CONTACT_INFORMATIVE
    },
    CONTACT: {
        stepperIndex: CONTACT_STEP_INDEX,
        previous: StepsEnum.CONFIRM_ONLY,
        next: StepsEnum.TERMS_OF_USE
    },
    CONTACT_INFORMATIVE: {
        stepperIndex: CONTACT_STEP_INDEX,
        previous: StepsEnum.CONFIRM_ONLY,
        next: StepsEnum.CONTACT_PHONE
    },
    CONTACT_PHONE: {
        stepperIndex: CONTACT_STEP_INDEX,
        previous: StepsEnum.CONTACT_INFORMATIVE,
        next: StepsEnum.TERMS_OF_USE
    },
    TERMS_OF_USE: {
        stepperIndex: COMPLETION_STEP_INDEX,
        previous: StepsEnum.CONTACT_INFORMATIVE,
        next: StepsEnum.TERMS_OF_USE
    }
};

export const StepsProvider = props => {
    const isMobile = useIsMobile();
    const [previous, setPrevious] = React.useState<StepsEnum>(StepsEnum.WELCOME);
    const [current, setCurrent] = React.useState<StepsEnum>(StepsEnum.WELCOME);

    const updateCurrent = (next: StepsEnum) => {
        setPrevious(current);
        setCurrent(next);
    };

    const currentSequence = () => (isMobile ? RESPONSIVE_STEPS_SEQUENCE : WEB_STEPS_SEQUENCE);

    const toNext = () => updateCurrent(currentSequence()[current].next);
    const toPrevious = () => updateCurrent(currentSequence()[current].previous);
    const activeIndex = () => currentSequence()[current].stepperIndex;
    const shouldHideStepper = () => current === StepsEnum.WELCOME;
    const toStep = (step: StepsEnum) => updateCurrent(step);

    const isCompleted = (step: StepsEnum) => currentSequence()[current].stepperIndex > currentSequence()[step].stepperIndex;

    React.useEffect(() => {
        if (isMobile) {
            if (current === StepsEnum.PASSWORD_AND_CONFIRM) {
                setCurrent(StepsEnum.PASSWORD_ONLY);
            } else if (current === StepsEnum.CONTACT) {
                setCurrent(StepsEnum.CONTACT_INFORMATIVE);
            }
        } else {
            if (current === StepsEnum.PASSWORD_ONLY || current === StepsEnum.CONFIRM_ONLY) {
                setCurrent(StepsEnum.PASSWORD_AND_CONFIRM);
            } else if (current === StepsEnum.CONTACT_INFORMATIVE || current === StepsEnum.CONTACT_PHONE) {
                setCurrent(StepsEnum.CONTACT);
            }
        }
    }, [isMobile, current]);

    const value: StepsContextProps = {
        current,
        previous,
        toNext,
        toPrevious,
        activeIndex,
        shouldHideStepper,
        toStep,
        isMobile,
        isCompleted
    };

    return <StepsContext.Provider value={value}>{props.children}</StepsContext.Provider>;
};

export const useSteps = () => React.useContext(StepsContext);

export const withStepsContext = () => <P extends object>(WrappedComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <StepsProvider>
            <WrappedComponent {...props} />
        </StepsProvider>
    );
};
