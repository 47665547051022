import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../loading/loading';
import './button-start.scss';

export interface WelcomeProps {
    onClick: () => void;
    disabled: boolean;
    isLoading?: boolean;
}

export const ButtonStart = (props: WelcomeProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style">
            <Button variant="contained" onClick={props.onClick} disabled={props.disabled}>
                {props.isLoading ? (
                    <div
                        style={{
                            width: '185px'
                        }}
                    >
                        <Loading />
                    </div>
                ) : (
                    <>{t('accept-invite.buttons.start').toUpperCase()}</>
                )}
            </Button>
        </div>
    );
};

export default ButtonStart;
