import { SeriesPosition } from 'model/share-history';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum SeriesPositionActionTypes {
    SERIES_POSITION_REQUEST = '@@seriesPosition/SERIES_POSITION_REQUEST',
    SERIES_POSITION_SUCCESS = '@@seriesPosition/SERIES_POSITION_SUCCESS',
    SERIES_POSITION_ERROR = '@@seriesPosition/SERIES_POSITION_ERROR',
    SERIES_POSITION_RESET_STATE = '@@seriesPosition/SERIES_POSITION_RESET_STATE'
}

export interface SeriesPositionState extends BaseStoreState {
    readonly position?: SeriesPosition;
}
