import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { seriesPositionReducer } from './series-position/reducer';
import seriesPositionSaga from './series-position/sagas';
import { SeriesPositionState } from './series-position/types';

export interface ShareHistoryState {
    seriesPosition: SeriesPositionState
}

export const ShareHistoryReducer: ReducersMapObject<ShareHistoryState, AnyAction> = {
    seriesPosition: seriesPositionReducer
};

export function* shareHistorySagas() {
    yield all([fork(seriesPositionSaga)]);
}
