import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { FinancialStatementMonth } from 'model/financial-statement-month';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import financialStatementMonthApi from 'services/api/financialStatementMonthApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { getFinancialStatementMonthError, getFinancialStatementMonthRequest, getFinancialStatementMonthSuccess } from './actions';
import { GetFinancialStatementMonthTypes } from './types';

function* handleGetFinancialStatementMonth(action: ReturnType<typeof getFinancialStatementMonthRequest>) {
    try {
        const result: AxiosResponse<FinancialStatementMonth> = yield call(financialStatementMonthApi.getFinancialStatementMonth, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getFinancialStatementMonthError(mapped));
            return;
        }
        yield put(getFinancialStatementMonthSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getFinancialStatementMonthError(mapped));
    }
}

function* watchGetFinancialStatementMonth() {
    yield takeEvery(GetFinancialStatementMonthTypes.GET_FINANCIAL_STATEMENT_MONTH_REQUEST, handleGetFinancialStatementMonth);
}

function* getFinancialStatementMonthSaga() {
    yield all([fork(watchGetFinancialStatementMonth)]);
}

export default getFinancialStatementMonthSaga;
