import { BaseStoreState } from 'reducer/baseStoreState';

export enum ChangePasswordActionTypes {
    CHANGE_PASSWORD_REQUEST = '@@changePassword/CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS = '@@changePassword/CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR = '@@changePassword/CHANGE_PASSWORD_ERROR',
    CHANGE_PASSWORD_RESET_STATE = '@@changePassword/CHANGE_PASSWORD_RESET_STATE',
}

export interface ChangePasswordState extends BaseStoreState {
    readonly user?: void;
}
