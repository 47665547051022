import { FindAllUserSeries } from 'model/series';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum FindAllSeriesByUserActionTypes {
    FIND_ALL_BY_USER_REQUEST = '@@find-all-by-user/FIND_ALL_BY_USER_REQUEST',
    FIND_ALL_BY_USER_SUCCESS = '@@find-all-by-user/FIND_ALL_BY_USER_SUCCESS',
    FIND_ALL_BY_USER_ERROR = '@@find-all-by-user/FIND_ALL_BY_USER_ERROR',
    FIND_ALL_BY_USER_RESET_STATE = '@@find-all-by-user/FIND_ALL_BY_USER_RESET_STATE'
}

export interface FindAllSeriesByUserState extends BaseStoreState {
    readonly userSeries?: ReadonlyArray<FindAllUserSeries>;
}
