import { SeriesSharePerformance } from 'model/series';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum SharePerformanceActionTypes {
    SHARE_PERFORMANCE_REQUEST = '@@sharePerformance/SHARE_PERFORMANCE_REQUEST',
    SHARE_PERFORMANCE_SUCCESS = '@@sharePerformance/SHARE_PERFORMANCE_SUCCESS',
    SHARE_PERFORMANCE_ERROR = '@@sharePerformance/SHARE_PERFORMANCE_ERROR',
    SHARE_PERFORMANCE_RESET_STATE = '@@seriesOsharePerformancefFund/SHARE_PERFORMANCE_RESET_STATE'
}

export interface SharePerformanceState extends BaseStoreState {
    readonly performance?: SeriesSharePerformance;
}
