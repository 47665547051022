import { FundDocumentsToGetAll } from 'model/fund';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum MyFundDocumentsTypes {
    MY_FUND_DOCUMENTS_REQUEST = '@@myFundDocuments/MY_FUND_DOCUMENTS_REQUEST',
    MY_FUND_DOCUMENTS_SUCCESS = '@@myFundDocuments/MY_FUND_DOCUMENTS_SUCCESS',
    MY_FUND_DOCUMENTS_ERROR = '@@myFundDocuments/MY_FUND_DOCUMENTS_ERROR',
    MY_FUND_DOCUMENTS_RESET_STATE = '@@myFundDocuments/MY_FUND_DOCUMENTS_RESET_STATE'
}

export interface MyFundDocumentsState extends BaseStoreState {
    readonly fundDocuments?: Page<FundDocumentsToGetAll>;
}
