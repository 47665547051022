import { AxiosResponse } from 'axios';
import { CityToSearch } from 'model/personAccount';
import HttpStatus from 'model/enums/httpStatus'
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import cityApi from 'services/api/citySearchApi';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { searchCitiesError, searchCitiesRequest, searchCitiesSuccess } from './actions';
import { SearchCitiesTypes } from './types';

function* handleSearchCities(_action: ReturnType<typeof searchCitiesRequest>) {
    try {
        const result: AxiosResponse<Page<CityToSearch>> = yield call(cityApi.searchCities, _action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(searchCitiesError(mapped));
            return;
        }
        yield put(searchCitiesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchCitiesError(mapped));
    }
}

function* watchSearchCities() {
    yield takeEvery(SearchCitiesTypes.SEARCH_CITIES_REQUEST, handleSearchCities);
}

function* searchCitiesSaga() {
    yield all([fork(watchSearchCities)]);
}

export default searchCitiesSaga;
