import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '.';
import { RootDispatcher } from '..';
import { AcceptLgpdTermsState } from './account/accept-lgpd-terms/types';
import { AcceptTermsState } from './account/accept-terms/types';
import { ForgotPasswordState } from './account/forgot-password/types';
import { TermsAcceptanceState } from './account/terms-acceptance/types';
import { UserAccountState } from './account/user-account/types';
import { ApplicationErrorState } from './application/error/types';
import { AuthenticationState } from './authentication/types';
import { SearchBanksState } from './bank/types';
import { SearchCitiesState } from './city/types';
import { GetAvailableYearsByIssuerIdState } from './financial-statement-month/get-available-years-by-issuer-id/types';
import { GetFinancialStatementMonthState } from './financial-statement-month/get-financial-statement-month/types';
import { GetYearlyFinancialStatementMonthState } from './financial-statement-month/get-yearly-financial-statement-month/types';
import { CapitalBalanceState } from './fund-account/capital-balance/types';
import { CapitalEvolutionState } from './fund-account/capital-evolution/types';
import { FundAccountCashAvailableBalanceState } from './fund-account/cash-available-balance/types';
import { FundAccountParticipantCostState } from './fund-account/participant-costs/types';
import { FundAccountrReceivablesVolumeState } from './fund-account/receivables-volume/types';
import { FundDocumentsDownloadState } from './fund/download-documents/types';
import { MyFundDocumentsState } from './fund/fund-documents/types';
import { GetAllSeriesPortfolioState } from './fund/get-all-series-portfolio/types';
import { MyFundsState } from './fund/my-funds/types';
import { FundResourcesSourceState } from './fund/resources-source/types';
import { IncomeStatementSubGroupAcquiredVolumeState } from './income-statement-sub-group/acquired-volume/types';
import { GetIncomeStatementAvailableYearsState } from './income-statement/get-available-years-by-issuer-id/types';
import { GetMonthlyIncomeStatementState } from './income-statement/get-monthly-income-statement/types';
import { GetYearlyIncomeStatementState } from './income-statement/get-yearly-income-statement/types';
import { NumberOfParticipantsState } from './raising/number-of-participants/types';
import { GetAllSecondaryAccessSeriesState } from './secondary-access/get-all-secondary-access-series/types';
import { FindAllSeriesByUserState } from './series/find-all-by-user/types';
import { NonParticipantOfFundState } from './series/non-participant-series/types';
import { SharePerformanceState } from './series/share-performance/types';
import { SeriesPositionState } from './share-history/series-position/types';
import { ShareholderAccountHistoryState } from './shareholder-account/history/types';
import { ShareholderInviteConfirmState } from './shareholder-invite/confirm/types';
import { ShareholderInviteToConfirmState } from './shareholder-invite/to-confirm/types';
import { ChangePasswordState } from './shareholder/profile/change-password/types';
import { PersonProfileState } from './shareholder/profile/types';
import { PersonUpdateProfileState } from './shareholder/profile/update-profile/types';

export const useRootDispatch = () => useDispatch<RootDispatcher>();

export const useAuthenticationState = () => useSelector<IRootState, AuthenticationState>((state: IRootState) => state.authentication);
export const useApplicationErrorState = () => useSelector<IRootState, ApplicationErrorState>((state: IRootState) => state.applicationError);

// account
export const useAcceptTermsState = () => useSelector<IRootState, AcceptTermsState>((state: IRootState) => state.acceptTerms);
export const useTermsAcceptanceState = () => useSelector<IRootState, TermsAcceptanceState>((state: IRootState) => state.termsAcceptance);
export const useUserAccountState = () => useSelector<IRootState, UserAccountState>((state: IRootState) => state.userAccount);
export const useLgpdTermsAcceptanceState = () => useSelector<IRootState, AcceptLgpdTermsState>((state: IRootState) => state.lgpdTermsAcceptance);

// shareholder-invite
export const useShareholderInviteToConfirmState = () => useSelector<IRootState, ShareholderInviteToConfirmState>((state: IRootState) => state.recoverShareholderInvite);
export const useShareholderInviteConfirmState = () => useSelector<IRootState, ShareholderInviteConfirmState>((state: IRootState) => state.confirmShareholderInvite);

// anchor-account
export const useShareholderAccountHistory = () => useSelector<IRootState, ShareholderAccountHistoryState>((state: IRootState) => state.shareholderAccountHistory);
export const useShareholderPortfolio = () => useSelector<IRootState, ShareholderAccountHistoryState>((state: IRootState) => state.shareholderAccountHistory);

// fund
export const useMyFundsState = () => useSelector<IRootState, MyFundsState>((state: IRootState) => state.myFunds);
export const useFundResourcesSourceState = () => useSelector<IRootState, FundResourcesSourceState>((state: IRootState) => state.resourcesSource);
export const useGetAllSeriesPortfolioState = () => useSelector<IRootState, GetAllSeriesPortfolioState>((state: IRootState) => state.allSeriesPortfolio);

// income-statement-sub-group
export const useIncomeStatementSubGroupAcquiredVolumeState = () => useSelector<IRootState, IncomeStatementSubGroupAcquiredVolumeState>((state: IRootState) => state.subGroupAcquiredVolume);

// raising
export const useNumberOfParticipantsState = () => useSelector<IRootState, NumberOfParticipantsState>((state: IRootState) => state.numberOfParticipants);
export const useCapitalEvolutionState = () => useSelector<IRootState, CapitalEvolutionState>((state: IRootState) => state.capitalEvolution);
export const useCapitalBalanceState = () => useSelector<IRootState, CapitalBalanceState>((state: IRootState) => state.capitalBalance);

// fund-account
export const useFundAccountCashAvailableBalanceState = () => useSelector<IRootState, FundAccountCashAvailableBalanceState>((state: IRootState) => state.cashAvailableBalance);
export const useFundAccountRecevablesVolumeState = () => useSelector<IRootState, FundAccountrReceivablesVolumeState>((state: IRootState) => state.receivables);
export const useFundAccountParticipantCosts = () => useSelector<IRootState, FundAccountParticipantCostState>((state: IRootState) => state.participantCosts);

// series
export const useSharePerformanceState = () => useSelector<IRootState, SharePerformanceState>((state: IRootState) => state.sharePerformance);
export const useNonParticipantSeriesOfFundState = () => useSelector<IRootState, NonParticipantOfFundState>((state: IRootState) => state.nonParticipantSeries);
export const useFindAllSeriesByUserState = () => useSelector<IRootState, FindAllSeriesByUserState>((state: IRootState) => state.findAllSeriesByUser);

// Financial Statement Month
export const useGetFinancialStatementMonthState = () => useSelector<IRootState, GetFinancialStatementMonthState>((state: IRootState) => state.financialStatementMonth);
export const useGetAvailableYearsByIssuerId = () => useSelector<IRootState, GetAvailableYearsByIssuerIdState>((state: IRootState) => state.availableYearsByIssuerId);
export const useGetYearlyFinancialStatementMonthState = () => useSelector<IRootState, GetYearlyFinancialStatementMonthState>((state: IRootState) => state.yearlyFinancialStatementMonth);

// Secondary Access
export const useGetAllSecondaryAcessSeriesState = () => useSelector<IRootState, GetAllSecondaryAccessSeriesState>((state: IRootState) => state.allSecondaryAccessSeries);

// share-history
export const useSeriesPositionState = () => useSelector<IRootState, SeriesPositionState>((state: IRootState) => state.seriesPosition);

export const useForgotPasswordState = () => useSelector<IRootState, ForgotPasswordState>((state: IRootState) => state.forgotPassword);

// fund-documents
export const useFundDocumentsState = () => useSelector<IRootState, MyFundDocumentsState>((state: IRootState) => state.fundDocuments);

// download-document
export const useDownloadDocumentState = () => useSelector<IRootState, FundDocumentsDownloadState>((state: IRootState) => state.downloadDocuments);

// person profile
export const usePersonProfileState = () => useSelector<IRootState, PersonProfileState>((state: IRootState) => state.personProfile);

// person update profile
export const usePersonUpdateProfileState = () => useSelector<IRootState, PersonUpdateProfileState>((state: IRootState) => state.personUpdateProfile);

// person change password
export const useChangePasswordState = () => useSelector<IRootState, ChangePasswordState>((state: IRootState) => state.personPasswordChange);

// search city
export const useSearchCitiesState = () => useSelector<IRootState, SearchCitiesState>((state: IRootState) => state.searchCities);

// search bank
export const useSearchBanksState = () => useSelector<IRootState, SearchBanksState>((state: IRootState) => state.searchBanks);

// Income Statement
export const useGetMonthlyIncomeStatementState = () => useSelector<IRootState, GetMonthlyIncomeStatementState>((state: IRootState) => state.monthlyIncomeStatement);
export const useGetIncomeStatementStateAvailableYears = () => useSelector<IRootState, GetIncomeStatementAvailableYearsState>((state: IRootState) => state.incomeStatementAvailableYears);
export const useGetYearlyIncomeStatementState = () => useSelector<IRootState, GetYearlyIncomeStatementState>((state: IRootState) => state.yearlyIncomeStatement);
