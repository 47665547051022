import React from 'react';
import { YAxis, YAxisProps } from 'recharts';
import { ChartUtils } from 'shared/util/chart-utils';
import { DEFAULT_Y_AXIS_PROPS } from './chart-components-style';

export type YAxisPropsType<T> = Omit<YAxisProps, 'dataKey'> & { dataKey?: Extract<keyof T, string> };

interface CustomizedYAxisProps<T> {
    yAxisProps: YAxisPropsType<T>;
}

const CustomizedYAxis = <T extends object>(props: CustomizedYAxisProps<T>) => {
    const { yAxisProps } = props;

    const mergedProps: YAxisPropsType<T> = ChartUtils.mergeProps(DEFAULT_Y_AXIS_PROPS, yAxisProps);

    return <YAxis {...mergedProps} />;
};

export default CustomizedYAxis;
