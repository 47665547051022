import { AxiosResponse } from 'axios';
import { FindAllUserSeries, FundAndPersonIdFilter, SeriesOfFund, SeriesSharePerformance } from 'model/series';
import { api } from './api';

export const series = () => {
    const getNonParticipantSeriesOfFund = (filter: FundAndPersonIdFilter): Promise<AxiosResponse<SeriesOfFund[]>> => {
        const _requestFilters = [filter?.personId ? `personId=${filter.personId}` : undefined];

        return api.get<SeriesOfFund[]>(`api/series/funds/${filter.fundId}/shareholders/non-participants?${_requestFilters}`);
    };

    const getSeriesSharePerformance = (seriesId: string): Promise<AxiosResponse<SeriesSharePerformance>> => {
        return api.get<SeriesSharePerformance>(`api/series/${seriesId}/share-performance`);
    };

    const findAllByUser = (): Promise<AxiosResponse<ReadonlyArray<FindAllUserSeries>>> => {
        return api.get<ReadonlyArray<FindAllUserSeries>>('api/series');
    };

    return {
        getNonParticipantSeriesOfFund,
        getSeriesSharePerformance,
        findAllByUser
    };
};

export default series();
