import EmbededForm from 'components/embeded-form/embeded-form';
import FeatureFlag from 'components/feature-flag/feature-flag';
import Loading from 'components/loading/loading';
import { UnleashEmbededFormFlag, UnleashFeatureToggleWalletFlag } from 'config/unleash/unleash-feature-toggle-flag';
import _ from 'lodash';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { WalletDataVisualization } from 'model/enums/wallet-data-visualization';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap/lib';
import { fundHistoryRequest, fundHistoryResetState, portfolioRequest, portfolioResetState } from 'reducer/fund/my-funds/actions';
import { useGetAllSecondaryAcessSeriesState, useMyFundsState, useRootDispatch } from 'reducer/hooks';
import { getAllSecondaryAccessSeriesRequest, getAllSecondaryAccessSeriesResetState } from 'reducer/secondary-access/get-all-secondary-access-series/actions';
import useFilterUtils from 'shared/hooks/use-filter-utils';
import SecondaryAccessPage from './components/secondary-access-card/secondary-access-page';
import WalletSwitch from './components/walled-switch/wallet-switch';
import WalletHistorySection from './components/wallet-history-section/wallet-history-section';
import WalletFundsSection from './components/wallet-portfolio-section/wallet-portfolio-section';
import './wallet.scss';

const WalletDashboard = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { history, portfolioStatus, historyStatus } = useMyFundsState();
    const [currentVisualization, setCurrentVisualization] = useState<WalletDataVisualization>(WalletDataVisualization.INVESTOR);
    const { allSecondaryAccessSeries, status: secondaryAccessListStatus } = useGetAllSecondaryAcessSeriesState();

    const isLoading = portfolioStatus === HttpRequestStatus.ONGOING || historyStatus === HttpRequestStatus.ONGOING || secondaryAccessListStatus === HttpRequestStatus.ONGOING;

    const { clearUrlFilter } = useFilterUtils();

    React.useEffect(() => {
        clearUrlFilter();
    }, [clearUrlFilter]);

    const handleSwitch: Record<WalletDataVisualization, () => void> = {
        INVESTOR: () => {
            setCurrentVisualization(WalletDataVisualization.SECONDARY_ACCESS);
        },
        SECONDARY_ACCESS: () => {
            setCurrentVisualization(WalletDataVisualization.INVESTOR);
        }
    };

    React.useEffect(() => {
        if (!history) return;
        if (history?.funds.length > 0) return;

        setCurrentVisualization(WalletDataVisualization.SECONDARY_ACCESS);
    }, [dispatch, history]);

    React.useEffect(() => {
        dispatch(getAllSecondaryAccessSeriesRequest());
    }, [dispatch]);

    React.useEffect(() => {
        return () => {
            dispatch(getAllSecondaryAccessSeriesResetState());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (historyStatus === HttpRequestStatus.NOOP) {
            dispatch(fundHistoryRequest());
        }
    }, [dispatch, historyStatus]);

    React.useEffect(() => {
        return () => {
            dispatch(fundHistoryResetState());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (portfolioStatus === HttpRequestStatus.NOOP) {
            dispatch(portfolioRequest());
        }
    }, [dispatch, portfolioStatus]);

    React.useEffect(() => {
        return () => {
            dispatch(portfolioResetState());
        };
    }, [dispatch]);

    const isInvestorVisualization: boolean = currentVisualization === WalletDataVisualization.INVESTOR;
    const isHistoryAvailable: boolean = !!history?.funds.length;
    const isSecondaryAccessAvailable: boolean = !_.isNil(allSecondaryAccessSeries) && !!allSecondaryAccessSeries?.length;

    const isSwitchAvailable: boolean = isHistoryAvailable && isSecondaryAccessAvailable;

    return (
        <div className="wallet__items">
            {isInvestorVisualization && isLoading && <Loading />}
            <Container>
                <div className="wallet__items--title">
                    <h1>{t('wallet.title')}</h1>
                    {isSwitchAvailable && <WalletSwitch currentVisualization={currentVisualization} handleSwitch={handleSwitch[currentVisualization]} />}
                </div>
                <div className="wallet__items--switch-container" />
                {isInvestorVisualization ? (
                    <>
                        <FeatureFlag flagName={UnleashFeatureToggleWalletFlag.MY_FUNDS_GRAPH} component={<WalletFundsSection />} />
                        <FeatureFlag flagName={UnleashFeatureToggleWalletFlag.BALANCE_HISTORY_GRAPH} component={<WalletHistorySection />} />
                    </>
                ) : (
                    <SecondaryAccessPage secondaryAccessSeries={allSecondaryAccessSeries ?? []} baseUrl="/investor/general" />
                )}
            </Container>
            <FeatureFlag flagName={UnleashEmbededFormFlag.SATISFACTION_SURVEY} component={<EmbededForm />} />
        </div>
    );
};

export default WalletDashboard;
