import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { PasswordChange } from './../../../../model/personAccount';

import { ChangePasswordActionTypes } from './types';

export const changePasswordRequest = (request: PasswordChange) => action(ChangePasswordActionTypes.CHANGE_PASSWORD_REQUEST, request);
export const changePasswordSuccess = (user: void) => action(ChangePasswordActionTypes.CHANGE_PASSWORD_SUCCESS, user);
export const changePasswordError = (error: GiroWebappError) => action(ChangePasswordActionTypes.CHANGE_PASSWORD_ERROR, error);
export const changePasswordResetState = () => action(ChangePasswordActionTypes.CHANGE_PASSWORD_RESET_STATE);
