import React from 'react';

import MenuItem, { MenuItemPropsList } from '../menu-item/menu-item';

const I18N_PREFIX = 'header.navbar';

const EntitiesMenu = () => {
    const baseUrl = '/investor';

    const entities: MenuItemPropsList[] = [
        {
            translation: `${I18N_PREFIX}.wallet`,
            link: `${baseUrl}/wallet`,
            visibility: true
        },
        {
            translation: `${I18N_PREFIX}.funds.title`,
            link: `${baseUrl}/general`,
            visibility: false,
            submenu: [
                {
                    translation: `${I18N_PREFIX}.funds.submenu.general`,
                    link: `${baseUrl}/general`,
                    visibility: false
                },
                {
                    translation: `${I18N_PREFIX}.funds.submenu.performance`,
                    link: `${baseUrl}/performance`,
                    visibility: false
                },
                {
                    translation: `${I18N_PREFIX}.funds.submenu.raising`,
                    link: `${baseUrl}/raising`,
                    visibility: false
                },
                {
                    translation: `${I18N_PREFIX}.funds.submenu.documents`,
                    link: `${baseUrl}/documents`,
                    visibility: false
                },
                {
                    translation: `${I18N_PREFIX}.funds.submenu.account`,
                    link: `${baseUrl}/account`,
                    visibility: false
                }
            ]
        }
    ];

    return <MenuItem list={entities} menu={false} />;
};

export default EntitiesMenu;
