import { Collapse, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { IncomeStatementGroupType } from 'model/enums/group-type';
import { HeaderVisualizationType } from 'model/enums/header-visualization-type';
import { monthValues } from 'model/enums/month-values';
import { IncomeStatementSubgroup } from 'model/income-statement';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMonthlyIncomeStatementState } from 'reducer/hooks';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import IncomeStatementMonthRow from '../income-statement-month-row';
import IncomeStatementStickyCell from '../income-statement-sticy-cell';

const I18N_PREFIX = 'anchor.income-statement-month';

interface MonthlyIncomeStatementTableProps {
    years: ReadonlyArray<number> | undefined;
}

const MonthlyIncomeStatementTable = (props: MonthlyIncomeStatementTableProps) => {
    const { years } = props;
    const { t } = useTranslation();

    const { incomeStatement } = useGetMonthlyIncomeStatementState();
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const [isGroupExpanded, setIsGroupExpanded] = React.useState<string[]>([]);

    const collapseOrExpandRows = (clickedId?: string) => {
        if (!clickedId) return;

        const isExpanded = isGroupExpanded.some(id => id === clickedId);

        if (isExpanded) {
            setIsGroupExpanded(prev => prev.filter(id => id !== clickedId));
            return;
        }

        setIsGroupExpanded(prev => [...prev, clickedId]);
    };

    const shouldCollapse = (subgroups: ReadonlyArray<IncomeStatementSubgroup>) => {
        return !!subgroups?.length;
    };

    const isSecondaryAccessActive: boolean = selectedFundAndSeries?.headerVisualizationType === HeaderVisualizationType.SECONDARY_ACCESS;

    return (
        <>
            {years?.length !== undefined && years?.length > 0 ? (
                <table
                    className={clsx('page-container--table', {
                        SECONDARY_ACCESS_ACTIVE: isSecondaryAccessActive
                    })}
                >
                    <thead className="page-container--table__header">
                        <tr>
                            <th className="page-container--table__sticky first-columns none-border" />
                            {monthValues.map(month => {
                                return (
                                    <th key={month} className="page-container--table__header-items">
                                        {t(`${I18N_PREFIX}.header.month.${month}`)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {incomeStatement?.groups
                            ?.filter(group => group.type === IncomeStatementGroupType.REVENUE)
                            .map(group => (
                                <Fragment key={group.id}>
                                    <tr className="revenue" onClick={() => shouldCollapse(group.subgroups) && collapseOrExpandRows(group.id)}>
                                        <IncomeStatementStickyCell className="revenue" cellTitle={group.name} isCollapsedArrow={!isGroupExpanded.some(id => id === group.id)} shouldCollapse={shouldCollapse(group.subgroups)} />
                                        <IncomeStatementMonthRow balances={group.balances} className="revenue" />
                                    </tr>
                                    {group?.subgroups?.map(subgroup => (
                                        <Collapse in={!isGroupExpanded.some(id => id === group.id)} key={subgroup.id}>
                                            <tr key={group.id}>
                                                <td className="page-container--table__sticky first-columns">
                                                    <Tooltip title={subgroup.name}>
                                                        <span>{subgroup.name}</span>
                                                    </Tooltip>
                                                </td>

                                                <IncomeStatementMonthRow balances={subgroup?.balances} className="revenue-color" />
                                            </tr>
                                        </Collapse>
                                    ))}
                                </Fragment>
                            ))}
                        {incomeStatement?.groups
                            ?.filter(group => group.type === IncomeStatementGroupType.OPERATIONAL)
                            .map(group => (
                                <Fragment key={group.id}>
                                    <tr className="operational" onClick={() => shouldCollapse(group.subgroups) && collapseOrExpandRows(group.id)}>
                                        <IncomeStatementStickyCell className="operational" cellTitle={group.name} isCollapsedArrow={!isGroupExpanded.some(id => id === group.id)} shouldCollapse={shouldCollapse(group.subgroups)} />
                                        <IncomeStatementMonthRow balances={group.balances} className="operational" />
                                    </tr>
                                    {group?.subgroups?.map(subgroup => (
                                        <Collapse in={!isGroupExpanded.some(id => id === group.id)} key={subgroup.id}>
                                            <tr key={group.id}>
                                                <td className="page-container--table__sticky first-columns">
                                                    <Tooltip title={subgroup.name}>
                                                        <span>{subgroup.name}</span>
                                                    </Tooltip>
                                                </td>

                                                <IncomeStatementMonthRow balances={subgroup?.balances} className="operational-color" />
                                            </tr>
                                        </Collapse>
                                    ))}
                                </Fragment>
                            ))}
                        {incomeStatement?.groups
                            ?.filter(group => _.isNil(group.type))
                            .map(group => (
                                <tr className="operational" key={group.nameType}>
                                    <IncomeStatementStickyCell className="operational" cellTitle={t(`${I18N_PREFIX}.default-groups.${group.name}`)} isCollapsedArrow={!isGroupExpanded.some(id => id === group.id)} shouldCollapse={false} />
                                    <IncomeStatementMonthRow balances={group.balances} className="operational" />
                                </tr>
                            ))}
                    </tbody>
                </table>
            ) : (
                <div className="income-statement__table--empty-years">{t(`${I18N_PREFIX}.empty-years`)}</div>
            )}
        </>
    );
};

export default MonthlyIncomeStatementTable;
