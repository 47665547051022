import _ from 'lodash';
import React, { ReactNode } from 'react';
import { LineChart, ResponsiveContainer, ResponsiveContainerProps } from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { DEFAULT_LINE_CHART_PROPS } from '../components/chart-components-style';
import CustomizedCartesianGrid, { CartesianGridPropsType } from '../components/customized-cartesian-grid';
import CustomizedLegend, { LegendPropsType } from '../components/customized-legend';
import CustomizedToolTip, { TooltipPropsType } from '../components/customized-tooltip';
import CustomizedXAxis, { XAxisPropsType } from '../components/customized-x-axis';
import CustomizedYAxis, { YAxisPropsType } from '../components/customized-y-axis';
import './multi-line-chart.scss';

type ResponsiveContainerPropsType = Omit<ResponsiveContainerProps, 'children'>;

interface MultiLineChartProps<T> {
    children: ReactNode;
    xAxisProps: XAxisPropsType<T>;
    yAxisProps: YAxisPropsType<T>;
    tooltipProps?: TooltipPropsType;
    legendProps?: LegendPropsType;
    cartesianGridProps?: CartesianGridPropsType;
    lineChartProps?: CategoricalChartProps;
    containerProps?: ResponsiveContainerPropsType;
}

const MultiLineChart = <T extends object>(props: MultiLineChartProps<T>) => {
    const { children: Lines, xAxisProps, yAxisProps, tooltipProps, legendProps, cartesianGridProps, lineChartProps, containerProps } = props;

    const mergedLineChartProps: CategoricalChartProps = _.merge({}, DEFAULT_LINE_CHART_PROPS, lineChartProps);

    return (
        <ResponsiveContainer className="multi-line-chart" {...containerProps}>
            <LineChart {...mergedLineChartProps}>
                {CustomizedCartesianGrid({ cartesianGridProps })}
                {CustomizedXAxis({ xAxisProps })}
                {CustomizedYAxis({ yAxisProps })}
                {CustomizedToolTip({ tooltipProps })}
                {CustomizedLegend({ legendProps })}
                {Lines}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default MultiLineChart;
