import ActionButton from 'components/buttons/action-button/action-button';
import SimpleCategoryBarChart from 'components/chart/bar-chart/category-bar-chart/simple-category-bar-chart';
import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import { formatLocalDate, LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { IncomeStatementSubGroupAcquiredVolumeChartData } from 'model/income-statement-sub-group';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIncomeStatementSubGroupAcquiredVolumeState, useRootDispatch } from 'reducer/hooks';
import { subGroupAcquiredVolumeRequest } from 'reducer/income-statement-sub-group/acquired-volume/actions';
import './acquired-volume-card.scss';

const CHART_HEIGHT = 262;

export interface AcquiredVolumeCardProps {
    fundId: string | undefined | null;
}

export const AcquiredVolumeCard = (props: AcquiredVolumeCardProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { status, volume } = useIncomeStatementSubGroupAcquiredVolumeState();

    const [years, setYears] = React.useState(false);

    const isLoading = status !== HttpRequestStatus.ERROR && status !== HttpRequestStatus.SUCCESS;
    const hasError = status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (!props.fundId) return;

        dispatch(subGroupAcquiredVolumeRequest(props.fundId));
    }, [dispatch, props.fundId]);

    if (isLoading) {
        return (
            <div className="items--card">
                <div className="items--card-title">
                    <div>{t('debenture-cards.acquired-volume.title')}</div>
                </div>
                <div className="items--card--loading">
                    <Loading />
                </div>
            </div>
        );
    }

    const handleTryAgain = () => {
        if (!props.fundId) return;

        dispatch(subGroupAcquiredVolumeRequest(props.fundId));
    };

    if (hasError || !volume) {
        return (
            <div className="items--card">
                <div className="items--card-title">
                    <div>{t('debenture-cards.acquired-volume.title')}</div>
                </div>
                <div className="items--card--error">
                    <div className="anchor-header--error-message">{t('debenture-cards.acquired-volume.data-recovery-exception')}</div>
                    <ActionButton label={t('global.try-again')} onClick={handleTryAgain} />
                </div>
            </div>
        );
    }

    return (
        <div className="items--card-block">
            <div className="items--card-header">
                <div className="items--card-header-title">{t('debenture-cards.acquired-volume.title')}</div>
                <div className="items--card-header-buttons" style={{ padding: 0 }}>
                    <div className={`items--card-header-buttons${!years ? '-active' : '-inactive'}`} onClick={() => setYears(false)}>
                        {t('debenture-cards.acquired-volume.monthly')}
                    </div>
                    <div className={`items--card-header-buttons${years ? '-active' : '-inactive'}`} onClick={() => setYears(true)}>
                        {t('debenture-cards.acquired-volume.yearly')}
                    </div>
                </div>
            </div>
            <div className="items--card-graph">{!years ? <AcquiredVolumeMonthlyChart data={volume.monthly.data} /> : <AcquiredVolumeYearlyChart data={volume.yearly.data} />}</div>
        </div>
    );
};

interface AcquiredVolumeChartProps {
    data: IncomeStatementSubGroupAcquiredVolumeChartData[];
}

const AcquiredVolumeMonthlyChart = (props: AcquiredVolumeChartProps) => {
    const { t } = useTranslation();
    const formatLabel = (value: number): string => formatCurrency(value, 0, true);
    const formatCategory = (value: Date | number): string => {
        if (typeof value === 'number') {
            const date = props.data?.length && props.data.length >= value ? props.data[value]?.date : undefined;
            return formatLocalDate(date, LocalDateFormatType.SHORT_MONTH_NAME);
        }
        return formatLocalDate(value, LocalDateFormatType.SHORT_MONTH_NAME);
    };

    const curtomizeTooltip = React.useMemo(() => {
        return (_id: string, value: number, color: unknown, x: number) => {
            const date = props.data?.length && props.data.length >= x ? props.data[x]?.date : undefined;

            return `
                <div class="tooltip--block">
                    <div class="tooltip--block-line">
                        <div class="tooltip--block-color" style="background-color:${color};"></div>
                        <b class="tooltip--block-text" >${formatLocalDate(date, LocalDateFormatType.SHORT_MONTH_NAME)}</b>
                    </div>
                    <hr />
                    <div class="tooltip--block-two">
                        <span class="tooltip--block-text">Aquisição: </span>
                        <span>${formatCurrency(value, 0, true)}</span>
                    </div>
                </div>
            `;
        };
    }, [props.data]);

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-three">{t('debenture-cards.acquired-volume.chart.title')}</div>
            </div>
            <div style={{ height: `${CHART_HEIGHT}px` }}>
                <UngroupedChartToolTip customize={curtomizeTooltip}>
                    {tooltip => (
                        <SimpleCategoryBarChart
                            data={props.data}
                            config={{
                                height: CHART_HEIGHT,
                                labelKey: 'date',
                                categoryFormat: formatCategory,
                                categoriesKeys: ['volume'],
                                categoryLabelFormat: formatLabel
                            }}
                            tooltipOptions={tooltip}
                        >
                            {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={_key => t('debenture-cards.acquired-volume.chart.legend')} />}
                        </SimpleCategoryBarChart>
                    )}
                </UngroupedChartToolTip>
            </div>
        </>
    );
};

const AcquiredVolumeYearlyChart = (props: AcquiredVolumeChartProps) => {
    const { t } = useTranslation();
    const formatLabel = (value: number): string => formatCurrency(value, 0, true);
    const formatCategory = (value: Date | number): string => {
        if (typeof value === 'number') {
            const date = props.data?.length && props.data.length >= value ? props.data[value]?.date : undefined;
            return formatLocalDate(date, LocalDateFormatType.FULL_YEAR);
        }
        return formatLocalDate(value, LocalDateFormatType.FULL_YEAR);
    };

    const curtomizeTooltip = React.useMemo(() => {
        return (_id: string, value: number, color: unknown, x: number) => {
            const date = props.data?.length && props.data.length >= x ? props.data[x]?.date : undefined;

            return `
            <div class="tooltip--block">
                <div class="tooltip--block-line">
                    <div class="tooltip--block-color" style="background-color:${color};"></div>
                    <b class="tooltip--block-text" >${formatLocalDate(date, LocalDateFormatType.FULL_YEAR)}</b>
                </div>
                <hr />
                <div class="tooltip--block-two">
                    <span class="tooltip--block-text">Aquisição: </span>
                    <span>${formatCurrency(value, 0, true)}</span>
                </div>
            </div>
        `;
        };
    }, [props.data]);

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-three">{t('debenture-cards.acquired-volume.chart.title')}</div>
            </div>
            <div style={{ height: `${CHART_HEIGHT}px` }}>
                <UngroupedChartToolTip customize={curtomizeTooltip}>
                    {tooltip => (
                        <SimpleCategoryBarChart
                            className="total-purchase-volume"
                            data={props.data}
                            config={{
                                height: CHART_HEIGHT,
                                labelKey: 'date',
                                categoryFormat: formatCategory,
                                categoriesKeys: ['volume'],
                                categoryLabelFormat: formatLabel
                            }}
                            tooltipOptions={tooltip}
                        >
                            {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={_key => t('debenture-cards.acquired-volume.chart.legend')} />}
                        </SimpleCategoryBarChart>
                    )}
                </UngroupedChartToolTip>
            </div>
        </>
    );
};

export default AcquiredVolumeCard;
