import ActionButton from 'components/buttons/action-button/action-button';
import SimpleCategoryBarChart from 'components/chart/bar-chart/category-bar-chart/simple-category-bar-chart';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import CurrencyFormatter, { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import LocalDateFormatter, { LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import PercentageFormatter from 'components/fomatter/percentage/percentage-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { SeriesPositionChart } from 'model/share-history';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRootDispatch, useSeriesPositionState } from 'reducer/hooks';
import { seriesPositionRequest } from 'reducer/share-history/series-position/actions';
import './series-position-card.scss';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { SeriesPositionFilter } from 'model/enums/my-position';

interface SeriesPositionSelectCardProps {
    seriesId: string;
}

export const SeriesPositionSelectCard = (props: SeriesPositionSelectCardProps) => {
    const { seriesId } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { status, position } = useSeriesPositionState();

    const [positionInfo, setPositionInfo] = React.useState<boolean>(false);
    const { selectedFundAndSeries } = useFundAndSeriesContext();


    const isLoading = status === HttpRequestStatus.ONGOING;
    const hasError = status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (!seriesId) return;

        const filter: SeriesPositionFilter = {
            personId: selectedFundAndSeries?.personId,
            seriesId: seriesId
        }

        dispatch(seriesPositionRequest(filter));
    }, [dispatch, selectedFundAndSeries, seriesId]);

    if (isLoading) {
        return (
            <div className="series-position">
                <div className="series-position-title">
                    <div>{t('debenture-cards.series-position.title')}</div>
                </div>
                <div className="series-position--loading">
                    <Loading />
                </div>
            </div>
        );
    }

    const handleTryAgain = () => {
        if (!seriesId) return;
        
        const filter: SeriesPositionFilter = {
            personId: selectedFundAndSeries?.personId,
            seriesId: seriesId
        }

        dispatch(seriesPositionRequest(filter));
    };

    if (hasError || !seriesId || !position) {
        return (
            <div className="series-position">
                <div className="series-position-title">
                    <div>{t('debenture-cards.series-position.title')}</div>
                </div>
                <div className="series-position-error">
                    <div className="anchor-header--error-message">{t('debenture-cards.series-position.data-recovery-exception')}</div>
                    <ActionButton label={t('global.try-again')} onClick={handleTryAgain} />
                </div>
            </div>
        );
    }

    return (
        <div className="series-position">
            <div className="series-position-title">
                <div>{t('debenture-cards.series-position.title')}</div>
                <div className="series-position-buttons">
                    <div className={!positionInfo ? 'series-position-buttons-active' : 'series-position-buttons-inactive'} onClick={() => setPositionInfo(false)}>
                        {t('debenture-cards.series-position.options.chart')}
                    </div>
                    <div className={positionInfo ? 'series-position-buttons-active' : 'series-position-buttons-inactive'} onClick={() => setPositionInfo(true)}>
                        {t('debenture-cards.series-position.options.description')}
                    </div>
                </div>
            </div>
            <div className="series-position-content-vertical">
                {!positionInfo ? (
                    <PositionChart chart={position.chart} />
                ) : (
                    <div className="series-position-content-card">
                        <div className="series-position-content-card-header">
                            <div className="series-position-label-one">{t('debenture-cards.series-position.description.current-yield')}</div>
                            <div className="series-position-value-five">
                                <PercentageFormatter value={position.description.yield} />
                            </div>
                        </div>
                        <div className="series-position-content-card-list">
                            <PositionCardItem label={t('debenture-cards.series-position.description.share-date')}>
                                <LocalDateFormatter value={position.description.date} type={LocalDateFormatType.LONG_FULL_DATE} />
                            </PositionCardItem>
                            <PositionCardItem label={t('debenture-cards.series-position.description.share-value')}>
                                <CurrencyFormatter value={position.description.value} prefix />
                            </PositionCardItem>
                            <PositionCardItem label={t('debenture-cards.series-position.description.share-amount')}>{position.description.amount}</PositionCardItem>
                        </div>
                    </div>
                )}

                <div className="series-position-subtitles-horizontal">
                    <div className="series-position-content-horizontal">
                        <PrositionInfo label={t('debenture-cards.series-position.gross-amount')}>
                            <CurrencyFormatter value={position.grossAmount} prefix />
                        </PrositionInfo>
                        <PrositionInfo label={t('debenture-cards.series-position.tax-value')}>
                            <CurrencyFormatter value={position.taxValue} prefix />
                        </PrositionInfo>
                        <PrositionInfo label={t('debenture-cards.series-position.net-value')} focus>
                            <CurrencyFormatter value={position.netValue} prefix />
                        </PrositionInfo>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface PositionChartProps {
    chart: SeriesPositionChart;
}

const PositionChart = (props: PositionChartProps) => {
    const formatLabel = (value: number): string => formatCurrency(value, 0, true);
    const formatCategory = (value: Date | number): string => {
        if (typeof value === 'number') {
            const date = props.chart.data?.length && props.chart.data.length >= value ? props.chart.data[value]?.date : undefined;
            return moment(date).format('MM/YYYY');
        }
        return moment(value).format('MM/YYYY');
    };

    // memo to avoid chart unnecessary re-renders
    const customizeToolTip = React.useMemo(() => {
        return (_id: string, value: number, color: unknown, x: number) => {
            const date = props.chart.data?.length && props.chart.data.length >= x ? props.chart.data[x]?.date : undefined;
            return `
                <div class="tooltip--block">
                    <div class="tooltip--block-line">
                        <div class="tooltip--block-color" style="background-color:${color};"></div>
                        <b class="tooltip--block-text-one">${moment(date).format('MM/YYYY')}</b>
                    </div>
                    <hr />
                    <span class="tooltip--block-text-two">${formatCurrency(value, 0, true)}</span>
                </div>
            `;
        };
    }, [props.chart]);

    const chartHeigth = 253;
    const chartWidth = 612;
    return (
        <div style={{ height: `${chartHeigth}px` }}>
            <UngroupedChartToolTip className="position" customize={customizeToolTip}>
                {tooltip => (
                    <SimpleCategoryBarChart
                        data={props.chart.data}
                        className="position"
                        config={{
                            height: chartHeigth,
                            width: chartWidth,
                            categoriesKeys: ['value'],
                            categoryLabelFormat: formatLabel,
                            labelKey: 'date',
                            rotateLabel: -45,
                            xHeight: 30,
                            categoryFormat: formatCategory
                        }}
                        tooltipOptions={tooltip}
                    />
                )}
            </UngroupedChartToolTip>
        </div>
    );
};

interface PrositionInfoProps {
    label: string;
    children: React.ReactNode;
    focus?: boolean;
}

const PrositionInfo = (props: PrositionInfoProps) => {
    return (
        <div className="series-position-subtitle">
            <div className="series-position-label-three">{props.label}</div>
            <div className={`${props.focus ? 'series-position-value-focus' : 'series-position-value-one'}`}>{props.children}</div>
        </div>
    );
};

interface PrositionCardItemProps {
    label: string;
    children: React.ReactNode;
}

const PositionCardItem = (props: PrositionCardItemProps) => {
    return (
        <div className="series-position-content-card-list-item">
            <div className="series-position-label-four">{props.label}</div>
            <div className="series-position-dashed-line" />
            <div className="series-position-value-three">{props.children}</div>
        </div>
    );
};

export default SeriesPositionSelectCard;
