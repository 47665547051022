import React from 'react';
import { Link } from 'react-router-dom';
import { useUserAccountState } from 'reducer/hooks';
import EntitiesMenu from './components/entities-menu/entities-menu';
import ProfileMenu from './components/profile-menu/profile-menu';
import './navbar.scss';

const NavBar = () => {
    const { account } = useUserAccountState();

    return (
        <div className="page-header">
            <div>
                <nav className="page-header--menu">
                    <Link to="/">
                        <div className="page-header--logo" />
                    </Link>
                    <div className="page-header--items">
                        <EntitiesMenu />
                    </div>
                    <ul className="page-header--menu-list">
                        <ProfileMenu name={account?.firstName ?? ''} />
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default NavBar;
