import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonNext from '../../../../components/button-next/button-next';
import ButtonPrevious from '../../../../components/button-previous/button-previous';
import Card from '../../../../components/card/card';
import FieldBasic from '../../../../components/field-basic/field-basic';
import FieldPassword from '../../../../components/field-password/field-password';
import PasswordStrength from '../../../../components/password-strength/password-strength';
import { ShareholderInviteToConfirm } from '../../../../model/shareholder-invite';
import { FOCUS_DELAY } from '../animation/animation-constants';
import { useSteps } from '../invite-steps/invite-steps-context';
import { validatePassword } from '../validation/validation-constants';
import './password.scss';

export interface PasswordOnlyProps {
    username: string;
    password: string;
    onChange: (value: Partial<ShareholderInviteToConfirm>) => void;
}

export const PasswordOnly = (props: PasswordOnlyProps): JSX.Element => {
    const { t } = useTranslation();
    const steps = useSteps();
    const [password, setPassword] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const INPUT_REF = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (steps.isMobile) {
            const timer = setTimeout(() => INPUT_REF.current?.focus({ preventScroll: true }), FOCUS_DELAY);
            return () => clearTimeout(timer);
        }
    }, [INPUT_REF, steps.isMobile]);

    const haltProgression = !validatePassword(password).isValid;

    const handleNext = () => {
        setValidation(true);
        if (haltProgression) return;

        const _password = password;
        props.onChange({ password: _password });
        steps.toNext();
    };

    const handleEnter = () => {
        if (steps.isMobile) handleNext();
    };

    return (
        <div className="body__password">
            <Row style={{ width: '100%' }}>
                <Col md="10" style={{ margin: 'auto' }}>
                    <div className="body__password--title">{t('accept-invite.password.title')}</div>
                </Col>
            </Row>
            <form className="form__style">
                <Row>
                    <Col md="6" offset="3" style={{ marginBottom: '15px' }}>
                        <FieldBasic label={t('accept-invite.password.email')} readOnly value={props.username} autoComplete="username" />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ margin: 'auto' }} className="password__strength--mobile">
                        <Card>
                            <div className="body__password-strength">{t('accept-invite.password.strength').toUpperCase()}</div>
                            <PasswordStrength password={password ?? ''} />
                        </Card>
                    </Col>
                </Row>
                <div className="body__password--components">
                    <Row>
                        <Col md="6" offset="3">
                            <FieldPassword
                                value={''}
                                label={t('accept-invite.password.password-field.label')}
                                onChange={pass => setPassword(pass)}
                                validate={validatePassword}
                                autoComplete="new-password"
                                inputRef={INPUT_REF}
                                onEnter={handleEnter}
                                showValidation={showValidation}
                            />
                        </Col>
                    </Row>
                </div>
            </form>
            <Row>
                <div className="body__password--buttons">
                    <Col>
                        <div className="body__password--button-previous">
                            <ButtonPrevious onClick={steps.toPrevious} />
                        </div>
                    </Col>
                    <Col>
                        <div className="body__password--button-next">
                            <ButtonNext onClick={handleNext} disabled={showValidation && haltProgression} />
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default PasswordOnly;
