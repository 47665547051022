import { AxiosResponse } from 'axios';
import { FundSeriesAndInvestor } from 'model/series';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { getAllSecondaryAccessSeriesError, getAllSecondaryAccessSeriesRequest, getAllSecondaryAccessSeriesSuccess } from './actions';
import { GetAllSecondaryAccessSeriesTypes } from './types';
import secondarAccessApi from 'services/api/secondarAccessApi';

function* handleGetAllSecondaryAccessSeries(_action: ReturnType<typeof getAllSecondaryAccessSeriesRequest>) {
    try {
        const result: AxiosResponse<FundSeriesAndInvestor[]> = yield call(secondarAccessApi.getAllSecondaryAccessSeries);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getAllSecondaryAccessSeriesError(mapped));
            return;
        }
        yield put(getAllSecondaryAccessSeriesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getAllSecondaryAccessSeriesError(mapped));
    }
}

function* watchGetAllSecondaryAccessSeries() {
    yield takeLeading(GetAllSecondaryAccessSeriesTypes.GET_ALL_SECONDARY_ACCESS_SERIES_REQUEST, handleGetAllSecondaryAccessSeries);
}

function* getAllSecondaryAccessSeriesSaga() {
    yield all([fork(watchGetAllSecondaryAccessSeries)]);
}

export default getAllSecondaryAccessSeriesSaga;
