import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { GetYearlyFinancialStatementMonthState, GetYearlyFinancialStatementMonthTypes } from './types';

const initialState: GetYearlyFinancialStatementMonthState = {
    status: HttpRequestStatus.NOOP,
    yearlyFinancialStatementMonth: undefined,
    error: undefined
};

const reducer: Reducer<GetYearlyFinancialStatementMonthState> = (state = initialState, action): GetYearlyFinancialStatementMonthState => {
    switch (action.type) {
        case GetYearlyFinancialStatementMonthTypes.GET_YEARLY_FINANCIAL_STATEMENT_MONTH_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case GetYearlyFinancialStatementMonthTypes.GET_YEARLY_FINANCIAL_STATEMENT_MONTH_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, yearlyFinancialStatementMonth: action.payload, error: undefined };
        }
        case GetYearlyFinancialStatementMonthTypes.GET_YEARLY_FINANCIAL_STATEMENT_MONTH_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case GetYearlyFinancialStatementMonthTypes.GET_YEARLY_FINANCIAL_STATEMENT_MONTH_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as getYearlyFinancialStatementMonthReducer };
