import clsx from 'clsx';
import React, { ReactNode } from 'react';
import './account-filter-buttons.scss';

interface AccountFilterButtonsProps {
    label: string | ReactNode;
    onClick: () => void;
    isActive: boolean;
}

const AccountFilterButtons = (props: AccountFilterButtonsProps) => {
    const { label, onClick, isActive } = props;

    return (
        <div
            role="button"
            className={clsx('account-filter-buttons', {
                ACTIVE: isActive
            })}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

export default AccountFilterButtons;
