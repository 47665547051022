import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export type LinkAction = () => void;

type I18NInterpolation = { name: string };

export interface MenuItemPropsList {
    translation: string;
    interpolation?: I18NInterpolation;
    link?: string;
    visibility: boolean;
    submenu?: {
        link: string;
        translation: string;
        visibility: boolean;
        action?: LinkAction;
    }[];
}

export interface MenuItemProps {
    icon?: string;
    list: MenuItemPropsList[];
    menu?: boolean;
}

const MenuItem = (props: MenuItemProps) => {
    const local = useLocation().pathname;
    const [itemActive, setItemActive] = React.useState<number | null>(null);
    const [submenu, setSubmenu] = React.useState<boolean>(false);
    const menuRef = React.useRef(null);

    const { t } = useTranslation();
    const { icon, list, menu } = props;

    const handleSubMenuVisibility = () => (submenu ? setSubmenu(false) : setSubmenu(true));

    const handleActiveItem = (index: number) => {
        setItemActive(index);
        handleSubMenuVisibility();
    };

    const handleActive = React.useCallback(
        (path?: string) => {
            const activeIndex = props.list.findIndex(it => it.submenu?.find(_it => _it.link.split('/')[2] === path));
            setItemActive(ps => (ps !== activeIndex ? activeIndex : ps));
        },
        [props.list]
    );

    const checkPageChange = React.useCallback(() => {
        const activeIndex = list.findIndex(it => it.submenu?.find(_it => _it.link.split('/')[2] === local.split('/')[2]));
        setItemActive(ps => (ps !== activeIndex ? activeIndex : ps));
    }, [list, local]);

    const handleSubMenu = (action?: LinkAction) => {
        if (action) {
            action();
        }
        handleSubMenuVisibility();
        setItemActive(null);
    };

    const handleOutside = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setSubmenu(false);
                setItemActive(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    };

    React.useEffect(() => {
        if (list.some(it => it.link)) return;
        if (!local.includes('profile')) return;

        if (itemActive === null) {
            handleActive(local.split('/')[2]);
        }
    }, [local, itemActive, handleActive, list]);

    React.useEffect(() => {
        if (list.some(it => !it.link)) return;

        if (itemActive === null) {
            handleActive(local.split('/')[2]);
            return;
        }

        checkPageChange();
    }, [local, itemActive, handleActive, list, checkPageChange]);

    React.useEffect(() => {
        if (list.some(it => it.link)) return;

        handleOutside(menuRef);
    }, [menuRef, list]);

    React.useEffect(() => {
        return () => {
            setSubmenu(false);
            setItemActive(null);
        };
    }, [setSubmenu, setItemActive]);

    return (
        <ul className="page-header--menu-list" ref={menu ? menuRef : null}>
            {list.map((item, index) => {
                return (
                    item.visibility && (
                        <li className="page-header--menu-list-item" key={index} ref={!menu ? menuRef : null}>
                            <div
                                className={clsx('page-header--menu-list-item-button', {
                                    active: item.link === local || itemActive === index,
                                    has_link: item.link
                                })}
                                onClick={() => handleActiveItem(index)}
                            >
                                {icon && <img src={icon} alt={icon} />}
                                {item.link ? (
                                    <Link to={item.link} className="page-header--menu-list-item-link" data-cy={`link#menu-${t(item.translation)}`}>
                                        <span> {t(item.translation, item.interpolation && item.interpolation)}</span>
                                    </Link>
                                ) : (
                                    <span data-cy={`link#menu-${t(item.translation)}`}> {t(item.translation, item.interpolation && item.interpolation)}</span>
                                )}
                            </div>
                            <ul className="page-header--submenu-list">
                                {itemActive === index &&
                                    submenu &&
                                    item.submenu
                                        ?.filter(it => it.visibility)
                                        .map(it => (
                                            <li key={it.link} className={clsx('page-header--submenu-list-item', { active: it.link === local })}>
                                                <Link to={it.link} className={clsx('page-header--submenu-list-item-link')} onClick={() => handleSubMenu(it.action)} data-cy={`link#submenu-${t(it.translation)}`}>
                                                    <span>{t(it.translation)}</span>
                                                </Link>
                                            </li>
                                        ))}
                            </ul>
                        </li>
                    )
                );
            })}
        </ul>
    );
};

export default MenuItem;
