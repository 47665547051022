import React from 'react';
import { Tooltip, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ChartUtils } from 'shared/util/chart-utils';
import { DEFAULT_TOOLTIP_PROPS } from './chart-components-style';

export type TooltipPropsType = TooltipProps<ValueType, NameType>;

interface CustomizedToolTipProps {
    tooltipProps?: TooltipPropsType;
}

const CustomizedToolTip = (props: CustomizedToolTipProps) => {
    const { tooltipProps } = props;

    const mergedProps: TooltipPropsType = ChartUtils.mergeProps(DEFAULT_TOOLTIP_PROPS, tooltipProps);

    return <Tooltip {...mergedProps} />;
};

export default CustomizedToolTip;
