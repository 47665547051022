import { FinancialStatementMonth } from 'model/financial-statement-month';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum GetFinancialStatementMonthTypes {
    GET_FINANCIAL_STATEMENT_MONTH_REQUEST = '@@get-financial-statement-month/GET_FINANCIAL_STATEMENT_MONTH_REQUEST',
    GET_FINANCIAL_STATEMENT_MONTH_SUCCESS = '@@get-financial-statement-month/GET_FINANCIAL_STATEMENT_MONTH_SUCCESS',
    GET_FINANCIAL_STATEMENT_MONTH_ERROR = '@@get-financial-statement-month/GET_FINANCIAL_STATEMENT_MONTH_ERROR',
    GET_FINANCIAL_STATEMENT_MONTH_RESET_STATE = '@@get-financial-statement-month/GET_FINANCIAL_STATEMENT_MONTH_RESET_STATE',
}

export interface GetFinancialStatementMonthState extends BaseStoreState {
    readonly financialStatementMonth?: FinancialStatementMonth;
}
