import Button from '@material-ui/core/Button';
import { InputAdornmentTypeMap } from '@material-ui/core/InputAdornment';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';
import './back-button.scss';

export interface BackButtonProps {
    label: string;
    onClick: () => void;
    style?: any;
    adornment?: OverridableComponent<InputAdornmentTypeMap>;
}

export const BackButton = (props: BackButtonProps): JSX.Element => {

    return (
        <div className="button__style--back">
            <Button variant="contained" onClick={props.onClick}>
                <>
                    {props.adornment}
                    {props.label}
                </>
            </Button>
        </div>
    );
};

export default BackButton;
