import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { EnvironmentUtils } from 'shared/util/environment-utils';

export const useFeatureFlagStatus = (flagName: string): boolean => {
    const { flagsReady } = useFlagsStatus();

    const isEnabled = useFlag(flagName);

    if (EnvironmentUtils.isDevelopmentEnvironment()) return true;
    if (!isEnabled || !flagsReady) return false;

    return true;
};
