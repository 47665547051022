import { GiroWebappError } from 'model/error';
import { NumberOfParticipants } from 'model/raising';
import { action } from 'typesafe-actions';
import { NumberOfParticipantsActionTypes } from './types';


export const numberOfParticipantsRequest = (fundId: string) => action(NumberOfParticipantsActionTypes.NUMBER_OF_PARTICIPANTS_REQUEST, fundId);
export const numberOfParticipantsSuccess = (response: NumberOfParticipants) => action(NumberOfParticipantsActionTypes.NUMBER_OF_PARTICIPANTS_SUCCESS, response);
export const numberOfParticipantsError = (error: GiroWebappError) => action(NumberOfParticipantsActionTypes.NUMBER_OF_PARTICIPANTS_ERROR, error);
export const numberOfParticipantsResetState = () => action(NumberOfParticipantsActionTypes.NUMBER_OF_PARTICIPANTS_RESET_STATE);
