import { GiroWebappError } from 'model/error';
import { FundDocumentsRequest, FundDocumentsToGetAll } from 'model/fund';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { MyFundDocumentsTypes } from './types';

export const myFundDocumentsRequest = (request: FundDocumentsRequest) => action(MyFundDocumentsTypes.MY_FUND_DOCUMENTS_REQUEST, request);
export const myFundDocumentsSuccess = (response: Page<FundDocumentsToGetAll>) => action(MyFundDocumentsTypes.MY_FUND_DOCUMENTS_SUCCESS, response);
export const myFundDocumentsError = (error: GiroWebappError) => action(MyFundDocumentsTypes.MY_FUND_DOCUMENTS_ERROR, error);
export const myFundDocumentsResetState = () => action(MyFundDocumentsTypes.MY_FUND_DOCUMENTS_RESET_STATE);
