import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { incomeStatementSubGroupAcquiredVolumeReducer } from './acquired-volume/reducer';
import incomeStatementSubGroupAcquiredVolumeSaga from './acquired-volume/sagas';
import { IncomeStatementSubGroupAcquiredVolumeState } from './acquired-volume/types';

export interface IncomeStatementSubGroupState {
    subGroupAcquiredVolume: IncomeStatementSubGroupAcquiredVolumeState;
}

export const IncomeStatementSubGroupReducer: ReducersMapObject<IncomeStatementSubGroupState, AnyAction> = {
    subGroupAcquiredVolume: incomeStatementSubGroupAcquiredVolumeReducer
};


export function* incomeStatementSubGroupSagas() {
    yield all([fork(incomeStatementSubGroupAcquiredVolumeSaga)]);
}
