import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import incomeStatementApi from 'services/api/incomeStatementApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { getIncomeStatementAvailableYearsError, getIncomeStatementAvailableYearsRequest, getIncomeStatementAvailableYearsSuccess } from './actions';
import { GetIncomeStatementAvailableYearsTypes } from './types';

function* handleGetIncomeStatementAvailableYears(action: ReturnType<typeof getIncomeStatementAvailableYearsRequest>) {
    try {
        const result: AxiosResponse<ReadonlyArray<number>> = yield call(incomeStatementApi.getAvailableYearsByIssuerId, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getIncomeStatementAvailableYearsError(mapped));
            return;
        }
        yield put(getIncomeStatementAvailableYearsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getIncomeStatementAvailableYearsError(mapped));
    }
}

function* watchGetIncomeStatementAvailableYears() {
    yield takeEvery(GetIncomeStatementAvailableYearsTypes.GET_INCOME_STATEMENT_AVAILABLE_YEARS_REQUEST, handleGetIncomeStatementAvailableYears);
}

function* getIncomeStatementAvailableYearsSaga() {
    yield all([fork(watchGetIncomeStatementAvailableYears)]);
}

export default getIncomeStatementAvailableYearsSaga;
