import { AxiosResponse } from 'axios';
import { FundDocumentsRequest, FundDocumentsToGetAll, FundHistory, FundResourcesSource, Portfolio } from 'model/fund';
import { FundSeriesAndInvestor } from 'model/series';
import { Page } from 'services/page';
import { api } from './api';

export const fund = () => {
    const getPortfolio = (): Promise<AxiosResponse<Portfolio>> => {
        return api.get<Portfolio>(`api/funds/portfolio`);
    };

    const getFundResourcesSource = (fundId: string): Promise<AxiosResponse<FundResourcesSource>> => {
        return api.get<FundResourcesSource>(`api/funds/${fundId}/resources-source`);
    };

    const getFundDocuments = (request: FundDocumentsRequest): Promise<AxiosResponse<Page<FundDocumentsToGetAll>>> => {
        return api.get<Page<FundDocumentsToGetAll>>(`api/fund-documents/funds/${request.fundId}`, {
            params: { ...request.pageable }
        });
    };

    const getDocumentDownload = (documentId: string): Promise<AxiosResponse<Uint8Array>> => {
        return api.get<Uint8Array>(`api/fund-documents/${documentId}/download`, { responseType: 'arraybuffer' });
    };

    const getFundHistory = (): Promise<AxiosResponse<FundHistory>> => {
        return api.get<FundHistory>(`api/funds/history`);
    };

    const getAllSeriesPortfolio = (): Promise<AxiosResponse<FundSeriesAndInvestor[]>> => {
        return api.get<FundSeriesAndInvestor[]>(`api/funds/portfolio-all`);
    };

    return {
        getPortfolio,
        getFundResourcesSource,
        getFundDocuments,
        getDocumentDownload,
        getFundHistory,
        getAllSeriesPortfolio
    };
};

export default fund();
