import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import React from 'react';
import { EnvironmentUtils } from 'shared/util/environment-utils';

interface FeatureFlagProps {
    flagName: string;
    component: React.ReactNode;
}

export const FeatureFlag = ({ flagName, component }: FeatureFlagProps) => {
    const { flagsReady } = useFlagsStatus();

    const isEnabled = useFlag(flagName);

    if (EnvironmentUtils.isDevelopmentEnvironment()) {
        return <>{component}</>;
    }

    if (!isEnabled || !flagsReady) {
        return <></>;
    }

    return <>{component}</>;
};

export default FeatureFlag;
