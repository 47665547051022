import { ShareholderInviteConfirmation } from '../../../model/shareholder-invite';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum ShareholderInviteConfirmActionTypes {
  SHAREHOLDER_INVITE_CONFIRM_REQUEST = '@@confirmShareholderInvite/SHAREHOLDER_INVITE_CONFIRM_REQUEST',
  SHAREHOLDER_INVITE_CONFIRM_SUCCESS = '@@confirmShareholderInvite/SHAREHOLDER_INVITE_CONFIRM_SUCCESS',
  SHAREHOLDER_INVITE_CONFIRM_ERROR = '@@confirmShareholderInvite/SHAREHOLDER_INVITE_CONFIRM_ERROR',
  SHAREHOLDER_INVITE_CONFIRM_RESET_STATE = '@@confirmShareholderInvite/SHAREHOLDER_INVITE_CONFIRM_RESET_STATE'
}

export interface ShareholderInviteConfirmState extends BaseStoreState {
  readonly invite?: ShareholderInviteConfirmation;
}
