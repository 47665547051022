import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fundApi from 'services/api/fundApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { fundDocumentsDownloadError, fundDocumentsDownloadRequest, fundDocumentsDownloadSuccess } from './action';
import { FundDocumentsDownloadTypes } from './types';

function* handleFundDocumentsDownload(action: ReturnType<typeof fundDocumentsDownloadRequest>) {
    try {
        const result: AxiosResponse<Uint8Array> = yield call(fundApi.getDocumentDownload, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(fundDocumentsDownloadError(mapped));
            return;
        }
        yield put(fundDocumentsDownloadSuccess(result.data));

        // tslint:disable-next-line:variable-name
        const _file_name = result.headers['content-disposition']?.split('filename=')[1];
        const _type = result.headers['content-type'];
        const _blob = new Blob([result.data], { type: _type });
        const _url = window.URL.createObjectURL(_blob);
        const _link: HTMLAnchorElement = document.createElement('a');
        _link.href = _url;
        _link.setAttribute('download', _file_name);
        document.body.appendChild(_link);
        _link.click();
        _link.remove();
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(fundDocumentsDownloadError(mapped));
    }
}

function* watchFundDocumentsDownload() {
    yield takeEvery(FundDocumentsDownloadTypes.FUND_DOCUMENTS_DOWNLOAD_REQUEST, handleFundDocumentsDownload);
}

function* fundDocumentsDownloadSaga() {
    yield all([fork(watchFundDocumentsDownload)]);
}

export default fundDocumentsDownloadSaga;
