import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap/lib';

import './empty-fund-warning.scss';

const EmptyFundWarning = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <div className="empty-fund-warning">
                <span>{t('anchor.empty-warning')}</span>
            </div>
        </Container>
    );
};

export default EmptyFundWarning;
