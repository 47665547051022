import { AxiosResponse } from 'axios';
import { FundAccountParticipantCost } from 'model/fund-account';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fundAccountApi from 'services/api/fundAccountApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { participantCostError, participantCostRequest, participantCostSuccess } from './actions';
import { FundAccountParticipantCostActionTypes } from './types';

function* handleGetparticipantCost(action: ReturnType<typeof participantCostRequest>) {
    try {
        const result: AxiosResponse<FundAccountParticipantCost> = yield call(fundAccountApi.getParticipantCosts, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(participantCostError(mapped));
            return;
        }
        yield put(participantCostSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(participantCostError(mapped));
    }
}

function* watchGetparticipantCost() {
    yield takeEvery(FundAccountParticipantCostActionTypes.PARTICIPANT_COST_REQUEST, handleGetparticipantCost);
}

function* fundAccountParticipantCostSaga() {
    yield all([fork(watchGetparticipantCost)]);
}

export default fundAccountParticipantCostSaga;
