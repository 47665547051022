type StringValue = string | undefined | null;

const EMPTY_STRING = '';

const isBlank = (value: StringValue): boolean => value === null || value === undefined || value?.trim() === EMPTY_STRING;

const isNotBlank = (value: StringValue): boolean => !isBlank(value);

const StringUtils = {
    EMPTY_STRING,
    isBlank,
    isNotBlank
};

export default StringUtils;
