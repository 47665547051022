import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { IncomeStatement } from 'model/income-statement';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import incomeStatementApi from 'services/api/incomeStatementApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { getMonthlyIncomeStatementError, getMonthlyIncomeStatementRequest, getMonthlyIncomeStatementSuccess } from './actions';
import { GetMonthlyIncomeStatementTypes } from './types';

function* handleGetMonthlyIncomeStatement(action: ReturnType<typeof getMonthlyIncomeStatementRequest>) {
    try {
        const result: AxiosResponse<IncomeStatement> = yield call(incomeStatementApi.getMonthlyIncomeStatement, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getMonthlyIncomeStatementError(mapped));
            return;
        }
        yield put(getMonthlyIncomeStatementSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getMonthlyIncomeStatementError(mapped));
    }
}

function* watchGetMonthlyIncomeStatement() {
    yield takeEvery(GetMonthlyIncomeStatementTypes.GET_MONTHLY_INCOME_STATEMENT_REQUEST, handleGetMonthlyIncomeStatement);
}

function* getMonthlyIncomeStatementSaga() {
    yield all([fork(watchGetMonthlyIncomeStatement)]);
}

export default getMonthlyIncomeStatementSaga;
