import { AxiosResponse } from 'axios';
import { SeriesPosition } from 'model/share-history';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import shareHistoryApi from 'services/api/shareHistoryApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { seriesPositionError, seriesPositionRequest, seriesPositionSuccess } from './actions';
import { SeriesPositionActionTypes } from './types';

function* handleSeriesPositionRequest(action: ReturnType<typeof seriesPositionRequest>) {
    try {
        const result: AxiosResponse<SeriesPosition> = yield call(shareHistoryApi.getSeriesPosition, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(seriesPositionError(mapped));
            return;
        }
        yield put(seriesPositionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(seriesPositionError(mapped));
    }
}

function* watchGetSeriesPosition() {
    yield takeEvery(SeriesPositionActionTypes.SERIES_POSITION_REQUEST, handleSeriesPositionRequest);
}

function* seriesPositionSaga() {
    yield all([fork(watchGetSeriesPosition)]);
}

export default seriesPositionSaga;
