import { ShareholderAccountHistory } from 'model/shareholder-account';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum ShareholderAccountHistoryActionTypes {
    SHAREHOLDER_ACCOUNT_HISTORY_REQUEST = '@@SHareholderAccountHistory/SHAREHOLDER_ACCOUNT_HISTORY_REQUEST',
    SHAREHOLDER_ACCOUNT_HISTORY_SUCCESS = '@@SHareholderAccountHistory/SHAREHOLDER_ACCOUNT_HISTORY_SUCCESS',
    SHAREHOLDER_ACCOUNT_HISTORY_ERROR = '@@SHareholderAccountHistory/SHAREHOLDER_ACCOUNT_HISTORY_ERROR',
    SHAREHOLDER_ACCOUNT_HISTORY_RESET_STATE = '@@SHareholderAccountHistory/SHAREHOLDER_ACCOUNT_HISTORY_RESET_STATE'
}

export interface ShareholderAccountHistoryState extends BaseStoreState {
    readonly accounts?: ShareholderAccountHistory[];
}
