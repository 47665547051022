import { SeriesType } from 'model/enums/series-type';
import React, { useMemo } from 'react';
import { RouteComponentProps, Switch } from 'react-router';
import { compose } from 'redux';
import { useFundAndSeriesContext, withFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { HeaderType } from 'shared/menu/header/header';
import PrivateRoute from 'shared/routes/private-route';
import AnchorRoutes from './anchor';
import ParticipantRoutes from './participant';
import WalletDashboard from './wallet/wallet';

interface MatchParams {
    url: string;
}

const WrappedRoutes = ({ match }: RouteComponentProps<MatchParams>) => {
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const isAnchor = useMemo((): boolean => {
        if (SeriesType.RESULT !== selectedFundAndSeries?.seriesType) {
            return false;
        }

        return true;
    }, [selectedFundAndSeries]);

    return (
        <Switch>
            <PrivateRoute exact path={`${match.url}/wallet`} component={WalletDashboard} headerType={HeaderType.ANCHOR} />
            {isAnchor ? <AnchorRoutes match={match} /> : <ParticipantRoutes match={match} />}
        </Switch>
    );
};

export default compose(withFundAndSeriesContext())(WrappedRoutes);
