import moment from 'moment';
import 'moment/locale/pt-br.js';
import React from 'react';
import './App.scss';
import AppRoutes from './shared/routes/routes';

const baseHref = '/';
moment.locale('pt-BR');

const App = () => {
    return (
        <div className="page-container">
            <AppRoutes basename={baseHref} />
        </div>
    );
};

export default App;
