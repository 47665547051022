import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useFindAllSeriesByUserState } from 'reducer/hooks';
import Footer, { FooterType } from 'shared/footer/footer';
import useRedirectByUserSeries from 'shared/hooks/use-redirect-by-user-series';
import useUnleashAuth from 'shared/hooks/use-unsleash-auth';
import ParticipantTabMenu, { ParticipantTab } from 'shared/menu/participant-header/participant-tab-menu';
import AuthUtil from '../../services/api/authUtil';
import AnchorTabMenu, { AnchorTab } from '../menu/anchor-header/anchor-tab-menu';
import { Header, HeaderType } from '../menu/header/header';
interface BasePrivateRouteProps extends Omit<RouteProps, 'component'> {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    headerType: HeaderType;
    footerType?: FooterType;
    DISABLED?: boolean;
}

interface DefaultRouteProps extends BasePrivateRouteProps {
    headerType: HeaderType.NONE | HeaderType.SIMPLE;
}

export interface AnchorPrivateRouteProps extends BasePrivateRouteProps {
    headerType: HeaderType.ANCHOR;
    withTab?: AnchorTab;
    component: React.ComponentType<any>;
}

export interface ParticipantPrivateRouteProps extends BasePrivateRouteProps {
    headerType: HeaderType.PARTICIPANT;
    withTab?: ParticipantTab;
}

export type PrivateRouteProps = DefaultRouteProps | AnchorPrivateRouteProps | ParticipantPrivateRouteProps;

export const PrivateRoute = (props: PrivateRouteProps) => {
    const [isAuthenticated] = React.useState<boolean>(AuthUtil.isAuthenticated());

    const { status: seriesStatus } = useFindAllSeriesByUserState();

    const loading: boolean = seriesStatus === HttpRequestStatus.ONGOING;

    const { shouldBlockRouteRender } = useRedirectByUserSeries();

    const history = useHistory();

    useUnleashAuth();

    useEffect(() => {
        if (isAuthenticated) return;

        history.replace('/');
    }, [history, isAuthenticated]);

    if (props.DISABLED) {
        return <Redirect to="/page-not-found" />;
    }
    if (loading || shouldBlockRouteRender) {
        return <Loading />;
    }

    switch (props.headerType) {
        case HeaderType.ANCHOR: {
            const { component: Component, withTab, ...rest }: AnchorPrivateRouteProps = props;
            return (
                <Route
                    {...rest}
                    render={routeProps => (
                        <>
                            <Header type={props.headerType} />
                            {withTab && <AnchorTabMenu tab={withTab} />}
                            <Component {...routeProps} headerType={props.headerType} />
                            <Footer type={props.footerType ?? FooterType.SIMPLE} />
                        </>
                    )}
                />
            );
        }
        case HeaderType.PARTICIPANT: {
            const { component: Component, withTab, ...rest }: ParticipantPrivateRouteProps = props;
            return (
                <Route
                    {...rest}
                    render={routeProps => (
                        <>
                            <Header type={props.headerType} />
                            {withTab && <ParticipantTabMenu tab={withTab} />}
                            <Component {...routeProps} />
                            <Footer type={props.footerType ?? FooterType.SIMPLE} />
                        </>
                    )}
                />
            );
        }
        case HeaderType.SIMPLE:
        case HeaderType.NONE:
        default: {
            const { component: Component, ...rest } = props;
            return (
                <Route
                    {...rest}
                    render={routeProps => (
                        <>
                            <Header type={props.headerType} />
                            <Component {...routeProps} />
                            <Footer type={props.footerType ?? FooterType.SIMPLE} />
                        </>
                    )}
                />
            );
        }
    }
};

export default PrivateRoute;
