import _ from 'lodash';
import { Authority } from 'model/enums/authority';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { userAccountRequest } from 'reducer/account/user-account/actions';
import { logoutRequest } from 'reducer/authentication/actions';
import { useFindAllSeriesByUserState, useRootDispatch, useUserAccountState } from 'reducer/hooks';
import { findAllSeriesByUserRequest, findAllSeriesByUserResetState } from 'reducer/series/find-all-by-user/actions';
import AuthUtil from 'services/api/authUtil';
import { useToast } from './use-toast';

const useRedirectByUserSeries = () => {
    const [shouldBlockRouteRender, setShouldBlockRouteRender] = useState(true);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const { account, status: userAccountStatus } = useUserAccountState();
    const { userSeries, status: seriesStatus } = useFindAllSeriesByUserState();
    const { toastError } = useToast();
    const { t } = useTranslation();

    useEffect(() => {
        if (userAccountStatus !== HttpRequestStatus.NOOP) return;
        if (!AuthUtil.isAuthenticated()) return;

        dispatch(userAccountRequest());
    }, [dispatch, userAccountStatus]);

    useEffect(() => {
        if (userAccountStatus !== HttpRequestStatus.SUCCESS) return;

        dispatch(findAllSeriesByUserRequest());
    }, [dispatch, userAccountStatus]);

    useEffect(() => {
        return () => {
            dispatch(findAllSeriesByUserResetState());
        };
    }, [dispatch]);

    const isInvestor: boolean = !!account?.authorities?.some(it => it === Authority.ROLE_INVESTOR);

    const logOutActions = useCallback(() => {
        history.push('/');
        dispatch(findAllSeriesByUserResetState());
        dispatch(logoutRequest());
        toastError(t('global.access-denied-by-series'));
    }, [history, dispatch, toastError, t]);

    const handleLogOut = useCallback(() => {
        if (seriesStatus !== HttpRequestStatus.SUCCESS) return;

        logOutActions();
    }, [logOutActions, seriesStatus]);

    useEffect(() => {
        if (seriesStatus !== HttpRequestStatus.ERROR) return;

        logOutActions();
    }, [seriesStatus, logOutActions]);

    const handleBlockAccessOnInvestor = useCallback(() => {
        if (!isInvestor) {
            toastError(t('global.access-denied-by-series'));
            return;
        }

        const shouldBlockAccess: boolean = _.isEmpty(userSeries);

        if (!shouldBlockAccess) {
            setShouldBlockRouteRender(false);
            return;
        }

        handleLogOut();
    }, [isInvestor, userSeries, handleLogOut, t, toastError]);

    useEffect(() => {
        if (userAccountStatus !== HttpRequestStatus.SUCCESS) return;

        handleBlockAccessOnInvestor();
    }, [handleBlockAccessOnInvestor, userAccountStatus]);

    return { shouldBlockRouteRender };
};

export default useRedirectByUserSeries;
