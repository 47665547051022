import { MenuItem } from '@material-ui/core';
import BackButton from 'components/buttons/back-button/back-button';
import FeatureFlag from 'components/feature-flag/feature-flag';
import Loading from 'components/loading/loading';
import SelectGraphs from 'components/select-graphs/select-graphs';
import { UnleashFeatureToggleFinancialStatementMonthFlag } from 'config/unleash/unleash-feature-toggle-flag';
import { FinancialStatementMonthVisualizationTypes } from 'model/enums/financial-statement-month-visualization-types';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { FinancialStatementAvailableYearsFilter, FinancialStatementMonthFilter, FinancialStatementMonthYearlyFilter } from 'model/financial-statement-month';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailableYearsByIssuerIdRequest, getAvailableYearsByIssuerIdResetState } from 'reducer/financial-statement-month/get-available-years-by-issuer-id/actions';
import { getFinancialStatementMonthRequest, getFinancialStatementMonthResetState } from 'reducer/financial-statement-month/get-financial-statement-month/actions';
import { getYearlyFinancialStatementMonthRequest, getYearlyFinancialStatementMonthResetState } from 'reducer/financial-statement-month/get-yearly-financial-statement-month/actions';
import { useGetAvailableYearsByIssuerId, useGetFinancialStatementMonthState, useGetYearlyFinancialStatementMonthState, useRootDispatch } from 'reducer/hooks';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { currentYear } from 'shared/util/date-utils';
import FinancialStatementMonthButtonSwitch from './components/financial-statement-month-button-switch/financial-statement-month-button-switch';
import MonthlyFinancialStatementTable from './components/monthly-financial-statement-month-table/monthly-financial-statement-month-table';
import YearlyFinancialStatementMonth from './components/yearly-financial-statement-month/yearly-financial-statement-month';

import './financial-statement-month.scss';

const I18N_PREFIX = 'anchor.financial-statement-month';

const FinancialStatementMonthPage = () => {
    const { t } = useTranslation();

    const dispatch = useRootDispatch();

    const { financialStatementMonth, status } = useGetFinancialStatementMonthState();
    const { status: yearlyStatus } = useGetYearlyFinancialStatementMonthState();
    const { years, status: yearsStatus } = useGetAvailableYearsByIssuerId();

    const [selectedYear, setSelectedYear] = React.useState<number | undefined>(undefined);
    const [currentVisualization, setCurrentVisualization] = useState<FinancialStatementMonthVisualizationTypes>(FinancialStatementMonthVisualizationTypes.MONTHLY);

    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const isAvailableYearsLoading: boolean = yearsStatus === HttpRequestStatus.ONGOING;
    const isFinancialStatementLoading: boolean = isAvailableYearsLoading || status === HttpRequestStatus.ONGOING || !financialStatementMonth || yearlyStatus === HttpRequestStatus.ONGOING;

    const hasError = status === HttpRequestStatus.ERROR || yearsStatus === HttpRequestStatus.ERROR;

    const handleAvailableYearsRequest = useCallback(() => {
        if (!selectedFundAndSeries?.fundId) return;
        if (!selectedFundAndSeries?.personId) return;
        if (currentVisualization !== FinancialStatementMonthVisualizationTypes.MONTHLY) return;

        const filter: FinancialStatementAvailableYearsFilter = {
            issuerId: selectedFundAndSeries?.fundId,
            personId: selectedFundAndSeries.personId
        };

        dispatch(getAvailableYearsByIssuerIdRequest(filter));
    }, [dispatch, selectedFundAndSeries, currentVisualization]);

    useEffect(() => {
        handleAvailableYearsRequest();
    }, [handleAvailableYearsRequest]);

    useEffect(() => {
        if (!years) {
            setSelectedYear(undefined);
            return;
        }

        setSelectedYear(years?.find(it => !!it) ?? currentYear());
    }, [years, setSelectedYear]);

    const handleMonthlyDispatchAction = useCallback(() => {
        if (!selectedFundAndSeries?.fundId || !selectedYear) return;
        if (!selectedFundAndSeries?.personId) return;
        if (yearsStatus !== HttpRequestStatus.SUCCESS) return;
        if (currentVisualization !== FinancialStatementMonthVisualizationTypes.MONTHLY) return;

        const filter: FinancialStatementMonthFilter = {
            issuerId: selectedFundAndSeries?.fundId,
            year: selectedYear,
            personId: selectedFundAndSeries.personId
        };

        dispatch(getFinancialStatementMonthRequest(filter));
    }, [dispatch, selectedFundAndSeries, selectedYear, yearsStatus, currentVisualization]);

    useEffect(() => {
        handleMonthlyDispatchAction();
    }, [handleMonthlyDispatchAction]);

    const handleYearlyDispatchAction = useCallback(() => {
        if (!selectedFundAndSeries?.fundId) return;
        if (!selectedFundAndSeries?.personId) return;
        if (currentVisualization !== FinancialStatementMonthVisualizationTypes.YEARLY) return;

        const filter: FinancialStatementMonthYearlyFilter = {
            issuerId: selectedFundAndSeries.fundId,
            personId: selectedFundAndSeries.personId
        };

        dispatch(getYearlyFinancialStatementMonthRequest(filter));
    }, [dispatch, selectedFundAndSeries, currentVisualization]);

    useEffect(() => {
        handleYearlyDispatchAction();
    }, [handleYearlyDispatchAction]);

    useEffect(() => {
        return () => {
            dispatch(getFinancialStatementMonthResetState());
            dispatch(getAvailableYearsByIssuerIdResetState());
            dispatch(getYearlyFinancialStatementMonthResetState());
        };
    }, [dispatch]);

    const handleSwitch: Record<FinancialStatementMonthVisualizationTypes, () => void> = {
        MONTHLY: () => {
            setCurrentVisualization(FinancialStatementMonthVisualizationTypes.YEARLY);
        },
        YEARLY: () => {
            setCurrentVisualization(FinancialStatementMonthVisualizationTypes.MONTHLY);
        }
    };

    const handleTyAgain = useCallback(() => {
        if (yearsStatus === HttpRequestStatus.ERROR) {
            handleAvailableYearsRequest();
            return;
        }

        if (status === HttpRequestStatus.ERROR) {
            handleMonthlyDispatchAction();
            return;
        }

        handleYearlyDispatchAction();
    }, [handleMonthlyDispatchAction, handleAvailableYearsRequest, yearsStatus, handleYearlyDispatchAction, status]);

    if (hasError) {
        return (
            <div className="financial-statement-month__error-button">
                <div className="error-message">{t(`${I18N_PREFIX}.error-message`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleTyAgain} />
            </div>
        );
    }

    const isMonthlyFinancialStatementMonthVisualization: boolean = currentVisualization === FinancialStatementMonthVisualizationTypes.MONTHLY;
    const isSelectYearSelectAvailable: boolean = isMonthlyFinancialStatementMonthVisualization && !!years?.length;

    return (
        <main className="financial-statement-month">
            <section className="financial-statement-month__container">
                <div className="financial-statement-month__table">
                    {isFinancialStatementLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <header className="financial-statement-month__table--container">
                                <div className="financial-statement-month__table--title">
                                    <h3> {t(`${I18N_PREFIX}.header.title.${currentVisualization}`)} </h3>
                                    {isSelectYearSelectAvailable && (
                                        <SelectGraphs value={selectedYear ?? ''} mapperFromString={_value => _value ?? '-'} onChange={year => setSelectedYear(Number(year))} externalUpdate>
                                            {years?.map(it => (
                                                <MenuItem key={it} value={it}>
                                                    {it}
                                                </MenuItem>
                                            ))}
                                        </SelectGraphs>
                                    )}
                                </div>
                                <FeatureFlag
                                    flagName={UnleashFeatureToggleFinancialStatementMonthFlag.SWITCH}
                                    component={<FinancialStatementMonthButtonSwitch currentVisualization={currentVisualization} handleSwitch={handleSwitch[currentVisualization]} />}
                                />
                            </header>
                            {isMonthlyFinancialStatementMonthVisualization ? <MonthlyFinancialStatementTable years={years} /> : <YearlyFinancialStatementMonth />}
                        </>
                    )}
                </div>
            </section>
        </main>
    );
};

export default FinancialStatementMonthPage;
