import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import financialStatementMonthApi from 'services/api/financialStatementMonthApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { getAvailableYearsByIssuerIdError, getAvailableYearsByIssuerIdRequest, getAvailableYearsByIssuerIdSuccess } from './actions';
import { GetAvailableYearsByIssuerIdTypes } from './types';

function* handleGetAvailableYearsByIssuerIdMonth(action: ReturnType<typeof getAvailableYearsByIssuerIdRequest>) {
    try {
        const result: AxiosResponse<ReadonlyArray<number>> = yield call(financialStatementMonthApi.getAvailableYearsByIssuerId, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getAvailableYearsByIssuerIdError(mapped));
            return;
        }
        yield put(getAvailableYearsByIssuerIdSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getAvailableYearsByIssuerIdError(mapped));
    }
}

function* watchGetAvailableYearsByIssuerId() {
    yield takeEvery(GetAvailableYearsByIssuerIdTypes.GET_AVAILABLE_YEARS_BY_ISSUER_ID_REQUEST, handleGetAvailableYearsByIssuerIdMonth);
}

function* getAvailableYearsByIssuerIdSaga() {
    yield all([fork(watchGetAvailableYearsByIssuerId)]);
}

export default getAvailableYearsByIssuerIdSaga;
