import { Token } from '../../model/authentication';
import { BaseStoreState } from '../baseStoreState';

export type ApiResponse = Record<string, any>;

export enum AuthenticationActionTypes {
    LOGIN_REQUEST = '@@authentication/LOGIN_REQUEST',
    FULL_LOGIN_REQUEST = '@@authentication/FULL_LOGIN_REQUEST',
    LOGIN_SUCCESS = '@@authentication/LOGIN_SUCCESS',
    LOGIN_ERROR = '@@authentication/LOGIN_ERROR',
    LOGIN_RESET_STATE = '@@authentication/LOGIN_RESET_STATE',
    LOGOUT_REQUEST = '@@authentication/LOGOUT_REQUEST'
}

export interface AuthenticationState extends BaseStoreState {
    readonly token?: Token;
}
