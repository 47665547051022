import { AxesOptions, ChartAPI, ChartConfiguration, Data, FormatFunction, generate, PointOptions, TooltipOptions } from 'c3';
import { ChartData } from 'components/chart/chart-data';
import React from 'react';
import useUID from '../../../../shared/util/uid-utils';
import {
    DEFAULT_CHART_TRANSITION_DURATION,
    DEFAULT_COLOR_PATTERN,
    DEFAULT_SPLINE_POINT_EXPAND_RADIUS,
    DEFAULT_SPLINE_POINT_RADIUS,
    DEFAULT_SPLINE_Y_PADDING_BOTTOM,
    DEFAULT_SPLINE_Y_PADDING_TOP,
    SPLINE_CHART_EMPTY_VALUES_MAX
} from '../../chart-constants';
import './spline-chart.scss';

export interface BarChartConfiguration<T extends ChartData, K extends keyof T> {
    height: number;
    labelKey: K;
    yKeys: K[];
    yLabelFormat?: (value: number) => string;
    colorPatern?: string[];
    pointRadius?: number;
    pointFocusRadius?: number;
    textColorY?: string;
    textColorX?: string;
    dataColorFunction?: (color: string, datum: any) => string;
    customStyle?: string;
    categoryFormat?: (value: number | Date) => string | number;
    dataFormat?: FormatFunction;
    rotateXLabel?: number;
}

export interface SimpleSplineChartProps<T extends ChartData, K extends keyof T> {
    data: T[];
    className?: string;
    config: BarChartConfiguration<T, K>;
    children?: (chart: ChartAPI, keys: string[]) => React.ReactNode;
    tooltipOptions?: TooltipOptions;
}

const LABEL_FORMAT_FALLBACK_FUNCTION = () => '';

export const SimpleSplineChart = <T extends ChartData, K extends keyof T>(props: SimpleSplineChartProps<T, K>) => {
    const UID = useUID();

    const [chart, setChart] = React.useState<ChartAPI>({} as ChartAPI);
    const [config] = React.useState<BarChartConfiguration<T, K>>(props.config);
    const [initialData] = React.useState<T[]>(props.data);
    const [hasLoaded, setLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        const chartData: Data = {
            json: initialData,
            type: 'spline',
            keys: {
                x: config.labelKey.toString(),
                value: config.yKeys.map(it => it.toString())
            },
            color: config.dataColorFunction,
            labels: {
                format: config.dataFormat ?? LABEL_FORMAT_FALLBACK_FUNCTION
            }
        };
        const axesConfig: AxesOptions = {
            x: {
                type: 'category',
                tick: {
                    format: config.categoryFormat,
                    outer: false,
                    multiline: false,
                    rotate: config.rotateXLabel
                }
            },
            y: {
                tick: {
                    format: config.yLabelFormat,
                    outer: false
                },
                padding: {
                    bottom: DEFAULT_SPLINE_Y_PADDING_BOTTOM,
                    top: DEFAULT_SPLINE_Y_PADDING_TOP
                }
            }
        };
        const pointConfig: PointOptions = {
            show: !!config.pointRadius || true,
            r: config.pointRadius || DEFAULT_SPLINE_POINT_RADIUS,
            focus: {
                expand: {
                    enabled: !!config.pointFocusRadius || true,
                    r: config.pointFocusRadius || DEFAULT_SPLINE_POINT_EXPAND_RADIUS
                }
            }
        };
        const charConfiguration: ChartConfiguration = {
            data: chartData,
            bindto: `#${UID}`,
            size: { height: config.height },
            legend: { hide: true },
            transition: { duration: DEFAULT_CHART_TRANSITION_DURATION },
            grid: { y: { show: true }, x: { show: true } },
            tooltip: { ...props.tooltipOptions },
            axis: axesConfig,
            point: pointConfig,
            color: { pattern: config.colorPatern ?? DEFAULT_COLOR_PATTERN }
        };
        const _chart = generate(charConfiguration);
        setChart(_chart);
        setLoaded(true);
    }, [UID, config, initialData, props.tooltipOptions, props.config.dataColorFunction]);

    React.useEffect(() => {
        if (chart.load) {
            chart.load({
                json: props.data,
                keys: {
                    x: config.labelKey.toString(),
                    value: config.yKeys.map(it => it.toString())
                }
            });
        }

        if (chart.axis) {
            const values: number[] = props.data
                ?.flatMap(it => [...config.yKeys.map(category => it[category])])
                .filter(it => typeof it === 'number' && !isNaN(it))
                .map(it => Number(it));
            const min = Math.min(...values);
            const max = Math.max(...values);

            if (min === 0 && max === 0) {
                chart.axis.max(SPLINE_CHART_EMPTY_VALUES_MAX);
            }
        }
    }, [props.data, config, chart]);

    const classes: string = ['spline-chart', props.className].filter(it => !!it).join(' ');

    return (
        <div className={classes}>
            <div id={UID} />
            <style>
                #{UID} .c3-axis-x text {'{'}
                fill: {props.config.textColorX};{'}'}#{UID} .c3-axis-y text {'{'}
                fill: {props.config.textColorY};{'}'}#{UID} {props.config.customStyle}
            </style>
            {props.children &&
                hasLoaded &&
                props.children(
                    chart,
                    props.config.yKeys.map(it => it.toString())
                )}
        </div>
    );
};
export default SimpleSplineChart;
