import FlagProvider from '@unleash/proxy-client-react';
import { GIRO_APP_NAME, GIRO_GTSEC_UNLEASH_URL, GIRO_UNLEASH_API_TOKEN } from 'config/constants';
import _ from 'lodash';
import React from 'react';
import { EnvironmentUtils } from 'shared/util/environment-utils';
import { IConfig } from 'unleash-proxy-client';

const unleashConfig: IConfig = {
    appName: GIRO_APP_NAME,
    url: GIRO_GTSEC_UNLEASH_URL,
    clientKey: GIRO_UNLEASH_API_TOKEN,
    refreshInterval: 600, // 10 min
    environment: EnvironmentUtils.getEnvironment() === 'production' ? 'production' : 'development'
};

export const UnleashProvider = ({ children }) => {
    if (_.isNil(GIRO_GTSEC_UNLEASH_URL) || _.isNil(GIRO_UNLEASH_API_TOKEN)) {
        return children;
    }

    return <FlagProvider config={unleashConfig}>{children}</FlagProvider>;
};

export default UnleashProvider;
