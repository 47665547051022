import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { GetAllSeriesPortfolioState, GetAllSeriesPortfolioTypes } from './types';

export const initialState: GetAllSeriesPortfolioState = {
    status: HttpRequestStatus.NOOP,
    allSeries: undefined,
    error: undefined
};

const reducer: Reducer<GetAllSeriesPortfolioState> = (state = initialState, action): GetAllSeriesPortfolioState => {
    switch (action.type) {
        case GetAllSeriesPortfolioTypes.GET_ALL_SERIES_PORTFOLIO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, allSeries: undefined, error: undefined };
        }
        case GetAllSeriesPortfolioTypes.GET_ALL_SERIES_PORTFOLIO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, allSeries: action.payload, error: undefined };
        }
        case GetAllSeriesPortfolioTypes.GET_ALL_SERIES_PORTFOLIO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case GetAllSeriesPortfolioTypes.GET_ALL_SERIES_PORTFOLIO_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as getAllSeriesPortfolioReducer };
