import IdentificationUtils from 'components/inputs/validation/identification-utils';
import { InputValidation } from 'components/inputs/validation/input-validation';
import { PersonToUpdateProfile } from 'model/personAccount';
import { EMAIL_REGEX, PHONE_REGEX } from '../../../config/constants';
import { Validations, ValidationUtils } from '../../../shared/util/validation-utils';

const NAME_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => it !== undefined && it !== null && it.trim() !== '',
        errorMessage: 'profile.password.password.validation.required'
    },
    min_length: {
        predicate: it => it === undefined || it === null || it.length > 0,
        errorMessage: 'profile.password.password.validation.min-length'
    },
    max_length: {
        predicate: it => it === undefined || it === null || it.length < 255,
        errorMessage: 'profile.password.password.validation.max-length'
    }
}
export const validateName = (value: string | undefined) => InputValidation.validate(value, NAME_VALIDATION);

const IDENTIFICATION_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => it !== undefined && it !== null && it.trim() !== '',
        errorMessage: 'profile.password.password.validation.required'
    },
    min_length: {
        predicate: it => it === undefined || it === null || it.length > 0,
        errorMessage: 'profile.password.password.validation.min-length'
    },
    pattern: {
        predicate: it => !!it && IdentificationUtils.isValidCPF(it),
        errorMessage: 'profile.password.password.validation.max-length'
    }
}
export const validateIdentification = (value: string | undefined) => InputValidation.validate(value, IDENTIFICATION_VALIDATION);

const EMAIL_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data.email.validation.required'
    },
    format: {
        predicate: it => !!it && EMAIL_REGEX.test(it),
        errorMessage: 'profile.data.email.validation.format'
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'profile.data.email.validation.max-length'
    }
};
export const validateEmail = (value: string | undefined) => InputValidation.validate(value, EMAIL_VALIDATION);

const PASSWORD_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.password.password.validation.required'
    },
    min_length: {
        predicate: it => !!it?.length && it.length >= 4,
        errorMessage: 'profile.password.password.validation.min-length'
    },
    max_length: {
        predicate: it => !it?.length || it.length <= 100,
        errorMessage: 'profile.password.password.validation.max-length'
    }
}
export const validatePassword = (value: string) => ValidationUtils.validate(value, PASSWORD_VALIDATION);

export interface PasswordMatch {
    password: string,
    match: string
}

const PASSWORD_MATCH_VALIDATION: Validations<PasswordMatch> = {
    required: {
        predicate: it => !!it && !!it.match,
        errorMessage: 'profile.password.password-confirm.validation.required'
    },
    min_length: {
        predicate: it => !!it?.match?.length && it.match.length >= 4,
        errorMessage: 'profile.password.password-confirm.validation.min-length'
    },
    max_length: {
        predicate: it => !it?.match?.length || it.match.length <= 100,
        errorMessage: 'profile.password.password-confirm.validation.max-length'
    },
    match: {
        predicate: it => !!it && !!it.password && !!it.match && it.password === it.match,
        errorMessage: 'profile.password.password-confirm.validation.match'
    },
}

export const validatePasswordMatch = (value: PasswordMatch) => ValidationUtils.validate(value, PASSWORD_MATCH_VALIDATION);

const PHONE_NUMBER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data.main-contact.validation.required'
    },
    format: {
        predicate: it => !!it && PHONE_REGEX.test(it),
        errorMessage: 'profile.data.main-contact.validation.format'
    }

}
export const validatePhoneNumber = (value: string | undefined) => ValidationUtils.validate(value, PHONE_NUMBER_VALIDATION);

const PHONE_TYPE_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data.main-contact.validation.required'
    },
}
export const validatePhoneType = (value: string | undefined) => ValidationUtils.validate(value, PHONE_TYPE_VALIDATION);

const PHONE_NUMBER_SUBSCRIBE_VALIDATION: Validations<string> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.contact.validation.required'
    },
    format: {
        predicate: it => !!it && PHONE_REGEX.test(it),
        errorMessage: 'profile.contact.validation.format'
    }
}

export const validatePhoneNumberSubscribe = (value: string) => ValidationUtils.validate(value, PHONE_NUMBER_SUBSCRIBE_VALIDATION);

export const validateProfileUpdate = (value: PersonToUpdateProfile | undefined): boolean => {
    if(!value) return false;

    return (
        validateName(value.name).isValid &&
        validateEmail(value.email).isValid && 
        validatePhoneNumber(value.phone?.number).isValid &&
        validatePhoneType(value.phone?.type).isValid
    )
}

