import { Tooltip } from '@material-ui/core';
import { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import _ from 'lodash';
import { HeaderVisualizationType } from 'model/enums/header-visualization-type';
import { FundPortfolio, SeriesOfFundPortfolio } from 'model/fund';
import { FundSeriesAndInvestor } from 'model/series';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Card, Col, Row } from 'reactstrap';
import { useMyFundsState, useUserAccountState } from 'reducer/hooks';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import useFilterUtils from 'shared/hooks/use-filter-utils';
import SelectAllModal from '../../../../components/select-all-modal/select-all-modal';
import WalletDiskGraph from '../wallet-disk-graph/wallet-disk-graph';
import WalletSeriesRow from '../wallet-series-row/wallet-series-row';

const WalletPortfolioSection = () => {
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const { t } = useTranslation();
    const { portfolio } = useMyFundsState();
    const { account } = useUserAccountState();

    const { handleSelectFundAndSeries } = useFundAndSeriesContext();

    const { buildSearchParams } = useFilterUtils();

    const history = useHistory();

    const fundWithBiggestSharePercentage: FundPortfolio | undefined = useMemo(() => {
        if (!portfolio?.funds) return undefined;

        const portfolioShare = portfolio.funds.reduce((prev, cur) => (prev.sharePercentage > cur.sharePercentage ? prev : cur), {} as FundPortfolio);

        return portfolioShare;
    }, [portfolio]);

    const handleFundAndSeries = useCallback(
        (series: SeriesOfFundPortfolio) => {
            if (!fundWithBiggestSharePercentage) return;

            const fundAndSeries: FundSeriesAndInvestor = {
                fundId: fundWithBiggestSharePercentage.id,
                fundName: fundWithBiggestSharePercentage.name,
                fundType: fundWithBiggestSharePercentage.type,
                seriesId: series.id,
                seriesName: series.name,
                seriesType: series.type,
                personId: account?.personId,
                personName: account?.personName,
                headerVisualizationType: HeaderVisualizationType.INVESTOR
            };

            handleSelectFundAndSeries(fundAndSeries);
            history.push({
                pathname: '/investor/general',
                search: buildSearchParams(fundAndSeries)
            });
        },
        [fundWithBiggestSharePercentage, handleSelectFundAndSeries, history, account, buildSearchParams]
    );

    return (
        <Row>
            <Col className="wallet__items--first-row">
                {portfolio && (
                    <Card className="wallet__items--card">
                        <div className="card-container">
                            <div className="items--card-title">{t('wallet.card-title')}</div>
                            <div className="card-container--child">
                                <div className="card-container--child__title">{t('wallet.value')}</div>
                                <div className="card-container--child__value">{formatCurrency(portfolio?.totalValue, 0, true)}</div>
                            </div>
                        </div>
                        <div className="infos-container">
                            <WalletDiskGraph portfolio={portfolio} />
                            <div className="share-card-container">
                                {!_.isUndefined(fundWithBiggestSharePercentage) && (
                                    <>
                                        <Tooltip title={fundWithBiggestSharePercentage.name}>
                                            <div className="share-card-container--child">
                                                <div className="items--card-subtitle" />
                                                <div className="items--card-subtitle--container">
                                                    <div className="items--card-subtitle--name">{fundWithBiggestSharePercentage.name}</div>
                                                    <div className="items--card-subtitle--share">{fundWithBiggestSharePercentage.sharePercentage}%</div>
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <hr className="share-card-container--hr" />
                                    </>
                                )}
                            </div>
                            <div className="fund-container">
                                {!_.isUndefined(fundWithBiggestSharePercentage) && (
                                    <div className="fund-container--child">
                                        <div className="fund-container--child__fund">
                                            <div className="fund-container--child__fund-title">
                                                <Tooltip title={fundWithBiggestSharePercentage.name}>
                                                    <div className="fund-container--child__fund-title--name">{fundWithBiggestSharePercentage?.name}</div>
                                                </Tooltip>
                                                <div>{formatCurrency(fundWithBiggestSharePercentage?.totalValue, 0, true)}</div>
                                            </div>
                                            <div className="fund-container--child__fund-content">
                                                {fundWithBiggestSharePercentage?.series?.map(
                                                    (serie, index) => serie.totalAmount !== 0 && <WalletSeriesRow key={serie.id} series={serie} handleFundAndSeries={handleFundAndSeries} dataCy={`button#detail-series-${index}`} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="fund-container--all-funds">
                                    <div className="fund-container--select-all" onClick={() => setShowModal(true)} data-cy="button#see-all">
                                        <span>{t('wallet.see-all')}</span>
                                        <span className="wallet__items--icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                )}
            </Col>
            {showModal && <SelectAllModal handleClose={() => setShowModal(false)} baseUrl="/investor/general" />}
        </Row>
    );
};

export default WalletPortfolioSection;
