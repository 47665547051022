import clsx from 'clsx';
import { FinancialStatementMonthVisualizationTypes } from 'model/enums/financial-statement-month-visualization-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './income-statement-button-switch.scss';
import { IncomeStatementVisualizationTypes } from 'model/enums/income-statement-visualization-types';

const I18N_PREFIX = 'anchor.financial-statement-month.switch-button';

interface ButtonSwitchProps {
    disabled?: boolean;
    handleSwitch: () => void;
    currentVisualization: IncomeStatementVisualizationTypes;
}

const IncomeStatementButtonSwitch = (props: ButtonSwitchProps) => {
    const { disabled, handleSwitch, currentVisualization } = props;

    const { t } = useTranslation();

    return (
        <div className="income-statement-button-switch">
            <div
                className={clsx('income-statement-button-switch--content', {
                    active: currentVisualization === IncomeStatementVisualizationTypes.MONTHLY,
                    disabled
                })}
                onClick={() => {
                    if (currentVisualization === IncomeStatementVisualizationTypes.MONTHLY) return;
                    handleSwitch();
                }}
                aria-hidden="true"
            >
                {t(`${I18N_PREFIX}.${FinancialStatementMonthVisualizationTypes.MONTHLY}`)}
            </div>
            <div
                className={clsx('income-statement-button-switch--content', {
                    active: currentVisualization === IncomeStatementVisualizationTypes.YEARLY,
                    disabled
                })}
                onClick={() => {
                    if (currentVisualization === IncomeStatementVisualizationTypes.YEARLY) return;
                    handleSwitch();
                }}
                aria-hidden="true"
            >
                {t(`${I18N_PREFIX}.${IncomeStatementVisualizationTypes.YEARLY}`)}
            </div>
        </div>
    );
};

export default IncomeStatementButtonSwitch;
