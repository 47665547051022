import { AxiosResponse } from 'axios';
import { IncomeStatement, IncomeStatementAvailableYearsFilter, IncomeStatementFilter, IncomeStatementYearlyFilter, YearlyIncomeStatement } from 'model/income-statement';
import { api } from './api';

const BASE_URL_ADMIN = 'api/income-statement';

const incomeStatementApi = () => {
    const getMonthlyIncomeStatement = (filter: IncomeStatementFilter): Promise<AxiosResponse<IncomeStatement>> => {
        const _requestFilters = [filter?.issuerId ? `issuerId=${filter.issuerId}` : undefined, filter?.year ? `year=${filter.year}` : undefined, filter?.personId ? `personId=${filter.personId}` : undefined ].filter(it => !!it).join('&');

        return api.get<IncomeStatement>(`${BASE_URL_ADMIN}/monthly?${_requestFilters}`);
    };

    const getAvailableYearsByIssuerId = (filter: IncomeStatementAvailableYearsFilter): Promise<AxiosResponse<ReadonlyArray<number>>> => {
        const _requestFilters = [filter?.personId ? `personId=${filter.personId}` : undefined ];

        return api.get<ReadonlyArray<number>>(`${BASE_URL_ADMIN}/issuer/${filter.issuerId}/available-years?${_requestFilters}`);
    };

    const getYearlyIncomeStatement = (filter: IncomeStatementYearlyFilter): Promise<AxiosResponse<YearlyIncomeStatement>> => {
        const _requestFilters = [filter?.issuerId ? `issuerId=${filter.issuerId}` : undefined, filter?.personId ? `personId=${filter.personId}` : undefined].filter(it => !!it).join('&');

        return api.get<YearlyIncomeStatement>(`${BASE_URL_ADMIN}/yearly?${_requestFilters}`);
    };

    return {
        getMonthlyIncomeStatement,
        getAvailableYearsByIssuerId,
        getYearlyIncomeStatement
    };
};

export default incomeStatementApi();
