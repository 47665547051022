import { AxiosResponse } from 'axios';
import { FundSeriesAndInvestor } from 'model/series';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import fundApi from '../../../services/api/fundApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { getAllSeriesPortfolioError, getAllSeriesPortfolioRequest, getAllSeriesPortfolioSuccess } from './actions';
import { GetAllSeriesPortfolioTypes } from './types';

function* handleGetAllSeriesPortfolio(_action: ReturnType<typeof getAllSeriesPortfolioRequest>) {
    try {
        const result: AxiosResponse<FundSeriesAndInvestor[]> = yield call(fundApi.getAllSeriesPortfolio);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getAllSeriesPortfolioError(mapped));
            return;
        }
        yield put(getAllSeriesPortfolioSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getAllSeriesPortfolioError(mapped));
    }
}

function* watchGetAllSeriesPortfolio() {
    yield takeLeading(GetAllSeriesPortfolioTypes.GET_ALL_SERIES_PORTFOLIO_REQUEST, handleGetAllSeriesPortfolio);
}

function* getAllSeriesPortfolioSaga() {
    yield all([fork(watchGetAllSeriesPortfolio)]);
}

export default getAllSeriesPortfolioSaga;
