import Account from 'features/account/account';
import FundDocuments from 'features/documents/documents';
import React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from 'shared/error/page-not-found';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { HeaderType } from 'shared/menu/header/header';
import { ParticipantTab } from 'shared/menu/participant-header/participant-tab-menu';
import PrivateRoute from 'shared/routes/private-route';
import ParticipantFunds from './funds/funds';
import ParticipantDashboard from './participant-dashboard';

interface MatchParams {
    url: string;
}

type ParamsType = Omit<RouteComponentProps<MatchParams>, 'location' | 'history'>;

const ParticipantRoutes = ({ match }: ParamsType) => {
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    if (!selectedFundAndSeries?.fundId || !selectedFundAndSeries?.seriesId) {
        return <Redirect to="/investor/wallet" />;
    }

    return (
        <Switch>
            <PrivateRoute exact path={`${match.url}/general`} component={ParticipantFunds} headerType={HeaderType.PARTICIPANT} withTab={ParticipantTab.GENERAL} />
            <PrivateRoute exact path={`${match.url}/account`} component={Account} headerType={HeaderType.PARTICIPANT} withTab={ParticipantTab.ACCOUNT} />
            <PrivateRoute exact path={`${match.url}/documents`} component={FundDocuments} headerType={HeaderType.PARTICIPANT} withTab={ParticipantTab.DOCUMENTS} />
            <PrivateRoute exact path={match.url} component={ParticipantDashboard} headerType={HeaderType.PARTICIPANT} />
            <PrivateRoute component={PageNotFound} headerType={HeaderType.SIMPLE} />
        </Switch>
    );
};

export default ParticipantRoutes;
