import { Tooltip } from '@material-ui/core';
import React, { useState }  from 'react';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import './secondary-access-header.scss';
import { FundSeriesAndInvestor } from 'model/series';
import SelectAllSecondaryAccessModal from 'components/select-all-secondary-access-modal/select-all-secondary-access-modal';
import StandardOutlinedButton from 'components/standard-outlined-button/standard-outlined-button';

interface HeaderSecondaryAccessProps {
    selectedFundAndSeries: FundSeriesAndInvestor;
}

const HeaderSecondaryAccess = (props: HeaderSecondaryAccessProps) => {
    const [showModal, setShowModal] = useState(false);

    const { selectedFundAndSeries } = props;

    const { t } = useTranslation();

    return (
        <>
            <Row>
                <Col>
                    <div className="secondary-access-header--container">
                        <div className="secondary-access-header--title">
                            <Tooltip title={selectedFundAndSeries?.fundName ? selectedFundAndSeries?.fundName : ''}>
                                <span>{selectedFundAndSeries?.fundName}</span>
                            </Tooltip>
                        </div>
                        <StandardOutlinedButton label="header-tab.select-series" onClick={() => setShowModal(true)} endIcon={<ImportExportIcon />} />
                    </div>
                </Col>
            </Row>
            <h2 className="secondary-access-header--subtitle">{t('header-tab.series', { series: selectedFundAndSeries?.seriesName })}</h2>
            <h2 className="secondary-access-header--subtitle">{t('header-tab.investor', { investor: selectedFundAndSeries?.personName })}</h2>
            {showModal && <SelectAllSecondaryAccessModal handleClose={() => setShowModal(false)} />}

        </>
    );
};

export default HeaderSecondaryAccess;
