import SimpleCategoryBarChart from 'components/chart/bar-chart/category-bar-chart/simple-category-bar-chart';
import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import { CapitalBalance } from 'model/fund-account';
import moment from 'moment';
import React from 'react';
import { capitalBalanceRequest } from 'reducer/fund-account/capital-balance/actions';
import { useCapitalBalanceState, useRootDispatch } from 'reducer/hooks';

interface CaptureCardProps {
    cardTitle: string;
    graphTitles: string[];
    graphLabels: string[];
    buttons: string[];
    fundId: string | null | undefined;
}

interface CaptureGraphProps {
    graphTitle: string;
    graphLabels: string[];
    data?: CapitalBalance;
    fundId: string | null | undefined;
}

const formatValue = (value: number) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

const CaptureVolumeGraph = (props: CaptureGraphProps) => {

    const customizeUngroupedToolTip = (_id: string, _value: number, _color: unknown, x: number) => {
        const percentShareholder = ((props.data?.data[x]?.shareholderSharesPercentage ?? 0) * 100);
        const percentParticipant = ((props.data?.data[x]?.participantSharesPercentage ?? 0) * 100);
        

        return `
        <div class="tooltip--block">
        <div class="tooltip--block-two">
            <div class="tooltip--block-line">
                <span class="tooltip--block-text"><b>${moment(props.data?.data[x]?.date).format('MM/YYYY')}</b></span>
            </div>
            <hr />
            <div class="tooltip--block-line">
                <div class="tooltip--block-color" style="background-color:#64b5f7;"></div>
                <span class="tooltip--block-text">
                    ${props.graphLabels[0].replace(' ', '-')}: 
                    ${percentParticipant.toFixed(2)}%
                </span>
            </div>
            <div class="tooltip--block-line">
                <span class="tooltip--block-text-two">
                    ${formatValue(props.data?.data[x]?.participantValue ?? 0)}
                </span>
            </div>
        </div>
        <div class="tooltip--block-two">
            <div class="tooltip--block-line">
                <div class="tooltip--block-color" style="background-color:#5790be;"></div>
                <span class="tooltip--block-text">
                    ${props.graphLabels[1]}:  
                    ${percentShareholder.toFixed(2)}%
                </span>
            </div>
            <div class="tooltip--block-line">
                <span class="tooltip--block-text-two">
                    ${formatValue(props.data?.data[x]?.shareholderValue ?? 0)}
                </span>
            </div>
        </div>
    </div>
        `;
    };

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-one">{props.graphTitle}</div>
            </div>
            <UngroupedChartToolTip className="capture-volume" customize={customizeUngroupedToolTip}>
                {tooltip => (
                    <SimpleCategoryBarChart
                        className="capture-volume"
                        data={props.data?.data!}
                        config={{
                            height: 300,
                            labelKey: 'dateMonth',
                            categoriesKeys: ['participantValue', 'shareholderValue'],
                            categoryLabelFormat: formatValue,
                            barRatio: 0.7,
                            rotateLabel: -45,
                            xHeight: 25
                        }}
                        tooltipOptions={tooltip}
                    >
                        {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={key => (key === 'participantValue' ? props.graphLabels[0] : props.graphLabels[1])} />}
                    </SimpleCategoryBarChart>
                )}
            </UngroupedChartToolTip>
        </>
    );
};

export const CaptureCard = (props: CaptureCardProps) => {
    const [balance, setBalance] = React.useState(false);
    const dispatch = useRootDispatch();
    const { report } = useCapitalBalanceState();

    React.useEffect(() => {
        if (!props.fundId) return;

        dispatch(
            capitalBalanceRequest({
                id: props.fundId
            })
        );
    }, [dispatch, props.fundId]);

    React.useEffect(() => {
        report?.data?.forEach(it => {
            it.dateMonth = moment(it.date).format('MM/YYYY');
        });
    }, [dispatch, report]);

    return (
        <div className={!balance ? 'items--card-block' : ''}>
            <div className="items--card-header">
                <div className="items--card-header-title">{props.cardTitle}</div>
                <div className="items--card-header-buttons">
                    <button className={`items--card-header-buttons${!balance ? '-active' : '-inactive'}`} onClick={() => setBalance(false)}>
                        {props.buttons[0]}
                    </button>
                    {/* <button className={`items--card-header-buttons${balance ? '-active' : '-inactive'}`} onClick={() => setBalance(true)}>
                        {props.buttons[1]}
                    </button> */}
                </div>
            </div>
            {report && (
                <div className="items--card-graph">
                    <CaptureVolumeGraph fundId={props.fundId} graphTitle={props.graphTitles[0]} graphLabels={props.graphLabels} data={report} />
                </div>
            )}
        </div>
    );
};

export default CaptureCard;
