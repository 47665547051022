import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import React from 'react';

import BaseSearchInput, { BaseSearchInputProps } from 'components/inputs/search-input/base-search-input';
import './simple-search-input.scss';

interface SimpleSearchInputProps extends BaseSearchInputProps {
    onChange?: (value: string) => void;
    onClick?: () => void;
    onFocus?: () => void;
    placeholder: string;
    label: string;
    value: string | undefined;
    searchActive?: boolean;
}

export const SimpleSearchInput = (props: SimpleSearchInputProps) => {
    const { onChange, placeholder, label, value, readOnly, onClick, onFocus, searchActive, disabled, id } = props;

    return (
        <FormControl className={clsx('search-input', { dirty: value, readonly: readOnly, searchActive })} fullWidth>
            <BaseSearchInput id={id} onChange={onChange} placeholder={placeholder} onClick={onClick} onFocus={onFocus} label={label} value={value} readOnly={readOnly} disabled={disabled} />
        </FormControl>
    );
};

export default SimpleSearchInput;
