import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface ExpandableCellProps {
    className: string;
    cellTitle: string;
    isCollapsedArrow: boolean;
    shouldCollapse: boolean;
}

const IncomeStatementStickyCell = (props: ExpandableCellProps) => {
    const { className, cellTitle, isCollapsedArrow, shouldCollapse } = props;

    return (
        <td className={clsx('page-container--table__sticky', className)}>
            <div
                className={clsx('page-container--table-icon', {
                    COLLAPSED: shouldCollapse && isCollapsedArrow,
                    RETRACTED: shouldCollapse && !isCollapsedArrow
                })}
            />

            <Tooltip title={cellTitle}>
                <span>{cellTitle}</span>
            </Tooltip>
        </td>
    );
};

export default IncomeStatementStickyCell;
