import React from 'react';
import { XAxis, XAxisProps } from 'recharts';
import { ChartUtils } from 'shared/util/chart-utils';
import { DEFAULT_X_AXIS_PROPS } from './chart-components-style';

export type XAxisPropsType<T> = Omit<XAxisProps, 'dataKey'> & { dataKey?: Extract<keyof T, string> };

interface CustomizedXAxisProps<T> {
    xAxisProps: XAxisPropsType<T>;
}

const CustomizedXAxis = <T extends object>(props: CustomizedXAxisProps<T>) => {
    const { xAxisProps } = props;

    const mergedProps = ChartUtils.mergeProps<XAxisPropsType<T>>(DEFAULT_X_AXIS_PROPS, xAxisProps);

    return <XAxis {...mergedProps} />;
};

export default CustomizedXAxis;
