import { FormControl } from '@material-ui/core';
import { validatePassword } from 'features/login/validation-constants';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { UserPasswordFinishRequest } from 'model/user';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { useForgotPasswordState, useRootDispatch } from 'reducer/hooks';
import FieldBasic from '../../components/field-basic/field-basic';
import { forgotPasswordFinishRequest, forgotPasswordResetState } from 'reducer/account/forgot-password/actions';
import ModalForgotPassword from './components/modal-forgot-password';
import './forgot-password.scss';

const useForgotPasswordAwareness = () => {
    const state = useForgotPasswordState();

    const isLoading = state.status === HttpRequestStatus.ONGOING;

    return { isLoading };
};

const useShowSuccess = () => {
    const state = useForgotPasswordState();
    const [showModal, setShowModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.SUCCESS) {
            setShowModal(ps => true);
        } else {
            setShowModal(ps => false);
        }
    }, [state.status, setShowModal]);

    return { showModal };
};

interface ForgotPasswordFinishParams {
    token: string;
}

const useCleanState = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        return () => {
            dispatch(forgotPasswordResetState());
        };
    }, [dispatch]);
};

export const ForgotPasswordFinish = () => {
    const { token } = useParams<ForgotPasswordFinishParams>();
    const { t } = useTranslation();

    const history = useHistory();
    const dispatch = useRootDispatch();

    useCleanState();

    const [password, setPassword] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const { isLoading } = useForgotPasswordAwareness();
    const { showModal } = useShowSuccess();

    const haltProgression = !validatePassword(password).isValid;

    const handleClick = async () => {
        setValidation(true);
        if (haltProgression) return;

        const payload: UserPasswordFinishRequest = { newPassword: password, key: token };
        dispatch(forgotPasswordFinishRequest(payload));
    };

    const handleCloseModal = () => {
        history.replace('/');
    };

    return (
        <div style={{ marginTop: '150px' }} className="body__forgot-password">
            <Row>
                <Col style={{ margin: 'auto' }} md={{ size: 3 }}>
                    <div className="body__forgot-password--title">{t('forgot-password.finish.title')}</div>
                    <form className="form__style">
                        <FieldBasic label={t('forgot-password.finish.label')} type={'password'} validate={validatePassword} onChange={setPassword} showValidation={showValidation} />
                        <FormControl className="forgot-password--text" fullWidth variant="filled">
                        {t('forgot-password.finish.tip')}
                        </FormControl>
                    </form>
                    <div style={{ textAlign: 'center' }}>
                        <button className="forgot-password--button" onClick={handleClick} disabled={(showValidation && haltProgression) || isLoading}>
                            {t('forgot-password.buttons.submit').toUpperCase()}
                        </button>
                    </div>
                </Col>
            </Row>
            <ModalForgotPassword title={t('forgot-password.finish.success.title')} message={t('forgot-password.finish.success.message')} action={t('forgot-password.finish.success.action')} open={showModal} closeModal={handleCloseModal} />
        </div>
    );
};

export default ForgotPasswordFinish;
