import { InputValidation, Validations } from 'components/inputs/validation/input-validation';
import { PersonToUpdateProfile } from 'model/personAccount';

const ADDRESS_CITY_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.address.validation.city.required'
    },
};
export const validateAddressCity = (value: string | undefined) => InputValidation.validate(value, ADDRESS_CITY_VALIDATION);


const ADDRESS_STREET_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.address.validation.street.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 255,
        errorMessage: 'profile.address.validation.street.max-length'
    }
};

export const validateAddressStreet = (value: string | undefined) => InputValidation.validate(value, ADDRESS_STREET_VALIDATION);

const ADDRESS_NUMBER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.address.validation.number.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 255,
        errorMessage: 'profile.address.validation.number.max-length'
    }
};
export const validateAddressNumber = (value: string | undefined) => InputValidation.validate(value, ADDRESS_NUMBER_VALIDATION);

const ADDRESS_COMPLEMENT_VALIDATION: Validations<string | undefined> = {
    maxLength: {
        predicate: it => !it?.length || it.length < 255,
        errorMessage: 'profile.address.validation.complement.max-length'
    }
};
export const validateAddressComplement = (value: string | undefined) => InputValidation.validate(value, ADDRESS_COMPLEMENT_VALIDATION);

const ADDRESS_NEIGHBORHOOD_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.address.validation.neighborhood.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 255,
        errorMessage: 'profile.address.validation.neighborhood.max-length'
    }
};
export const validateAddressNeighborhood = (value: string | undefined) => InputValidation.validate(value, ADDRESS_NEIGHBORHOOD_VALIDATION);

const ADDRESS_ZIPCODE_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.address.validation.zip-code.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 255,
        errorMessage: 'profile.address.validation.zip-code.max-length'
    }
};
export const validateAddressZipCode = (value: string | undefined) => InputValidation.validate(value, ADDRESS_ZIPCODE_VALIDATION);

export const validateAddressProfileUpdate = (value: PersonToUpdateProfile | undefined): boolean => {
    if (!value) return false;
    return (
        validateAddressCity(value?.address?.city?.id).isValid &&
        validateAddressStreet(value?.address?.street).isValid &&
        validateAddressNumber(value?.address?.number).isValid &&
        validateAddressComplement(value?.address?.complement).isValid &&
        validateAddressNeighborhood(value.address?.neighborhood).isValid &&
        validateAddressZipCode(value?.address?.zipCode).isValid
    );
}