const isDev = (): boolean => process.env.NODE_ENV === 'development';

const logInfo = (message?: any, ...optionalParams: any[]): void => {
    if (!isDev()) return;
    console.log(message, ...optionalParams);
};

const debugInfo = (message?: any, ...optionalParams: any[]): void => {
    if (!isDev()) return;
    console.debug(message, ...optionalParams);
};

const LoggingUtils = {
    logInfo,
    debugInfo
};

export default LoggingUtils;
