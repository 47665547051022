import { FundSeriesAndInvestor } from 'model/series';
import React, { createContext, FunctionComponent, useContext } from 'react';
import useFilterUtils from 'shared/hooks/use-filter-utils';

interface FundAndSeriesSelectionContextProps {
    selectedFundAndSeries: FundSeriesAndInvestor | undefined;
    handleSelectFundAndSeries: (fundAndSeries: FundSeriesAndInvestor) => void;
}

const FundAndSeriesSelectionContext = createContext<FundAndSeriesSelectionContextProps>({} as FundAndSeriesSelectionContextProps);

const FundAndSeriesSelectionProvider: FunctionComponent = props => {
    const { parsedQuery, setUrlFilter } = useFilterUtils();

    const handleSelectFundAndSeries = (fundAndSeries: FundSeriesAndInvestor) => {
        setUrlFilter(fundAndSeries);
    };

    const value: FundAndSeriesSelectionContextProps = {
        selectedFundAndSeries: parsedQuery,
        handleSelectFundAndSeries
    };

    return <FundAndSeriesSelectionContext.Provider value={value}>{props.children}</FundAndSeriesSelectionContext.Provider>;
};

export const useFundAndSeriesContext = () => useContext(FundAndSeriesSelectionContext);

export const withFundAndSeriesContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <FundAndSeriesSelectionProvider>
            <WrapperComponent {...props} />
        </FundAndSeriesSelectionProvider>
    );
};
