import Modal from '@material-ui/core/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import { useAuthenticationState } from '../../../../reducer/hooks';
import './invite-sucess.scss';

export interface InviteSuccessProps {
    open: boolean;
    onStartUse: () => void;
}

export const InviteSuccess = (props: InviteSuccessProps) => {
    const { t } = useTranslation();
    const authenticationState = useAuthenticationState();

    const handleStartClick = () => {
        if (authenticationState.status !== HttpRequestStatus.ONGOING) {
            props.onStartUse();
        }
    };

    return (
        <div className="container">
            <div className="body__invite-sucess">
                <Modal open={props.open} disableBackdropClick disableEscapeKeyDown>
                    <div id="ref-base-component">
                        <Row style={{ background: 'white', height: '100vh' }} className="align-items-center">
                            <Col md={{ size: 6, offset: 3 }}>
                                <div className="body__invite-sucess--itens">
                                    <div className="body__invite-sucess--image" />
                                    <div style={{ marginBottom: '30px' }} />
                                    <div className="body__invite-sucess--title-start">
                                        {t('accept-invite.invite-sucess.title.start')}
                                        <span> {t('accept-invite.invite-sucess.title.middle')} </span>
                                    </div>
                                    <div style={{ marginBottom: '30px' }} />
                                    <div className="body__invite-sucess--title-end">{t('accept-invite.invite-sucess.end')}</div>
                                    <div style={{ marginBottom: '70px' }} />
                                    <div>
                                        <button
                                            className="button--start-use"
                                            onClick={handleStartClick}
                                            disabled={authenticationState.status === HttpRequestStatus.ONGOING}
                                        >
                                            <>{t('accept-invite.buttons.start-use').toUpperCase()}</>
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default InviteSuccess;
