import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { shareholderAccountHistoryReducer } from './history/reducer';
import shareholderAccountHistorySaga from './history/sagas';
import { ShareholderAccountHistoryState } from './history/types';

export interface ShareholderAccountState {
    readonly shareholderAccountHistory: ShareholderAccountHistoryState;
}

export const ShareholderAccountReducer: ReducersMapObject<ShareholderAccountState, AnyAction> = {
    shareholderAccountHistory: shareholderAccountHistoryReducer
};

export function* shareholderAccountSagas() {
    yield all([fork(shareholderAccountHistorySaga)]);
}
