import { MenuItem } from '@material-ui/core';
import BackButton from 'components/buttons/back-button/back-button';
import Loading from 'components/loading/loading';
import SelectGraphs from 'components/select-graphs/select-graphs';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { IncomeStatementAvailableYearsFilter, IncomeStatementFilter, IncomeStatementYearlyFilter } from 'model/income-statement';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetIncomeStatementStateAvailableYears, useGetMonthlyIncomeStatementState, useGetYearlyIncomeStatementState, useRootDispatch } from 'reducer/hooks';
import { getIncomeStatementAvailableYearsRequest, getIncomeStatementAvailableYearsResetState } from 'reducer/income-statement/get-available-years-by-issuer-id/actions';
import { getMonthlyIncomeStatementRequest, getMonthlyIncomeStatementResetState } from 'reducer/income-statement/get-monthly-income-statement/actions';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { currentYear } from 'shared/util/date-utils';

import { IncomeStatementVisualizationTypes } from 'model/enums/income-statement-visualization-types';
import IncomeStatementButtonSwitch from './components/income-statement-button-switch/income-statement-button-switch';
import MonthlyIncomeStatementTable from './components/monthly/income-statement-month-table';
import './income-statement.scss';
import YearlyIncomeStatementTable from './components/yearly/yearly-income-statement-month-table';
import { UnleashFeatureToggleIncomeStatementFlag } from 'config/unleash/unleash-feature-toggle-flag';
import FeatureFlag from 'components/feature-flag/feature-flag';
import { getYearlyIncomeStatementRequest, getYearlyIncomeStatementResetState } from 'reducer/income-statement/get-yearly-income-statement/actions';

const I18N_PREFIX = 'anchor.income-statement-month';

const IncomeStatement = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const { status, incomeStatement } = useGetMonthlyIncomeStatementState();
    const { status: yearlyIncomeStatementStatus } = useGetYearlyIncomeStatementState();

    const { status: yearsStatus, years } = useGetIncomeStatementStateAvailableYears();
    const [currentVisualization, setCurrentVisualization] = useState<IncomeStatementVisualizationTypes>(IncomeStatementVisualizationTypes.MONTHLY);

    const [selectedYear, setSelectedYear] = React.useState<number | undefined>(undefined);

    const isMonthlyIncomeStatementVisualization: boolean = currentVisualization === IncomeStatementVisualizationTypes.MONTHLY;
    const isSelectedYearAvailable: boolean = isMonthlyIncomeStatementVisualization && !!years?.length;

    const hasError = status === HttpRequestStatus.ERROR || yearsStatus === HttpRequestStatus.ERROR || yearlyIncomeStatementStatus === HttpRequestStatus.ERROR;

    const handleAvailableYearsRequest = useCallback(() => {
        if (!selectedFundAndSeries?.fundId) return;
        if (!isMonthlyIncomeStatementVisualization) return;

        const filter: IncomeStatementAvailableYearsFilter = {
            issuerId: selectedFundAndSeries.fundId,
            personId: selectedFundAndSeries.personId

        }

        dispatch(getIncomeStatementAvailableYearsRequest(filter));
    }, [dispatch, selectedFundAndSeries, isMonthlyIncomeStatementVisualization]);

    useEffect(() => {
        handleAvailableYearsRequest();
    }, [handleAvailableYearsRequest]);

    useEffect(() => {
        if (!years) {
            setSelectedYear(undefined);
            return;
        }

        setSelectedYear(years?.find(it => !!it) ?? currentYear());
    }, [years, setSelectedYear]);

    const handleMonthlyDispatchAction = useCallback(() => {
        if (!selectedFundAndSeries?.fundId || !selectedYear) return;
        if (!selectedFundAndSeries?.personId) return;
        if (yearsStatus !== HttpRequestStatus.SUCCESS) return;
        if (currentVisualization !== IncomeStatementVisualizationTypes.MONTHLY) return;

        const filter: IncomeStatementFilter = {
            issuerId: selectedFundAndSeries.fundId,
            personId: selectedFundAndSeries.personId,
            year: selectedYear
        };

        dispatch(getMonthlyIncomeStatementRequest(filter));
    }, [dispatch, selectedFundAndSeries, selectedYear, yearsStatus, currentVisualization]);

    useEffect(() => {
        handleMonthlyDispatchAction();
    }, [handleMonthlyDispatchAction]);

    const handleYearlyDispatchAction = useCallback(() => {
        if (!selectedFundAndSeries?.fundId) return;
        if (!selectedFundAndSeries?.personId) return;
        if (currentVisualization !== IncomeStatementVisualizationTypes.YEARLY) return;

        const filter: IncomeStatementYearlyFilter = {
            issuerId: selectedFundAndSeries.fundId,
            personId: selectedFundAndSeries.personId
        };

        dispatch(getYearlyIncomeStatementRequest(filter));
    }, [dispatch, selectedFundAndSeries, currentVisualization]);

    useEffect(() => {
        handleYearlyDispatchAction();
    }, [handleYearlyDispatchAction]);

    const resetIncomeStatementStates = useCallback(() => {
        dispatch(getIncomeStatementAvailableYearsResetState());
        dispatch(getMonthlyIncomeStatementResetState());
        dispatch(getYearlyIncomeStatementResetState());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            resetIncomeStatementStates();
        };
    }, [resetIncomeStatementStates]);

    const handleSwitch: Record<IncomeStatementVisualizationTypes, () => void> = {
        MONTHLY: () => {
            setCurrentVisualization(IncomeStatementVisualizationTypes.YEARLY);
        },
        YEARLY: () => {
            setCurrentVisualization(IncomeStatementVisualizationTypes.MONTHLY);
        }
    };

    const handleTryAgain = useCallback(() => {
        if (yearsStatus === HttpRequestStatus.ERROR) {
            handleAvailableYearsRequest();
            return;
        }

        if (status === HttpRequestStatus.ERROR) {
            handleMonthlyDispatchAction();
            return;
        }

        handleYearlyDispatchAction();
    }, [handleMonthlyDispatchAction, handleAvailableYearsRequest, yearsStatus, handleYearlyDispatchAction, status]);

    const isLoading: boolean = yearsStatus === HttpRequestStatus.ONGOING || status === HttpRequestStatus.ONGOING || 
                                    !incomeStatement || yearlyIncomeStatementStatus === HttpRequestStatus.ONGOING;

    if (hasError) {
        return (
            <div className="income-statement__error-button">
                <div className="error-message">{t(`${I18N_PREFIX}.error-message`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    return (
        <main className="income-statement">
            <section className="income-statement__container">
                <div className="income-statement__table">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <header className="income-statement__table--container">
                                <div className="income-statement__table--title">
                                    <h3> {t(`${I18N_PREFIX}.header.title.${currentVisualization}`)} </h3>
                                    {isSelectedYearAvailable && isMonthlyIncomeStatementVisualization && (
                                        <SelectGraphs value={selectedYear ?? ''} mapperFromString={_value => _value ?? '-'} onChange={year => setSelectedYear(Number(year))} externalUpdate>
                                            {years?.map(it => (
                                                <MenuItem key={it} value={it}>
                                                    {it}
                                                </MenuItem>
                                            ))}
                                        </SelectGraphs>
                                    )}
                                </div>
                                <FeatureFlag
                                    flagName={UnleashFeatureToggleIncomeStatementFlag.SWITCH}
                                    component={<IncomeStatementButtonSwitch currentVisualization={currentVisualization} handleSwitch={handleSwitch[currentVisualization]} />}
                                />
                            </header>
                            {isMonthlyIncomeStatementVisualization ? <MonthlyIncomeStatementTable years={years} /> : <YearlyIncomeStatementTable />}
                        </>
                    )}
                </div>
            </section>
        </main>
    );
};

export default IncomeStatement;
