import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonNext from '../../../../components/button-next/button-next';
import Card from '../../../../components/card/card';
import FieldBasic from '../../../../components/field-basic/field-basic';
import FieldPassword from '../../../../components/field-password/field-password';
import PasswordStrength from '../../../../components/password-strength/password-strength';
import { ShareholderInviteToConfirm } from '../../../../model/shareholder-invite';
import StringUtils from '../../../../shared/util/string-utils';
import { useSteps } from '../invite-steps/invite-steps-context';
import { PasswordMatch, validatePassword, validatePasswordMatch } from '../validation/validation-constants';
import './password.scss';

export interface PasswordAndConfirmProps {
    username: string;
    password: string;
    confirm: string;
    onChange: (value: Partial<ShareholderInviteToConfirm>) => void;
}

const mapPasswordMatch = (p: string, m: string): PasswordMatch => ({ password: p, match: m });

export const PasswordAndConfirm = (props: PasswordAndConfirmProps): JSX.Element => {
    const { t } = useTranslation();
    const steps = useSteps();
    const [password, setPassword] = React.useState<string>(props.password);
    const [confirm, setConfirm] = React.useState<string>(props.confirm);
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const updateInvite = (_password: string, _confirm: string) => props.onChange({ password: _password, confirm: _confirm });

    const haltProgression = showValidation && (!validatePassword(password).isValid || !validatePasswordMatch(mapPasswordMatch(password, confirm)).isValid);

    const handleNext = () => {
        setValidation(true);
        if (!validatePassword(password).isValid || !validatePasswordMatch(mapPasswordMatch(password, confirm)).isValid) {
            return;
        }
        updateInvite(password, confirm);
        steps.toNext();
    };

    return (
        <div className="body__password">
            <Row style={{ width: '100%' }}>
                <Col md="10" style={{ margin: 'auto' }}>
                    <div className="body__password--title">
                        {t('accept-invite.password.title')}
                        <span>{t('accept-invite.password.title-constrast')}</span>
                    </div>
                </Col>
            </Row>
            <form className="form__style">
                <Row>
                    <div className="body__password--components" style={{ justifyContent: 'center', marginBottom: '15px' }}>
                        <Col md="6" offset="3">
                            <FieldBasic label={t('accept-invite.password.email')} readOnly value={props.username} autoComplete="username" />
                        </Col>
                    </div>
                </Row>
                <Row>
                    <div className="body__password--components">
                        <Col md="6" offset="3">
                            <FieldPassword
                                value={password}
                                label={t('accept-invite.password.password-field.label')}
                                onChange={setPassword}
                                validate={validatePassword}
                                autoComplete="new-password"
                                helpText={t('accept-invite.password.password-field.description')}
                                showValidation={showValidation}
                            />
                            {StringUtils.isNotBlank(password) && (
                                <FieldPassword
                                    value={confirm}
                                    label={t('accept-invite.password.confirm-field.label')}
                                    onChange={setConfirm}
                                    validate={it => validatePasswordMatch(mapPasswordMatch(password, it))}
                                    autoComplete="new-password"
                                    showValidation={showValidation}
                                />
                            )}
                        </Col>
                        <Col md="3" className="password__strength--desktop">
                            <Card>
                                <div className="body__password-strength">{t('accept-invite.password.strength').toUpperCase()}</div>
                                <PasswordStrength password={password ?? ''} />
                            </Card>
                        </Col>
                    </div>
                </Row>
            </form>
            <Row>
                <div className="body__password--buttons">
                    <Col>
                        <div className="body__password--button-finish">
                            <ButtonNext onClick={handleNext} disabled={haltProgression} />
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default PasswordAndConfirm;
