import React from 'react';
import NavBar from '../navbar/navbar';
import SimpleHeader from './simple-header';

export enum HeaderType {
    NONE = 'NONE',
    SIMPLE = 'SIMPLE',
    ANCHOR = 'ANCHOR',
    PARTICIPANT = 'PARTICIPANT'
}

export interface HeaderProps {
    type: HeaderType;
    fundId?: string | undefined;
}

export const Header = (props: HeaderProps) => {
    switch (props.type) {
        case HeaderType.ANCHOR:
        case HeaderType.PARTICIPANT:
            return <NavBar />;
        case HeaderType.SIMPLE:
            return <SimpleHeader />;
        case HeaderType.NONE:
        default:
            return <></>;
    }
};
