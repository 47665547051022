import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { FundAccountrReceivablesVolumeState, FundAccountrReceivablesVolumeActionTypes } from './types';

export const initialState: FundAccountrReceivablesVolumeState = {
    status: HttpRequestStatus.NOOP,
    receivables: undefined,
    error: undefined
};

const reducer: Reducer<FundAccountrReceivablesVolumeState> = (state = initialState, action): FundAccountrReceivablesVolumeState => {
    switch (action.type) {
        case FundAccountrReceivablesVolumeActionTypes.RECEIVABLES_VOLUME_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, receivables: undefined, error: undefined };
        }
        case FundAccountrReceivablesVolumeActionTypes.RECEIVABLES_VOLUME_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, receivables: action.payload, error: undefined };
        }
        case FundAccountrReceivablesVolumeActionTypes.RECEIVABLES_VOLUME_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case FundAccountrReceivablesVolumeActionTypes.RECEIVABLES_VOLUME_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as fundAccountReceivablesVolumeReducer };
