import H from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../components/loading/loading';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import { termsAcceptanceResetState } from '../../../../reducer/account/terms-acceptance/actions';
import { userAccountResetState, userAccountWithTermsRequest } from '../../../../reducer/account/user-account/actions';
import { loginResetState } from '../../../../reducer/authentication/actions';
import { useRootDispatch, useTermsAcceptanceState, useUserAccountState } from '../../../../reducer/hooks';
import AuthUtil from '../../../../services/api/authUtil';
import './auto-login.scss';

const useFetchAccountWithTerms = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(userAccountWithTermsRequest());
    }, [dispatch]);
};

const useHandleExceptions = (history: H.History) => {
    const [isAuthenticated] = React.useState<boolean>(AuthUtil.isAuthenticated());

    const dispatch = useRootDispatch();
    const accountState = useUserAccountState();
    const termsState = useTermsAcceptanceState();

    React.useEffect(() => {
        if (accountState.status === HttpRequestStatus.ERROR || termsState.status === HttpRequestStatus.ERROR) {
            AuthUtil.removeToken();
            dispatch(loginResetState());
            dispatch(userAccountResetState());
            dispatch(termsAcceptanceResetState());
            history.replace('/');
        }
    }, [dispatch, history, accountState, termsState]);

    React.useEffect(() => {
        if (isAuthenticated) return;

        history.replace('/');
    }, [history, isAuthenticated]);
};

export const AutoLogin = () => {
    const history: H.History = useHistory();

    useFetchAccountWithTerms();
    useHandleExceptions(history);

    return (
        <div className="auto-login__loading">
            <Loading />
        </div>
    );
};

export default AutoLogin;
