import { FundSeriesAndInvestor } from 'model/series';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum GetAllSeriesPortfolioTypes {
    GET_ALL_SERIES_PORTFOLIO_REQUEST = '@@funds/GET_ALL_SERIES_PORTFOLIO_REQUEST',
    GET_ALL_SERIES_PORTFOLIO_SUCCESS = '@@funds/GET_ALL_SERIES_PORTFOLIO_SUCCESS',
    GET_ALL_SERIES_PORTFOLIO_ERROR = '@@funds/GET_ALL_SERIES_PORTFOLIO_ERROR',
    GET_ALL_SERIES_PORTFOLIO_RESET_STATE = '@@funds/GET_ALLGET_ALL_SERIES_PORTFOLIO_RESET_STATE'
}

export interface GetAllSeriesPortfolioState extends BaseStoreState {
    readonly allSeries?: FundSeriesAndInvestor[];
}
