import { ShareholderInvite } from '../../../model/shareholder-invite';
import { BaseStoreState } from '../../baseStoreState';

export enum ShareholderInviteToConfirmActionTypes {
    SHAREHOLDER_INVITE_TO_CONFIRM_REQUEST = '@@shareholderInviteToConfirm/SHAREHOLDER_INVITE_TO_CONFIRM_REQUEST',
    SHAREHOLDER_INVITE_TO_CONFIRM_SUCCESS = '@@shareholderInviteToConfirm/SHAREHOLDER_INVITE_TO_CONFIRM_SUCCESS',
    SHAREHOLDER_INVITE_TO_CONFIRM_ERROR = '@@shareholderInviteToConfirm/SHAREHOLDER_INVITE_TO_CONFIRM_ERROR',
    SHAREHOLDER_INVITE_TO_CONFIRM_RESET_STATE = '@@shareholderInviteToConfirm/SHAREHOLDER_INVITE_TO_CONFIRM_RESET_STATE'
}

export interface ShareholderInviteToConfirmState extends BaseStoreState {
    readonly invite?: ShareholderInvite;
}
