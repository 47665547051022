import { ApmRoute } from '@elastic/apm-rum-react';
import CloseButton from 'components/button-close/close-button';
import WrappedRoutes from 'features';
import AcceptInvite from 'features/accept-invite/accept-invite';
import AcceptLgpdTerms from 'features/accept-lgpd-terms/accept-lgpd-terms';
import AcceptTermsOfUse from 'features/accept-terms-of-use/accept-terms-of-use';
import ChartSamples from 'features/chart-samples/chart-samples';
import ForgotPasswordInit from 'features/forgot-password/forgot-password';
import ForgotPasswordFinish from 'features/forgot-password/forgot-password-finish';
import Login from 'features/login/login';
import Profile from 'features/profile/profile';
import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FooterType } from 'shared/footer/footer';
import { GIRO_API_TERMS_WEBVIEW, GIRO_TECH_URL } from '../../config/constants';
import ErrorHandler from '../error/error-handler';
import PageNotFound from '../error/page-not-found';
import { HeaderType } from '../menu/header/header';
import ExternalRoute from './external-route';
import PrivateRoute from './private-route';
import PublicRoute from './public-route';

export interface AppRoutesProps {
    basename: string;
}

const AppRoutes = (props: AppRoutesProps) => {
    return (
        <BrowserRouter basename={props.basename}>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} className="toastify-container" toastClassName="toastify-toast" hideProgressBar pauseOnHover closeButton={<CloseButton />} />
            <ErrorHandler />
            <Switch>
                <PublicRoute exact path="/" component={Login} headerType={HeaderType.NONE} footerType={FooterType.NONE} />

                <PublicRoute exact path="/forgot-password" component={ForgotPasswordInit} />
                <PublicRoute exact path="/forgot-password/:token/finish" component={ForgotPasswordFinish} />
                <PublicRoute path="/accept-invite/:token" component={AcceptInvite} />

                <ExternalRoute exact path="/giro.tech" externalUrl={GIRO_TECH_URL} />
                <ExternalRoute exact path="/full-terms" externalUrl={GIRO_API_TERMS_WEBVIEW} />

                <PrivateRoute exact path="/accept-terms-of-use" component={AcceptTermsOfUse} headerType={HeaderType.SIMPLE} />
                <PrivateRoute exact path="/investor/profile" component={Profile} headerType={HeaderType.ANCHOR} />
                <PrivateRoute exact path="/accept-lgpd-terms" component={AcceptLgpdTerms} headerType={HeaderType.SIMPLE} />

                <ApmRoute path="/investor" component={WrappedRoutes} />

                {/* PROVISIONAL */}
                <PublicRoute exact path="/chart-samples" component={ChartSamples} headerType={HeaderType.NONE} footerType={FooterType.NONE} />
                <PublicRoute component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRoutes;
