import { DATE_FNS_DAY_ONLY_DATE_FORMAT, DATE_FNS_FULL_MONTH_NAME, DATE_FNS_FULL_YEAR, DATE_FNS_LONG_FULL_DATE_FORMAT, DATE_FNS_SHORT_FULL_DATE_FORMAT, DATE_FNS_SHORT_MONTH_NAME, DATE_FNS_DAY_AND_SHORT_MONTH_NAME } from 'config/constants';
import React from 'react';
import FormatterUtils from '../formatter-utils';

export type LocalDateValue = Date | string | undefined | null;

export enum LocalDateFormatType {
    SHORT_FULL_DATE = 'SHORT_FULL_DATE',
    LONG_FULL_DATE = 'LONG_FULL_DATE',
    DAY_ONLY = 'DAY_ONLY',
    SHORT_MONTH_NAME = 'SHORT_MONTH_NAME',
    FULL_MONTH_NAME = 'FULL_MONTH_NAME',
    FULL_YEAR = 'FULL_YEAR',
    DAY_AND_SHORT_MONTH_NAME = 'DATE_AND_SHORT_MONTH_NAME'
}

export interface LocalDateFormatterProps {
    value: LocalDateValue;
    type: LocalDateFormatType;
}

const TYPE_TO_FORMAT_RECORD: Record<LocalDateFormatType, string> = {
    LONG_FULL_DATE: DATE_FNS_LONG_FULL_DATE_FORMAT,
    SHORT_FULL_DATE: DATE_FNS_SHORT_FULL_DATE_FORMAT,
    DAY_ONLY: DATE_FNS_DAY_ONLY_DATE_FORMAT,
    SHORT_MONTH_NAME: DATE_FNS_SHORT_MONTH_NAME,
    FULL_MONTH_NAME: DATE_FNS_FULL_MONTH_NAME,
    FULL_YEAR: DATE_FNS_FULL_YEAR,
    DATE_AND_SHORT_MONTH_NAME: DATE_FNS_DAY_AND_SHORT_MONTH_NAME
};

export const LocalDateFormatter = (props: LocalDateFormatterProps) => {
    const { value, type } = props;
    const parsed: Date | undefined = FormatterUtils.parseDateFromStringISO(value);
    if (parsed === null || parsed === undefined) return <></>;

    return <>{FormatterUtils.formatDate(parsed, TYPE_TO_FORMAT_RECORD[type])}</>;
};

export const formatLocalDate = (value: LocalDateValue, type: LocalDateFormatType): string => FormatterUtils.formatDate(FormatterUtils.parseDateFromStringISO(value), TYPE_TO_FORMAT_RECORD[type]);

export const formatShortFullDate = (value: LocalDateValue): string => FormatterUtils.formatDate(FormatterUtils.parseDateFromStringISO(value), DATE_FNS_SHORT_FULL_DATE_FORMAT);

export default LocalDateFormatter;
