import FeatureFlag from 'components/feature-flag/feature-flag';
import { UnleashFeatureTogglePerformanceFlag } from 'config/unleash/unleash-feature-toggle-flag';
import React from 'react';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import CashAvailableBalanceCard from 'shared/debenture-cards/cash-available-balance/cash-available-balance-card';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import EmptyFundWarning from '../../../components/empty-fund-warning/empty-fund-warning';
import AcquiredVolumeCard from '../../../shared/debenture-cards/acquired-volume/acquired-volume-card';

import './anchor-performance.scss';

const AnchorPerformance = () => {
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    if (!selectedFundAndSeries?.fundId) {
        return (
            <div className="anchor-performance__items">
                <EmptyFundWarning />
            </div>
        );
    }

    const AcquiredVolumeGraph: JSX.Element = (
        <Card className="anchor-performance__items--card">
            <AcquiredVolumeCard fundId={selectedFundAndSeries.fundId} />
        </Card>
    );

    const CashAvailableBalanceGraph: JSX.Element = (
        <Card className="anchor-performance__items--card">
            <CashAvailableBalanceCard fundId={selectedFundAndSeries.fundId} />
        </Card>
    );


    return (
        <div className="anchor-performance__items">
            <Container>
                <Row style={{ paddingBottom: '70px' }}>
                    <Col md="6">
                        <FeatureFlag flagName={UnleashFeatureTogglePerformanceFlag.ACQUIRED_VOLUME_GRAPH} component={AcquiredVolumeGraph} />
                    </Col>
                    <Col md="6">
                        <FeatureFlag flagName={UnleashFeatureTogglePerformanceFlag.CASH_AVAILABLE_BALANCE_GRAPH} component={CashAvailableBalanceGraph} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default AnchorPerformance;
