import Grid from '@material-ui/core/Grid';
import IdentificationFormatter from 'components/fomatter/identification/identification-formatter';
import PhoneFormatter from 'components/fomatter/phone/phone-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { PersonType } from 'model/enums/person-type';
import { WhatsAppStatus } from 'model/enums/whatsapp-status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePersonProfileState, useRootDispatch } from 'reducer/hooks';
import { personProfileRequest } from 'reducer/shareholder/profile/actions';
import { useProfileContext } from '../../profile-context';
import ToggleSwitch from '../toggle-switch/toggle-switch';
import './profile-display.scss';

const usePersonProfileRequest = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(personProfileRequest());
    }, [dispatch]);
};

export const ProfileDisplay = () => {
    const { t } = useTranslation();
    const { user } = useProfileContext();

    usePersonProfileRequest();
    const { profile, status } = usePersonProfileState();
    const isLoading = status !== HttpRequestStatus.ERROR && status !== HttpRequestStatus.SUCCESS;

    return (
        <div className="account-profile__items">
            <div className="profile-display__items">
                <div className="container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <Grid id="data">
                                <div className="mouse-hover-behavior">
                                    <Grid item className="profile-display__items--title">
                                        {t('profile.data.title')}
                                    </Grid>
                                    <Grid item className="profile-display__items--text">
                                        <span className="profile-display__items--text-first">{t('profile.data.name')}</span>
                                        <span className="profile-display__items--text-second">{profile?.name ?? '-'}</span>
                                    </Grid>
                                    <Grid item className="profile-display__items--text">
                                        <span className="profile-display__items--text-first">{t('profile.data.cpf')}</span>
                                        <span className="profile-display__items--text-second">
                                            {' '}
                                            <IdentificationFormatter value={profile?.identification ?? '-'} type={PersonType.INDIVIDUAL} />
                                        </span>
                                    </Grid>
                                    <Grid item className="profile-display__items--text">
                                        <span className="profile-display__items--text-first">{t('profile.data.email.label')}</span>
                                        <span className="profile-display__items--text-second">{profile?.email ?? '-'}</span>
                                    </Grid>
                                    <Grid item className="profile-display__items--text">
                                        <span className="profile-display__items--text-first">{t('profile.data.main-contact.label')}</span>
                                        <span className="profile-display__items--text-second">
                                            <PhoneFormatter value={profile?.phone?.number} />
                                        </span>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid id="address">
                                <div className="mouse-hover-behavior">
                                    <Grid item className="personal-data__items--title">
                                        {t('profile.address.title')}
                                    </Grid>
                                    <div className="profile-display__items--text-two">
                                        <Grid item xs={7} className="profile-display__items--text">
                                            <span className="profile-display__items--text-two-first">{t('profile.address.street')}</span>
                                            <span className="profile-display__items--text-two-second">{profile?.address?.street ?? '-'}</span>
                                        </Grid>
                                        <Grid item xs={3} className="profile-display__items--text">
                                            <span className="profile-display__items--text-two-first">{t('profile.address.number')}</span>
                                            <span className="profile-display__items--text-two-second">{profile?.address?.number ?? '-'}</span>
                                        </Grid>
                                        <Grid item xs={3} className="profile-display__items--text">
                                            <span className="profile-display__items--text-two-first">{t('profile.address.complement')}</span>
                                            <span className="profile-display__items--text-two-second">{profile?.address?.complement ?? '-'}</span>
                                        </Grid>
                                    </div>
                                    <div className="profile-display__items--text-two">
                                        <Grid item xs={7} className="profile-display__items--text">
                                            <span className="profile-display__items--text-two-first">{t('profile.address.city')}</span>
                                            <span className="profile-display__items--text-two-second">{profile?.address?.city?.name ?? '-'}</span>
                                        </Grid>
                                        <Grid item xs={3} className="profile-display__items--text">
                                            <span className="profile-display__items--text-two-first">{t('profile.address.state')}</span>
                                            <span className="profile-display__items--text-two-second">{profile?.address?.city?.stateName ?? '-'}</span>
                                        </Grid>
                                        <Grid item xs={3} className="profile-display__items--text">
                                            <span className="profile-display__items--text-two-first">{t('profile.address.zip-code')}</span>
                                            <span className="profile-display__items--text-two-second">{profile?.address?.zipCode ?? '-'}</span>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item className="personal-data__items--title">
                                {t('profile.data-bank.title')}
                            </Grid>
                            {profile?.accounts.length !== 0 ? (
                                profile?.accounts.map(({ bank, agencyNumber, agencyDigit, accountNumber, accountDigit, type, mainAccount }) => (
                                    <>
                                        <Grid id="data-bank">
                                            <div className="mouse-hover-behavior">
                                                <Grid item>
                                                    <span className="profile-display__items--subtitle">{mainAccount ? t('profile.data-bank.main-account') : ''}</span>
                                                </Grid>
                                                <Grid item className="profile-display__items--text">
                                                    <span className="profile-display__items--text-first">{t('profile.data-bank.bank')}</span>
                                                    <span className="profile-display__items--text-second">{bank?.name ?? '-'}</span>
                                                </Grid>
                                                <div className="profile-display__items--text-two">
                                                    <Grid item xs={4} className="profile-display__items--text">
                                                        <span className="profile-display__items--text-two-first">{t('profile.data-bank.agency')}</span>
                                                        <span className="profile-display__items--text-two-second">{agencyNumber ?? '-'}</span>
                                                    </Grid>
                                                    <Grid item xs={3} className="profile-display__items--text">
                                                        <span className="profile-display__items--text-two-first">{t('profile.data-bank.dv')}</span>
                                                        <span className="profile-display__items--text-two-second">{agencyDigit ?? '-'}</span>
                                                    </Grid>
                                                    <Grid item xs={4} className="profile-display__items--text">
                                                        <span className="profile-display__items--text-two-first">{t(`profile.data-bank.${type}`)}</span>
                                                        <span className="profile-display__items--text-two-second">{accountNumber ?? '-'}</span>
                                                    </Grid>
                                                    <Grid item xs={3} className="profile-display__items--text">
                                                        <span className="profile-display__items--text-two-first">{t('profile.data-bank.dv')}</span>
                                                        <span className="profile-display__items--text-two-second">{accountDigit ?? '-'}</span>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                    </>
                                ))
                            ) : (
                                <>
                                    <Grid id="data-bank">
                                        <div className="mouse-hover-behavior">
                                            <Grid item className="personal-data__items--title">
                                                {t('profile.data-bank.title')}
                                            </Grid>
                                            <Grid item className="profile-display__items--text">
                                                <span className="profile-display__items--text-first">{t('profile.data-bank.bank')}</span>
                                                <span className="profile-display__items--text-second">{'-'}</span>
                                            </Grid>
                                            <div className="profile-display__items--text-two">
                                                <Grid item xs={5} className="profile-display__items--text">
                                                    <span className="profile-display__items--text-two-first">{t('profile.data-bank.agency')}</span>
                                                    <span className="profile-display__items--text-two-second">{'-'}</span>
                                                </Grid>
                                                <Grid item xs={4} className="profile-display__items--text">
                                                    <span className="profile-display__items--text-two-first">{t('profile.data-bank.dv')}</span>
                                                    <span className="profile-display__items--text-two-second">{'-'}</span>
                                                </Grid>
                                                <Grid item xs={4} className="profile-display__items--text">
                                                    <span className="profile-display__items--text-two-first">{t(`profile.data-bank.unknown-account`)}</span>
                                                    <span className="profile-display__items--text-two-second">{'-'}</span>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            )}
                            <Grid id="password">
                                <div className="mouse-hover-behavior">
                                    <Grid item className="personal-data__items--title">
                                        {t('profile.password.title')}
                                    </Grid>
                                    <Grid item className="profile-display__items--text">
                                        <input type="password" className="profile-display__items--text-second" value={user ? user?.mockPassword : '-'} readOnly />
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid id="contact">
                                <div className="mouse-hover-behavior">
                                    <Grid item className="personal-data__items--title">
                                        {t('profile.contact.title')}
                                    </Grid>
                                    <Grid className="profile-display__items--text">
                                        <Grid>
                                            <Grid item xs={10}>
                                                <span className="profile-display__items--text-third">
                                                    {t('profile.contact.whatsapp-subscribe')} <PhoneFormatter value={profile?.phone?.number} />
                                                </span>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ToggleSwitch name="whatsApp-subscribe" checked={profile?.phone?.whatsAppStatus === WhatsAppStatus.RECEIVE ? true : false} disabled />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileDisplay;
