import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { MyFundsActionTypes, MyFundsState } from './types';

export const initialState: MyFundsState = {
    status: HttpRequestStatus.NOOP,
    portfolioStatus: HttpRequestStatus.NOOP,
    historyStatus: HttpRequestStatus.NOOP,
    funds: [],
    portfolio: undefined,
    history: undefined,
    error: undefined
};

const reducer: Reducer<MyFundsState> = (state = initialState, action): MyFundsState => {
    switch (action.type) {
        case MyFundsActionTypes.MY_FUNDS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, funds: [], error: undefined };
        }
        case MyFundsActionTypes.MY_FUNDS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, funds: action.payload, error: undefined };
        }
        case MyFundsActionTypes.MY_FUNDS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case MyFundsActionTypes.MY_FUNDS_RESET_STATE: {
            return { ...initialState };
        }
        case MyFundsActionTypes.PORTFOLIO_REQUEST: {
            return { ...state, portfolioStatus: HttpRequestStatus.ONGOING, portfolio: undefined, error: undefined };
        }
        case MyFundsActionTypes.PORTFOLIO_SUCCESS: {
            return { ...state, portfolioStatus: HttpRequestStatus.SUCCESS, portfolio: action.payload, error: undefined };
        }
        case MyFundsActionTypes.PORTFOLIO_ERROR: {
            return { ...state, portfolioStatus: HttpRequestStatus.ERROR, error: action.payload };
        }
        case MyFundsActionTypes.PORTFOLIO_RESET_STATE: {
            return { ...initialState };
        }
        case MyFundsActionTypes.FUND_HISTORY_REQUEST: {
            return { ...state, historyStatus: HttpRequestStatus.ONGOING, history: undefined, error: undefined };
        }
        case MyFundsActionTypes.FUND_HISTORY_SUCCESS: {
            return { ...state, historyStatus: HttpRequestStatus.SUCCESS, history: action.payload, error: undefined };
        }
        case MyFundsActionTypes.FUND_HISTORY_ERROR: {
            return { ...state, historyStatus: HttpRequestStatus.ERROR, error: action.payload };
        }
        case MyFundsActionTypes.FUND_HISTORY_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as myFundsReducer };
