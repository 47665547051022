import { IncomeStatement } from 'model/income-statement';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum GetMonthlyIncomeStatementTypes {
    GET_MONTHLY_INCOME_STATEMENT_REQUEST = '@@get-income-statement/GET_MONTHLY_INCOME_STATEMENT_REQUEST',
    GET_MONTHLY_INCOME_STATEMENT_SUCCESS = '@@get-income-statement/GET_MONTHLY_INCOME_STATEMENT_SUCCESS',
    GET_MONTHLY_INCOME_STATEMENT_ERROR = '@@get-income-statement/GET_MONTHLY_INCOME_STATEMENT_ERROR',
    GET_MONTHLY_INCOME_STATEMENT_RESET_STATE = '@@get-income-statement/GET_MONTHLY_INCOME_STATEMENT_RESET_STATE'
}

export interface GetMonthlyIncomeStatementState extends BaseStoreState {
    readonly incomeStatement?: IncomeStatement;
}
