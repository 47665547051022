import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ShareholderInviteConfirmActionTypes, ShareholderInviteConfirmState } from './types';

export const initialState: ShareholderInviteConfirmState = {
    status: HttpRequestStatus.NOOP,
    invite: undefined,
    error: undefined
};

const reducer: Reducer<ShareholderInviteConfirmState> = (state = initialState, action): ShareholderInviteConfirmState => {
    switch (action.type) {
        case ShareholderInviteConfirmActionTypes.SHAREHOLDER_INVITE_CONFIRM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, invite: undefined, error: undefined };
        }
        case ShareholderInviteConfirmActionTypes.SHAREHOLDER_INVITE_CONFIRM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, invite: action.payload, error: undefined };
        }
        case ShareholderInviteConfirmActionTypes.SHAREHOLDER_INVITE_CONFIRM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ShareholderInviteConfirmActionTypes.SHAREHOLDER_INVITE_CONFIRM_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as confirmShareholderInviteReducer };
