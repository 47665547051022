import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { findAllSeriesByUserReducer } from './find-all-by-user/reducer';
import findAllSeriesByUserSaga from './find-all-by-user/sagas';
import { FindAllSeriesByUserState } from './find-all-by-user/types';
import { nonParticipantOfFundReducer } from './non-participant-series/reducer';
import nonParticipantSeriesOfFundSaga from './non-participant-series/sagas';
import { NonParticipantOfFundState } from './non-participant-series/types';
import { sharePerformanceReducer } from './share-performance/reducer';
import sharePerformanceSaga from './share-performance/sagas';
import { SharePerformanceState } from './share-performance/types';

export interface SeriesState {
    sharePerformance: SharePerformanceState;
    nonParticipantSeries: NonParticipantOfFundState;
    findAllSeriesByUser: FindAllSeriesByUserState;
}

export const SeriesReducer: ReducersMapObject<SeriesState, AnyAction> = {
    sharePerformance: sharePerformanceReducer,
    nonParticipantSeries: nonParticipantOfFundReducer,
    findAllSeriesByUser: findAllSeriesByUserReducer
};

export function* seriesSagas() {
    yield all([fork(sharePerformanceSaga), fork(nonParticipantSeriesOfFundSaga), fork(findAllSeriesByUserSaga)]);
}
