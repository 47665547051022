import { GiroWebappError } from 'model/error';
import { CapitalBalance, FundAccountCapitalBalanceRequest } from 'model/fund-account';
import { action } from 'typesafe-actions';
import { CapitalBalanceActionTypes } from './types';


export const capitalBalanceRequest = (data: FundAccountCapitalBalanceRequest) => action(CapitalBalanceActionTypes.CAPITAL_BALANCE_REQUEST, data);
export const capitalBalanceSuccess = (response: CapitalBalance) => action(CapitalBalanceActionTypes.CAPITAL_BALANCE_SUCCESS, response);
export const capitalBalanceError = (error: GiroWebappError) => action(CapitalBalanceActionTypes.CAPITAL_BALANCE_ERROR, error);
export const capitalBalanceResetState = () => action(CapitalBalanceActionTypes.CAPITAL_BALANCE_RESET_STATE);
