import { FormControl, MenuListProps, MenuProps, PopoverOrigin, Select, SelectProps } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './select-graphs.scss';

type Base = Pick<SelectProps, 'children' | 'disabled' | 'MenuProps'>;

interface BaseSelectInputProps<T> extends Base {
    dataCi?: string;
    label?: string;
    value: T;
    onChange?: (value: T) => void;
    mapperFromString: (value?: string) => T;
    placeholder?: string | null;
    externalUpdate?: boolean;
}

type SelectInputProps<T> = BaseSelectInputProps<T>;

interface SelectGraphsProps<T> extends SelectInputProps<T> {
    value: T;
    onChange?: (value: T) => void;
    mapperFromString: (value?: string) => T;
}

const SelectGraphs = <T extends {}>(props: SelectGraphsProps<T>) => {
    const { value: propsValue, onChange, mapperFromString, externalUpdate, placeholder, ...rest } = props;

    const { t } = useTranslation();

    const [value, setValue] = useState<T>(propsValue);

    const anchorOrigin: PopoverOrigin = {
        vertical: 'bottom',
        horizontal: 'left'
    };

    const menuListProps: Partial<MenuListProps> = {
        disablePadding: true,
        className: 'select-graphs--menu-list'
    };

    const menuProps: Partial<MenuProps> = {
        MenuListProps: menuListProps,
        anchorOrigin,
        getContentAnchorEl: null
    };

    const handleChange = (event: any) => {
        const _value = mapperFromString(event?.target?.value);
        if (_value === value) return;

        setValue(_value);

        if (onChange) {
            onChange(_value);
        }
    };

    React.useEffect(() => {
        if (externalUpdate) {
            setValue(ps => (ps === propsValue ? ps : propsValue ?? undefined));
        }
    }, [externalUpdate, propsValue]);

    return (
        <div className="select-graphs">
            <FormControl>
                <Select variant="outlined" placeholder={t(placeholder ?? '')} value={value} IconComponent={KeyboardArrowDownIcon} MenuProps={menuProps} onChange={handleChange} {...rest} />
            </FormControl>
        </div>
    );
};

export default SelectGraphs;
