import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { ShareholderInvite } from '../../../model/shareholder-invite';
import shareholderInviteApi from '../../../services/api/shareholderInviteApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { shareholderToConfirmError, shareholderToConfirmRequest, shareholderToConfirmSuccess } from './actions';
import { ShareholderInviteToConfirmActionTypes } from './types';


function* handleShareholderInviteToConfirmRequest(action: ReturnType<typeof shareholderToConfirmRequest>) {
  try {
    const result: AxiosResponse<ShareholderInvite> = yield call(shareholderInviteApi.recoverByToken, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroIdhApiError(result);
      yield put(markError(mapped));
      yield put(shareholderToConfirmError(mapped));
      return;
    }
    yield put(shareholderToConfirmSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(shareholderToConfirmError(mapped));
  }
}

function* watchHandleShareholderInviteToConfirmRequest() {
  yield takeEvery(ShareholderInviteToConfirmActionTypes.SHAREHOLDER_INVITE_TO_CONFIRM_REQUEST, handleShareholderInviteToConfirmRequest);
}

function* shareholderInviteToConfirmtSaga() {
  yield all([fork(watchHandleShareholderInviteToConfirmRequest)]);
}

export default shareholderInviteToConfirmtSaga;
