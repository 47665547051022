import { AxiosResponse } from 'axios';
import { SeriesOfFund } from 'model/series';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import seriesApi from 'services/api/seriesApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { sharePerformanceError, sharePerformanceRequest, sharePerformanceSuccess } from './actions';
import { SharePerformanceActionTypes } from './types';

function* handleSharePerformanceRequest(action: ReturnType<typeof sharePerformanceRequest>) {
    try {
        const result: AxiosResponse<SeriesOfFund[]> = yield call(seriesApi.getSeriesSharePerformance,action.payload );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(sharePerformanceError(mapped));
            return;
        }
        yield put(sharePerformanceSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(sharePerformanceError(mapped));
    }
}

function* watchGetSharePerformance() {
    yield takeEvery(SharePerformanceActionTypes.SHARE_PERFORMANCE_REQUEST, handleSharePerformanceRequest);
}

function* sharePerformanceSaga() {
    yield all([fork(watchGetSharePerformance)]);
}

export default sharePerformanceSaga;
