import _ from 'lodash';

const ELLIPSIS_STRING = '...';
const STRING_MAX_LENGTH = 40;

const formatEllipsis = (inputString: string, maxLength?: number): string => {
    if (inputString.length < (maxLength ?? STRING_MAX_LENGTH)) {
        return inputString;
    }

    return inputString.slice(0, (maxLength ?? STRING_MAX_LENGTH) - 3) + ELLIPSIS_STRING;
};

const mergeProps = <T extends object>(defaultProps: T | any, props?: T): T => {
    return _.merge({}, defaultProps, props);
};

export const ChartUtils = {
    formatEllipsis,
    mergeProps
};
