import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { GetMonthlyIncomeStatementState, GetMonthlyIncomeStatementTypes } from './types';

const initialState: GetMonthlyIncomeStatementState = {
    status: HttpRequestStatus.NOOP,
    incomeStatement: undefined,
    error: undefined
};

const reducer: Reducer<GetMonthlyIncomeStatementState> = (state = initialState, action): GetMonthlyIncomeStatementState => {
    switch (action.type) {
        case GetMonthlyIncomeStatementTypes.GET_MONTHLY_INCOME_STATEMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case GetMonthlyIncomeStatementTypes.GET_MONTHLY_INCOME_STATEMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, incomeStatement: action.payload, error: undefined };
        }
        case GetMonthlyIncomeStatementTypes.GET_MONTHLY_INCOME_STATEMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case GetMonthlyIncomeStatementTypes.GET_MONTHLY_INCOME_STATEMENT_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as getMonthlyIncomeStatementReducer };
