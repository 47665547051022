import React from 'react';
import { Line, LineProps } from 'recharts';
import { ChartUtils } from 'shared/util/chart-utils';
import { DEFAULT_MULTILINE_PROPS } from './chart-components-style';

export type CustomizedLinePropsType = Omit<LineProps, 'ref'> & { index?: number };

interface CustomizedLineProps {
    customizedLineProps?: CustomizedLinePropsType;
}

const CustomizedLine = (props: CustomizedLineProps) => {
    const { customizedLineProps } = props;

    const mergedProps: CustomizedLinePropsType = ChartUtils.mergeProps(DEFAULT_MULTILINE_PROPS(customizedLineProps?.index as number), customizedLineProps);

    return <Line {...mergedProps} />;
};

export default CustomizedLine;
