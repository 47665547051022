import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { NumberOfParticipants } from 'model/raising';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import raisingApi from 'services/api/raisingApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { numberOfParticipantsError, numberOfParticipantsRequest, numberOfParticipantsSuccess } from './actions';
import { NumberOfParticipantsActionTypes } from './types';

function* handleGetNumberOfParticipants(action: ReturnType<typeof numberOfParticipantsRequest>) {
    try {
        const result: AxiosResponse<NumberOfParticipants> = yield call(raisingApi.getNumberOfParticipants, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(numberOfParticipantsError(mapped));
            return;
        }
        yield put(numberOfParticipantsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(numberOfParticipantsError(mapped));
    }
}

function* watchGetNumberOfParticipants() {
    yield takeEvery(NumberOfParticipantsActionTypes.NUMBER_OF_PARTICIPANTS_REQUEST, handleGetNumberOfParticipants);
}

function* numberOfParticipantsSaga() {
    yield all([fork(watchGetNumberOfParticipants)]);
}

export default numberOfParticipantsSaga;
