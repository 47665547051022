import {
    CORPORATION_IDENTIFICATION_LENGTH,
    CORPORATION_IDENTIFICATION_MASK,
    CORPORATION_IDENTIFICATION_MASK_REGEX,
    DATE_FNS_LOCALE,
    DEFAULT_PHONE_LENGTH,
    DEFAULT_PHONE_MASK,
    DEFAULT_PHONE_REGEX,
    EXTENDED_PHONE_LENGTH,
    EXTENDED_PHONE_MASK,
    EXTENDED_PHONE_REGEX,
    INDIVIDUAL_IDENTIFICATION_LENGTH,
    INDIVIDUAL_IDENTIFICATION_MASK,
    INDIVIDUAL_IDENTIFICATION_MASK_REGEX
} from 'config/constants';
import { format, isValid, parseISO } from 'date-fns';
import { PersonType } from 'model/enums/person-type';
import moment from 'moment';
import { CURRENCY_FORMATTER, CURRENCY_FORMATTER_PREFIXED, DECIMAL_FORMATTER, PERCENTAGE_FORMATTER, PERCENTAGE_INTEGER_FORMATTER, UNIT_PRICE_FORMATTER, UNIT_PRICE_FORMATTER_PREFIXED } from './formatter-constants';

const NON_DIGITS_SEARCH_REGEX = /\D/g;
const EMPTY_STRING = '';

type Optional<T> = T | undefined | null;
type Undefined<T> = T | undefined;

const formatNumberToCurrency = (value: Optional<number>, defaultValue?: number, prefix?: boolean): string => {
    const formatter = prefix ? CURRENCY_FORMATTER_PREFIXED : CURRENCY_FORMATTER;
    return formatter.format(value ?? defaultValue ?? 0);
};

const formatNumberToUnitPrice = (value: Optional<number>, defaultValue?: number, prefix?: boolean): string => {
    const formatter = prefix ? UNIT_PRICE_FORMATTER_PREFIXED : UNIT_PRICE_FORMATTER;
    return formatter.format(value ?? defaultValue ?? 0);
};

const formatNumberToPercentage = (value: Optional<number>, defaultValue?: number): string => {
    return PERCENTAGE_FORMATTER.format(value ?? defaultValue ?? 0);
};

const formatNumberToIntegerPercentage = (value: Optional<number>, defaultValue?: number): string => {
    return PERCENTAGE_INTEGER_FORMATTER.format(value ?? defaultValue ?? 0);
};

const formatNumberDecimal = (value: Optional<number>, defaultValue?: number): string => {
    return DECIMAL_FORMATTER.format(value ?? defaultValue ?? 0);
};

const parseDateFromStringISO = (value: Optional<string | Date>): Undefined<Date> => {
    if (value === undefined || value === null) return undefined;
    return typeof value === 'string' ? parseISO(value) : value;
};

const formatDate = (value: Optional<Date>, formatToken: string): string => {
    if (value === null || value === undefined || !isValid(value)) return EMPTY_STRING;
    return format(value, formatToken, { locale: DATE_FNS_LOCALE });
};

const formatDateByString = (value: string, formatterString: string): string => {
    return moment(value).format(formatterString);
};

const formatIdentification = (type: Optional<PersonType>, value: Optional<string>): string => {
    if (value === null || value === undefined) return EMPTY_STRING;
    const digits = value.replace(NON_DIGITS_SEARCH_REGEX, EMPTY_STRING);
    if (type === PersonType.INDIVIDUAL && digits.length === INDIVIDUAL_IDENTIFICATION_LENGTH) return digits.replace(INDIVIDUAL_IDENTIFICATION_MASK_REGEX, INDIVIDUAL_IDENTIFICATION_MASK);
    if (type === PersonType.CORPORATION && digits.length === CORPORATION_IDENTIFICATION_LENGTH) return digits.replace(CORPORATION_IDENTIFICATION_MASK_REGEX, CORPORATION_IDENTIFICATION_MASK);
    if (digits.length === INDIVIDUAL_IDENTIFICATION_LENGTH) return digits.replace(INDIVIDUAL_IDENTIFICATION_MASK_REGEX, INDIVIDUAL_IDENTIFICATION_MASK);
    if (digits.length === CORPORATION_IDENTIFICATION_LENGTH) return digits.replace(CORPORATION_IDENTIFICATION_MASK_REGEX, CORPORATION_IDENTIFICATION_MASK);
    return value;
};

const formatPhone = (value: Optional<string>): string => {
    if (value === null || value === undefined) return EMPTY_STRING;
    const digits = value.replace(NON_DIGITS_SEARCH_REGEX, EMPTY_STRING);
    if (digits.length === DEFAULT_PHONE_LENGTH) return digits.replace(DEFAULT_PHONE_REGEX, DEFAULT_PHONE_MASK);
    if (digits.length === EXTENDED_PHONE_LENGTH) return digits.replace(EXTENDED_PHONE_REGEX, EXTENDED_PHONE_MASK);
    return value;
};

const FormatterUtils = {
    formatNumberToCurrency,
    formatNumberToUnitPrice,
    formatNumberToPercentage,
    formatNumberToIntegerPercentage,
    formatNumberDecimal,
    parseDateFromStringISO,
    formatDate,
    formatDateByString,
    formatIdentification,
    formatPhone
};

export default FormatterUtils;
