import { useUnleashContext } from '@unleash/proxy-client-react';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { useEffect } from 'react';
import { useUserAccountState } from 'reducer/hooks';

const useUnleashAuth = () => {
    const { account, status } = useUserAccountState();

    const updateContext = useUnleashContext();

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;

        updateContext({ userId: account?.email });
    }, [status, account, updateContext]);
};

export default useUnleashAuth;
