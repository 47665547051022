import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './clear-button.scss';

export interface ClearButtonProps {
    onClick?: () => void;
    disabled?: boolean;
}

export const ClearButton = (props: ClearButtonProps): JSX.Element => {
    const { disabled, onClick } = props;
    const { t } = useTranslation();

    return (
        <div className="button__style--clear">
            <Button variant="contained" onClick={onClick} disabled={disabled}>
                {t('entity.action.clear')}
            </Button>
        </div>
    );
};

export default ClearButton;
