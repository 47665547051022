import { Collapse, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import { HeaderVisualizationType } from 'model/enums/header-visualization-type';
import { IncomeStatementGroupType } from 'model/enums/income-statement-group-type';
import { YearlyIncomeStatementSubgroup } from 'model/income-statement';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetYearlyIncomeStatementState } from 'reducer/hooks';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import IncomeStatementStickyCell from '../income-statement-sticy-cell';
import YearlyIncomeStatementRow from '../yearly-income-statement-row';
import { fillArrayWithNullValues } from './utils/array-utils';

const I18N_PREFIX = 'anchor.income-statement-month';

const YearlyIncomeStatementTable = () => {
    const { t } = useTranslation();

    const [isGroupExpanded, setIsGroupExpanded] = React.useState<string[]>([]);

    const { yearlyIncomeStatement } = useGetYearlyIncomeStatementState();
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const collapseOrExpandRows = (clickedId?: string) => {
        if (!clickedId) return;

        const isExpanded = isGroupExpanded.some(id => id === clickedId);

        if (isExpanded) {
            setIsGroupExpanded(prev => prev.filter(id => id !== clickedId));
            return;
        }

        setIsGroupExpanded(prev => [...prev, clickedId]);
    };

    const shouldCollapse = (subgroups: ReadonlyArray<YearlyIncomeStatementSubgroup>) => {
        return !!subgroups?.length;
    };

    if (!yearlyIncomeStatement) {
        return <></>;
    }

    const yearlyFilledStatements = {
        years: fillArrayWithNullValues(yearlyIncomeStatement?.years),
        groups: fillArrayWithNullValues(yearlyIncomeStatement?.groups)
    };

    const isSecondaryAccessActive: boolean = selectedFundAndSeries?.headerVisualizationType === HeaderVisualizationType.SECONDARY_ACCESS;

    return (
        <>
            {yearlyIncomeStatement?.years.length > 0 ? (
                <table
                    className={clsx('page-container--table', {
                        SECONDARY_ACCESS_ACTIVE: isSecondaryAccessActive
                    })}
                >
                    <thead className="page-container--table__header">
                        <tr>
                            <th className="page-container--table__sticky first-columns none-border" />
                            {yearlyFilledStatements?.years.map(year => {
                                return (
                                    <th key={year} className="page-container--table__header-items">
                                        {year ?? '-'}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {yearlyFilledStatements?.groups
                            ?.filter(group => group?.type === IncomeStatementGroupType.REVENUE)
                            .map(group => (
                                <Fragment key={group?.id}>
                                    <tr className="revenue" onClick={() => shouldCollapse(group?.subgroups) && collapseOrExpandRows(group?.id)}>
                                        <IncomeStatementStickyCell className="revenue" cellTitle={group?.name} isCollapsedArrow={!isGroupExpanded.some(id => id === group?.id)} shouldCollapse={shouldCollapse(group?.subgroups)} />
                                        <YearlyIncomeStatementRow years={yearlyFilledStatements.years} balances={group?.balances} className="revenue" />
                                    </tr>
                                    {group?.subgroups?.map(subgroup => (
                                        <Collapse in={!isGroupExpanded.some(id => id === group?.id)} key={subgroup?.id}>
                                            <tr key={group?.id}>
                                                <td className="page-container--table__sticky first-columns">
                                                    <Tooltip title={subgroup?.name}>
                                                        <span>{subgroup?.name}</span>
                                                    </Tooltip>
                                                </td>

                                                <YearlyIncomeStatementRow years={yearlyFilledStatements?.years} balances={subgroup?.balances} className="revenue-color" />
                                            </tr>
                                        </Collapse>
                                    ))}
                                </Fragment>
                            ))}
                        {yearlyFilledStatements?.groups
                            ?.filter(group => group?.type === IncomeStatementGroupType.OPERATIONAL)
                            .map(group => (
                                <Fragment key={group?.id}>
                                    <tr className="operational" onClick={() => shouldCollapse(group?.subgroups) && collapseOrExpandRows(group?.id)}>
                                        <IncomeStatementStickyCell className="operational" cellTitle={group?.name} isCollapsedArrow={!isGroupExpanded.some(id => id === group?.id)} shouldCollapse={shouldCollapse(group?.subgroups)} />
                                        <YearlyIncomeStatementRow years={yearlyFilledStatements.years} balances={group?.balances} className="operational" />
                                    </tr>
                                    {group?.subgroups?.map(subgroup => (
                                        <Collapse in={!isGroupExpanded.some(id => id === group?.id)} key={subgroup?.id}>
                                            <tr key={group?.id}>
                                                <td className="page-container--table__sticky first-columns">
                                                    <Tooltip title={subgroup?.name}>
                                                        <span>{subgroup?.name}</span>
                                                    </Tooltip>
                                                </td>

                                                <YearlyIncomeStatementRow years={yearlyFilledStatements.years} balances={subgroup?.balances} className="operational-color" />
                                            </tr>
                                        </Collapse>
                                    ))}
                                </Fragment>
                            ))}
                        {yearlyFilledStatements?.groups
                            ?.filter(group => _.isNil(group?.type) && !_.isNil(group?.nameType))
                            .map(group => (
                                <tr className="operational" key={group?.nameType}>
                                    <IncomeStatementStickyCell className="operational" cellTitle={t(`${I18N_PREFIX}.default-groups.${group?.name}`)} isCollapsedArrow={!isGroupExpanded.some(id => id === group?.id)} shouldCollapse={false} />
                                    <YearlyIncomeStatementRow years={yearlyFilledStatements?.years} balances={group?.balances} className="operational" />
                                </tr>
                            ))}
                    </tbody>
                </table>
            ) : (
                <div className="income-statement__table--empty-years">{t(`${I18N_PREFIX}.empty-yearly-income-statement`)}</div>
            )}
        </>
    );
};

export default YearlyIncomeStatementTable;
