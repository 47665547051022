import Account from 'features/account/account';
import AnchorDashboard from 'features/anchor/anchor-dashboard';
import FundDocuments from 'features/documents/documents';
import React from 'react';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from 'shared/error/page-not-found';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { AnchorTab } from 'shared/menu/anchor-header/anchor-tab-menu';
import { HeaderType } from 'shared/menu/header/header';
import PrivateRoute from 'shared/routes/private-route';
import anchorCapture from './capture/anchor-capture';
import FinancialStatementMonthPage from './financial-statement-month/financial-statement-month';
import anchorGeneral from './general/anchor-general';
import IncomeStatement from './income-statement-month/income-statement';
import anchorPerformance from './performance/anchor-performance';

interface MatchParams {
    url: string;
}

type ParamsType = Omit<RouteComponentProps<MatchParams>, 'location' | 'history'>;

const AnchorRoutes = ({ match }: ParamsType) => {
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    if (!selectedFundAndSeries?.fundId || !selectedFundAndSeries?.seriesId) {
        return <Redirect to="/investor/wallet" />;
    }

    return (
        <Switch>
            <PrivateRoute exact path={`${match.url}/general`} component={anchorGeneral} headerType={HeaderType.ANCHOR} withTab={AnchorTab.GENERAL} />
            <PrivateRoute exact path={`${match.url}/performance`} component={anchorPerformance} headerType={HeaderType.ANCHOR} withTab={AnchorTab.PERFORMANCE} />
            <PrivateRoute exact path={`${match.url}/raising`} component={anchorCapture} headerType={HeaderType.ANCHOR} withTab={AnchorTab.CAPTURE} />
            <PrivateRoute exact path={`${match.url}/documents`} component={FundDocuments} headerType={HeaderType.ANCHOR} withTab={AnchorTab.DOCUMENTS} />
            <PrivateRoute exact path={`${match.url}/account`} component={Account} headerType={HeaderType.ANCHOR} withTab={AnchorTab.ACCOUNT} />
            <PrivateRoute exact path={match.url} component={AnchorDashboard} headerType={HeaderType.ANCHOR} />
            <PrivateRoute exact path={`${match.url}/financial-statement-month`} component={FinancialStatementMonthPage} headerType={HeaderType.ANCHOR} withTab={AnchorTab.FINANCIAL_STATEMENT_MONTH} />
            <PrivateRoute exact path={`${match.url}/income-statement-month`} component={IncomeStatement} headerType={HeaderType.ANCHOR} withTab={AnchorTab.INCOME_STATEMENT_MONTH} />
            <PrivateRoute component={PageNotFound} headerType={HeaderType.SIMPLE} />
        </Switch>
    );
};

export default AnchorRoutes;
