import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { FundDocumentsToGetAll } from 'model/fund';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'anchor.documents.header';

export type FundDocumentsSortableProperties = keyof FundDocumentsToGetAll | 'file.name';

interface DashboardHeaderProps {
    documents: FundDocumentsToGetAll[];
    handleSort: (property: FundDocumentsSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const DashboardHeader = (props: DashboardHeaderProps) => {
    const { t } = useTranslation();
    const { documents, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: FundDocumentsSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: documents.length <= 1,
            onSort: () => handleSort(_property, sortOrder)
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('file.name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.type`).toUpperCase()} sortable={mapSortable('type')} />
            <SortableColumn label={t(`${I18N_PREFIX}.date`).toUpperCase()} sortable={mapSortable('createdDate')} />
        </tr>
    );
};

export default DashboardHeader;
