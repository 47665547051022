import { YearlyFinancialStatementMonth } from 'model/financial-statement-month';
import { BaseStoreState } from 'reducer/baseStoreState';

export enum GetYearlyFinancialStatementMonthTypes {
    GET_YEARLY_FINANCIAL_STATEMENT_MONTH_REQUEST = '@@get-yearly-financial-statement-month/GET_YEARLY_FINANCIAL_STATEMENT_MONTH_REQUEST',
    GET_YEARLY_FINANCIAL_STATEMENT_MONTH_SUCCESS = '@@get-yearly-financial-statement-month/GET_YEARLY_FINANCIAL_STATEMENT_MONTH_SUCCESS',
    GET_YEARLY_FINANCIAL_STATEMENT_MONTH_ERROR = '@@get-yearly-financial-statement-month/GET_YEARLY_FINANCIAL_STATEMENT_MONTH_ERROR',
    GET_YEARLY_FINANCIAL_STATEMENT_MONTH_RESET_STATE = '@@get-yearly-financial-statement-month/GET_YEARLY_FINANCIAL_STATEMENT_MONTH_RESET_STATE'
}

export interface GetYearlyFinancialStatementMonthState extends BaseStoreState {
    readonly yearlyFinancialStatementMonth?: YearlyFinancialStatementMonth;
}
