import { Collapse, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { FundAccountType } from 'model/enums/fund-account-type';
import { HeaderVisualizationType } from 'model/enums/header-visualization-type';
import { monthValues } from 'model/enums/month-values';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFinancialStatementMonthState } from 'reducer/hooks';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import ExpandableFirstCell from '../expandable-first-cell';
import MonthlyFinancialStatementMonthRow from '../monthly-financial-statement-month-row';

const I18N_PREFIX = 'anchor.financial-statement-month';

interface MonthlyFinancialStatementTableProps {
    years: ReadonlyArray<number> | undefined;
}

type ExpandedAccountsTypes = {
    active: boolean;
    passive: boolean;
    debentures: boolean;
};

const defaultExpandedAccounts: ExpandedAccountsTypes = {
    active: true,
    passive: true,
    debentures: false
};

const MonthlyFinancialStatementTable = (props: MonthlyFinancialStatementTableProps) => {
    const { years } = props;

    const { t } = useTranslation();
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const { financialStatementMonth: balanceDataByAccount } = useGetFinancialStatementMonthState();

    const [expandedAccounts, setExpandedAccounts] = React.useState<ExpandedAccountsTypes>(defaultExpandedAccounts);

    const toggleExpandedAccounts = (account: keyof ExpandedAccountsTypes) => () => {
        setExpandedAccounts(prev => ({ ...prev, [account]: !prev[account] }));
    };

    const isSecondaryAccessActive: boolean = selectedFundAndSeries?.headerVisualizationType === HeaderVisualizationType.SECONDARY_ACCESS;

    return (
        <>
            {years?.length !== undefined && years?.length > 0 ? (
                <table
                    className={clsx('page-container--table', {
                        SECONDARY_ACCESS_ACTIVE: isSecondaryAccessActive
                    })}
                >
                    <thead className="page-container--table__header">
                        <tr>
                            <th className="page-container--table__sticky first-columns none-border" />
                            {monthValues.map(month => {
                                return (
                                    <th key={month} className="page-container--table__header-items">
                                        {t(`${I18N_PREFIX}.header.month.${month}`)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="active" onClick={toggleExpandedAccounts('active')}>
                            <ExpandableFirstCell className="active" cellTitle={t(`${I18N_PREFIX}.active`)} isCollapsedArrow={expandedAccounts.active} />
                            <MonthlyFinancialStatementMonthRow balances={balanceDataByAccount?.balances.map(it => ({ id: it.id, month: it.month, value: it.totalActive })) ?? []} className="active" />
                        </tr>

                        {balanceDataByAccount?.accounts
                            .filter(fundAccount => fundAccount.type === FundAccountType.ACTIVE)
                            .map(fundAccount => (
                                <Collapse in={expandedAccounts.active} key={fundAccount.id}>
                                    <tr>
                                        <td className="page-container--table__sticky first-columns">
                                            <Tooltip title={fundAccount.name}>
                                                <span>{fundAccount.name}</span>
                                            </Tooltip>
                                        </td>

                                        <MonthlyFinancialStatementMonthRow balances={fundAccount?.balances} />
                                    </tr>
                                </Collapse>
                            ))}

                        <tr className="passive" onClick={toggleExpandedAccounts('passive')}>
                            <ExpandableFirstCell className="passive" cellTitle={t(`${I18N_PREFIX}.passive`)} isCollapsedArrow={expandedAccounts.passive} />
                            <MonthlyFinancialStatementMonthRow balances={balanceDataByAccount?.balances.map(it => ({ id: it.id, month: it.month, value: it.totalPassive })) ?? []} className="passive" />
                        </tr>
                        {balanceDataByAccount?.accounts
                            .filter(fundAccount => fundAccount.type === FundAccountType.PASSIVE)
                            .map(fundAccount => (
                                <Collapse in={expandedAccounts.passive} key={fundAccount.id}>
                                    <tr>
                                        <td className="page-container--table__sticky first-columns">
                                            <Tooltip title={fundAccount.name}>
                                                <span>{fundAccount.name}</span>
                                            </Tooltip>
                                        </td>

                                        <MonthlyFinancialStatementMonthRow balances={fundAccount?.balances} className="passive-color" />
                                    </tr>
                                </Collapse>
                            ))}
                        <Collapse in={expandedAccounts.passive}>
                            <tr className="pointer" onClick={toggleExpandedAccounts('debentures')}>
                                <ExpandableFirstCell className="debentures-columns" cellTitle={t(`${I18N_PREFIX}.debentures`)} isCollapsedArrow={expandedAccounts.debentures} />
                                <MonthlyFinancialStatementMonthRow balances={balanceDataByAccount?.debentures?.balances ?? []} className="passive-weighted-color" />
                            </tr>
                        </Collapse>
                        {balanceDataByAccount?.debentures?.series.map(series => (
                            <Collapse in={expandedAccounts.passive && expandedAccounts.debentures} key={series.id}>
                                <tr>
                                    <td className="page-container--table__sticky debentures-series">
                                        <Tooltip title={series.name}>
                                            <span>{series.name}</span>
                                        </Tooltip>
                                    </td>
                                    {<MonthlyFinancialStatementMonthRow balances={series?.balances} className="passive-debentures-color" />}
                                </tr>
                            </Collapse>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="financial-statement-month__table--empty-years">{t(`${I18N_PREFIX}.empty-years`)}</div>
            )}
        </>
    );
};

export default MonthlyFinancialStatementTable;
