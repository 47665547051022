import { Tooltip } from '@material-ui/core';
import StandardButton from 'components/button-standard/standard-button';
import { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import { FundSeriesAndInvestor } from 'model/series';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import SelectAllChartBar from '../select-all-chart-bar/select-all-chart-bar';

interface SelectAllCardProps {
    fundAndSeries: FundSeriesAndInvestor;
    action: () => void;
    dataCy: string;
}

const SelectAllCard = (props: SelectAllCardProps) => {
    const { fundAndSeries, action, dataCy } = props;

    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const { t } = useTranslation();

    const isDisabled: boolean = selectedFundAndSeries?.seriesId === fundAndSeries.seriesId;

    return (
        <div className="select-all-modal--row">
            <div className="select-all-modal--items">
                <SelectAllChartBar />
                <div className="select-all-modal--items__fund">
                    <Tooltip title={fundAndSeries.fundName}>
                        <span>{fundAndSeries.fundName}</span>
                    </Tooltip>
                </div>
                <div className="select-all-modal--items__series">
                    <Tooltip title={fundAndSeries.seriesName}>
                        <span>{fundAndSeries.seriesName}</span>
                    </Tooltip>
                </div>
                <div className="select-all-modal--items__balance">
                    <span className="select-all-modal--items__balance-title">{t('wallet.modal.balance')}</span>
                    <Tooltip title={formatCurrency(fundAndSeries.seriesGrossAmount, 0, true)}>
                        <span>{formatCurrency(fundAndSeries.seriesGrossAmount, 0, true)}</span>
                    </Tooltip>
                </div>
                <div className="select-all-modal--items__button">
                    <StandardButton label={t('wallet.modal.button')} onClick={action} disabled={isDisabled} dataCy={dataCy} />
                </div>
            </div>
        </div>
    );
};

export default SelectAllCard;
