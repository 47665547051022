import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { FundAccountCashAvailableBalanceState, FundAccountCashAvailableBalanceActionTypes } from './types';

export const initialState: FundAccountCashAvailableBalanceState = {
    status: HttpRequestStatus.NOOP,
    balance: undefined,
    error: undefined
};

const reducer: Reducer<FundAccountCashAvailableBalanceState> = (state = initialState, action): FundAccountCashAvailableBalanceState => {
    switch (action.type) {
        case FundAccountCashAvailableBalanceActionTypes.CASH_AVAILABLE_BALANCE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, balance: undefined, error: undefined };
        }
        case FundAccountCashAvailableBalanceActionTypes.CASH_AVAILABLE_BALANCE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, balance: action.payload, error: undefined };
        }
        case FundAccountCashAvailableBalanceActionTypes.CASH_AVAILABLE_BALANCE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case FundAccountCashAvailableBalanceActionTypes.CASH_AVAILABLE_BALANCE_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as fundAccountCashAvailableBalanceReducer };
