import { FundSeriesAndInvestor } from 'model/series';
import queryString from 'query-string';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

const useFilterUtils = () => {
    const { search } = useLocation();
    const history = useHistory();

    const params: string = useMemo(() => {
        return new URLSearchParams(search).toString();
    }, [search]);

    const buildSearchParams = useCallback((value: FundSeriesAndInvestor) => {
        return queryString.stringify(value);
    }, []);

    const clearUrlFilter = useCallback(() => {
        history.replace({ search: '' });
    } , [history]);

    const setUrlFilter = useCallback(
        (value: FundSeriesAndInvestor) => {
            const url: string = buildSearchParams(value);

            history.replace({ search: url });
        },
        [history, buildSearchParams]
    );

    const parsedQuery: FundSeriesAndInvestor = useMemo(() => {
        return (queryString.parse(params) as unknown) as FundSeriesAndInvestor;
    }, [params]);

    const returnObject = useMemo(() => {
        return { setUrlFilter, parsedQuery, buildSearchParams, params, clearUrlFilter };
    }, [parsedQuery, setUrlFilter, buildSearchParams, params, clearUrlFilter]);

    return returnObject;
};

export default useFilterUtils;
