import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import { MASK_DIGIT_REGEX } from 'components/inputs/constants';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { ValidationResult } from '../../shared/util/validation-utils';
import FieldBasic, { FieldBasicsProps } from '../field-basic/field-basic';
import './input-address-zip-code.scss';

const ZIP_CODE_MASK = [
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '.',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '-',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
];


const ZIP_CODE__MASK_FUNCTION = () => {

  return ZIP_CODE_MASK;
};

const TextMaskCustom = props => {
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            mask={ZIP_CODE__MASK_FUNCTION}
            placeholderChar={undefined}
            placeholder={"___.____-___"}
            guide={false}
        />
    );
};


export const InputAddressZipCode = (props: FieldBasicsProps) => {
    const { className: propClassName, onChange, validate, InputProps, externalUpdate, showValidation, ...rest } = props;

    const handleChange = (value: string) => {
        if (onChange) onChange(value.replace(/\D/g, ''));
    };

    const handleValidation = (value: string): ValidationResult => {
        if (validate) {
            return validate(value.replace(/\D/g, ''));
        }
        return { isValid: true };
    };

    const className = ['number__field', propClassName].filter(it => !!it).join(' ');

    return (
        <FieldBasic
            className={className}
            onChange={handleChange}
            validate={handleValidation}
            InputProps={{ inputComponent: TextMaskCustom as React.ElementType<InputBaseComponentProps>, ...InputProps }}
            InputLabelProps={{ shrink: true }}
            externalUpdate={externalUpdate}
            showValidation={showValidation}
            {...rest}
        />
    );
};

export default InputAddressZipCode;

