import _ from 'lodash';
import { BankAccountToUpdate } from 'model/bank-account';
import { PasswordChange, PersonToUpdateProfile } from 'model/personAccount';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { changePasswordRequest } from 'reducer/shareholder/profile/change-password/actions';
import { personUpdateProfileRequest } from 'reducer/shareholder/profile/update-profile/actions';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { useChangePasswordState, usePersonProfileState, usePersonUpdateProfileState, useRootDispatch } from '../../reducer/hooks';
import { validateAddressProfileUpdate } from './validation/address-update-validation';
import { validatePassword, validatePasswordMatch, validateProfileUpdate } from './validation/validation';

export interface ProfileContextProps {
    user: {
        mockPassword?: string;
    };
    isEditing: boolean;
    profileUpdateSuccess: boolean;
    passwordUpdateSuccess: boolean;
    isChangingPassword: boolean;
    setShowValidation: (value: boolean) => void;
    setChangePassword: (value: PasswordChange) => void;
    setIsEditing: (value: boolean) => void;
    activeId: string;
    setActiveId: (value: string) => void;
    messageVisibility: boolean;
    setMessageVisibility: (value: boolean) => void;
    messageErrorVisibility: boolean;
    setMessageErrorVisibility: (value: boolean) => void;
    handleUpdateProfile: (update: Partial<PersonToUpdateProfile>) => void;
    newProfile: PersonToUpdateProfile;
    setNewProfile: (value: PersonToUpdateProfile) => void;
    changePassword: PasswordChange;
    handlePasswordChange: (value: Partial<PasswordChange>) => void;
    isAddingBankAccount: boolean;
    setIsAddingBankAccount: (value: boolean) => void;
    handleSave: () => void;
    handleDisplayChange: (value: boolean) => void;
    handleMouseFunction: (evt: any) => void;
    showValidation: boolean;
    newBankAccount: BankAccountToUpdate;
    setNewBankAccount: (account: Partial<BankAccountToUpdate>) => void;
    handleNewAccount: () => void;
}

export const ProfileContext = React.createContext<ProfileContextProps>({} as ProfileContextProps);

export const ProfileProvider: React.FunctionComponent = props => {
    const dispatch = useRootDispatch();

    const user = {
        mockPassword: 'xxxxxxx'
    };
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [newProfile, setNewProfile] = React.useState<PersonToUpdateProfile>({} as PersonToUpdateProfile);
    const [isAddingBankAccount, setIsAddingBankAccount] = React.useState<boolean>(false);
    const [showValidation, setShowValidation] = React.useState<boolean>(false);
    const [changePassword, setChangePassword] = React.useState<PasswordChange>({} as PasswordChange);
    const { profile } = usePersonProfileState();
    const { status: updateStatus } = usePersonUpdateProfileState();
    const { status: passwordChangeStatus } = useChangePasswordState();
    const [messageVisibility, setMessageVisibility] = React.useState<boolean>(false);
    const [messageErrorVisibility, setMessageErrorVisibility] = React.useState<boolean>(false);
    const [activeId, setActiveId] = React.useState(useLocation().hash.slice(1) ?? 'data');
    const isChangingPassword = changePassword != null && Object.keys(changePassword).length !== 0;
    const [newBankAccount, setNewBankAccount] = React.useState<BankAccountToUpdate>({ active: true } as BankAccountToUpdate);

    const profileUpdateSuccess = React.useMemo(() => updateStatus === HttpRequestStatus.SUCCESS, [updateStatus]);
    const passwordUpdateSuccess = React.useMemo(() => passwordChangeStatus === HttpRequestStatus.SUCCESS, [passwordChangeStatus]);

    const handleSave = () => {
        if (_.isEqual(profile, newProfile) && !isChangingPassword) {
            return;
        }

        if (isChangingPassword && _.isEqual(profile, newProfile)) {
            setShowValidation(true);

            updatePasswordRequest();
            return;
        }

        if (isChangingPassword && !_.isEqual(profile, newProfile)) {
            setShowValidation(true);
            updateProfileRequest();
            updatePasswordRequest();

            return;
        }

        updateProfileRequest();
    };

    const handleNewAccount = () => {
        if (profile?.accounts) {
            setNewProfile({ ...newProfile, accounts: [...profile?.accounts, newBankAccount] });
            setIsAddingBankAccount(false);
        } else {
            setNewProfile({ ...newProfile, accounts: [newBankAccount] });
            setIsAddingBankAccount(false);
        }
    };

    const updateProfileRequest = () => {
        if (!validateProfileUpdate(newProfile)) {
            return;
        }
        if (!validateAddressProfileUpdate(newProfile)) {
            return;
        }

        dispatch(personUpdateProfileRequest(newProfile));
    };
    const updatePasswordRequest = () => {
        if (!validatePassword(changePassword.newPassword).isValid) {
            return;
        }
        if (!validatePasswordMatch({ password: changePassword.newPassword, match: changePassword.newPasswordConfirm }).isValid) {
            return;
        }

        dispatch(changePasswordRequest(changePassword));
        return;
    };

    const handleDisplayChange = (editing: boolean) => {
        editing ? setIsEditing(true) : setIsEditing(false);
        setMessageVisibility(false);
        setActiveId('data');
        window.location.hash = '#data';
    };

    const handleMouseFunction = React.useCallback(
        (evt: any) => {
            const currentId = evt.currentTarget.parentNode.id;
            activeId !== currentId && setActiveId(currentId);
        },
        [activeId, setActiveId]
    );

    const handleUpdateProfile = (update: Partial<PersonToUpdateProfile>) => setNewProfile(ps => ({ ...ps, ...update }));
    const handlePasswordChange = (update: Partial<PasswordChange>) => setChangePassword(ps => ({ ...ps, ...update }));

    const value: ProfileContextProps = {
        user,
        isEditing,
        setIsEditing,
        profileUpdateSuccess,
        passwordUpdateSuccess,
        isChangingPassword,
        setShowValidation,
        setChangePassword,
        activeId,
        setActiveId,
        messageVisibility,
        setMessageVisibility,
        messageErrorVisibility,
        setMessageErrorVisibility,
        handleSave,
        handleDisplayChange,
        handleMouseFunction,
        newProfile,
        setNewProfile,
        changePassword,
        handlePasswordChange,
        isAddingBankAccount,
        setIsAddingBankAccount,
        handleUpdateProfile,
        showValidation,
        newBankAccount,
        setNewBankAccount,
        handleNewAccount
    };
    return <ProfileContext.Provider value={value}>{props.children}</ProfileContext.Provider>;
};

export const useProfileContext = () => React.useContext(ProfileContext);

export const withProfileContext = () => <P extends object>(WrapperComponent: React.ComponentType<P>) => (props: P) => {
    return (
        <ProfileProvider>
            <WrapperComponent {...props} />
        </ProfileProvider>
    );
};
