import { BankToSearch } from 'model/bank-account';
import { GiroWebappError } from 'model/error';
import { SearchRequest } from 'model/searchRequest';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { SearchBanksTypes } from './types';

export const searchBanksRequest = (search: SearchRequest) => action(SearchBanksTypes.SEARCH_BANKS_REQUEST, search);
export const searchBanksSuccess = (bank: Page<BankToSearch>) => action(SearchBanksTypes.SEARCH_BANKS_SUCCESS, bank);
export const searchBanksError = (error: GiroWebappError) => action(SearchBanksTypes.SEARCH_BANKS_ERROR, error);
export const searchBanksResetState = () => action(SearchBanksTypes.SEARCH_BANKS_RESET_STATE);
