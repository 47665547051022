import clsx from 'clsx';
import CurrencyFormatter from 'components/fomatter/currency/currency-formatter';
import LocalDateFormatter, { LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import ExpandIcon from 'images/expand-arrows.svg';
import ReduceIcon from 'images/reduce-arrows.svg';
import _ from 'lodash';
import { ShareholderShareReportType } from 'model/enums/shareholder-share-report-type';
import { ShareholderAccountHistoryRequest } from 'model/shareholder-account';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { AnchorMonthHistory } from '../account';

interface AccountTimeLineProps {
    data: AnchorMonthHistory[];
    filterActive: ShareholderAccountHistoryRequest;
}

const getClassFor = (type: ShareholderShareReportType) => {
    if (type === ShareholderShareReportType.SALE) return '-sale';
    return '-purchase';
};

const AccountTimeLine = (props: AccountTimeLineProps) => {
    const { data, filterActive } = props;

    const [isExpandMonth, setIsExpandMonth] = useState<boolean>(false);
    const [expandedMonths, setExpandedMonths] = useState<string[]>([]);
    const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);

    const { t } = useTranslation();

    const handleExpandMonth = (id: string) => {
        const hasSelectedId = expandedMonths?.some(monthId => monthId === id);

        if (hasSelectedId) {
            const filteredData = expandedMonths?.filter(monthId => monthId !== id);
            setExpandedMonths(filteredData);
            return;
        }

        setExpandedMonths([...expandedMonths, id]);
        setIsExpandMonth(true);
    };

    return data.length !== 0 ? (
        <Row>
            <Col>
                <div className="account__items--timeline__content">
                    {data.map(month => (
                        <div key={month.monthId} style={{ margin: '0px 10px' }}>
                            <div className={clsx('account__items--timeline__month-header', { active: isExpandMonth && expandedMonths?.some(id => id === month.monthId), disabled: !!selectedDay })}>
                                <span> {month.monthName} </span>
                                <div className="account__items--timeline__month-header-button" onClick={() => handleExpandMonth(month.monthId)}>
                                    <img src={expandedMonths?.some(id => id === month.monthId) ? ReduceIcon : ExpandIcon} alt="Icon" />
                                </div>
                            </div>
                            <div className="account__items--timeline__redemption">
                                {isExpandMonth &&
                                    expandedMonths?.some(id => id === month.monthId) &&
                                    month.children.map(day => {
                                        const clss = getClassFor(day.type);
                                        return (
                                            <div className={clsx(`account__items--timeline__redemption-items${clss}`, { disabled: !!selectedDay && selectedDay !== day.id })} key={day.id}>
                                                <div className={`account__items--timeline__redemption-items${clss}-gout`} />
                                                <div className={`account__items--timeline__redemption-items${clss}-number`} onMouseEnter={() => setSelectedDay(day.id)} onMouseLeave={() => setSelectedDay(undefined)}>
                                                    <LocalDateFormatter value={day.date} type={LocalDateFormatType.DAY_ONLY} />
                                                </div>
                                                <div className={`account__items--timeline__redemption-items${clss}-line`} />
                                                <div className={`account__items--timeline__redemption-items${clss}-info`} onMouseEnter={() => setSelectedDay(day.id)} onMouseLeave={() => setSelectedDay(undefined)}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className={`account__items--timeline__redemption-items${clss}-info-circle`} />
                                                        <div className={`account__items--timeline__redemption-items${clss}-info-title`}>{t(`account.${day.type.toLocaleLowerCase()}`)}</div>
                                                    </div>
                                                    <div className={`account__items--timeline__redemption-items${clss}-info-text`}>
                                                        {day?.type === ShareholderShareReportType.SALE && <CurrencyFormatter value={day.redemptionValue ?? 0} prefix />}
                                                        {day?.type === ShareholderShareReportType.PURCHASE && <CurrencyFormatter value={day.investmentValue ?? 0} prefix />}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    ))}
                </div>
            </Col>
        </Row>
    ) : (
        <>
            <div className="account__items--timeline__no-results">
                <div className="account__items--timeline__no-results-logo-giro" />
                {!_.isEmpty(filterActive) ? (
                    <span className="account__items--timeline__no-results-text"> {t('account.no-results.filter')} </span>
                ) : (
                    <span className="account__items--timeline__no-results-text">
                        <Trans t={t} i18nKey={'account.no-results.empty-filter'} />
                    </span>
                )}
            </div>
        </>
    );
};

export default AccountTimeLine;
