import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { AccountReducer, accountSagas, AccountState } from './account/account.reducer';
import { applicationErrorReducer } from './application/error/reducer';
import { ApplicationErrorState } from './application/error/types';
import { authenticationReducer } from './authentication/reducer';
import authenticationSaga from './authentication/sagas';
import { AuthenticationState } from './authentication/types';
import { searchBanksReducer } from './bank/reducer';
import searchBanksSaga from './bank/sagas';
import { SearchBanksState } from './bank/types';
import { searchCitiesReducer } from './city/reducer';
import searchCitiesSaga from './city/sagas';
import { SearchCitiesState } from './city/types';
import { FinancialStatementMonthReducer, financialStatementMonthSagas, FinancialStatementMonthState } from './financial-statement-month/financial-statement.reducer';
import { FundAccountReducer, fundAccountSagas, FundAccountState } from './fund-account/fund-account.reducer';
import { FundReducer, fundSagas, FundState } from './fund/fund.reducer';
import { IncomeStatementSubGroupReducer, incomeStatementSubGroupSagas, IncomeStatementSubGroupState } from './income-statement-sub-group/income-statement-sub-group.reducer';
import { IncomeStatementReducer, incomeStatementSagas, IncomeStatementState } from './income-statement/income-statement.reducer';
import { RaisingReducer, raisingSagas, RaisingState } from './raising/raising.reducer';
import { SecondaryAccessReducer, secondaryAccessSagas, SecondaryAccessState  } from './secondary-access/secondary-access.reducer';
import { SeriesReducer, seriesSagas, SeriesState } from './series/series.reducer';
import { ShareHistoryReducer, shareHistorySagas, ShareHistoryState } from './share-history/share-history.reducer';
import shareholderAccountHistorySaga from './shareholder-account/history/sagas';
import { ShareholderAccountReducer, ShareholderAccountState } from './shareholder-account/shareholder-account.reducer';
import { ShareholderInviteReducer, shareholderInviteSagas, ShareholderInviteState } from './shareholder-invite/shareholder-invite.reducer';
import { changePasswordReducer } from './shareholder/profile/change-password/reducer';
import changePasswordSaga from './shareholder/profile/change-password/sagas';
import { ChangePasswordState } from './shareholder/profile/change-password/types';
import { personProfileReducer } from './shareholder/profile/reducer';
import personProfileSaga from './shareholder/profile/sagas';
import { PersonProfileState } from './shareholder/profile/types';
import { personUpdateProfileReducer } from './shareholder/profile/update-profile/reducer';
import personUpdateProfileSaga from './shareholder/profile/update-profile/sagas';
import { PersonUpdateProfileState } from './shareholder/profile/update-profile/types';

// prettier-ignore
export interface IRootState extends
        AccountState,
        ShareholderInviteState,
        FundState,
        FundAccountState,
        IncomeStatementSubGroupState,
        RaisingState,
        SeriesState,
        ShareHistoryState,
        ShareholderAccountState,
        FinancialStatementMonthState,
        IncomeStatementState,
        SecondaryAccessState {
    readonly applicationError: ApplicationErrorState;
    readonly authentication: AuthenticationState;
    readonly personProfile: PersonProfileState;
    readonly personUpdateProfile: PersonUpdateProfileState;
    readonly personPasswordChange: ChangePasswordState;
    readonly searchCities: SearchCitiesState;
    readonly searchBanks: SearchBanksState;
}

const rootReducer = combineReducers<IRootState>({
    authentication: authenticationReducer,
    searchCities: searchCitiesReducer,
    applicationError: applicationErrorReducer,
    personProfile: personProfileReducer,
    personUpdateProfile: personUpdateProfileReducer,
    personPasswordChange: changePasswordReducer,
    searchBanks: searchBanksReducer,
    ...AccountReducer,
    ...ShareholderInviteReducer,
    ...FundReducer,
    ...FundAccountReducer,
    ...IncomeStatementSubGroupReducer,
    ...RaisingReducer,
    ...SeriesReducer,
    ...ShareHistoryReducer,
    ...ShareholderAccountReducer,
    ...FinancialStatementMonthReducer,
    ...IncomeStatementReducer,
    ...SecondaryAccessReducer
});

export type RootReducer = typeof rootReducer;

// prettier-ignore
export function* rootSaga() {
    yield all([
        fork(authenticationSaga),
        fork(accountSagas),
        fork(shareholderInviteSagas),
        fork(fundSagas),
        fork(fundAccountSagas), 
        fork(incomeStatementSubGroupSagas),
        fork(raisingSagas),
        fork(seriesSagas),
        fork(shareHistorySagas),
        fork(shareholderAccountHistorySaga),
        fork(personProfileSaga),
        fork(personUpdateProfileSaga),
        fork(changePasswordSaga),
        fork(searchCitiesSaga),
        fork(searchBanksSaga),
        fork(financialStatementMonthSagas),
        fork(incomeStatementSagas),
        fork(secondaryAccessSagas)
    ]);
}

export default rootReducer;
