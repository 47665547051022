import { AxiosResponse } from 'axios';
import { PersonSimple, PersonTermsAcceptance } from 'model/person';
import { PasswordChange, PersonToProfile, PersonToUpdateProfile } from 'model/personAccount';
import { UserAccount, UserPasswordFinishRequest, UserPasswordResetRequest } from 'model/user';

import { api } from './api';

export const account = () => {
    const getProfile = (): Promise<AxiosResponse<PersonToProfile>> => {
        return api.get<PersonToProfile>('api/account/profile');
    };

    const updateProfile = (person: PersonToUpdateProfile): Promise<AxiosResponse<PersonToProfile>> => {
        return api.put<PersonToProfile>('api/account/profile', person);
    };

    const hasChangedPasswordProfile = (password: PasswordChange): Promise<AxiosResponse<void>> => {
        return api.post<void>('api/account/change-password', password);
    };

    const recoverAccount = (): Promise<AxiosResponse<UserAccount>> => {
        return api.get<UserAccount>('api/account');
    };

    const termsAcceptance = (): Promise<AxiosResponse<PersonTermsAcceptance>> => {
        return api.get<PersonTermsAcceptance>('api/accepted-terms');
    };

    const forgotPasswordInit = (user: UserPasswordResetRequest): Promise<AxiosResponse<void>> => {
        return api.post<void>(`api/account/reset-password/${user.email}/init`);
    };

    const forgotPasswordFinish = (user: UserPasswordFinishRequest): Promise<AxiosResponse<void>> => {
        return api.post<void>(`api/account/reset-password/finish`, user);
    };

    const acceptTerms = (): Promise<AxiosResponse<PersonSimple>> => {
        return api.post<PersonSimple>('api/accepted-terms');
    };

    const acceptLgpdTerms = (): Promise<AxiosResponse<PersonSimple>> => {
        return api.post<PersonSimple>('api/accepted-lgpd-terms');
    };

    return {
        acceptTerms,
        getProfile,
        updateProfile,
        hasChangedPasswordProfile,
        recoverAccount,
        forgotPasswordInit,
        forgotPasswordFinish,
        termsAcceptance,
        acceptLgpdTerms
    };
};

export default account();
