import clsx from 'clsx';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import BaseSelectInput, { BaseSelectInputProps } from './base-select-input';
import React from 'react';

type BaseInput<T> = BaseSelectInputProps<T>;
type BaseValidation<T> = Omit<ValidInputProps<T>, 'children'>;

export type ValidSelectInputProps<T> = BaseInput<T> & BaseValidation<T>;

export const ValidSelectInput = <T extends {}>(props: ValidSelectInputProps<T>) => {
    const { validate, showValidation, ...otherProps } = props;

    return (
        <ValidInput
            value={props.value}
            validate={validate}
            showValidation={showValidation}
            className={clsx('select-input', { dirty: props.value })}
        >
            {({ error }) => <BaseSelectInput {...otherProps} error={error} />}
        </ValidInput>
    );
};

export default ValidSelectInput;
