import { ReactElement, SVGProps } from 'react';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { ChartUtils } from 'shared/util/chart-utils';
import { CartesianGridPropsType } from './customized-cartesian-grid';
import { LegendPropsType } from './customized-legend';
import { CustomizedLinePropsType } from './customized-line';
import { TooltipPropsType } from './customized-tooltip';
import { XAxisPropsType } from './customized-x-axis';
import { YAxisPropsType } from './customized-y-axis';

export const colorArray: string[] = ['#64B5F7', '#5991BC', '#163C6E', '#32aacf', '#ACC8DD', '#1585c5', '#a4daf6', '#a5f2d8'];

const DEFAULT_AXIS_TICK: SVGProps<SVGTextElement> | ReactElement<SVGElement> | ((props: any) => ReactElement<SVGElement>) | boolean = {
    fill: '#4a4a4a',
    fontFamily: 'Frutiger',
    fontSize: '11px',
    cursor: 'text'
};

// X Axis default props
export const DEFAULT_X_AXIS_PROPS: XAxisPropsType<any> = {
    allowDuplicatedCategory: false,
    tick: DEFAULT_AXIS_TICK,
    tickMargin: 10
};

// Y Axis default props
export const DEFAULT_Y_AXIS_PROPS: YAxisPropsType<any> = {
    tick: DEFAULT_AXIS_TICK,
    tickLine: false,
    interval: 'preserveStartEnd',
    axisLine: false
};

// Tooltip default props
const DEFAULT_TOOLTIP_LABEL_STYLE: React.CSSProperties = { fontFamily: 'Frutiger', fontWeight: 700, fontSize: '14px', paddingBottom: '6px', marginBottom: '3px', borderBottom: '1px solid #e3e3e3' };
const DEFAULT_TOOLTIP_WRAPPER_STYLE: React.CSSProperties = { maxWidth: '500px', border: '1px solid #b9b9b9', borderRadius: '6.5px', opacity: '0.9', backgroundColor: '#ffffff', padding: '12px' };
const DEFAULT_TOOLTIP_CONTENT_STYLE: React.CSSProperties = { overflow: 'hidden', border: 'none', lineHeight: '15px' };

export const DEFAULT_TOOLTIP_PROPS: TooltipPropsType = {
    labelStyle: DEFAULT_TOOLTIP_LABEL_STYLE,
    wrapperStyle: DEFAULT_TOOLTIP_WRAPPER_STYLE,
    contentStyle: DEFAULT_TOOLTIP_CONTENT_STYLE,
    isAnimationActive: false,
    cursor: true
};

// Legend default props
export const DEFAULT_LEGEND_PROPS: LegendPropsType = {
    iconSize: 10,
    iconType: 'square',
    formatter: (value: string) => ChartUtils.formatEllipsis(value)
};

// Cartesiangrid default props
export const DEFAULT_CARTESIAN_GRID_PROPS: CartesianGridPropsType = {
    strokeDasharray: '0',
    vertical: false,
    stroke: '#e3e3e3'
};

// Line chart default props
export const DEFAULT_LINE_CHART_PROPS: CategoricalChartProps = {
    style: { cursor: 'pointer' },
    margin: { top: 15, right: 30, left: 30, bottom: 5 }
};

export const MAX_LINE_CHART_PROPS: CategoricalChartProps = {
    margin: { top: 15, right: 45, left: 45, bottom: 5 }
};

// Line default props
export const DEFAULT_MULTILINE_PROPS = (index: number): CustomizedLinePropsType => ({
    type: 'monotone',
    activeDot: { r: 8 },
    stroke: colorArray[index],
    dot: { fill: colorArray[index], strokeWidth: 1, r: 4 }
});
