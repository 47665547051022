export type Predicate<T> = (value: T | undefined | null) => boolean;

export interface Validation<T> {
    predicate: Predicate<T>;
    errorMessage: string;
}

export interface Validations<T> {
    [key: string]: Validation<T>;
}

export interface ValidResult {
    isValid: true;
    errorMessage?: undefined;
}

export interface InvalidReulst {
    isValid: false;
    errorMessage: string;
}

export type ValidationResult = ValidResult | InvalidReulst;

const validatePredicate = <T>(value: T | undefined | null, validation: Validation<T>): ValidationResult => {
    return validation.predicate(value) ? { isValid: true } : { isValid: false, errorMessage: validation.errorMessage };
};

const validate = <T>(value: T | undefined | null, validation: Validations<T>): ValidationResult => {
    return (
        Object.values(validation)
            .map(it => validatePredicate(value, it))
            .find(it => !it.isValid) ?? { isValid: true }
    );
};

export const InputValidation = {
    validate
};
