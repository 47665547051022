import H from 'history';
import profileSvg from 'images/profile.svg';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { logoutRequest } from 'reducer/authentication/actions';
import { useRootDispatch } from 'reducer/hooks';
import MenuItem, { MenuItemPropsList } from '../menu-item/menu-item';

interface ProfileMenuProps {
    name: string;
}

const ProfileMenu = (props: ProfileMenuProps) => {
    const { name } = props;

    const history: H.History = useHistory();
    const dispatch = useRootDispatch();

    const handleLogout = () => {
        dispatch(logoutRequest());
        history.push('/');
    };

    const baseUrl = '/investor';

    const profile: MenuItemPropsList[] = [
        {
            translation: 'header.navbar.profile',
            interpolation: { name },
            visibility: true,
            submenu: [
                {
                    translation: 'header.navbar.editProfile',
                    link: `${baseUrl}/profile`,
                    visibility: true
                },
                {
                    translation: 'header.navbar.logout',
                    link: '/',
                    visibility: true,
                    action: handleLogout
                }
            ]
        }
    ];

    return <MenuItem list={profile} icon={profileSvg} />;
};

export default ProfileMenu;
