import React from 'react';
import { CartesianGrid, CartesianGridProps } from 'recharts';
import { ChartUtils } from 'shared/util/chart-utils';
import { DEFAULT_CARTESIAN_GRID_PROPS } from './chart-components-style';

export type CartesianGridPropsType = Omit<CartesianGridProps, 'ref'>;

interface CustomizedCartesianGridProps {
    cartesianGridProps?: CartesianGridPropsType;
}

const CustomizedCartesianGrid = (props: CustomizedCartesianGridProps) => {
    const { cartesianGridProps } = props;

    const mergedProps: CartesianGridPropsType = ChartUtils.mergeProps(DEFAULT_CARTESIAN_GRID_PROPS, cartesianGridProps);

    return <CartesianGrid {...mergedProps} />;
};

export default CustomizedCartesianGrid;
