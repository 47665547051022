import { Checkbox } from '@material-ui/core';
import ActionButton from 'components/buttons/action-button/action-button';
import H from 'history';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { acceptLgpdTermsRequest, acceptLgpdTermsResetState } from 'reducer/account/accept-lgpd-terms/actions';
import { termsAcceptanceRequest, termsAcceptanceResetState } from 'reducer/account/terms-acceptance/actions';
import { useLgpdTermsAcceptanceState, useRootDispatch, useTermsAcceptanceState } from 'reducer/hooks';
import useRedirectToDashboard from 'shared/hooks/redirect-to-dashboard';
import './accept-lgpd-terms.scss';
import ScrollableLgpdTerms from './components/scrollable-lgpd-terms/scrollable-lgpd-terms';

const I18N_PREFIX = 'accept-lgpd-terms';

const AcceptLgpdTerms = () => {
    const [acceptLgpd, setAcceptLgpd] = useState<boolean>(false);

    const { t } = useTranslation();
    const { status } = useLgpdTermsAcceptanceState();
    const { terms } = useTermsAcceptanceState();

    const dispatch = useRootDispatch();
    const history: H.History = useHistory();

    const areAllTermsAccepted: boolean = !!terms?.acceptedTerms && !!terms.acceptedLgpdTerms;

    const handleChangeCheckbox = () => {
        setAcceptLgpd(!acceptLgpd);
    };

    const handleAcceptLgpdTerms = () => {
        if (!acceptLgpd) return;

        dispatch(acceptLgpdTermsRequest());
    };

    useRedirectToDashboard(history, areAllTermsAccepted);

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;

        dispatch(termsAcceptanceRequest());
    }, [dispatch, status]);

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS && !terms?.acceptedTerms) {
            history.push('/accept-terms-of-use');
        }
    }, [status, history, terms]);

    useEffect(() => {
        return () => {
            dispatch(termsAcceptanceResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(acceptLgpdTermsResetState());
            dispatch(termsAcceptanceResetState());
        };
    }, [dispatch]);

    const isLoading: boolean = status === HttpRequestStatus.ONGOING;

    return (
        <Container>
            <Row>
                <div className="accept-lgpd-terms">
                    <Row>
                        <Col md="9" className="accept-lgpd-terms--container">
                            <div className="accept-lgpd-terms--title">
                                <span>{t(`${I18N_PREFIX}.description.start`)}</span>
                                {<Trans i18nKey={`${I18N_PREFIX}.description.middle`} />}
                                <span>{t(`${I18N_PREFIX}.description.contrast`)}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="accept-lgpd-terms--box">
                        <Col md="9" className="accept-lgpd-terms--container">
                            <ScrollableLgpdTerms />
                        </Col>
                    </Row>
                    <Row className="accept-lgpd-terms--box">
                        <Col md="9" className="accept-lgpd-terms--container">
                            <div className="accept-lgpd-terms__checkbox" onClick={handleChangeCheckbox}>
                                <Checkbox checked={acceptLgpd} />
                                <span>{t(`${I18N_PREFIX}.accept`)}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row className="accept-lgpd-terms--container">
                        <div className="accept-lgpd-terms__actions">{acceptLgpd && <ActionButton label={t(`${I18N_PREFIX}.continue`)} isLoading={isLoading} onClick={handleAcceptLgpdTerms} />}</div>
                    </Row>
                </div>
            </Row>
        </Container>
    );
};

export default AcceptLgpdTerms;
