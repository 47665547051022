import SimpleStackedBarChart from 'components/chart/bar-chart/stacked-bar-chart/simple-stacked-bar-chart';
import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import Loading from 'components/loading/loading';
import { FundAccountPeriod } from 'model/enums/fund-account-period';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { CapitalEvolution } from 'model/fund-account';
import moment from 'moment';
import React from 'react';
import { capitalEvolutionRequest } from 'reducer/fund-account/capital-evolution/actions';
import { useCapitalEvolutionState, useRootDispatch } from 'reducer/hooks';

interface CapitalEvolutionCardProps {
    cardTitles: string[];
    graphLabels: string[];
    graphTitle: string;
    buttons: string[];
    fundId: string | undefined | null;
}

interface CapitalEvolutionGraphProps extends Partial<CapitalEvolutionCardProps> {
    graphTitle: string;
    graphLabels: string[];
    data?: CapitalEvolution;
    fundId: string | undefined | null;
}

const formatLabel = (x: number): string => `${x.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
const formatValue = (value: number) => value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

const calculateAmountPercent = (totalValue: number, investorValue: number): number => {
    if (!totalValue) {
        return 0;
    }

    return (investorValue / totalValue) * 100;
};

const CapitalEvolutionTwelveMonthsBarGraph = (props: CapitalEvolutionGraphProps) => {
    const customizeUngroupedToolTip = (_id: string, _value: number, _color: unknown, x: number) => {
        const { data: capitalEvolution } = props;

        const participantAmount = capitalEvolution?.data[x].shareholderAmount ?? 0;
        const anchorAmount = capitalEvolution?.data[x].debentureAmount ?? 0;
        const totalAmount = participantAmount + anchorAmount;

        const participantAmountPercent = calculateAmountPercent(totalAmount, participantAmount).toFixed(2);
        const anchorAmountPercent = calculateAmountPercent(totalAmount, anchorAmount).toFixed(2);

        return `
            <div class="tooltip--block">
                <div class="tooltip--block-two">
                    <div class="tooltip--block-line">
                        <span class="tooltip--block-text"><b>${moment(props.data?.data[x]?.date).format('MM/YYYY')}</b></span>
                    </div>
                    <hr />
                    <div class="tooltip--block-line">
                        <div class="tooltip--block-color" style="background-color:#64b5f7;"></div>
                        <span class="tooltip--block-text">
                            ${props.graphLabels[0].replace(' ', '-')}: 
                            ${formatValue(props.data?.data[x]?.shareholderAmount ?? 0)} - ${participantAmountPercent}%
                        </span>
                    </div>
                </div>
                <div class="tooltip--block-two">
                    <div class="tooltip--block-line">
                        <div class="tooltip--block-color" style="background-color:#5790be;"></div>
                        <span class="tooltip--block-text">
                            ${props.graphLabels[1]}: 
                            ${formatValue(props.data?.data[x]?.debentureAmount ?? 0)} - ${anchorAmountPercent}%
                        </span>
                    </div>
                </div>
            </div>
        `;
    };

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-one">{props.graphTitle}</div>
            </div>
            <UngroupedChartToolTip className="capital-evolution-twelve-months" customize={customizeUngroupedToolTip}>
                {tooltip => (
                    <SimpleStackedBarChart
                        className="capital-evolution-twelve-months"
                        data={props.data?.data ?? []}
                        config={{
                            height: 260,
                            labelKey: 'dateMonth',
                            stackKeys: ['debentureAmount', 'shareholderAmount'],
                            stackLabelFormat: formatLabel,
                            rotateLabel: -45,
                            xHeight: 30,
                            colorPatern: ['#5790BE', '#64B5F7']
                        }}
                        tooltipOptions={tooltip}
                    >
                        {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={key => (key === 'debentureAmount' ? props.graphLabels[1] : props.graphLabels[0])} />}
                    </SimpleStackedBarChart>
                )}
            </UngroupedChartToolTip>
        </>
    );
};

const CapitalEvolutionFiveYearsBarGraph = (props: CapitalEvolutionGraphProps) => {
    const customizeUngroupedToolTip = (_id: string, _value: number, _color: unknown, x: number) => {
        const { data: capitalEvolution } = props;

        const participantAmount = capitalEvolution?.data[x].shareholderAmount ?? 0;
        const anchorAmount = capitalEvolution?.data[x].debentureAmount ?? 0;
        const totalAmount = participantAmount + anchorAmount;

        const participantAmountPercent = calculateAmountPercent(totalAmount, participantAmount).toFixed(2);
        const anchorAmountPercent = calculateAmountPercent(totalAmount, anchorAmount).toFixed(2);

        return `
            <div class="tooltip--block">
                <div class="tooltip--block-two">
                    <div class="tooltip--block-line">
                        <span class="tooltip--block-text"><b>${moment(props.data?.data[x]?.date).format('YYYY')}</b></span>
                    </div>
                    <hr />
                    <div class="tooltip--block-line">
                        <div class="tooltip--block-color" style="background-color:#64b5f7;"></div>
                        <span class="tooltip--block-text">
                            ${props.graphLabels[0].replace(' ', '-')}: 
                            ${participantAmountPercent}%
                        </span>
                    </div>
                    <div class="tooltip--block-line">
                        <span class="tooltip--block-text-two">
                            ${formatValue(props.data?.data[x]?.shareholderAmount ?? 0)}
                        </span>
                    </div>
                </div>
                <div class="tooltip--block-two">
                    <div class="tooltip--block-line">
                        <div class="tooltip--block-color" style="background-color:#5790be;"></div>
                        <span class="tooltip--block-text">
                            ${props.graphLabels[1]}:  
                            ${anchorAmountPercent}%
                        </span>
                    </div>
                    <div class="tooltip--block-line">
                        <span class="tooltip--block-text-two">
                            ${formatValue(props.data?.data[x]?.debentureAmount ?? 0)}
                        </span>
                    </div>
                </div>
            </div>
        `;
    };

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-one">{props.graphTitle}</div>
            </div>
            <UngroupedChartToolTip className="capital-evolution-five-years" customize={customizeUngroupedToolTip}>
                {tooltip => (
                    <SimpleStackedBarChart
                        className="capital-evolution-five-years"
                        data={props.data?.data!}
                        config={{
                            height: 260,
                            labelKey: 'dateYear',
                            stackKeys: ['shareholderAmount', 'debentureAmount'],
                            stackLabelFormat: formatLabel
                        }}
                        tooltipOptions={tooltip}
                    >
                        {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={key => (key === 'debentureAmount' ? props.graphLabels[1] : props.graphLabels[0])} />}
                    </SimpleStackedBarChart>
                )}
            </UngroupedChartToolTip>
        </>
    );
};

export const CapitalEvolutionCard = (props: CapitalEvolutionCardProps) => {
    const [years, setYears] = React.useState(false);

    const dispatch = useRootDispatch();
    const { report, status } = useCapitalEvolutionState();

    const isLoading: boolean = status === HttpRequestStatus.ONGOING;

    const capitalEvolution = React.useCallback(() => {
        if (!props.fundId) return;

        dispatch(
            capitalEvolutionRequest({
                id: props.fundId,
                period: years ? FundAccountPeriod.LAST_FIVE_YEARS : FundAccountPeriod.LAST_YEAR
            })
        );
    }, [dispatch, props.fundId, years]);

    React.useEffect(() => {
        capitalEvolution();
    }, [capitalEvolution]);

    React.useEffect(() => {
        report?.data
            ?.sort((a, b) => {
                const firstDate = new Date(a.date);
                const secoundDate = new Date(b.date);
                return firstDate.getTime() - secoundDate.getTime();
            })
            .forEach(it => {
                it.dateMonth = moment(it.date).format('MM/YYYY');
                it.dateYear = moment(it.date).format('YYYY');
            });
    }, [dispatch, report]);

    if (isLoading) {
        return (
            <div className={years ? 'items--card-block' : ''}>
                <div className="items--card-header">
                    <div className="items--card-header-title">{props.cardTitles.length > 1 ? `${props.cardTitles[0]}: ${props.cardTitles[1]}` : `${props.cardTitles[0]}`}</div>
                </div>
                <Loading />
            </div>
        );
    }

    return (
        <div className={'items--card-block'}>
            <div className="items--card-header">
                <div className="items--card-header-title">{props.cardTitles.length > 1 ? `${props.cardTitles[0]}: ${props.cardTitles[1]}` : `${props.cardTitles[0]}`}</div>
                <div className="items--card-header-buttons">
                    <button className={`items--card-header-buttons${!years ? '-active' : '-inactive'}`} onClick={() => setYears(false)}>
                        {props.buttons[0]}
                    </button>
                    <button className={`items--card-header-buttons${years ? '-active' : '-inactive'}`} onClick={() => setYears(true)}>
                        {props.buttons[1]}
                    </button>
                </div>
            </div>
            <div className="items--card-graph">
                {!years ? (
                    <CapitalEvolutionTwelveMonthsBarGraph fundId={props.fundId} data={report} graphLabels={props.graphLabels} graphTitle={props.graphTitle} />
                ) : (
                    <CapitalEvolutionFiveYearsBarGraph fundId={props.fundId} data={report} graphLabels={props.graphLabels} graphTitle={props.graphTitle} />
                )}
            </div>
        </div>
    );
};

export default CapitalEvolutionCard;
