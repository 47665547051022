import { YearlyIncomeStatement } from 'model/income-statement';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum GetYearlyIncomeStatementTypes {
    GET_YEARLY_INCOME_STATEMENT_REQUEST = '@@get-income-statement-yearly/GET_YEARLY_INCOME_STATEMENT_REQUEST',
    GET_YEARLY_INCOME_STATEMENT_SUCCESS = '@@get-income-statement-yearly/GET_YEARLY_INCOME_STATEMENT_SUCCESS',
    GET_YEARLY_INCOME_STATEMENT_ERROR = '@@get-income-statement-yearly/GET_YEARLY_INCOME_STATEMENT_ERROR',
    GET_YEARLY_INCOME_STATEMENT_RESET_STATE = '@@get-income-statement-yearly/GET_YEARLY_INCOME_STATEMENT_RESET_STATE'
}

export interface GetYearlyIncomeStatementState extends BaseStoreState {
    readonly yearlyIncomeStatement?: YearlyIncomeStatement;
}
