import React from 'react';
import { Legend, LegendProps } from 'recharts';
import { ChartUtils } from 'shared/util/chart-utils';
import { DEFAULT_LEGEND_PROPS } from './chart-components-style';

export type LegendPropsType = Omit<LegendProps, 'ref'>;

interface CustomizedLegendProps {
    legendProps?: LegendPropsType;
}

const CustomizedLegend = (props: CustomizedLegendProps) => {
    const { legendProps } = props;

    const mergedProps: LegendPropsType = ChartUtils.mergeProps(DEFAULT_LEGEND_PROPS, legendProps);

    return <Legend {...mergedProps} />;
};

export default CustomizedLegend;
