import React from 'react';
import { LoopCircleLoading } from 'react-loadingg';
import './loading.scss';

export const Loading = props => {
    return (
        <>
            {props.isTable ? (
                <tr className="loading--row">
                    <td className="loading--cell">
                        <LoopCircleLoading color="#3f41d1" />
                    </td>
                </tr>
            ) : (
                <div className="loading--div">
                    <LoopCircleLoading color="#3f41d1" />
                </div>
            )}
        </>
    );
};

export default Loading;
