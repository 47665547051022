import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { YearlyFinancialStatementMonth } from 'model/financial-statement-month';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import financialStatementMonthApi from 'services/api/financialStatementMonthApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { getYearlyFinancialStatementMonthError, getYearlyFinancialStatementMonthRequest, getYearlyFinancialStatementMonthSuccess } from './actions';
import { GetYearlyFinancialStatementMonthTypes } from './types';

function* handleGetYearlyFinancialStatementMonth(action: ReturnType<typeof getYearlyFinancialStatementMonthRequest>) {
    try {
        const result: AxiosResponse<YearlyFinancialStatementMonth> = yield call(financialStatementMonthApi.getYearlyFinancialStatementMonth, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getYearlyFinancialStatementMonthError(mapped));
            return;
        }
        yield put(getYearlyFinancialStatementMonthSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getYearlyFinancialStatementMonthError(mapped));
    }
}

function* watchGetYearlyFinancialStatementMonth() {
    yield takeEvery(GetYearlyFinancialStatementMonthTypes.GET_YEARLY_FINANCIAL_STATEMENT_MONTH_REQUEST, handleGetYearlyFinancialStatementMonth);
}

function* getYearlyFinancialStatementMonthSaga() {
    yield all([fork(watchGetYearlyFinancialStatementMonth)]);
}

export default getYearlyFinancialStatementMonthSaga;
