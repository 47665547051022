import { SeriesPosition } from 'model/share-history';
import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { SeriesPositionActionTypes } from './types';
import { SeriesPositionFilter } from 'model/enums/my-position';

export const seriesPositionRequest = (seriesAndPersonId: SeriesPositionFilter) => action(SeriesPositionActionTypes.SERIES_POSITION_REQUEST, seriesAndPersonId);
export const seriesPositionSuccess = (response: SeriesPosition) => action(SeriesPositionActionTypes.SERIES_POSITION_SUCCESS, response);
export const seriesPositionError = (error: GiroWebappError) => action(SeriesPositionActionTypes.SERIES_POSITION_ERROR, error);
export const seriesPositionResetState = () => action(SeriesPositionActionTypes.SERIES_POSITION_RESET_STATE);
