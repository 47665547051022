import { PersonToProfile } from 'model/personAccount';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum PersonProfileActionTypes {
    PERSON_PROFILE_REQUEST = '@@personProfile/PERSON_PROFILE_REQUEST',
    PERSON_PROFILE_SUCCESS = '@@personProfile/PERSON_PROFILE_SUCCESS',
    PERSON_PROFILE_ERROR = '@@personProfile/PERSON_PROFILE_ERROR',
    PERSON_PROFILE_RESET_STATE = '@@personProfile/PERSON_PROFILE_RESET_STATE'
}

export interface PersonProfileState extends BaseStoreState {
    readonly profile?: PersonToProfile;
}
