import React from 'react';
import { ValidationResult } from '../../shared/util/validation-utils';
import FieldBasic from '../field-basic/field-basic';

export interface InputBankAccountNumberProps {
    label: string;
    value?: string;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    readOnly?: boolean;
    validate?: (value: string) => ValidationResult;
    autoComplete?: string;
    onEnter?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    onChange?: (value: string) => void;
    showValidation?: boolean;
    externalUpdate?: boolean;
}

export const InputBankAccountNumber = (props: InputBankAccountNumberProps) => {
    const handleChange = (value: string) => {
        if (props.onChange) props.onChange(value.replace(/\D/g, ''));
    };

    const handleValidation = (value: string): ValidationResult => {
        if (props.validate) {
            return props.validate(value.replace(/\D/g, ''));
        }
        return { isValid: true };
    };

    return (
        <FieldBasic
            label={props.label}
            readOnly={props.readOnly}
            value={props.value ?? ''}
            autoFocus={props.autoFocus}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            validate={handleValidation}
            autoComplete={props.autoComplete}
            onEnter={props.onEnter}
            onChange={handleChange}
            showValidation={props.showValidation}
            externalUpdate={props.externalUpdate}
            hasExternalSanitization
        />
    );
};

export default InputBankAccountNumber;
