import EmptyFundWarning from 'components/empty-fund-warning/empty-fund-warning';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import FundReceivablesCard from 'shared/debenture-cards/fund-receivables/fund-receivables-card';
import SeriesPositionSelectCard from 'shared/debenture-cards/series-position/series-position-card-select';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';

import FeatureFlag from 'components/feature-flag/feature-flag';
import { UnleashFeatureToggleCaptureFlag, UnleashFeatureToggleGeneralFlag } from 'config/unleash/unleash-feature-toggle-flag';
import CapitalEvolutionCard from 'features/anchor/capture/components/capital-evolution-card';
import './funds.scss';

const ParticipantFunds = () => {
    const { t } = useTranslation();

    const { selectedFundAndSeries } = useFundAndSeriesContext();

    if (!selectedFundAndSeries?.fundId) {
        return (
            <div className="participant-general__items">
                <EmptyFundWarning />
            </div>
        );
    }

    const FundReceivablesGraph: JSX.Element = (
        <Card className="participant-general__items--card">
            <div className="items--card-one">
                <FundReceivablesCard
                    fundId={selectedFundAndSeries.fundId}
                    cardTitle={t('participant.general.cards.receivables.card-title')}
                    cardLegends={[t('participant.general.cards.receivables.card-label-one'), t('participant.general.cards.receivables.card-label-two'), t('participant.general.cards.receivables.card-label-three')]}
                    graphLabels={[t('participant.general.cards.receivables.graph-label-one'), t('participant.general.cards.receivables.graph-label-two')]}
                />
            </div>
        </Card>
    );

    const SeriesPositionSelectGraph: JSX.Element = (
        <Card className="participant-general__items--card">
            <div className="items--card-three">
                <SeriesPositionSelectCard seriesId={selectedFundAndSeries.seriesId} />
            </div>
        </Card>
    );

    const CapitalEvolutionGraph: JSX.Element = (
        <Card className="anchor-capture__items--card">
            <div className="items--card-protection">
                <CapitalEvolutionCard
                    fundId={selectedFundAndSeries.fundId}
                    cardTitles={[t('participant.funds.cards.capital-evolution.title')]}
                    graphLabels={[t('participant.funds.cards.capital-evolution.labels.participant'), t('participant.funds.cards.capital-evolution.labels.anchor')]}
                    graphTitle={t('anchor.capture.cards.capital-evolution.graph-title-one')}
                    buttons={[t('anchor.capture.cards.capital-evolution.button-twelve-months'), t('anchor.capture.cards.capital-evolution.button-five-years')]}
                />
            </div>
        </Card>
    );

    return (
        <div className="participant-general__items">
            <Container>
                <Row style={{ paddingBottom: '70px' }}>
                    <Col md="5">
                        <FeatureFlag flagName={UnleashFeatureToggleGeneralFlag.RECEIVABLES_GRAPH} component={FundReceivablesGraph} />
                    </Col>
                    <Col md="7">
                        <FeatureFlag flagName={UnleashFeatureToggleGeneralFlag.SERIES_POSITION_GRAPH} component={SeriesPositionSelectGraph} />
                        <FeatureFlag flagName={UnleashFeatureToggleCaptureFlag.CAPITAL_EVOLUTION} component={CapitalEvolutionGraph} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParticipantFunds;
