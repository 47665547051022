import jwt_decode from 'jwt-decode';
import { DecodedToken, Token } from '../../model/authentication';
import { Authority } from '../../model/enums/authority';
import LoggingUtils from '../../shared/util/logging-utils';

export interface TokenDTO {
    sub?: string;
    auth?: string;
    exp?: number;
}

class AuthUtil {
    private AUTH_TOKEN_KEY = 'giro-authenticationToken';

    isAuthenticated(): boolean {
        return localStorage.getItem(this.AUTH_TOKEN_KEY) !== null;
    }

    getToken(): Token | null {
        const value: string | null = localStorage.getItem(this.AUTH_TOKEN_KEY);
        return value === null ? null : { id_token: value };
    }

    decode(): DecodedToken {
        const value: string | null = localStorage.getItem(this.AUTH_TOKEN_KEY);
        if (value === null || value === undefined || value?.trim() === '') return {};
        try {
            const decoded = jwt_decode<TokenDTO>(value);
            const authorities: Authority[] =
                decoded?.auth
                    ?.split(',')
                    ?.map(it => Authority[it] ?? undefined)
                    ?.filter(it => !!it) ?? [];
            return { login: decoded?.sub, authorities };
        } catch (error) {
            LoggingUtils.logInfo('Token could not be decoded', error);
            return {};
        }
    }

    setToken(token: Token) {
        localStorage.setItem(this.AUTH_TOKEN_KEY, token.id_token);
    }

    removeToken() {
        localStorage.removeItem(this.AUTH_TOKEN_KEY);
    }
}

export default new AuthUtil();
