export enum ErrorType {
    NON_BLOCKING = 'NON_BLOCKING',
    BLOCKING = 'BLOCKING',
    IGNORED = 'IGNORED'
}

export enum GiroApiErrorConstants {
    // general
    ERR_CONCURRENCY_FAILURE = 'error.concurrencyFailure',
    ERR_VALIDATION = 'error.validation',
    ERR_ACCESS_DENIED = 'error.accessDenied',
    ERR_INTERNAL_SERVER_ERROR = 'error.internalServerError',

    // authentication
    UNAUTHORIZED = 'error.http.401',
    BAD_CREDENTIALS = 'error.authentication.badCredentials',

    // user
    USER_NOT_ACTIVATE = 'error.user.notactive',
    USER_NOT_FOUND = 'error.user.notFound',

    // person
    PERSON_NOT_FOUND = 'error.person.notFound',
    PERSON_DOES_NOT_HAVE_ACCESS_TO_FUND = 'error.person.doesNotHaveAccessToFund',
    PERSON_DOES_NOT_HAVE_ACCESS_TO_FUND_BY_INVESTOR = 'error.person.doesNotHaveAccessToFundByInvestor',
    PERSON_DOES_NOT_HAVE_ACCESS_TO_SERIES = 'error.person.doesNotHaveAccessToSeries',
    PERSON_DOES_NOT_HAVE_ACCESS_TO_SERIES_BY_INVESTOR = 'error.person.doesNotHaveAccessToSeriesByInvestor',

    // shareholder-invite
    SHAREHOLDER_INVITE_NOT_FOUND = 'error.shareholderInvite.notFound',
    SHAREHOLDER_INVITE_WRONG_STATUS = 'error.shareholderInvite.wrongStatus',
    SHAREHOLDER_INVITE_PASSWORD_DOES_NOT_MATCH = 'error.shareholderInvite.passwordDoesNotMatch',
    SHAREHOLDER_INVITE_PHONE_ABSENT = 'error.shareholderInvite.phoneAbsent',
    SHAREHOLDER_INVITE_PENDING_TERMS_ACCEPTANCE = 'error.shareholderInvite.pendingTermsAcceptance'
}

export enum GiroWebappErrorConstants {
    // http
    HTTP_UNAUTHORIZED = 'error.http.unauthorized',
    HTTP_FORBIDDEN = 'error.http.forbidden',

    // invite
    INVITE_INVALID = 'error.invite.invalid',
    INVITE_STATUS_CONFIRMED = 'error.invite.confirmed',
    INVITE_STATUS_WAITING_CONFIRMATION = 'error.invite.waitingConfirmation',
    INVITE_STATUS_CLOSED = 'error.invite.closed',

    // user
    USER_NOT_ACTIVATED = 'error.user.notActivated',

    // fund
    SHAREHOLDER_NO_FUNDS = 'error.shareholder.noFunds',

    // series
    SHAREHOLDER_NO_SERIES = 'error.shareholder.noSeries',
    SHAREHOLDER_MULTIPLE_SERIES = 'error.shareholder.multipleSeries'
}

export const personUnauthorizedErrors: string[] = [
    GiroApiErrorConstants.PERSON_DOES_NOT_HAVE_ACCESS_TO_FUND,
    GiroApiErrorConstants.PERSON_DOES_NOT_HAVE_ACCESS_TO_FUND_BY_INVESTOR,
    GiroApiErrorConstants.PERSON_DOES_NOT_HAVE_ACCESS_TO_SERIES,
    GiroApiErrorConstants.PERSON_DOES_NOT_HAVE_ACCESS_TO_SERIES_BY_INVESTOR
];

export type ErrorConstants = GiroApiErrorConstants | GiroWebappErrorConstants;
