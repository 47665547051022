import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { PersonTermsAcceptance } from '../../../model/person';
import accountApi from '../../../services/api/accountApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { termsAcceptanceError, termsAcceptanceSuccess } from './actions';
import { TermsAcceptanceActionTypes } from './types';

function* handleTermsAcceptance() {
    try {
        const result: AxiosResponse<PersonTermsAcceptance> = yield call(accountApi.termsAcceptance);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(termsAcceptanceError(mapped));
            return;
        }
        yield put(termsAcceptanceSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(termsAcceptanceError(mapped));
    }
}

function* watchTermsAcceptanceRequest() {
    yield takeEvery(TermsAcceptanceActionTypes.TERMS_ACCEPTANCE_REQUEST, handleTermsAcceptance);
}

function* termsAceeptanceSaga() {
    yield all([fork(watchTermsAcceptanceRequest)]);
}

export default termsAceeptanceSaga;
