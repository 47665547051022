import { SearchRequest } from 'model/searchRequest';
import { AxiosResponse } from 'axios';
import { CityToSearch } from 'model/personAccount';
import { Page } from 'services/page';
import { api } from './api';

export const cities = () => {
    const searchCities = (request: SearchRequest): Promise<AxiosResponse<Page<CityToSearch>>> => {
        return api.get<Page<CityToSearch>>(`api/cities/simple?search=${encodeURIComponent(request.search)}`, {
            params: {...request.pageable},
        });
    };

    return {
        searchCities
    };
};

export default cities();
