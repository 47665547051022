import { createStyles, Theme, withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import React from 'react';

interface ToggleSwitchProps {
    checked?: boolean;
    disabled?: boolean;
    onChange?: (value: boolean) => void;
    name: string;
}

const SwitchElement = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 44,
            height: 20,
            padding: 0,
            display: 'flex'
        },
        switchBase: {
            padding: 0.5,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(24px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: '#30ab64',
                    borderColor: '#30ab64'
                }
            }
        },
        thumb: {
            width: 19,
            height: 19,
            boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.5)'
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 28 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white
        },
        checked: {}
    })
)(Switch);

export const ToggleSwitch = (props: ToggleSwitchProps) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.disabled) {
            return;
        }
        
        if (props.onChange) {
            props.onChange(event.target.checked);
        }
    };

    return (
        <div style={{ display: 'flex', alignSelf: 'center', height: 'auto' }}>
            <SwitchElement checked={props.checked} onChange={handleChange} name={props.name} />
        </div>
    );
};

export default ToggleSwitch;
