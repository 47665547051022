import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Token } from '../../model/authentication';
import HttpStatus from '../../model/enums/httpStatus';
import { PersonTermsAcceptance } from '../../model/person';
import { UserAccount } from '../../model/user';
import accountApi from '../../services/api/accountApi';
import authenticationApi from '../../services/api/authenticationApi';
import AuthUtil from '../../services/api/authUtil';
import { ErrorUtils } from '../../shared/error/error-utils';
import LoggingUtils from '../../shared/util/logging-utils';
import { termsAcceptanceError, termsAcceptanceResetState, termsAcceptanceSuccess } from '../account/terms-acceptance/actions';
import { userAccountError, userAccountResetState, userAccountSuccess } from '../account/user-account/actions';
import { markError } from '../application/error/actions';
import { loginError, loginRequest, loginSuccess } from './actions';
import { AuthenticationActionTypes } from './types';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
    try {
        const result: AxiosResponse<Token> = yield call(authenticationApi.login, action.payload);

        if (result.status !== 200 || !result.data) {
            AuthUtil.removeToken();
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(loginError(mapped));
            return;
        }

        const token: Token = result.data;
        AuthUtil.setToken(token);

        yield put(loginSuccess(result.data));
    } catch (error) {
        AuthUtil.removeToken();
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(loginError(mapped));
    }
}

function* watchLoginRequest() {
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* handleFullLogin(action: ReturnType<typeof loginRequest>) {
    try {
        const result: AxiosResponse<Token> = yield call(authenticationApi.login, action.payload);

        if (result.status !== 200 || !result.data) {
            AuthUtil.removeToken();
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(loginError(mapped));
            return;
        }

        const token: Token = result.data;
        AuthUtil.setToken(token);

        yield recoverUserAccount();

        yield put(loginSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(loginError(mapped));
    }
}

function* recoverUserAccount() {
    const result: AxiosResponse<UserAccount> = yield call(accountApi.recoverAccount);
    if (result.status !== HttpStatus.OK) {
        const mapped = ErrorUtils.mapGiroIdhApiError(result);
        yield put(markError(mapped));
        yield put(userAccountError(mapped));
        return;
    }
    yield checkTermsAcceptance();
    yield put(userAccountSuccess(result.data));
}

function* checkTermsAcceptance() {
    const result: AxiosResponse<PersonTermsAcceptance> = yield call(accountApi.termsAcceptance);
    if (result.status !== HttpStatus.OK) {
        const mapped = ErrorUtils.mapGiroIdhApiError(result);
        yield put(markError(mapped));
        yield put(termsAcceptanceError(mapped));
        return;
    }

    yield put(termsAcceptanceSuccess(result.data));
}

function* watchFullLoginRequest() {
    yield takeEvery(AuthenticationActionTypes.FULL_LOGIN_REQUEST, handleFullLogin);
}

function* handleLogoutRequest() {
    try {
        AuthUtil.removeToken();
        yield put(userAccountResetState());
        yield put(termsAcceptanceResetState());
    } catch (error) {
        LoggingUtils.debugInfo('Could not process logout', error);
    }
}

function* watchLogoutRequest() {
    yield takeEvery(AuthenticationActionTypes.LOGOUT_REQUEST, handleLogoutRequest);
}

function* authenticationSaga() {
    yield all([fork(watchLoginRequest), fork(watchFullLoginRequest), fork(watchLogoutRequest)]);
}

export default authenticationSaga;
