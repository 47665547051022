import { DatePeriod } from './enums/date-period';
import { ShareholderShareReportType } from './enums/shareholder-share-report-type';
import { PersonInvestorFilter } from './person';

export interface ShareholderAccountHistoryRequest extends PersonInvestorFilter {
    fundName?: string;
    fundId?: string;
    seriesId?: string;
    startDate?: string;
    endDate?: string;
    datePeriod?: DatePeriod;
}

export const defaultAccountFilterRequest: ShareholderAccountHistoryRequest = {
    datePeriod: DatePeriod.THREE_MONTHS
};

export interface ShareholderAccountHistory {
    issuerName: string;
    seriesName: string;
    shareQuantity: number;
    date: string;
    investmentValue: number;
    netValue: number;
    redemptionValue: number;
    revenueValue: number;
    taxValue: number;
    type: ShareholderShareReportType;
}
