import clsx from 'clsx';
import CurrencyFormatter from 'components/fomatter/currency/currency-formatter';
import _ from 'lodash';
import { YearlyIncomeStatementGroupBalances } from 'model/income-statement';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface YearlyIncomeStatementRowProps {
    years: ReadonlyArray<number>;
    balances: ReadonlyArray<YearlyIncomeStatementGroupBalances>;
    className?: string;
}

const YearlyIncomeStatementRow = (props: YearlyIncomeStatementRowProps) => {
    const { balances, className, years } = props;

    const filteredBalances = (year: number) => balances?.filter(value => value?.year === year).find(balance => !_.isNil(balance.value));

    return (
        <>
            {years.map(year => (
                <td
                    key={year ?? uuidv4()}
                    className={clsx('page-container--table__row-items', className, {
                        NEGATIVE_VALUES: _.lt(filteredBalances(Number(year))?.value, 0)
                    })}
                >
                    <span>{!_.isNil(filteredBalances(Number(year))?.value) ? <CurrencyFormatter value={filteredBalances(Number(year))?.value} prefix /> : '-'}</span>
                </td>
            ))}
        </>
    );
};
export default YearlyIncomeStatementRow;
