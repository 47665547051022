import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './empty-collection-aware.scss';

export interface EmptyAware<T> {
    collection: readonly T[];
    className?: string;
    label: { key: string; params?: any };
    children: React.ReactNode;
    isTable?: boolean;
}

const EmptyCollectionAware = <T extends {}>(props: EmptyAware<T>) => {
    const { collection, className, label, children } = props;

    const { t } = useTranslation();

    if (collection === null || collection === undefined || collection.length <= 0) {
        return (
            <>
                {props.isTable ? (
                    <>
                        {(label.key || label.params) && (
                            <tr>
                                <td className={clsx('empty--text', className)}>{t(label.key, label.params)}</td>
                            </tr>
                        )}
                    </>
                ) : (
                    <>{(label.key || label.params) && <span className={clsx('empty--text', className)}>{t(label.key, label.params)}</span>}</>
                )}
            </>
        );
    }

    return <>{children}</>;
};

export default EmptyCollectionAware;
