import React from 'react';

const SelectAllChartBar = () => {
    return (
        <div className="select-all-secondary-access-modal--items__chart">
            <div className="select-all-secondary-access-modal--items__chart-bar" />
            <div className="select-all-secondary-access-modal--items__chart-bar" />
            <div className="select-all-secondary-access-modal--items__chart-bar" />
            <div className="select-all-secondary-access-modal--items__chart-bar" />
        </div>
    );
};

export default SelectAllChartBar;
