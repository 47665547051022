import React from 'react';
import { useTranslation } from 'react-i18next';
import './scrollable-lgpd-terms.scss';

const I18N_PREFIX = 'accept-lgpd-terms';

const ScrollableLgpdTerms = () => {
    const { t } = useTranslation();

    return (
        <div className="scrollable-lgpd-terms">
            <div className="scrollable-lgpd-terms--list-section">
                <div className="scrollable-lgpd-terms--text">
                    <h3>{t(`${I18N_PREFIX}.title`).toUpperCase()}</h3>
                    <div>
                        <p className="scrollable-lgpd-terms--text__paragraph">{t(`${I18N_PREFIX}.introduction`)}</p>
                        <p className="scrollable-lgpd-terms--text__paragraph">{t(`${I18N_PREFIX}.understanding`)}</p>
                    </div>
                    <div>
                        <h4>{t(`${I18N_PREFIX}.terms.personalData.title`).toUpperCase()}</h4>
                        <p className="scrollable-lgpd-terms--text__subtitle">{t(`${I18N_PREFIX}.terms.personalData.description`)}</p>
                        <ul>
                            <li>{t(`${I18N_PREFIX}.terms.personalData.first-item`)}</li>
                        </ul>
                    </div>
                    <div>
                        <h4>{t(`${I18N_PREFIX}.terms.finality.title`).toUpperCase()}</h4>
                        <p className="scrollable-lgpd-terms--text__subtitle">{t(`${I18N_PREFIX}.terms.finality.description`)}</p>
                        <ul>
                            <li>{t(`${I18N_PREFIX}.terms.finality.first-item`)}</li>
                        </ul>
                    </div>
                    <div>
                        <h4>{t(`${I18N_PREFIX}.terms.security.title`).toUpperCase()}</h4>
                        <p className="scrollable-lgpd-terms--text__subtitle">{t(`${I18N_PREFIX}.terms.security.description`)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScrollableLgpdTerms;
