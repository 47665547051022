import FeatureFlag from 'components/feature-flag/feature-flag';
import { UnleashFeatureToggleGeneralFlag } from 'config/unleash/unleash-feature-toggle-flag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import FundReceivablesCard from 'shared/debenture-cards/fund-receivables/fund-receivables-card';
import ResourcesSourceCard from 'shared/debenture-cards/resources-source/resources-source-card';
import SeriesPositionCard from 'shared/debenture-cards/series-position/series-position-card';
import SharePerformanceCard from 'shared/debenture-cards/share-performance/share-performance-card';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import EmptyFundWarning from '../../../components/empty-fund-warning/empty-fund-warning';

import './anchor-general.scss';

const AnchorGeneral = () => {
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const { t } = useTranslation();

    if (!selectedFundAndSeries?.fundId) {
        return (
            <div className="anchor-general__items">
                <EmptyFundWarning />
            </div>
        );
    }

    const FundReceivablesGraph: JSX.Element = (
        <Card className="anchor-general__items--card">
            <div className="items--card-one">
                <FundReceivablesCard
                    fundId={selectedFundAndSeries?.fundId}
                    cardTitle={t('anchor.general.cards.receivables.card-title')}
                    cardLegends={[t('anchor.general.cards.receivables.card-label-one'), t('anchor.general.cards.receivables.card-label-two'), t('anchor.general.cards.receivables.card-label-three')]}
                    graphLabels={[t('anchor.general.cards.receivables.graph-label-one'), t('anchor.general.cards.receivables.graph-label-two')]}
                />
            </div>
        </Card>
    );

    const ResourcesSourcesGraph: JSX.Element = (
        <Card className="anchor-general__items--card">
            <div className="items--card-two">
                <ResourcesSourceCard fundId={selectedFundAndSeries.fundId} />
            </div>
        </Card>
    );

    const SeriesPositionGraph: JSX.Element = (
        <Card className="anchor-general__items--card">
            <div className="items--card-three">
                <SeriesPositionCard seriesId={selectedFundAndSeries.seriesId} />
            </div>
        </Card>
    );

    const SharePerformanceGraph: JSX.Element = (
        <Card className="anchor-general__items--card">
            <SharePerformanceCard seriesId={selectedFundAndSeries.seriesId} />
        </Card>
    );

    return (
        <div className="anchor-general__items">
            <Container>
                <Row style={{ paddingBottom: '70px' }}>
                    <Col md="5">
                        <FeatureFlag flagName={UnleashFeatureToggleGeneralFlag.RECEIVABLES_GRAPH} component={FundReceivablesGraph} />
                        <FeatureFlag flagName={UnleashFeatureToggleGeneralFlag.RESOURCES_SOURCE_GRAPH} component={ResourcesSourcesGraph} />
                    </Col>
                    <Col md="7">
                        <FeatureFlag flagName={UnleashFeatureToggleGeneralFlag.SERIES_POSITION_GRAPH} component={SeriesPositionGraph} />
                        <FeatureFlag flagName={UnleashFeatureToggleGeneralFlag.SHARE_PERFORMANCE_GRAPH} component={SharePerformanceGraph} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AnchorGeneral;
