import { AxiosResponse } from 'axios';
import { FundHistory, Portfolio } from 'model/fund';
import { all, call, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import fundApi from '../../../services/api/fundApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { fundHistoryError, fundHistoryRequest, fundHistorySuccess, portfolioError, portfolioRequest, portfolioSuccess } from './actions';
import { MyFundsActionTypes } from './types';

function* handleGetPortfolio(_action: ReturnType<typeof portfolioRequest>) {
    try {
        const result: AxiosResponse<Portfolio> = yield call(fundApi.getPortfolio);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(portfolioError(mapped));
            return;
        }
        yield put(portfolioSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(portfolioError(mapped));
    }
}

function* handleGetFundHistory(_action: ReturnType<typeof fundHistoryRequest>) {
    try {
        const result: AxiosResponse<FundHistory> = yield call(fundApi.getFundHistory);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(fundHistoryError(mapped));
            return;
        }
        yield put(fundHistorySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(fundHistoryError(mapped));
    }
}

function* watchGetPortfolio() {
    yield takeLeading(MyFundsActionTypes.PORTFOLIO_REQUEST, handleGetPortfolio);
}

function* watchGetFundHistory() {
    yield takeEvery(MyFundsActionTypes.FUND_HISTORY_REQUEST, handleGetFundHistory);
}

function* myFundsSaga() {
    yield all([fork(watchGetPortfolio), fork(watchGetFundHistory)]);
}

export default myFundsSaga;
