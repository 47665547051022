import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { FundResourcesSourceActionTypes, FundResourcesSourceState } from './types';

export const initialState: FundResourcesSourceState = {
    status: HttpRequestStatus.NOOP,
    source: undefined,
    error: undefined
};

const reducer: Reducer<FundResourcesSourceState> = (state = initialState, action): FundResourcesSourceState => {
    switch (action.type) {
        case FundResourcesSourceActionTypes.FUND_RESOURCES_SOURCE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, source: undefined, error: undefined };
        }
        case FundResourcesSourceActionTypes.FUND_RESOURCES_SOURCE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, source: action.payload, error: undefined };
        }
        case FundResourcesSourceActionTypes.FUND_RESOURCES_SOURCE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case FundResourcesSourceActionTypes.FUND_RESOURCES_SOURCE_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as fundResourcesSourceReducer };
