import { MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAddressNumber from 'components/input-address-number/input-address-number';
import InputAddressZipCode from 'components/input-address-zip-code/input-address-zip-code';
import InputAddress from 'components/input-address/input-address';
import InputBankAccountNumber from 'components/input-bank-account-number/input-bank-account-number';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ModalSearch from 'components/modal-search/modal-search';
import ValidSelectInput from 'components/select-input/valid-select-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'config/constants';
import { BankToSearch } from 'model/bank-account';
import { BankAccountType } from 'model/enums/bank-account-type';
import { PhoneType } from 'model/enums/phone-type';
import { WhatsAppStatus } from 'model/enums/whatsapp-status';
import { CityToSearch } from 'model/personAccount';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { searchBanksRequest } from 'reducer/bank/actions';
import { searchCitiesRequest } from 'reducer/city/actions';
import { usePersonProfileState } from 'reducer/hooks';
import FieldPassword from '../../../../components/field-password/field-password';
import InputEmail from '../../../../components/input-email/input-email';
import PasswordStrength from '../../../../components/password-strength/password-strength';
import PhoneInput from '../../../../components/phone-input/phone-input';
import { useProfileContext } from '../../profile-context';
import { validateAddressCity, validateAddressComplement, validateAddressNeighborhood, validateAddressNumber, validateAddressStreet, validateAddressZipCode } from '../../validation/address-update-validation';
import { validateBank, validateBankAccountDigit, validateBankAccountNumber, validateBankAccountType, validateBankAgencyDigit, validateBankAgencyNumber, validateBankProfileUpdate } from '../../validation/bank-account-update-validation';
import { PasswordMatch, validateEmail, validatePassword, validatePasswordMatch, validatePhoneNumber, validatePhoneType } from '../../validation/validation';
import ToggleSwitch from '../toggle-switch/toggle-switch';
import './profile-editing.scss';

const mapPasswordMatch = (p: string, m: string): PasswordMatch => ({ password: p, match: m });

export const ProfileEditing = () => {
    const { t } = useTranslation();

    const { handleUpdateProfile, newProfile, setNewProfile, isAddingBankAccount, setIsAddingBankAccount, handlePasswordChange, changePassword, showValidation, newBankAccount, setNewBankAccount, handleNewAccount } = useProfileContext();
    const { profile } = usePersonProfileState();

    React.useEffect(() => {
        if (profile) {
            setNewProfile({
                id: profile?.id,
                name: profile?.name,
                identification: profile?.identification,
                email: profile?.email,
                phone: { id: profile?.phone?.id, number: profile?.phone?.number, type: profile?.phone?.type, whatsAppStatus: profile?.phone?.whatsAppStatus },
                address: {
                    id: profile?.address?.id,
                    name: profile?.address?.name,
                    type: profile?.address?.type,
                    street: profile?.address?.street,
                    number: profile?.address?.number,
                    complement: profile?.address?.complement,
                    neighborhood: profile?.address?.neighborhood,
                    zipCode: profile?.address?.zipCode,
                    city: { id: profile?.address?.city?.id, name: profile?.address?.city?.name, stateAcronym: profile?.address?.city?.stateAcronym, stateName: profile?.address?.city?.stateName }
                },
                type: profile?.type,
                accounts: [...profile?.accounts]
            });
        }
    }, [profile, setNewProfile]);

    const handleCancelEditBankAccount = () => {
        setIsAddingBankAccount(false);
    };

    const handleWhatsappStatus = (receiveWhatsapp: boolean) => {
        if (receiveWhatsapp) {
            handleUpdateProfile({ phone: { ...newProfile?.phone, whatsAppStatus: WhatsAppStatus.RECEIVE } });
            return;
        }
        handleUpdateProfile({ phone: { ...newProfile?.phone, whatsAppStatus: WhatsAppStatus.NOT_RECEIVE } });
    };

    const handleActiveAccount = (isActive: boolean, id: string) => {
        const account = newProfile.accounts?.find(it => it.id === id);
        if (!account) {
            return;
        }
        account.active = !isActive;
        if (!account.active) {
            account.mainAccount = false;
        }
        handleUpdateProfile({ ...newProfile });
    };

    const handleMainAccount = (isMainAccount: boolean, id: string) => {
        const account = newProfile.accounts?.find(it => it.id === id);
        const otherAccounts = newProfile.accounts?.filter(it => it.id !== id);
        if (!account) {
            return;
        }
        if (!otherAccounts) {
            return;
        }
        account.mainAccount = !isMainAccount;
        if (account.mainAccount) {
            account.active = true;
        }
        otherAccounts.map(it => (it.mainAccount = false));
        handleUpdateProfile({ ...newProfile });
    };

    return (
        <div style={{ background: 'white' }} className="account-profile-editing__items">
            <div className="profile-editing__items">
                <div className="container">
                    <Grid id="data">
                        <div className="mouse-hover-behavior">
                            <Grid item className="personal-data__items--title">
                                {t('profile.data.title')}
                            </Grid>
                            <Grid item xs={12} className="profile-editing__items--fields">
                                <Grid item xs={7}>
                                    <InputEmail label={t('profile.data.email.label')} email={newProfile?.email} onChange={email => handleUpdateProfile({ email })} validate={validateEmail} showValidation externalUpdate />
                                </Grid>
                                <Grid item xs={7}>
                                    <PhoneInput
                                        label={t('profile.data.main-contact.label')}
                                        value={newProfile.phone?.number}
                                        onChange={phone => handleUpdateProfile({ phone: { ...newProfile?.phone, number: phone } })}
                                        validate={validatePhoneNumber}
                                        showValidation
                                        externalUpdate
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <ValidSelectInput
                                        label={'profile.data.main-contact.select-phone-type'}
                                        value={newProfile?.phone?.type ?? ''}
                                        mapperFromString={_value => (_value ? PhoneType[_value] : undefined)}
                                        placeholder={'profile.data.main-contact.select-phone-type'}
                                        onChange={phoneType => handleUpdateProfile({ phone: { ...newProfile?.phone, type: PhoneType[phoneType] } })} // handleUpdateProfile({ accounts: [{ ...newProfile.accounts[0], type: BankAccountType[accountType] }] })}
                                        validate={validatePhoneType}
                                        showValidation
                                        externalUpdate
                                    >
                                        <MenuItem value={PhoneType.HOME}>{t('profile.data.main-contact.HOME')}</MenuItem>
                                        <MenuItem value={PhoneType.CELLULAR}>{t('profile.data.main-contact.CELLULAR')}</MenuItem>
                                        <MenuItem value={PhoneType.PROFESSIONAL}>{t('profile.data.main-contact.PROFESSIONAL')}</MenuItem>
                                        <MenuItem value={PhoneType.OTHER}>{t('profile.data.main-contact.OTHER')}</MenuItem>
                                    </ValidSelectInput>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid id="address">
                        <div className="mouse-hover-behavior">
                            <Grid item className="personal-data__items--title">
                                {t('profile.address.title')}
                            </Grid>
                            <Grid item xs={12} className="profile-editing__items--address">
                                <Grid item xs={7}>
                                    <ModalSearch<CityToSearch>
                                        id="inputText_addressData_modal_city"
                                        itemSize={SEARCH_ITEM_SIZE_LARGE}
                                        action={searchCitiesRequest}
                                        modalTitle={t(`profile.address.modal.title`)}
                                        modalLabel={t(`profile.address.modal.label`)}
                                        modalPlaceholder={t(`profile.address.modal.placeholder`)}
                                        onSelect={city => handleUpdateProfile({ address: { ...newProfile?.address, city: { id: city?.id, name: city?.name, stateAcronym: city?.stateAcronym } } })}
                                        renderItem={city => (city.name && city.stateAcronym ? `${city.name} - ${city.stateAcronym}` : city.name)}
                                        statusSelector={(state: IRootState) => state.searchCities.status}
                                        dataSelector={(state: IRootState) => state.searchCities.cities}
                                    >
                                        {handleOpen => (
                                            <ValidSearchInput
                                                id="inputText_addressData_city"
                                                label={`profile.address.modal.title`}
                                                placeholder={`profile.address.select-city`}
                                                value={newProfile?.address?.city?.name ? `${newProfile?.address?.city?.name} - ${newProfile?.address?.city?.stateAcronym}` : ''}
                                                onClick={handleOpen}
                                                onFocus={handleOpen}
                                                validate={validateAddressCity}
                                                showValidation
                                                readOnly
                                                externalUpdate
                                            />
                                        )}
                                    </ModalSearch>
                                </Grid>
                                <Grid item xs={7}>
                                    <InputAddress
                                        label={t('profile.address.street')}
                                        address={newProfile?.address?.street}
                                        onChange={street => handleUpdateProfile({ address: { ...newProfile?.address, street: street } })}
                                        showValidation
                                        validate={validateAddressStreet}
                                        externalUpdate
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <InputAddressNumber
                                        label={t('profile.address.number')}
                                        address={newProfile?.address?.number}
                                        onChange={number => handleUpdateProfile({ address: { ...newProfile?.address, number: number } })}
                                        showValidation
                                        validate={validateAddressNumber}
                                        externalUpdate
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <InputAddress
                                        label={t('profile.address.complement')}
                                        address={newProfile?.address?.complement}
                                        onChange={complement => handleUpdateProfile({ address: { ...newProfile?.address, complement: complement } })}
                                        showValidation
                                        validate={validateAddressComplement}
                                        externalUpdate
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <InputAddress
                                        label={t('profile.address.neighborhood')}
                                        address={newProfile?.address?.neighborhood}
                                        onChange={neighborhood => handleUpdateProfile({ address: { ...newProfile?.address, neighborhood: neighborhood } })}
                                        showValidation
                                        validate={validateAddressNeighborhood}
                                        externalUpdate
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <InputAddressZipCode
                                        label={t('profile.address.zip-code')}
                                        value={newProfile?.address?.zipCode}
                                        onChange={zipCode => handleUpdateProfile({ address: { ...newProfile?.address, zipCode: zipCode } })}
                                        showValidation
                                        validate={validateAddressZipCode}
                                        externalUpdate
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item className="personal-data__items--title">
                        {t('profile.data-bank.title')}
                    </Grid>
                    {!isAddingBankAccount ? (
                        newProfile.accounts ? (
                            newProfile.accounts.map(({ id, bank, agencyNumber, agencyDigit, accountNumber, accountDigit, type, active, mainAccount }) => (
                                <>
                                    <Grid id="data-bank">
                                        <div className="mouse-hover-behavior">
                                            <div className="data-bank-container">
                                                <Grid item className="profile-editing__items--text">
                                                    <span className="profile-editing__items--text-first">{t('profile.data-bank.bank')}</span>
                                                    <span className="profile-editing__items--text-second">{bank?.name ?? '-'}</span>
                                                </Grid>
                                                <div className="profile-editing__items--text-two">
                                                    <Grid item xs={5} className="profile-editing__items--text">
                                                        <span className="profile-editing__items--text-two-first">{t('profile.data-bank.agency')}</span>
                                                        <span className="profile-editing__items--text-two-second">{agencyNumber ?? '-'}</span>
                                                    </Grid>
                                                    <Grid item xs={4} className="profile-editing__items--text">
                                                        <span className="profile-editing__items--text-two-first">{t('profile.data-bank.dv')}</span>
                                                        <span className="profile-editing__items--text-two-second">{agencyDigit ?? '-'}</span>
                                                    </Grid>
                                                    <Grid item xs={4} className="profile-editing__items--text">
                                                        <span className="profile-editing__items--text-two-first">{t(`profile.data-bank.${type}`)}</span>
                                                        <span className="profile-editing__items--text-two-second">{accountNumber ?? '-'}</span>
                                                    </Grid>
                                                    <Grid item xs={4} className="profile-editing__items--text">
                                                        <span className="profile-editing__items--text-two-first">{t('profile.data-bank.dv')}</span>
                                                        <span className="profile-editing__items--text-two-second">{accountDigit ?? '-'}</span>
                                                    </Grid>
                                                </div>
                                                <Grid item style={{ display: 'flex' }}>
                                                    <span className="profile-editing__items--subtitle" style={{ width: '100px', margin: '4px' }}>
                                                        {t('profile.data-bank.main-account')}
                                                    </span>
                                                    <ToggleSwitch
                                                        checked={mainAccount}
                                                        onChange={() => {
                                                            if (id !== undefined && mainAccount !== undefined) {
                                                                handleMainAccount(mainAccount, id);
                                                            }
                                                        }}
                                                        name="active-account"
                                                    />
                                                </Grid>
                                                <div>
                                                    <Grid item style={{ display: 'flex' }}>
                                                        <span className="profile-editing__items--subtitle" style={{ width: '100px', margin: '4px' }}>
                                                            {t('profile.data-bank.active')}
                                                        </span>
                                                        <ToggleSwitch
                                                            checked={active}
                                                            onChange={() => {
                                                                if (id !== undefined && active !== undefined) {
                                                                    handleActiveAccount(active, id);
                                                                }
                                                            }}
                                                            name="active-account"
                                                        />
                                                    </Grid>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            ))
                        ) : (
                            <>
                                <Grid id="data-bank">
                                    <div className="mouse-hover-behavior">
                                        <div className="data-bank-container">
                                            <Grid item className="profile-editing__items--text">
                                                <span className="profile-editing__items--text-first">{t('profile.data-bank.bank')}</span>
                                                <span className="profile-editing__items--text-second">{'-'}</span>
                                            </Grid>
                                            <div className="profile-editing__items--text-two">
                                                <Grid item xs={5} className="profile-editing__items--text">
                                                    <span className="profile-editing__items--text-two-first">{t('profile.data-bank.agency')}</span>
                                                    <span className="profile-editing__items--text-two-second">{'-'}</span>
                                                </Grid>
                                                <Grid item xs={4} className="profile-editing__items--text">
                                                    <span className="profile-editing__items--text-two-first">{t('profile.data-bank.dv')}</span>
                                                    <span className="profile-editing__items--text-two-second">{'-'}</span>
                                                </Grid>
                                                <Grid item xs={4} className="profile-editing__items--text">
                                                    <span className="profile-editing__items--text-two-first">{t(`profile.data-bank.unknown-account`)}</span>
                                                    <span className="profile-editing__items--text-two-second">{'-'}</span>
                                                </Grid>
                                            </div>
                                            <Grid item>
                                                <span className="profile-editing__items--subtitle">{t('profile.data-bank.main-account')}</span>
                                            </Grid>
                                        </div>
                                    </div>
                                    <button onClick={() => setIsAddingBankAccount(true)} className="profile-editing__items--new-account" style={{ verticalAlign: 'middle' }}>
                                        <span>{t('profile.data-bank.add-account')}</span>
                                    </button>
                                </Grid>
                            </>
                        )
                    ) : (
                        <Grid id="data-bank">
                            <div className="mouse-hover-behavior">
                                <Grid item xs={12} className="profile-editing__items--data-bank">
                                    <Grid item xs={7}>
                                        <ModalSearch<BankToSearch>
                                            id="inputText_bankAccountData_modal_bank"
                                            itemSize={SEARCH_ITEM_SIZE_LARGE}
                                            action={searchBanksRequest}
                                            modalTitle={t(`profile.data-bank.modal.title`)}
                                            modalLabel={`profile.data-bank.modal.label`}
                                            modalPlaceholder={`profile.data-bank.modal.placeholder`}
                                            onSelect={bank => setNewBankAccount({ ...newBankAccount, bank: bank })}
                                            renderItem={bank => bank.name}
                                            statusSelector={(state: IRootState) => state.searchBanks.status}
                                            dataSelector={(state: IRootState) => state.searchBanks.banks}
                                        >
                                            {handleOpen => (
                                                <ValidSearchInput
                                                    id="inputText_addressData_city"
                                                    label={t('profile.data-bank.bank')}
                                                    placeholder={t('profile.data-bank.select-bank')}
                                                    value={newBankAccount.bank?.name ?? ''}
                                                    onClick={handleOpen}
                                                    onFocus={handleOpen}
                                                    validate={validateBank}
                                                    showValidation
                                                    readOnly
                                                    externalUpdate
                                                />
                                            )}
                                        </ModalSearch>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <InputBankAccountNumber
                                            label={t('profile.data-bank.agency-number')}
                                            value={newBankAccount.agencyNumber ?? ''}
                                            onChange={agencyNumber => setNewBankAccount({ ...newBankAccount, agencyNumber: agencyNumber })}
                                            validate={validateBankAgencyNumber}
                                            showValidation
                                            externalUpdate
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <InputBankAccountNumber
                                            label={t('profile.data-bank.agency-digit')}
                                            value={newBankAccount.agencyDigit ?? ''}
                                            onChange={agencyDigit => setNewBankAccount({ ...newBankAccount, agencyDigit: agencyDigit })}
                                            validate={validateBankAgencyDigit}
                                            showValidation
                                            externalUpdate
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <InputBankAccountNumber
                                            label={t('profile.data-bank.account-number')}
                                            value={newBankAccount.accountNumber ?? ''}
                                            onChange={accountNumber => setNewBankAccount({ ...newBankAccount, accountNumber: accountNumber })}
                                            validate={validateBankAccountNumber}
                                            showValidation
                                            externalUpdate
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <InputBankAccountNumber
                                            label={t('profile.data-bank.account-digit')}
                                            value={newBankAccount.accountDigit ?? ''}
                                            onChange={accountDigit => setNewBankAccount({ ...newBankAccount, accountDigit: accountDigit })}
                                            validate={validateBankAccountDigit}
                                            showValidation
                                            externalUpdate
                                        />
                                    </Grid>

                                    <Grid item xs={7}>
                                        <ValidSelectInput
                                            label={t('profile.data-bank.account-type')}
                                            value={newBankAccount.type ?? ''}
                                            mapperFromString={_value => (_value ? BankAccountType[_value] : undefined)}
                                            placeholder={t('profile.data-bank.account-type')}
                                            onChange={(accountType: string) => setNewBankAccount({ ...newBankAccount, type: BankAccountType[accountType] })}
                                            validate={validateBankAccountType}
                                            showValidation
                                            externalUpdate
                                        >
                                            <MenuItem value={BankAccountType.CHECKING_ACCOUNT}>{t(`profile.data-bank.CHECKING_ACCOUNT`)}</MenuItem>
                                            <MenuItem value={BankAccountType.SAVINGS_ACCOUNT}>{t(`profile.data-bank.SAVINGS_ACCOUNT`)}</MenuItem>
                                        </ValidSelectInput>
                                    </Grid>

                                    <button onClick={handleCancelEditBankAccount} className="profile-editing__items--cancel" style={{ verticalAlign: 'middle', marginRight: '20px' }}>
                                        <span>{t('profile.data-bank.cancel')}</span>
                                    </button>
                                    <button onClick={handleNewAccount} className="profile-editing__items--save" disabled={!validateBankProfileUpdate(newBankAccount)} style={{ verticalAlign: 'middle', marginRight: '20px' }}>
                                        <span>{t('profile.data-bank.add')}</span>
                                    </button>
                                </Grid>
                            </div>
                        </Grid>
                    )}
                    <button onClick={() => setIsAddingBankAccount(true)} className="profile-editing__items--new-account" disabled={isAddingBankAccount} style={{ verticalAlign: 'middle', paddingTop: '1px', marginTop: '10px' }}>
                        <span>{t('profile.data-bank.add-account')}</span>
                    </button>
                    <Grid id="password">
                        <div className="mouse-hover-behavior">
                            <Grid item className="personal-data__items--title">
                                {t('profile.password.title')}
                            </Grid>
                            <Grid className="profile-editing__items--fields">
                                <Grid item xs={7}>
                                    <FieldPassword
                                        label={t('profile.password.label')}
                                        // id="pass"
                                        value={changePassword?.currentPassword}
                                        onChange={currentPassword => handlePasswordChange({ currentPassword })}
                                        validate={validatePassword}
                                        showValidation={showValidation}
                                    />
                                </Grid>
                                <Grid>
                                    <Grid item xs={7}>
                                        <FieldPassword
                                            label={t('profile.password.password.label')}
                                            value={changePassword?.newPassword}
                                            onChange={newPassword => handlePasswordChange({ newPassword })}
                                            validate={validatePassword}
                                            showValidation={showValidation}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <PasswordStrength password={changePassword?.newPassword} />
                                    </Grid>
                                </Grid>
                                <Grid className="confirm-password">
                                    <Grid item xs={7}>
                                        <FieldPassword
                                            label={t('profile.password.password-confirm.label')}
                                            value={changePassword?.newPasswordConfirm}
                                            onChange={newPasswordConfirm => handlePasswordChange({ newPasswordConfirm })}
                                            validate={it => validatePasswordMatch(mapPasswordMatch(changePassword?.newPassword, it))}
                                            showValidation={showValidation}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid id="contact">
                        <div className="mouse-hover-behavior">
                            <Grid item className="personal-data__items--title">
                                {t('profile.contact.title')}
                            </Grid>
                            <Grid className="profile-editing__items--block">
                                <Grid item xs={10} className="profile-editing__items--block-first">
                                    {t('profile.contact.whatsapp-subscribe')}
                                </Grid>
                                <Grid item xs={2} className="profile-editing__items--block-second">
                                    <ToggleSwitch checked={newProfile?.phone?.whatsAppStatus === WhatsAppStatus.RECEIVE} onChange={handleWhatsappStatus} name="whatsApp-subscribe" />
                                </Grid>
                                <Grid item xs={5} className="profile-editing__items--block-third">
                                    <PhoneInput
                                        label={t('profile.contact.label')}
                                        value={newProfile.phone?.number}
                                        onChange={phone => handleUpdateProfile({ phone: { number: phone } })}
                                        validate={validatePhoneNumber}
                                        showValidation
                                        externalUpdate
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default ProfileEditing;
