import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { CapitalEvolution } from 'model/fund-account';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fundAccountApi from 'services/api/fundAccountApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { capitalEvolutionError, capitalEvolutionRequest, capitalEvolutionSuccess } from './actions';
import { CapitalEvolutionActionTypes } from './types';

function* handleGetCapitalEvolution(action: ReturnType<typeof capitalEvolutionRequest>) {
    try {
        const result: AxiosResponse<CapitalEvolution> = yield call(fundAccountApi.getCapitalEvolution, action.payload);

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(capitalEvolutionError(mapped));
            return;
        }
        yield put(capitalEvolutionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(capitalEvolutionError(mapped));
    }
}

function* watchGetCapitalEvolution() {
    yield takeEvery(CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_REQUEST, handleGetCapitalEvolution);
}

function* capitalEvolutionSaga() {
    yield all([fork(watchGetCapitalEvolution)]);
}

export default capitalEvolutionSaga;
