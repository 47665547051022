import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ShareholderInviteToConfirmActionTypes, ShareholderInviteToConfirmState } from './types';

export const initialState: ShareholderInviteToConfirmState = {
  status: HttpRequestStatus.NOOP,
  invite: undefined,
  error: undefined
};

const reducer: Reducer<ShareholderInviteToConfirmState> = (state = initialState, action): ShareholderInviteToConfirmState => {
  switch (action.type) {
    case ShareholderInviteToConfirmActionTypes.SHAREHOLDER_INVITE_TO_CONFIRM_REQUEST: {
      return { ...state, status: HttpRequestStatus.ONGOING, invite: undefined, error: undefined };
    }
    case ShareholderInviteToConfirmActionTypes.SHAREHOLDER_INVITE_TO_CONFIRM_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, invite: action.payload, error: undefined };
    }
    case ShareholderInviteToConfirmActionTypes.SHAREHOLDER_INVITE_TO_CONFIRM_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }

    case ShareholderInviteToConfirmActionTypes.SHAREHOLDER_INVITE_TO_CONFIRM_RESET_STATE: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};

export { reducer as shareholderInviteToConfirmReducer };

