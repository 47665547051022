import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { IncomeStatementSubGroupAcquiredVolumeState, IncomeStatementSubGroupAcquiredVolumeActionTypes } from './types';

export const initialState: IncomeStatementSubGroupAcquiredVolumeState = {
           status: HttpRequestStatus.NOOP,
           volume: undefined,
           error: undefined
       };

const reducer: Reducer<IncomeStatementSubGroupAcquiredVolumeState> = (state = initialState, action): IncomeStatementSubGroupAcquiredVolumeState => {
    switch (action.type) {
        case IncomeStatementSubGroupAcquiredVolumeActionTypes.SUB_GROUP_ACQUIRED_VOLUME_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, volume: undefined, error: undefined };
        }
        case IncomeStatementSubGroupAcquiredVolumeActionTypes.SUB_GROUP_ACQUIRED_VOLUME_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, volume: action.payload, error: undefined };
        }
        case IncomeStatementSubGroupAcquiredVolumeActionTypes.SUB_GROUP_ACQUIRED_VOLUME_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case IncomeStatementSubGroupAcquiredVolumeActionTypes.SUB_GROUP_ACQUIRED_VOLUME_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as incomeStatementSubGroupAcquiredVolumeReducer };
