import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum FundDocumentsDownloadTypes {
    FUND_DOCUMENTS_DOWNLOAD_REQUEST = '@@document-download/FUND_DOCUMENTS_DOWNLOAD_REQUEST',
    FUND_DOCUMENTS_DOWNLOAD_SUCCESS = '@@document-download/FUND_DOCUMENTS_DOWNLOAD_SUCCESS',
    FUND_DOCUMENTS_DOWNLOAD_ERROR = '@@document-download/FUND_DOCUMENTS_DOWNLOAD_ERROR',
    FUND_DOCUMENTS_DOWNLOAD_RESET_STATE = '@@document-download/FUND_DOCUMENTS_DOWNLOAD_RESET_STATE'
}

export interface FundDocumentsDownloadState extends BaseStoreState {
    readonly document?: Uint8Array;
}
