import { useMediaQuery } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import CurrencyFormatter from 'components/fomatter/currency/currency-formatter';
import LocalDateFormatter, { LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import Loading from 'components/loading/loading';
import { ShareholderShareReportType } from 'model/enums/shareholder-share-report-type';
import { ShareholderAccountHistory, ShareholderAccountHistoryRequest } from 'model/shareholder-account';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer/AutoSizer';
import Column from 'react-virtualized/dist/commonjs/Table/Column';
import Table from 'react-virtualized/dist/commonjs/Table/Table';
import 'react-virtualized/styles.css'; // only needs to be imported once
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './account-list.scss';

interface AccountListProps {
    data?: ShareholderAccountHistory[];
    filterActive: ShareholderAccountHistoryRequest | undefined;
    activeTab: ShareholderShareReportType | undefined;
    isLoading?: boolean;
}

export const AccountList = (props: AccountListProps) => {
    const { data, filterActive, activeTab, isLoading } = props;
    const { t } = useTranslation();

    return (
        <div className="App">
            <div className="App dinamic-height">
                <Container fluid className="d-flex flex-column h-100">
                    <Row className="flex-grow-1">
                        {isLoading ? (
                            <>
                                <Loading />
                            </>
                        ) : (
                            <Col style={{ padding: '22px' }}>
                                {data && data?.length !== 0 ? (
                                    <MyTable data={data} activeTab={activeTab} />
                                ) : (
                                    <div className="empty-account-list">
                                        <div className="empty-account-list--icon" />
                                        <span className="empty-account-list--title"> {t('participant.account.historic.no-results.empty')} </span>
                                        <span className="empty-account-list--text"> {!!filterActive && t('participant.account.historic.no-results.filter')} </span>
                                    </div>
                                )}
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        </div>
    );
};

interface MyTableProps {
    data: ShareholderAccountHistory[];
    activeTab: ShareholderShareReportType | undefined;
}

function MyTable(props: MyTableProps) {
    const { data } = props;

    const height = useMediaQuery('(min-height: 900px)', { noSsr: true });

    const { t } = useTranslation();
    const [sort, setSort] = useState({ sortBy: 'name', sortDirection: 'ASC' });

    function _sort({ sortBy, sortDirection }) {
        setSort({ sortBy, sortDirection });
    }

    const sortedList = data.slice();

    sortedList.sort((a, b) => {
        const [aa, bb] = sort.sortDirection === 'ASC' ? [a, b] : [b, a];
        if (aa[sort.sortBy] === undefined && bb[sort.sortBy] === undefined) return 0;
        return aa[sort.sortBy].toString().localeCompare(bb[sort.sortBy].toString());
    });

    function renderHeaderRow({ className, columns, style }) {
        return (
            <div className={className} role="row" style={style}>
                {columns.map(c => (
                    <div onClick={c.props.onClick} className={c.props.className} style={c.props.style} key={c.key}>
                        {c.props['aria-label'] || c.props.children}
                        {c.props.children.length > 1 ? (
                            c.props.children[1].props.sortDirection === 'ASC' ? (
                                <span className="icon-flechas1" />
                            ) : (
                                <span className="icon-flechas2" />
                            )
                        ) : (
                            c.props.onClick !== undefined && <span className="icon-flechas" />
                        )}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <AutoSizer key={Math.random()}>
            {({ width }) => (
                <Table
                    width={width}
                    height={height ? 300 : 250}
                    headerHeight={30}
                    rowHeight={40}
                    rowCount={data.length}
                    rowGetter={({ index }) => sortedList[index]}
                    style={{ fontSize: '14px', fontFamily: 'Frutiger-light', color: '#464646', fontWeight: '300' }}
                    sort={_sort}
                    sortBy={sort.sortBy}
                    sortDirection={sort.sortDirection}
                    key={Math.random()}
                    headerRowRenderer={renderHeaderRow}
                >
                    {props.activeTab === ShareholderShareReportType.PURCHASE && (
                        <Column width={110} label={t('participant.account.date')} dataKey="date" style={{}} cellRenderer={({ rowData }) => <LocalDateFormatter value={rowData.date} type={LocalDateFormatType.LONG_FULL_DATE} />} />
                    )}
                    {props.activeTab === ShareholderShareReportType.PURCHASE && <Column width={102} label={t('participant.account.share-quantity')} dataKey="shareQuantity" style={{}} />}
                    {props.activeTab === ShareholderShareReportType.PURCHASE && (
                        <Column width={400} label={t('participant.account.value')} dataKey="investmentValue" style={{}} cellRenderer={({ rowData }) => <CurrencyFormatter value={rowData.investmentValue} prefix />} />
                    )}

                    {props.activeTab === ShareholderShareReportType.SALE && (
                        <Column width={270} label={t('participant.account.date')} dataKey="date" style={{}} cellRenderer={({ rowData }) => <LocalDateFormatter value={rowData.date} type={LocalDateFormatType.LONG_FULL_DATE} />} />
                    )}
                    {props.activeTab === ShareholderShareReportType.SALE && <Column width={250} label={t('participant.account.share-quantity')} dataKey="shareQuantity" style={{}} />}
                    {props.activeTab === ShareholderShareReportType.SALE && (
                        <Column width={400} label={t('participant.account.invested-value')} dataKey="investmentValue" style={{}} cellRenderer={({ rowData }) => <CurrencyFormatter value={rowData.investmentValue} prefix />} />
                    )}
                    {props.activeTab === ShareholderShareReportType.SALE && (
                        <Column width={420} label={t('participant.account.redemption-value')} dataKey="redemptionValue" style={{}} cellRenderer={({ rowData }) => <CurrencyFormatter value={rowData.redemptionValue} prefix />} />
                    )}
                    {props.activeTab === ShareholderShareReportType.SALE && (
                        <Column width={270} label={t('participant.account.tax-value')} dataKey="taxValue" style={{}} cellRenderer={({ rowData }) => <CurrencyFormatter value={rowData.taxValue} prefix />} />
                    )}
                    {props.activeTab === ShareholderShareReportType.SALE && (
                        <Column width={400} label={t('participant.account.net-value')} dataKey="netValue" style={{}} cellRenderer={({ rowData }) => <CurrencyFormatter value={rowData.netValue} prefix />} />
                    )}
                    {props.activeTab === ShareholderShareReportType.SALE && (
                        <Column width={400} label={t('participant.account.revenue-value')} dataKey="netValue" style={{}} cellRenderer={({ rowData }) => <CurrencyFormatter value={rowData.revenueValue} prefix />} />
                    )}
                </Table>
            )}
        </AutoSizer>
    );
}

export default AccountList;
