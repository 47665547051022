import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z, APP_LOCAL_DATE_FORMAT_TO_SERVER } from '../../config/constants';

export const convertDateTimeFromServer = (date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined) => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = (date: string | number | void | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined) => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const defaultTodayDate = (): string => moment().format(APP_LOCAL_DATE_FORMAT_TO_SERVER);

export const lastNinetyDays = (): string =>
    moment()
        .subtract(90, 'days')
        .format(APP_LOCAL_DATE_FORMAT_TO_SERVER);

export const currentYear = (): number => moment().year();
