import Loading from 'components/loading/loading';
import _ from 'lodash';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { SeriesType } from 'model/enums/series-type';
import { FundSeriesAndInvestor } from 'model/series';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { Card } from 'reactstrap';
import { useGetAllSecondaryAcessSeriesState } from 'reducer/hooks';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import useFilterUtils from 'shared/hooks/use-filter-utils';
import { ParticipantsBaseTabLinks } from 'shared/menu/participant-header/participant-tab-menu';
import SelectAllSecondaryAccessCard from './components/select-all-secondary-access-card/select-all-secondary-access-card';

import { HeaderVisualizationType } from 'model/enums/header-visualization-type';
import './secondary-access-page.scss';

interface SecondaryAccessPageProps {
    baseUrl?: string;
    secondaryAccessSeries: FundSeriesAndInvestor[];
}

const SecondaryAccessPage = (props: SecondaryAccessPageProps) => {
    const { secondaryAccessSeries, baseUrl } = props;
    const { t } = useTranslation();

    const { status } = useGetAllSecondaryAcessSeriesState();
    const { buildSearchParams } = useFilterUtils();
    const { handleSelectFundAndSeries } = useFundAndSeriesContext();

    const history = useHistory();
    const { url } = useRouteMatch();

    const participantTabLinks = _.values(ParticipantsBaseTabLinks);
    const existsByParticipantsTabLinks: boolean = participantTabLinks.includes(url);

    const getUrlToRedirect = useCallback(
        (seriesType: string): string => {
            if (seriesType !== SeriesType.RESULT && !existsByParticipantsTabLinks) {
                return ParticipantsBaseTabLinks.GENERAL;
            }

            return baseUrl ?? url;
        },
        [baseUrl, url, existsByParticipantsTabLinks]
    );

    const handleFundAndSeries = useCallback(
        (fundAndSeries: FundSeriesAndInvestor) => {
            const fundSeriesAndInvestor: FundSeriesAndInvestor = {
                fundId: fundAndSeries.fundId,
                fundName: fundAndSeries.fundName,
                fundType: fundAndSeries.fundType,
                seriesId: fundAndSeries.seriesId,
                seriesName: fundAndSeries.seriesName,
                seriesType: fundAndSeries.seriesType,
                personId: fundAndSeries.personId,
                personName: fundAndSeries.personName,
                headerVisualizationType: HeaderVisualizationType.SECONDARY_ACCESS
            };

            handleSelectFundAndSeries(fundSeriesAndInvestor);
            history.push({
                pathname: getUrlToRedirect(fundSeriesAndInvestor.seriesType),
                search: buildSearchParams(fundSeriesAndInvestor)
            });
        },
        [handleSelectFundAndSeries, history, buildSearchParams, getUrlToRedirect]
    );

    const isLoading = status === HttpRequestStatus.ONGOING;

    if (isLoading) return <Loading />;

    return (
        <Card className="secondary-access-page--card">
            <div className="secondary-access-page--list">
                <>
                    <div className="card-container">
                        <div className="secondary-access-page--title">{t('secondary-access-page.title')}</div>
                    </div>
                    {secondaryAccessSeries?.map((it, index) => (
                        <SelectAllSecondaryAccessCard key={it.seriesId} fundAndSeries={it} action={() => handleFundAndSeries(it)} dataCy={`button#select-series-${index}`} />
                    ))}
                </>
            </div>
        </Card>
    );
};

export default SecondaryAccessPage;
