import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum GetIncomeStatementAvailableYearsTypes {
    GET_INCOME_STATEMENT_AVAILABLE_YEARS_REQUEST = '@@get-income-statement/GET_INCOME_STATEMENT_AVAILABLE_YEARS_REQUEST',
    GET_INCOME_STATEMENT_AVAILABLE_YEARS_SUCCESS = '@@get-income-statement/GET_INCOME_STATEMENT_AVAILABLE_YEARS_SUCCESS',
    GET_INCOME_STATEMENT_AVAILABLE_YEARS_ERROR = '@@get-income-statement/GET_INCOME_STATEMENT_AVAILABLE_YEARS_ERROR',
    GET_INCOME_STATEMENT_AVAILABLE_YEARS_RESET_STATE = '@@get-income-statement/GET_INCOME_STATEMENT_AVAILABLE_YEARS_RESET_STATE'
}

export interface GetIncomeStatementAvailableYearsState extends BaseStoreState {
    readonly years?: ReadonlyArray<number>;
}
