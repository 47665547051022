import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { SeriesPositionActionTypes, SeriesPositionState } from './types';

export const initialState: SeriesPositionState = {
    status: HttpRequestStatus.NOOP,
    position: undefined,
    error: undefined
};

const reducer: Reducer<SeriesPositionState> = (state = initialState, action): SeriesPositionState => {
    switch (action.type) {
        case SeriesPositionActionTypes.SERIES_POSITION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, position: undefined, error: undefined };
        }
        case SeriesPositionActionTypes.SERIES_POSITION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, position: action.payload, error: undefined };
        }
        case SeriesPositionActionTypes.SERIES_POSITION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SeriesPositionActionTypes.SERIES_POSITION_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as seriesPositionReducer };
