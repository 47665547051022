import { GiroWebappError } from 'model/error';
import { UserPasswordFinishRequest, UserPasswordResetRequest } from 'model/user';
import { action } from 'typesafe-actions';
import { ForgotPasswordActionTypes } from './types';

export const forgotPasswordRequest = (request: UserPasswordResetRequest) => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST, request);
export const forgotPasswordSuccess = () => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS);
export const forgotPasswordError = (error: GiroWebappError) => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_ERROR, error);
export const forgotPasswordResetState = () => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_RESET_STATE);

export const forgotPasswordFinishRequest = (request: UserPasswordFinishRequest) => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_FINISH_REQUEST, request);
export const forgotPasswordFinishSuccess = () => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_FINISH_SUCCESS);
export const forgotPasswordFinishError = (error: GiroWebappError) => action(ForgotPasswordActionTypes.FORGOT_PASSWORD_FINISH_ERROR, error);
