import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import FieldBasic from '../../../../components/field-basic/field-basic';
import FieldPassword from '../../../../components/field-password/field-password';
import { Authentication } from '../../../../model/authentication';
import { HttpRequestStatus } from '../../../../model/enums/httpRequestStatus';
import { fullLoginRequest } from '../../../../reducer/authentication/actions';
import { useAuthenticationState, useRootDispatch } from '../../../../reducer/hooks';
import { validateEmail, validatePassword } from '../../validation-constants';
import LoginCarousel from '../login-carousel/login-carousel';
import './user-login.scss';

export const UserLogin = () => {
    const { t } = useTranslation();

    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const dispatch = useRootDispatch();
    const state = useAuthenticationState();

    const isLoading = state.status === HttpRequestStatus.ONGOING;

    const haltProgression = !validateEmail(email).isValid || !validatePassword(password).isValid;

    const handleEnter = () => {
        setValidation(true);
        if (haltProgression) return;

        const request: Authentication = { username: email, password };
        dispatch(fullLoginRequest(request));
    };
    
    return (
        <div className="page-container">
            <Row style={{ height: '100vh', width: '100vw' }}>
                <Col md="6">
                    <div className="login--logo" />
                    <LoginCarousel />
                </Col>

                <Col style={{ margin: 'auto' }} md="6">
                    <Row>
                        <Col md={{ size: 6, offset: 3 }} sm="12">
                            <div className="login--container">
                                <div className="login--title">
                                    <span style={{ fontWeight: 'normal' }}>{t('login.title.start')}</span>
                                    <div style={{ marginLeft: '5px' }}>{t('login.title.end')}</div>
                                </div>
                                <FieldBasic data-cy="login#input-email" autoComplete="username" label={t('login.email.label')} value={email} showValidation={showValidation} validate={validateEmail} onChange={setEmail} onEnter={handleEnter} />
                                <div style={{ marginBottom: '22px' }} />
                                <div className="login--link">
                                    <Link to="/forgot-password">{t('login.components.password')}</Link>
                                </div>
                                <div style={{ marginBottom: '10px' }} />
                                <FieldPassword
                                    dataCy="login#input-password"
                                    label={t('login.password.label')}
                                    validate={validatePassword}
                                    value={password}
                                    onChange={setPassword}
                                    autoComplete="current-password"
                                    showValidation={showValidation}
                                    onEnter={handleEnter}
                                />
                                <div style={{ marginBottom: '70px' }} />
                                <div className="login--buttons">
                                    <Button data-cy="login#button-submit" className="button--enter" onClick={handleEnter} disabled={isLoading || (showValidation && haltProgression)}>
                                        <span>{t('login.buttons.enter')}</span>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default UserLogin;
