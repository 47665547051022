import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { PersonSimple } from 'model/person';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import accountApi from 'services/api/accountApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { acceptLgpdTermsError, acceptLgpdTermsSuccess } from './actions';
import { AcceptLgpdTermsActionTypes } from './types';

function* handleAcceptLgpTerms() {
    try {
        const result: AxiosResponse<PersonSimple> = yield call(accountApi.acceptLgpdTerms);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(acceptLgpdTermsError(mapped));
            return;
        }
        yield put(acceptLgpdTermsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(acceptLgpdTermsError(mapped));
    }
}

function* watchAcceptLgpdTerms() {
    yield takeLeading(AcceptLgpdTermsActionTypes.ACCEPT_LGPD_TERMS_REQUEST, handleAcceptLgpTerms);
}

function* acceptLgpdTermsSaga() {
    yield all([fork(watchAcceptLgpdTerms)]);
}

export default acceptLgpdTermsSaga;
