import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { NonParticipantOfFundActionTypes, NonParticipantOfFundState } from './types';

export const initialState: NonParticipantOfFundState = {
    status: HttpRequestStatus.NOOP,
    series: [],
    error: undefined
};

const reducer: Reducer<NonParticipantOfFundState> = (state = initialState, action): NonParticipantOfFundState => {
    switch (action.type) {
        case NonParticipantOfFundActionTypes.NON_PARTICIPANT_SERIES_OF_FUND_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, series: [], error: undefined };
        }
        case NonParticipantOfFundActionTypes.NON_PARTICIPANT_SERIES_OF_FUND_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, series: action.payload, error: undefined };
        }
        case NonParticipantOfFundActionTypes.NON_PARTICIPANT_SERIES_OF_FUND_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case NonParticipantOfFundActionTypes.NON_PARTICIPANT_OF_FUND_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as nonParticipantOfFundReducer };
