import H from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { GIRO_PHONE_CONTACT } from '../../config/constants';
import { Authority } from '../../model/enums/authority';
import { ErrorConstants, ErrorType, GiroWebappErrorConstants } from '../../model/enums/error-constants';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { ErrorHandlingCustomization } from '../../model/error';
import { termsAcceptanceResetState } from '../../reducer/account/terms-acceptance/actions';
import { userAccountResetState } from '../../reducer/account/user-account/actions';
import { customizeErrorHandling, markError } from '../../reducer/application/error/actions';
import { loginResetState } from '../../reducer/authentication/actions';
import { useAuthenticationState, useRootDispatch, useTermsAcceptanceState, useUserAccountState } from '../../reducer/hooks';
import AuthUtil from '../../services/api/authUtil';
import useHasAnyAuthority from '../../shared/hooks/has-any-authority-hook';
import LoggingUtils from '../../shared/util/logging-utils';
import AutoLogin from './components/auto-login/auto-login';
import UserLogin from './components/user-login/user-login';
import { useToast } from 'shared/hooks/use-toast';
import { useTranslation } from 'react-i18next';

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.authentication.badCredentials': { type: ErrorType.NON_BLOCKING, message: { key: 'login.invalid' } }
        };
        dispatch(customizeErrorHandling({ record }));
    }, [dispatch]);
};

const useAuthenticationAwareness = (): boolean => {
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(AuthUtil.isAuthenticated());

    const authenticationState = useAuthenticationState();
    const accountState = useUserAccountState();
    const termsState = useTermsAcceptanceState();

    const update = AuthUtil.isAuthenticated();

    React.useEffect(() => {
        if (authenticationState.status === HttpRequestStatus.ONGOING) return;
        if (accountState.status === HttpRequestStatus.ONGOING) return;
        if (termsState.status === HttpRequestStatus.ONGOING) return;

        setIsAuthenticated(ps => (ps === update ? ps : update));
    }, [update, authenticationState.status, accountState.status, termsState.status]);

    return isAuthenticated;
};

const useHandleUserNotActivated = () => {
    const dispatch = useRootDispatch();
    const { status, account } = useUserAccountState();

    React.useEffect(() => {
        const record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.user.notActivated': {
                type: ErrorType.BLOCKING,
                message: { key: GiroWebappErrorConstants.USER_NOT_ACTIVATED, options: { contact_number: GIRO_PHONE_CONTACT } },
                action: { noAction: true }
            }
        };
        dispatch(customizeErrorHandling({ record }));
    }, [dispatch]);

    React.useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS && account?.activated !== true) {
            dispatch(markError({ message: GiroWebappErrorConstants.USER_NOT_ACTIVATED }));
        }
    }, [dispatch, status, account]);
};

const useHandleAbsentProfile = (history: H.History) => {
    const dispatch = useRootDispatch();
    const { status, account } = useUserAccountState();
    const { toastError } = useToast();
    const { t } = useTranslation();

    const isProfileAbsent = !useHasAnyAuthority(Authority.ROLE_INVESTOR);

    React.useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS && account?.activated === true && isProfileAbsent) {
            LoggingUtils.debugInfo(`Missing debenture profile`, account.authorities);
            toastError(t('global.access-denied-by-series'));
            AuthUtil.removeToken();
            dispatch(loginResetState());
            dispatch(userAccountResetState());
            dispatch(termsAcceptanceResetState());
            history.replace('/');
        }
    }, [dispatch, history, status, account, isProfileAbsent, toastError, t]);
};

const useHandlePendingTerms = (history: H.History) => {
    const dispatch = useRootDispatch();
    const { account } = useUserAccountState();
    const { status, terms } = useTermsAcceptanceState();

    const hasProfile = useHasAnyAuthority(Authority.ROLE_INVESTOR);

    React.useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS && account?.activated === true && hasProfile && terms?.acceptedTerms !== true) {
            LoggingUtils.debugInfo(`Pending terms acceptance`, terms);
            history.push('/accept-terms-of-use');
            return;
        }

        if (status === HttpRequestStatus.SUCCESS && account?.activated === true && hasProfile && terms?.acceptedLgpdTerms !== true) {
            LoggingUtils.debugInfo(`Pending terms acceptance`, terms);
            history.push('/accept-lgpd-terms');
        }
    }, [dispatch, history, status, account, terms, hasProfile]);
};

const useRedirectToDashboard = (history: H.History) => {
    const dispatch = useRootDispatch();
    const { status, account } = useUserAccountState();
    const { terms } = useTermsAcceptanceState();

    React.useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;
        if (account?.activated !== true) return;
        if (terms?.acceptedTerms !== true) return;
        if (terms?.acceptedLgpdTerms !== true) return;

        if (account.authorities?.some(it => it === Authority.ROLE_INVESTOR)) {
            history.push('/investor');
            return;
        }
    }, [dispatch, history, status, account, terms]);
};

export const Login = () => {
    const history: H.History = useHistory();

    useErrorConfiguration();
    const isAuthenticated = useAuthenticationAwareness();
    useHandleUserNotActivated();
    useHandleAbsentProfile(history);
    useHandlePendingTerms(history);
    useRedirectToDashboard(history);

    if (isAuthenticated) {
        return <AutoLogin />;
    }
    return <UserLogin />;
};

export default Login;
