export enum UnleashFeatureToggleWalletFlag {
    MY_FUNDS_GRAPH = 'my-funds-graph',
    BALANCE_HISTORY_GRAPH = 'balance-history-graph'
}

export enum UnleashFeatureToggleGeneralFlag {
    RECEIVABLES_GRAPH = 'general-receivables-graph',
    RESOURCES_SOURCE_GRAPH = 'general-resources-source-graph',
    SERIES_POSITION_GRAPH = 'general-series-position-graph',
    SHARE_PERFORMANCE_GRAPH = 'general-share-performance-graph'
}

export enum UnleashFeatureTogglePerformanceFlag {
    ACQUIRED_VOLUME_GRAPH = 'performance-acquired-volume-graph',
    CASH_AVAILABLE_BALANCE_GRAPH = 'performance-cash-available-balance-graph',
    LOSSES_GRAPH = 'performance-losses-graph'
}

export enum UnleashFeatureToggleCaptureFlag {
    CAPITAL_EVOLUTION = 'capture-capital-evolution-graph',
    NUMBER_OF_PARTICIPANTS_GRAPH = 'capture-number-of-participants-graph',
    CAPTURE_GRAPH = 'capture-capture-graph',
    PARTICIPANT_COSTS_GRAPH = 'capture-participant-costs-graph'
}

export enum UnleashFeatureToggleFinancialStatementMonthFlag {
    SWITCH = 'financial-statement-month-switch'
}

export enum UnleashFeatureToggleIncomeStatementFlag {
    SWITCH = 'income-statement-switch'
}

export enum UnleashEmbededFormFlag {
    SATISFACTION_SURVEY = 'typeform-satisfaction-survey'
}
