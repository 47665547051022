import { ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Monthly Income Statement
import { getMonthlyIncomeStatementReducer } from './get-monthly-income-statement/reducer';
import getMonthlyIncomeStatementSaga from './get-monthly-income-statement/sagas';
import { GetMonthlyIncomeStatementState } from './get-monthly-income-statement/types';

// Available Years
import { getIncomeStatementAvailableYearsReducer } from './get-available-years-by-issuer-id/reducer';
import getIncomeStatementAvailableYearsSaga from './get-available-years-by-issuer-id/sagas';
import { GetIncomeStatementAvailableYearsState } from './get-available-years-by-issuer-id/types';
import { GetYearlyIncomeStatementState } from './get-yearly-income-statement/types';
import { getYearlyIncomeStatementReducer } from './get-yearly-income-statement/reducer';
import getYearlyIncomeStatementSaga from './get-yearly-income-statement/sagas';

export interface IncomeStatementState {
    monthlyIncomeStatement: GetMonthlyIncomeStatementState;
    incomeStatementAvailableYears: GetIncomeStatementAvailableYearsState;
    yearlyIncomeStatement: GetYearlyIncomeStatementState;
}

export const IncomeStatementReducer: ReducersMapObject<IncomeStatementState> = {
    monthlyIncomeStatement: getMonthlyIncomeStatementReducer,
    incomeStatementAvailableYears: getIncomeStatementAvailableYearsReducer,
    yearlyIncomeStatement: getYearlyIncomeStatementReducer
};

export function* incomeStatementSagas() {
    yield all([fork(getMonthlyIncomeStatementSaga), fork(getIncomeStatementAvailableYearsSaga), fork(getYearlyIncomeStatementSaga)]);
}
