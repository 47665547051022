import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { FundDocumentsDownloadState, FundDocumentsDownloadTypes } from './types';

const initialState: FundDocumentsDownloadState = {
    status: HttpRequestStatus.NOOP,
    document: undefined,
    error: undefined
};

const reducer: Reducer<FundDocumentsDownloadState> = (state = initialState, action): FundDocumentsDownloadState => {
    switch (action.type) {
        case FundDocumentsDownloadTypes.FUND_DOCUMENTS_DOWNLOAD_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, error: undefined };
        }
        case FundDocumentsDownloadTypes.FUND_DOCUMENTS_DOWNLOAD_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, document: action.payload, error: undefined };
        }
        case FundDocumentsDownloadTypes.FUND_DOCUMENTS_DOWNLOAD_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case FundDocumentsDownloadTypes.FUND_DOCUMENTS_DOWNLOAD_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as fundDocumentsDownloadReducer };
