import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';

// Financial Statement Month
import { getFinancialStatementMonthReducer } from './get-financial-statement-month/reducer';
import getFinancialStatementMonthSaga from './get-financial-statement-month/sagas';
import { GetFinancialStatementMonthState } from './get-financial-statement-month/types';

// Available years
import { getAvailableYearsByIssuerIdReducer } from './get-available-years-by-issuer-id/reducer';
import getAvailableYearsByIssuerIdSaga from './get-available-years-by-issuer-id/sagas';
import { GetAvailableYearsByIssuerIdState } from './get-available-years-by-issuer-id/types';

// Yearly Financial Statement Month
import { getYearlyFinancialStatementMonthReducer } from './get-yearly-financial-statement-month/reducer';
import getYearlyFinancialStatementMonthSaga from './get-yearly-financial-statement-month/sagas';
import { GetYearlyFinancialStatementMonthState } from './get-yearly-financial-statement-month/types';

export interface FinancialStatementMonthState {
    financialStatementMonth: GetFinancialStatementMonthState;
    availableYearsByIssuerId: GetAvailableYearsByIssuerIdState;
    yearlyFinancialStatementMonth: GetYearlyFinancialStatementMonthState;
}

export const FinancialStatementMonthReducer: ReducersMapObject<FinancialStatementMonthState, AnyAction> = {
    financialStatementMonth: getFinancialStatementMonthReducer,
    availableYearsByIssuerId: getAvailableYearsByIssuerIdReducer,
    yearlyFinancialStatementMonth: getYearlyFinancialStatementMonthReducer
};

export function* financialStatementMonthSagas() {
    yield all([fork(getFinancialStatementMonthSaga), fork(getAvailableYearsByIssuerIdSaga), fork(getYearlyFinancialStatementMonthSaga)]);
}
