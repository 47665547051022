import { AxiosError, AxiosResponse } from 'axios';
import { GiroApiErrorConstants, personUnauthorizedErrors } from '../../model/enums/error-constants';
import HttpStatus from '../../model/enums/httpStatus';
import { ForbiddenError, GiroApiError, GiroWebappError, UnauthorizedError, UnknownError } from '../../model/error';
import AuthUtil from '../../services/api/authUtil';

const mapLocalError = (error: any): GiroWebappError => {
    if ((error as AxiosError).response) {
        const response = (error as AxiosError).response;
        const value: GiroApiError = {
            httpStatus: response?.status,
            message: response?.data?.message,
            code: response?.data?.code,
            fieldErrors: response?.data?.fieldErrors,
            detail: response?.data?.error_description
        };
        return mapHttpError(value);
    }
    if (error instanceof Error && error.stack) {
        if (process.env.NODE_ENV === 'development') {
            console.debug(error);
        }
    }
    return UnknownError;
};

const mapGiroIdhApiError = (response: AxiosResponse): GiroApiError => {
    const value: GiroApiError = {
        httpStatus: response.status,
        message: response.data?.message,
        code: response.data?.code,
        fieldErrors: response.data?.fieldErrors,
        detail: response.data?.error_description
    };
    return mapHttpError(value);
};

const mapHttpError = (error: GiroApiError): GiroApiError => {
    if (error?.httpStatus === HttpStatus.UNAUTHORIZED) {
        return mapUnauthorizedError(error)
    }
    if (error?.httpStatus === HttpStatus.FORBIDDEN) {
        return ForbiddenError;
    }
    return error;
};

const mapUnauthorizedError = (error: GiroApiError) => {
    if (error.message === GiroApiErrorConstants.BAD_CREDENTIALS) {
        return error;
    }
    if (error.message === GiroApiErrorConstants.USER_NOT_ACTIVATE) {
        AuthUtil.removeToken();
        return error;
    }
    if (personUnauthorizedErrors.includes(error.message ?? "")) {
        return UnauthorizedError;
    }

    AuthUtil.removeToken();
    return UnauthorizedError;
}

export const ErrorUtils = {
    mapLocalError,
    mapGiroIdhApiError
};
