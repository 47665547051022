import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { fundDocumentsDownloadReducer } from './download-documents/reducer';
import fundDocumentsDownloadSaga from './download-documents/sagas';
import { FundDocumentsDownloadState } from './download-documents/types';
import { myFundDocumentsReducer } from './fund-documents/reducer';
import myFundDocumentsSaga from './fund-documents/sagas';
import { MyFundDocumentsState } from './fund-documents/types';
import { getAllSeriesPortfolioReducer } from './get-all-series-portfolio/reducer';
import getAllSeriesPortfolioSaga from './get-all-series-portfolio/sagas';
import { GetAllSeriesPortfolioState } from './get-all-series-portfolio/types';
import { myFundsReducer } from './my-funds/reducer';
import myFundsSaga from './my-funds/sagas';
import { MyFundsState } from './my-funds/types';
import { fundResourcesSourceReducer } from './resources-source/reducer';
import fundResourcesSourceSaga from './resources-source/sagas';
import { FundResourcesSourceState } from './resources-source/types';

export interface FundState {
    myFunds: MyFundsState;
    resourcesSource: FundResourcesSourceState;
    fundDocuments: MyFundDocumentsState;
    downloadDocuments: FundDocumentsDownloadState;
    allSeriesPortfolio: GetAllSeriesPortfolioState;
}

export const FundReducer: ReducersMapObject<FundState, AnyAction> = {
    myFunds: myFundsReducer,
    resourcesSource: fundResourcesSourceReducer,
    fundDocuments: myFundDocumentsReducer,
    downloadDocuments: fundDocumentsDownloadReducer,
    allSeriesPortfolio: getAllSeriesPortfolioReducer
};

// prettier-ignore
export function* fundSagas() {
    yield all([
        fork(myFundsSaga), 
        fork(fundResourcesSourceSaga),
        fork(myFundDocumentsSaga),
        fork(fundDocumentsDownloadSaga),
        fork(getAllSeriesPortfolioSaga)
    ]);
}
