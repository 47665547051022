import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum GetAvailableYearsByIssuerIdTypes {
    GET_AVAILABLE_YEARS_BY_ISSUER_ID_REQUEST = '@@get-financial-statement-month/GET_AVAILABLE_YEARS_BY_ISSUER_ID_REQUEST',
    GET_AVAILABLE_YEARS_BY_ISSUER_ID_SUCCESS = '@@get-financial-statement-month/GET_AVAILABLE_YEARS_BY_ISSUER_ID_SUCCESS',
    GET_AVAILABLE_YEARS_BY_ISSUER_ID_ERROR = '@@get-financial-statement-month/GET_AVAILABLE_YEARS_BY_ISSUER_ID_ERROR',
    GET_AVAILABLE_YEARS_BY_ISSUER_ID_RESET_STATE = '@@get-financial-statement-month/GET_AVAILABLE_YEARS_BY_ISSUER_ID_RESET_STATE'
}

export interface GetAvailableYearsByIssuerIdState extends BaseStoreState {
    readonly years?: ReadonlyArray<number>;
}
