import { MAX_LINE_CHART_PROPS } from 'components/chart/line-chart/components/chart-components-style';
import CustomizedLine from 'components/chart/line-chart/components/customized-line';
import { TooltipPropsType } from 'components/chart/line-chart/components/customized-tooltip';
import { XAxisPropsType } from 'components/chart/line-chart/components/customized-x-axis';
import { YAxisPropsType } from 'components/chart/line-chart/components/customized-y-axis';
import MultiLineChart from 'components/chart/line-chart/multi-line-chart/multi-line-chart';
import { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import FormatterUtils from 'components/fomatter/formatter-utils';
import { FundHistoryDetails, FundHistoryEntry } from 'model/fund';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { useMyFundsState } from 'reducer/hooks';
import { ChartUtils } from 'shared/util/chart-utils';

const xAxisProps: XAxisPropsType<FundHistoryEntry> = {
    dataKey: 'date',
    tickFormatter: value => FormatterUtils.formatDateByString(value, 'MM/YYYY')
};

const yAxisProps: YAxisPropsType<FundHistoryEntry> = { dataKey: 'amount', tickFormatter: value => formatCurrency(value, 0, true), type: 'number' };

const tooltipProps: TooltipPropsType = {
    labelFormatter: value => FormatterUtils.formatDateByString(value, 'MM/YYYY'),
    formatter: (value, name) => [FormatterUtils.formatNumberToCurrency(Number(value), 0, true), ChartUtils.formatEllipsis(name.toString())]
};

const ONE_MILLION: number = 1_000_000;

const WalletHistorySection = () => {
    const { history } = useMyFundsState();

    const { t } = useTranslation();

    const sortFundsByHighestAmountInLastDate = (a: FundHistoryDetails, b: FundHistoryDetails) => {
        const lastDateFundA = a.entries[a.entries.length - 1];
        const lastDateFundB = b.entries[b.entries.length - 1];

        return lastDateFundB.amount - lastDateFundA.amount;
    };

    const sortedFundsByAmountAndDate = history?.funds.sort(sortFundsByHighestAmountInLastDate);

    const lineChartProps = useCallback((): CategoricalChartProps | undefined => {
        const hasAnyAmountAboveAMillion = history?.funds?.some(it => it.entries?.some(entry => entry.amount >= ONE_MILLION));

        if (!hasAnyAmountAboveAMillion) return;

        return MAX_LINE_CHART_PROPS;
    }, [history]);

    return (
        <Row>
            <Col className="wallet__items--second-row">
                {sortedFundsByAmountAndDate && (
                    <Card className="wallet__items--card">
                        <div className="history-items--card__title">{t('wallet.card-two-title')}</div>
                        <div className="history-container">
                            <MultiLineChart<FundHistoryEntry> xAxisProps={xAxisProps} yAxisProps={yAxisProps} tooltipProps={tooltipProps} lineChartProps={lineChartProps()}>
                                {sortedFundsByAmountAndDate.map((it, index) => CustomizedLine({ customizedLineProps: { key: it.id, name: it.name, dataKey: 'amount', data: it.entries, index } }))}
                            </MultiLineChart>
                        </div>
                    </Card>
                )}
            </Col>
        </Row>
    );
};

export default WalletHistorySection;
