import { ChartAPI, ChartConfiguration, Data, generate, TooltipOptions } from 'c3';
import { ChartData } from 'components/chart/chart-data';
import React from 'react';
import useUID from '../../../../shared/util/uid-utils';
import { BAR_CHART_EMPTY_VALUES_MAX, DEFAULT_BAR_SPACE, DEFAULT_BAR_WITDTH_RATIO, DEFAULT_BAR_Y_AXIS_PADDING_BOTTOM, DEFAULT_BAR_Y_AXIS_PADDING_TOP, DEFAULT_CHART_TRANSITION_DURATION, DEFAULT_COLOR_PATTERN } from '../../chart-constants';
import _ from 'lodash';
import './category-bar-chart.scss';

export interface BarChartConfiguration<T extends ChartData, K extends keyof T> {
    height: number;
    width?: number;
    labelKey: K;
    categoriesKeys: K[];
    categoryLabelFormat?: (value: number) => string;
    categoryFormat?: (value: number | Date) => string | number;
    colorPatern?: string[];
    rotateLabel?: number;
    barRatio?: number;
    xHeight?: number;
}

export interface BarChartProps<T extends ChartData, K extends keyof T> {
    data: T[];
    className?: string;
    config: BarChartConfiguration<T, K>;
    children?: (chart: ChartAPI, keys: string[]) => React.ReactNode;
    tooltipOptions?: TooltipOptions;
    ticks?: number;
}

export const SimpleCategoryBarChart = <T extends ChartData, K extends keyof T>(props: BarChartProps<T, K>) => {
    const UID = useUID();

    const [chart, setChart] = React.useState<ChartAPI>({} as ChartAPI);
    const [config] = React.useState<BarChartConfiguration<T, K>>(props.config);
    const [initialData] = React.useState<T[]>(props.data);
    const [hasLoaded, setLoaded] = React.useState<boolean>(false);

    
    const values: number[] = props.data
        ?.flatMap(it => [...config.categoriesKeys.map(category => it[category])])
        .filter(it => typeof it === 'number' && !isNaN(it))
        .map(it => Number(it));

    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);

    React.useEffect(() => {
        const chartData: Data = {
            json: initialData,
            type: 'bar',
            keys: {
                x: config.labelKey.toString(),
                value: config.categoriesKeys.map(it => it.toString())
            }
        };
        const charConfiguration: ChartConfiguration = {
            data: chartData,
            bindto: `#${UID}`,
            size: {
                height: config.height,
                width: config.width
            },
            legend: {
                hide: true
            },
            transition: {
                duration: DEFAULT_CHART_TRANSITION_DURATION
            },
            grid: {
                y: {
                    show: true
                }
            },
            color: {
                pattern: config.colorPatern ?? DEFAULT_COLOR_PATTERN
            },
            tooltip: {
                ...props.tooltipOptions
            },
            axis: {
                x: {
                    type: 'category',
                    tick: {
                        format: config.categoryFormat,
                        outer: false,
                        rotate: config.rotateLabel,
                        multiline: false
                    },
                    height: config.xHeight
                },
                y: {
                    tick: {
                        format: config.categoryLabelFormat,
                        outer: false,
                        values: props.ticks ? _.range(minValue, maxValue + props.ticks, props.ticks) : undefined
                    },
                    padding: {
                        top: DEFAULT_BAR_Y_AXIS_PADDING_TOP,
                        bottom: DEFAULT_BAR_Y_AXIS_PADDING_BOTTOM
                    }
                }
            },
            bar: {
                space: DEFAULT_BAR_SPACE,
                width: {
                    ratio: config.barRatio ?? DEFAULT_BAR_WITDTH_RATIO
                }
            }
        };
        const _chart = generate(charConfiguration);
        setLoaded(true);
        setChart(_chart);
    }, [UID, config, initialData, props.tooltipOptions, maxValue, minValue, props.ticks]);

    React.useEffect(() => {
        if (chart.load) {
            chart.load({
                json: props.data,
                keys: {
                    x: config.labelKey.toString(),
                    value: config.categoriesKeys.map(it => it.toString())
                }
            });
        }

        if (chart.axis) {
            const values: number[] = props.data
                ?.flatMap(it => [...config.categoriesKeys.map(category => it[category])])
                .filter(it => typeof it === 'number' && !isNaN(it))
                .map(it => Number(it));
            const min = Math.min(...values);
            const max = Math.max(...values);

            if (min === 0 && max === 0) {
                chart.axis.max(BAR_CHART_EMPTY_VALUES_MAX);
            }
        }
    }, [props.data, config, chart]);

    const classes: string = ['category-bar-chart', props.className].filter(it => !!it).join(' ');

    return (
        <div className={classes}>
            <div id={UID} />
            {props.children &&
                hasLoaded &&
                props.children(
                    chart,
                    props.config.categoriesKeys.map(it => it.toString())
                )}
        </div>
    );
};
export default SimpleCategoryBarChart;
