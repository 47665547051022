import { FundAccountReceivables } from 'model/fund-account';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum FundAccountrReceivablesVolumeActionTypes {
    RECEIVABLES_VOLUME_REQUEST = '@@fundAccount/RECEIVABLES_VOLUME_REQUEST',
    RECEIVABLES_VOLUME_SUCCESS = '@@fundAccount/RECEIVABLES_VOLUME_SUCCESS',
    RECEIVABLES_VOLUME_ERROR = '@@fundAccount/RECEIVABLES_VOLUME_ERROR',
    RECEIVABLES_VOLUME_RESET_STATE = '@@fundAccount/RECEIVABLES_VOLUME_RESET_STATE'
}

export interface FundAccountrReceivablesVolumeState extends BaseStoreState {
    readonly receivables?: FundAccountReceivables;
}
