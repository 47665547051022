import { AxiosResponse } from 'axios';
import { FundAccountCashAvailableBalance } from 'model/fund-account';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fundAccountApi from 'services/api/fundAccountApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { cashAvailableBalanceError, cashAvailableBalanceRequest, cashAvailableBalanceSuccess } from './actions';
import { FundAccountCashAvailableBalanceActionTypes } from './types';

function* handleGetCashAvailableBalance(action: ReturnType<typeof cashAvailableBalanceRequest>) {
    try {
        const result: AxiosResponse<FundAccountCashAvailableBalance> = yield call(fundAccountApi.getCashAvailableBalance, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(cashAvailableBalanceError(mapped));
            return;
        }
        yield put(cashAvailableBalanceSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(cashAvailableBalanceError(mapped));
    }
}

function* watchGetCashAvailableBalance() {
    yield takeEvery(FundAccountCashAvailableBalanceActionTypes.CASH_AVAILABLE_BALANCE_REQUEST, handleGetCashAvailableBalance);
}

function* fundAccountCashAvailableBalanceSaga() {
    yield all([fork(watchGetCashAvailableBalance)]);
}

export default fundAccountCashAvailableBalanceSaga;
