import { GiroWebappError } from 'model/error';
import { CapitalEvolution, FundAccountCapitalEvolutionRequest } from 'model/fund-account';
import { action } from 'typesafe-actions';
import { CapitalEvolutionActionTypes } from './types';


export const capitalEvolutionRequest = (data: FundAccountCapitalEvolutionRequest) => action(CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_REQUEST, data);
export const capitalEvolutionSuccess = (response: CapitalEvolution) => action(CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_SUCCESS, response);
export const capitalEvolutionError = (error: GiroWebappError) => action(CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_ERROR, error);
export const capitalEvolutionResetState = () => action(CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_RESET_STATE);
