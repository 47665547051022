import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'reactstrap/lib/Card';
import Container from 'reactstrap/lib/Container';
import { useProfileContext } from '../../profile-context';

export const Message = () => {
    const { t } = useTranslation();
    const { setMessageVisibility } = useProfileContext();

    return (
        <div>
          <Container className="personal-data__items"> 
              <Card id="success" className="personal-data__items--card" >
                  <div className="personal-data__items--card-block">
                      <div className="personal-data__items--card-positive" />
                      <span className="personal-data__items--card-text">
                          {t('profile.update.first')}
                          <span>{t('profile.update.success')}</span>
                      </span>
                      <div className="personal-data__items--card-close" onClick={() => setMessageVisibility(false)} />
                  </div>                   
              </Card>
          </Container>
        </div>
    );
}

export default Message;