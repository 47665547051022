import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import Footer, { FooterType } from 'shared/footer/footer';
import ErrorHandler from '../error/error-handler';
import { Header, HeaderType } from '../menu/header/header';

export interface PublicRouteProps extends Omit<RouteProps, 'component'> {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    headerType?: HeaderType;
    footerType?: FooterType;
    DISABLED?: boolean;
}

export const PublicRoute = (props: PublicRouteProps) => {
    const { component: Component, ...rest } = props;
    if (props.DISABLED) {
        return <Redirect to="/page-not-found" />;
    }
    return (
        <Route
            {...rest}
            render={routeProps => (
                <>
                    <ErrorHandler />
                    <Header type={props.headerType ?? HeaderType.SIMPLE} />
                    <Component {...routeProps} />
                    <Footer type={props.footerType ?? FooterType.SIMPLE} />
                </>
            )}
        />
    );
};

export default PublicRoute;
