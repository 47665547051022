import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { CapitalEvolutionActionTypes, CapitalEvolutionState } from './types';

export const initialState: CapitalEvolutionState = {
    status: HttpRequestStatus.NOOP,
    report: undefined,
    error: undefined
};

const reducer: Reducer<CapitalEvolutionState> = (state = initialState, action): CapitalEvolutionState => {
    switch (action.type) {
        case CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, report: undefined, error: undefined };
        }
        case CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, report: action.payload, error: undefined };
        }
        case CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CapitalEvolutionActionTypes.CAPITAL_EVOLUTION_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as capitalEvolutionReducer };
