import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { ValidationResult } from '../../shared/util/validation-utils';
import FieldBasic, { FieldBasicsProps } from '../field-basic/field-basic';
import './phone-input.scss';

const PHONE_MASK = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const EXTENDED_PHONE_MASK = ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const NON_DIGIT_SEARCH_REGEX = /\D/g;
const EMPTY_STRING = '';

const PHONE_MASK_FUNCTION = (value?: string) => {
  const digits = value?.replace(NON_DIGIT_SEARCH_REGEX, EMPTY_STRING);
  if (!digits || digits.length < 11) {
    return PHONE_MASK;
  }
  return EXTENDED_PHONE_MASK;
};

const TextMaskCustom = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return (
        <MaskedInput
            {...others}
            mask={PHONE_MASK_FUNCTION}
            placeholderChar={'\u2000'}
            placeholder="(  )     -    "
            guide={false}
        />
    );
};


export const PhoneInput = (props: FieldBasicsProps) => {
    const { className: propClassName, onChange, validate, InputProps, externalUpdate, showValidation, ...rest } = props;

    const handleChange = (value: string) => {
        if (onChange) onChange(value.replace(/\D/g, ''));
    };

    const handleValidation = (value: string): ValidationResult => {
        if (validate) {
            return validate(value.replace(/\D/g, ''));
        }
        return { isValid: true };
    };

    const className = ['number__field', propClassName].filter(it => !!it).join(' ');

    return (
        <FieldBasic
            className={className}
            onChange={handleChange}
            validate={handleValidation}
            InputProps={{ inputComponent: TextMaskCustom as React.ElementType<InputBaseComponentProps>, ...InputProps }}
            InputLabelProps={{ shrink: true }}
            externalUpdate={externalUpdate}
            showValidation={showValidation}
            {...rest}
        />
    );
};

export default PhoneInput;
