import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'reactstrap/lib/Card';
import Container from 'reactstrap/lib/Container';
import { useProfileContext } from '../../profile-context';

interface MessageErrorProps {
    message: string;
}

export const MessageError = (props: MessageErrorProps) => {
    const { t } = useTranslation();
    const { setMessageErrorVisibility } = useProfileContext();

    return (
        <div>
            <Container className="personal-data__items">
                <Card id="error" className="personal-data__items--card">
                    <div className="personal-data__items--card-block">
                        <div className="personal-data__items--card-error" />
                        <span className="personal-data__items--card-text">
                            {t('profile.error.first')}
                            <span>{t(`${props.message}`)}</span>
                        </span>
                        <div className="personal-data__items--card-close" onClick={() => setMessageErrorVisibility(false)} />
                    </div>
                </Card>
            </Container>
        </div>
    );
};

export default MessageError;
