import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { FindAllSeriesByUserActionTypes, FindAllSeriesByUserState } from 'reducer/series/find-all-by-user/types';
import { Reducer } from 'redux';

export const initialState: FindAllSeriesByUserState = {
    status: HttpRequestStatus.NOOP,
    userSeries: undefined,
    error: undefined
};

const reducer: Reducer<FindAllSeriesByUserState> = (state = initialState, action): FindAllSeriesByUserState => {
    switch (action.type) {
        case FindAllSeriesByUserActionTypes.FIND_ALL_BY_USER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, userSeries: undefined, error: undefined };
        }
        case FindAllSeriesByUserActionTypes.FIND_ALL_BY_USER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, userSeries: action.payload, error: undefined };
        }
        case FindAllSeriesByUserActionTypes.FIND_ALL_BY_USER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case FindAllSeriesByUserActionTypes.FIND_ALL_BY_USER_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as findAllSeriesByUserReducer };
