import { AxiosResponse } from 'axios';
import { BankToSearch } from 'model/bank-account';
import { SearchRequest } from 'model/searchRequest';
import { api } from 'services/api/api';
import { Page } from 'services/page';

export const bank = () => {
    const searchBanks = (request: SearchRequest): Promise<AxiosResponse<Page<BankToSearch>>> => {
        return api.get<Page<BankToSearch>>(`api/banks/simple?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    return {
        searchBanks,
    };
};

export default bank();
