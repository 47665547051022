import { AxiosResponse } from 'axios';
import { FundSeriesAndInvestor } from 'model/series';
import { api } from './api';

export const secondaryAccess = () => {
    
    const getAllSecondaryAccessSeries = (): Promise<AxiosResponse<FundSeriesAndInvestor[]>> => {
        return api.get<FundSeriesAndInvestor[]>(`api/secondary-access/portfolio-all`);
    };

    return {
        getAllSecondaryAccessSeries
    };
};

export default secondaryAccess();
