import { PersonType } from 'model/enums/person-type';
import React from 'react';
import FormatterUtils from '../formatter-utils';

export type IdentificationValue = string | undefined | null;

export interface IdentificationFormatterProps {
  value: IdentificationValue;
  type: PersonType;
}

export const IdentificationFormatter = (props: IdentificationFormatterProps) => {
  const { value, type } = props;
  return <>{FormatterUtils.formatIdentification(type, value)}</>;
};

export const formatIdentification = FormatterUtils.formatIdentification;

export default IdentificationFormatter;
