import ActionButton from 'components/buttons/action-button/action-button';
import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import SimpleSplineChart from 'components/chart/line-chart/spline-chart/simple-spline-chart';
import { formatLocalDate, LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import { formatPercentage } from 'components/fomatter/percentage/percentage-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { SeriesSharePerformanceData } from 'model/series';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRootDispatch, useSharePerformanceState } from 'reducer/hooks';
import { sharePerformanceRequest } from 'reducer/series/share-performance/actions';
import './share-performance-card.scss';

interface PerformanceCardProps {
    seriesId: string;
}

export const SharePerformanceCard = (props: PerformanceCardProps) => {
    const { seriesId } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { status, performance } = useSharePerformanceState();

    const isLoading = status === HttpRequestStatus.ONGOING;
    const hasError = status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (!seriesId) return;

        dispatch(sharePerformanceRequest(seriesId));
    }, [dispatch, seriesId]);

    if (isLoading) {
        return (
            <div className="items--card">
                <div className="items--card-title">
                    <div>{t('debenture-cards.series-position.title')}</div>
                </div>
                <div className="items--card--loading">
                    <Loading />
                </div>
            </div>
        );
    }

    const handleTryAgain = () => {
        if (!seriesId) return;

        dispatch(sharePerformanceRequest(seriesId));
    };

    if (hasError || !performance || !seriesId) {
        return (
            <div className="items--card">
                <div className="items--card-title">
                    <div>{t('debenture-cards.share-performance.title')}</div>
                </div>
                <div className="items--card-error">
                    <div className="anchor-header--error-message">{t('debenture-cards.share-performance.data-recovery-exception')}</div>
                    <ActionButton label={t('global.try-again')} onClick={handleTryAgain} />
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="items--card-title">{t('debenture-cards.share-performance.title')}</div>
            <div className="items--card-content-vertical">
                <div className="items--card-graph">
                    <PerformanceGraph data={performance.data} />
                </div>
            </div>
        </>
    );
};

interface PerformanceGraphProps {
    data: SeriesSharePerformanceData[];
}

const PerformanceGraph = (props: PerformanceGraphProps) => {
    const { t } = useTranslation();
    const formatLabel = (value: number): string => formatPercentage(value);
    const formatCategory = (value: Date | number): string => {
        if (typeof value === 'number') {
            const date = props.data?.length && props.data.length >= value ? props.data[value]?.date : undefined;
            return formatLocalDate(date, LocalDateFormatType.SHORT_MONTH_NAME);
        }
        return formatLocalDate(value, LocalDateFormatType.SHORT_MONTH_NAME);
    };

    // memo to avoid chart unnecessary re-renders
    const customizeToolTip = React.useMemo(() => {
        return (_id: string, value: number, color: unknown, x: number) => {
            const date = props.data?.length && props.data.length >= x ? props.data[x]?.date : undefined;

            return `
                <div class="tooltip--block">
                    <div class="tooltip--block-line">
                        <div class="tooltip--block-color" style="background-color:${color};"></div>
                        <b class="tooltip--block-text-one">${formatLocalDate(date, LocalDateFormatType.SHORT_MONTH_NAME)}</b>
                    </div>
                    <hr />
                    <span class="tooltip--block-text">Percentual: <b>${formatPercentage(value)}</b></span>
                </div>
            `;
        };
    }, [props.data]);

    return (
        <div>
            <UngroupedChartToolTip className="performance" customize={customizeToolTip}>
                {tooltip => (
                    <SimpleSplineChart
                        data={props.data}
                        className="performance"
                        config={{
                            height: 275,
                            yKeys: ['performance'],
                            labelKey: 'date',
                            pointRadius: 4.5,
                            pointFocusRadius: 6,
                            categoryFormat: formatCategory,
                            yLabelFormat: formatLabel
                        }}
                        tooltipOptions={tooltip}
                    >
                        {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={() => t('debenture-cards.share-performance.anchors')} />}
                    </SimpleSplineChart>
                )}
            </UngroupedChartToolTip>
        </div>
    );
};

export default SharePerformanceCard;
