import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { ShareholderInviteConfirmation } from '../../../model/shareholder-invite';
import shareholderInviteApi from '../../../services/api/shareholderInviteApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { confirmShareholderInviteError, confirmShareholderInviteRequest, confirmShareholderInviteSuccess } from './actions';
import { ShareholderInviteConfirmActionTypes } from './types';


function* handleConfirmShareholderInvite(action: ReturnType<typeof confirmShareholderInviteRequest>) {
  try {
    const result: AxiosResponse<ShareholderInviteConfirmation> = yield call(shareholderInviteApi.confirmByToken, action.payload);
    if (result.status !== HttpStatus.OK) {
      const mapped = ErrorUtils.mapGiroIdhApiError(result);
      yield put(markError(mapped));
      yield put(confirmShareholderInviteError(mapped));
      return;
    }
    yield put(confirmShareholderInviteSuccess(result.data));
  } catch (error) {
    const mapped = ErrorUtils.mapLocalError(error);
    yield put(markError(mapped));
    yield put(confirmShareholderInviteError(mapped));
  }
}

function* watchConfirmShareholderInviteRequest() {
  yield takeEvery(ShareholderInviteConfirmActionTypes.SHAREHOLDER_INVITE_CONFIRM_REQUEST, handleConfirmShareholderInvite);
}

function* confirmShareholderInviteSaga() {
    yield all([fork(watchConfirmShareholderInviteRequest)]);
}

export default confirmShareholderInviteSaga;
