import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import SimpleDonutChart from 'components/chart/donut-chart/simple-donut-chart';
import { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import { formatIntegerPercentage } from 'components/fomatter/percentage/percentage-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { receivablesVolumeRequest } from 'reducer/fund-account/receivables-volume/actions';
import { useFundAccountRecevablesVolumeState, useRootDispatch } from 'reducer/hooks';
import './fund-receivables-card.scss';

const I18N_PREFIX = 'anchor.general.cards.receivables';

interface ChartData extends Record<string, number> {
    expired: number;
    unexpired: number;
}

interface ReceivablesCardProps {
    fundId: string | null | undefined;
    cardTitle: string;
    graphLabels: string[];
    cardLegends: string[];
}

interface ReceivablesGraphProps {
    graphLabels: string[];
}

interface ReceivablesSubtitleItemProps {
    label: string;
    value: number;
    total: number;
    color: string;
    img?: boolean;
    focus?: boolean;
    tooltip?: ReactNode;
}

const chartData: ChartData[] = [{ expired: 40, unexpired: 80 }];

const ReceivablesGraph = (props: ReceivablesGraphProps) => {
    const graphData = ['expired', 'unexpired'];

    const label = (value: string) => (value === graphData[0] ? props.graphLabels[0] : props.graphLabels[1]);

    const customizeUngroupedToolTip = (id: string, value: number, color: unknown) => {
        const formatLabel = (labelValue: number) => `${((labelValue * 100) / (chartData[0].expired + chartData[0].unexpired)).toFixed(1)}%`;

        return `
            <div class="tooltip--block">
                <div class="tooltip--block-line">
                    <div class="tooltip--block-color" style="background-color:${color};"></div>
                    <b class="tooltip--block-text-one">${label(id)}</b>
                </div>
                <hr />
                <span class="tooltip--block-text-two">Percentual: ${formatLabel(value)}</span>
            </div>
        `;
    };

    // const formatLabel = (value: number): string => `${value}%`;

    return (
        <div>
            <UngroupedChartToolTip className="receivables" customize={customizeUngroupedToolTip}>
                {tooltip => (
                    <SimpleDonutChart
                        data={chartData}
                        className="receivables"
                        config={{
                            height: 240,
                            donutWidth: 70,
                            donutLabelPositionAdjust: 0.875,
                            // categoryLabelFormat: formatLabel,
                            categoriesKeys: graphData
                        }}
                        tooltipOptions={tooltip}
                    >
                        {(chart, _keys) => <CustomChartLegend chart={chart} keys={props.graphLabels} formatKey={key => label(key)} />}
                    </SimpleDonutChart>
                )}
            </UngroupedChartToolTip>
        </div>
    );
};

const ReceivablesSubtitleItem = (props: ReceivablesSubtitleItemProps) => {
    const itemPercentage: number = props.total === 0 ? 0 : props.value / props.total;

    const percentage: string = formatIntegerPercentage(itemPercentage);

    const formatedValue: string = formatCurrency(props.value, 0, true);

    return (
        <div className="items--card-subtitle">
            <div className="items--card-label-one">
                {props.img && <div className="items--card-img" />}
                <span>{props.label}</span>
                {props.tooltip && <>{props.tooltip}</>}
            </div>
            <div className={`${props.focus ? 'items--card-value-focus' : 'items--card-value'}`}>{formatedValue}</div>
            <div className="items--card-item">
                <div className="items--card-bar">
                    <div className="items--card-bar-progress" style={{ width: percentage, background: props.color }} />
                </div>
                <div className="items--card-label">{percentage}</div>
            </div>
        </div>
    );
};

/**
 * pending back-end conection
 *
 * @param props
 * @returns
 */
export const FundReceivablesCard = (props: ReceivablesCardProps) => {
    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const { status, receivables } = useFundAccountRecevablesVolumeState();
    const isLoading = status === HttpRequestStatus.ONGOING;

    React.useEffect(() => {
        if (!props.fundId) return;

        dispatch(receivablesVolumeRequest({ id: props.fundId }));
    }, [dispatch, props.fundId]);

    const total = (receivables?.cashAmount ?? 0) + (receivables?.receivableUsedAmount ?? 0) ?? 0;

    return (
        <>
            <div className="items--card-title">{props.cardTitle}</div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="items--card-content-vertical">
                    {false && (
                        <div className="items--card-graph">
                            <ReceivablesGraph graphLabels={props.graphLabels} />
                        </div>
                    )}
                    <div className="items--card-subtitles-vertical">
                        <ReceivablesSubtitleItem
                            label={props.cardLegends[0]}
                            total={total}
                            value={receivables?.receivableUsedAmount ?? 0}
                            color="#4b6d81"
                            tooltip={
                                <Tooltip title={<div className='funds-tooltip-info--title'>{t(`${I18N_PREFIX}.tooltip-info`).toString()}</div>} interactive>
                                    <InfoIcon />
                                </Tooltip>
                            }
                            img
                            focus
                        />
                        <ReceivablesSubtitleItem label={props.cardLegends[1]} total={total} value={receivables?.cashAmount ?? 0} color="#319ff8" />
                        <ReceivablesSubtitleItem label={props.cardLegends[2]} total={total} value={total} color="#319ff8" />
                    </div>
                </div>
            )}
        </>
    );
};

export default FundReceivablesCard;
