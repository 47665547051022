import CloseButton from 'components/button-close/close-button';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { MODAL_SEARCH_HEIGHT, MODAL_SEARCH_WIDTH, SEARCH_PAGE_SIZE_DEFAULT } from '../../config/constants';
import { debounce } from 'lodash';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { SearchRequest } from 'model/searchRequest';
import React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { AnyAction } from 'redux';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { v4 as uuidV4 } from 'uuid';
import './modal-search.scss';

export interface ModalSearchProps<T> {
    id?: string;
    modalTitle: string;
    modalLabel: string;
    modalPlaceholder: string;
    itemSize: number;
    action: (search: SearchRequest) => AnyAction;
    onSelect: (value: T) => void;
    renderItem: (item: T) => React.ReactNode;
    children: (handleOpen: () => void) => React.ReactNode;
    dataSelector: (state: IRootState) => Page<T> | undefined;
    statusSelector: (state: IRootState) => HttpRequestStatus;
    requestParameters?: object;
}

const ModalSearch = <T extends object>(props: ModalSearchProps<T>) => {
    const {
        children,
        onSelect,
        dataSelector,
        action,
        modalTitle,
        modalPlaceholder,
        modalLabel,
        statusSelector,
        renderItem,
        itemSize,
        requestParameters,
        id,
    } = props;
    const dispatch = useRootDispatch();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [valueSearch, setValueSearch] = useState<string>('');

    const data = useSelector<IRootState, Page<T> | undefined>(dataSelector);
    const status = useSelector<IRootState, HttpRequestStatus>(statusSelector);

    const [filteredData, setFilteredData] = useState(data?.content ?? []);

    const isLoading: boolean = status !== HttpRequestStatus.ERROR && status !== HttpRequestStatus.SUCCESS;
    const isItemLoaded = ({ index }: any) => !!filteredData[index];
    const dataCount: number = data && !data.last ? (filteredData?.length ?? 0) + 1 : filteredData?.length ?? 1;

    const itemKey = (index: number, data: any): string | number => {
        return (data && data[index]?.id) ?? index;
    };

    const searchPageable = useCallback(
        (search: string) => {
            const _pageable: Pageable = { page: 0, size: SEARCH_PAGE_SIZE_DEFAULT };
            const request = { search: search, pageable: _pageable, ...requestParameters };
            return request;
        },
        [requestParameters]
    );

    const requestNextPage = () => {
        const _pageable: Pageable = { page: (data?.number ?? 0) + 1, size: SEARCH_PAGE_SIZE_DEFAULT };

        const request = { search: '', pageable: _pageable, ...requestParameters };
        if (data && !data.last) {
            dispatch(action(request));
        }
    };

    const loadMoreItems = (_startIndex: number, _stopIndex: number): any => {
        if (!isLoading) {
            requestNextPage();
        }
    };

    const debounceSearch = useRef(
        debounce(value => {
            dispatch(action(searchPageable(value)));
        }, 500)
    );

    const handleOnChange = (searchInput: string) => {
        setValueSearch(searchInput);
        setFilteredData([]);
        debounceSearch.current(searchInput);
    };

    const handleClick = useMemo(() => {
        return () => {
            setOpenModal(true);
        };
    }, []);

    const handleSelect = (value: T) => {
        onSelect(value);
        setOpenModal(false);
    };

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS) {
            const newData = data?.content ?? [];
            const newPage = data?.number === 0;
            setFilteredData(oldData => [...(!newPage && oldData ? oldData : []), ...newData]);
        }
    }, [status, data]);

    useEffect(() => {
        if (openModal) {
            dispatch(action(searchPageable('')));
        }
    }, [dispatch, action, openModal, searchPageable]);
    return (
        <>
            {openModal && (
                <Modal isOpen>
                    <div className="select-option-modal-content-container">
                        <div className="select-option-modal-content-header">
                            <label className="select-option-modal-title-style"> {modalTitle} </label>
                            <CloseButton onClick={() => setOpenModal(false)} />
                        </div>
                        <div className="select-option-modal-search-input-container">
                            <SimpleSearchInput
                                id={id}
                                label={modalLabel}
                                value={valueSearch}
                                placeholder={modalPlaceholder}
                                onChange={handleOnChange}
                            />
                        </div>

                        <AutoSizer defaultHeight={MODAL_SEARCH_HEIGHT} defaultWidth={MODAL_SEARCH_WIDTH}>
                            {({ height, width }) => {
                                return (
                                    <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={dataCount}>
                                        {({ onItemsRendered, ref }) => (
                                            <FixedSizeList
                                                onItemsRendered={onItemsRendered}
                                                className="select-option-modal-div-style"
                                                ref={ref}
                                                height={height}
                                                itemKey={itemKey}
                                                itemCount={dataCount}
                                                itemSize={itemSize}
                                                width={width}
                                                itemData={filteredData}
                                            >
                                                {({ index, style }) => (
                                                    <div
                                                        key={uuidV4()}
                                                        className="select-option-modal-item-container"
                                                        style={{
                                                            ...style,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={() => {
                                                            handleSelect(filteredData[index]);
                                                        }}
                                                    >
                                                        {filteredData[index] && renderItem(filteredData[index])}
                                                    </div>
                                                )}
                                            </FixedSizeList>
                                        )}
                                    </InfiniteLoader>
                                );
                            }}
                        </AutoSizer>
                        {filteredData.length !== 0 && <div className="select-option-modal-div-style" />}
                    </div>
                </Modal>
            )}
            {children(handleClick)}
        </>
    );
};
export default ModalSearch;
