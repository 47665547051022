import { IncomeStatementSubGroupAcquiredVolume } from 'model/income-statement-sub-group';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum IncomeStatementSubGroupAcquiredVolumeActionTypes {
    SUB_GROUP_ACQUIRED_VOLUME_REQUEST = '@@incomeStatementSubGroup/SUB_GROUP_ACQUIRED_VOLUME_REQUEST',
    SUB_GROUP_ACQUIRED_VOLUME_SUCCESS = '@@incomeStatementSubGroup/SUB_GROUP_ACQUIRED_VOLUME_SUCCESS',
    SUB_GROUP_ACQUIRED_VOLUME_ERROR = '@@incomeStatementSubGroup/SUB_GROUP_ACQUIRED_VOLUME_ERROR',
    SUB_GROUP_ACQUIRED_VOLUME_RESET_STATE = '@@incomeStatementSubGroup/SUB_GROUP_ACQUIRED_VOLUME_RESET_STATE'
}

export interface IncomeStatementSubGroupAcquiredVolumeState extends BaseStoreState {
    readonly volume?: IncomeStatementSubGroupAcquiredVolume;
}
