import { MASK_DIGIT_REGEX } from 'components/inputs/constants';
import { ptBR } from 'date-fns/locale';

const config = {
    VERSION: process.env.VERSION
};

export default config;

export const GIRO_APP_NAME: string = 'giro-investment-vehicle-management-webapp';

export const ELASTIC_APM_RUM_SERVER_URL = process.env.REACT_APP_ELASTIC_SERVER_URL;

export const GIRO_APP_VERSION: string = '0.9.2';

export const SERVER_API_URL = process.env.REACT_APP_API_URL;
export const GIRO_API_URL: string = process.env.REACT_APP_OLD_FRONTEND ?? 'https://app.giro.tech';

export const GIRO_TECH_URL: string = process.env.REACT_APP_SITE_URL ?? '#';

export const GIRO_API_TERMS_WEBVIEW: string = `${GIRO_API_URL}#/accept-terms-webview`;

export const GIRO_GTSEC_UNLEASH_URL: string = 'https://unleash.gtsec.infra.giro.tech/api/frontend';

export const GIRO_UNLEASH_API_TOKEN: string = process.env.REACT_APP_UNLEASH_API_TOKEN ?? 'undefined_token';

export const messages = {
    DATA_ERROR_ALERT: 'Internal Error'
};

export const GIRO_PHONE_CONTACT: string = '(41) 98875-4600';

export const EMAIL_REGEX: RegExp = /^[\w-]+(?:\.[\w-]+)*@[a-z0-9-]+(?:\.[a-z0-9]+)*(?:\.[a-z]{2,})$/i;
export const PHONE_REGEX: RegExp = /^[1-9]{2}\d?\d{8}$/;

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATE_FORMAT_TO_SERVER = 'YYYY-MM-DD';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_IMAGE_SIZE_LIMIT = 15555555;
export const APP_CALENDAR_LOCALE_LANGUAGE = 'pt-BR';

/**
 * in favor of i18n localization
 * TODO remove static translation dependency
 * @deprecated
 */
export const MONTHS_LIST = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

export const CURRENCY_FIXED_DECIMAL = 2;
export const UNIT_PRICE_FIXED_DECIMAL = 8;
export const PERCENTAGE_MAX_DECIMAL = 2;
export const NUMBER_MAX_DECIMAL = 2;

export const NUMBER_DEFAULT_DECIMAL_SEPARATOR = '.'; // dot

export const NUMBER_DECIMAL_SEPARATOR = ','; // comma
export const NUMBER_THOUSAND_SEPARATOR = '.'; // dot

export const INTL_LOCALE = 'pt-BR';
export const INTL_CURRENCY = 'BRL';

export const DATE_FNS_LOCALE = ptBR;

export const DATE_FNS_LOCAL_DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FNS_SHORT_FULL_DATE_FORMAT = 'dd/MM/yy';
export const DATE_FNS_LONG_FULL_DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FNS_DAY_ONLY_DATE_FORMAT = 'dd';
export const DATE_FNS_DAY_AND_SHORT_MONTH_NAME = 'dd/MMM';
export const DATE_FNS_SHORT_MONTH_NAME = 'MMM';
export const DATE_FNS_FULL_MONTH_NAME = 'MMMM';
export const DATE_FNS_FULL_YEAR = 'yyyy';

export const INDIVIDUAL_IDENTIFICATION_LENGTH = 11;
export const INDIVIDUAL_IDENTIFICATION_MASK_REGEX = /(\d{3})(\d{3})(\d{3})(\d{2})/g;
export const INDIVIDUAL_IDENTIFICATION_MASK = '$1.$2.$3-$4';

export const CORPORATION_IDENTIFICATION_LENGTH = 14;
export const CORPORATION_IDENTIFICATION_MASK_REGEX = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
export const CORPORATION_IDENTIFICATION_MASK = '$1.$2.$3/$4-$5';

export const DEFAULT_PHONE_LENGTH = 10;
export const DEFAULT_PHONE_REGEX = /(\d{2})(\d{4})(\d{4})/g;
export const DEFAULT_PHONE_MASK = '($1) $2-$3';

export const EXTENDED_PHONE_LENGTH = 11;
export const EXTENDED_PHONE_REGEX = /(\d{2})(\d{5})(\d{4})/g;
export const EXTENDED_PHONE_MASK = '($1) $2-$3';
export const ZIP_CODE_MASK_REGEX = [MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, '.', MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, '-', MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, MASK_DIGIT_REGEX];
export const ZIP_CODE_MASK = '$1.$2-$3';
// Modal Constants
export const SEARCH_PAGE_SIZE_DEFAULT = 10;
export const SEARCH_ITEM_SIZE = 50;
export const SEARCH_ITEM_SIZE_LARGE = 70;

export const MODAL_SEARCH_HEIGHT = 200;
export const MODAL_SEARCH_WIDTH = 335;

export const LIST_PAGE_SIZE = 10;
