import { action } from 'typesafe-actions';
import { Authentication, Token } from '../../model/authentication';
import { GiroWebappError } from '../../model/error';
import { AuthenticationActionTypes } from './types';

export const loginRequest = (authenticate: Authentication) => action(AuthenticationActionTypes.LOGIN_REQUEST, authenticate);
export const fullLoginRequest = (authenticate: Authentication) => action(AuthenticationActionTypes.FULL_LOGIN_REQUEST, authenticate);

export const loginSuccess = (token: Token) => action(AuthenticationActionTypes.LOGIN_SUCCESS, token);
export const loginError = (error: GiroWebappError) => action(AuthenticationActionTypes.LOGIN_ERROR, error);

export const loginResetState = () => action(AuthenticationActionTypes.LOGIN_RESET_STATE);

export const logoutRequest = () => action(AuthenticationActionTypes.LOGOUT_REQUEST);
