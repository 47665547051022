import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import AuthUtil from './authUtil';

const createApiInstance = (): AxiosInstance => {
    const config: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        timeout: 1 * 60 * 1000
    };

    const instance = axios.create(config);
    instance.interceptors.request.use(async _config => {
        const token = AuthUtil.getToken();
        if (token) {
            _config.headers.Authorization = `Bearer ${token.id_token}`;
        }
        return _config;
    });

    return instance;
};

export const api = createApiInstance();
export default {
    api
};
