import FormControl from '@material-ui/core/FormControl';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import DatePicker from 'aeyos-react-date-picker';
import clsx from 'clsx';
import { APP_CALENDAR_LOCALE_LANGUAGE } from 'config/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseMaskInputProps } from '../../base-mask-input';
import InputUtils from '../../input-utils';
import './date-picker-input.scss';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'value'>;

export interface SimpleDatePickerInputProps extends BaseInput {
    id?: string;
    value: string;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    defaultDate?: Date;
    placeholder: string;
    endAdornment?: boolean;
    minDate?: string;
    maxDate?: string;
    format?: string;
    disabled?: boolean;
    dataCy?: string;
}

export const SimpleDatePickerInput = (props: SimpleDatePickerInputProps) => {
    const { value: propsValue, onChange, minDate, maxDate, label, format, disabled, placeholder, id, dataCy } = props;

    const [refDate, setRefDate] = React.useState<Date | null>(InputUtils.isValidDateISO(propsValue) ? InputUtils.parseDateFromISO(propsValue) : null);

    const { t } = useTranslation();

    const handleChange = (value: Date | Date[]) => {
        if (!value) return;

        const _date = InputUtils.parseDate(value);
        setRefDate(_date);

        if (onChange && InputUtils.isValidDate(_date)) {
            onChange(InputUtils.formatToLocalDateISO(_date));
        }
    };

    return (
        <FormControl className={clsx('date__input', { dirty: propsValue })}>
            <label className="date__input--label">{t(label)}</label>
            <DatePicker
                name={id}
                className="date__input--date"
                locale={APP_CALENDAR_LOCALE_LANGUAGE}
                value={propsValue && refDate && InputUtils.isValidDate(refDate) ? refDate : null}
                minDate={minDate ? InputUtils.parseDateFromISO(minDate) : undefined}
                maxDate={maxDate ? InputUtils.parseDateFromISO(maxDate) : undefined}
                calendarClassName="date__input--calendar"
                calendarIcon={<div className="date__input--icon" data-cy={dataCy} />}
                placeholder={t(placeholder)}
                onChange={(date: Date | Date[]) => handleChange(date)}
                clearIcon={null}
                showLeadingZeros
                disabled={disabled}
                format={format}
                data-cy={dataCy}
            />
        </FormControl>
    );
};

export default SimpleDatePickerInput;
