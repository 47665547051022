import { AxiosResponse } from 'axios';
import { NumberOfParticipants } from 'model/raising';
import { api } from './api';

export const raising = () => {
    const getNumberOfParticipants = (fundId: string): Promise<AxiosResponse<NumberOfParticipants>> => {
        return api.get<NumberOfParticipants>(`api/raising/number-of-stakeholders?fundId=${fundId}`);
    };

    return {
        getNumberOfParticipants
    };
};

export default raising();
