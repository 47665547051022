import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import accountApi from 'services/api/accountApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { forgotPasswordError, forgotPasswordFinishRequest, forgotPasswordRequest, forgotPasswordSuccess } from './actions';
import { ForgotPasswordActionTypes } from './types';

function* handleForgotPasswordInit(action: ReturnType<typeof forgotPasswordRequest>) {
    try {
        const result: AxiosResponse<void> = yield call(accountApi.forgotPasswordInit, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(forgotPasswordError(mapped));
            return;
        }
        yield put(forgotPasswordSuccess());
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(forgotPasswordError(mapped));
    }
}

function* handleForgotPasswordFinish(action: ReturnType<typeof forgotPasswordFinishRequest>) {
    try {
        const result: AxiosResponse<void> = yield call(accountApi.forgotPasswordFinish, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(forgotPasswordError(mapped));
            return;
        }
        yield put(forgotPasswordSuccess());
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(forgotPasswordError(mapped));
    }
}

function* watchForgotPasswordInit() {
    yield takeEvery(ForgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST, handleForgotPasswordInit);
}

function* watchForgotPasswordFinish() {
    yield takeEvery(ForgotPasswordActionTypes.FORGOT_PASSWORD_FINISH_REQUEST, handleForgotPasswordFinish);
}

function* forgotPasswordSaga() {
    yield all([fork(watchForgotPasswordInit), fork(watchForgotPasswordFinish)]);
}

export default forgotPasswordSaga;
