import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonNextStep from '../../../../components/button-next-step/button-next-step';
import ButtonPrevious from '../../../../components/button-previous/button-previous';
import FieldPassword from '../../../../components/field-password/field-password';
import { ShareholderInviteToConfirm } from '../../../../model/shareholder-invite';
import { FOCUS_DELAY } from '../animation/animation-constants';
import { useSteps } from '../invite-steps/invite-steps-context';
import { PasswordMatch, validatePasswordMatch } from '../validation/validation-constants';
import './password.scss';

export interface ConfirmOnlyProps {
    username: string;
    password: string;
    confirm: string;
    onChange: (value: Partial<ShareholderInviteToConfirm>) => void;
}

const mapPasswordMatch = (p: string, m: string): PasswordMatch => ({ password: p, match: m });

export const ConfirmOnly = (props: ConfirmOnlyProps): JSX.Element => {
    const { t } = useTranslation();
    const steps = useSteps();
    const [confirm, setConfirm] = React.useState<string>(props.confirm);
    const [showValidation, setValidation] = React.useState<boolean>(false);

    const INPUT_REF = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (steps.isMobile) {
            const timer = setTimeout(() => INPUT_REF.current?.focus({ preventScroll: true }), FOCUS_DELAY);
            return () => clearTimeout(timer);
        }
    }, [INPUT_REF, steps.isMobile]);

    const haltProgression = !validatePasswordMatch(mapPasswordMatch(props.password, confirm)).isValid;

    const handleNext = () => {
        setValidation(true);
        if (haltProgression) return;

        const _confirm = confirm;
        props.onChange({ confirm: _confirm });
        steps.toNext();
    };

    const handleEnter = () => {
        if (steps.isMobile) handleNext();
    };

    return (
        <div className="body__password">
            <Row style={{ width: '100%' }}>
                <Col md="10" style={{ margin: 'auto' }}>
                    <div style={{ marginBottom: '0' }} className="body__password--title">
                        {t('accept-invite.password.confirm-title')}
                    </div>
                </Col>
            </Row>
            <div className="body__password--components">
                <Col md="6" offset="3">
                    <form className="form__style">
                        <input id="confirm-hidden-username" hidden readOnly value={props.username} autoComplete="username" />
                        <FieldPassword
                            // id="filled-adornment-confirm"
                            value={''}
                            label={t('accept-invite.password.confirm-field.label')}
                            onChange={passConfirm => setConfirm(passConfirm)}
                            validate={it => validatePasswordMatch(mapPasswordMatch(props.password, it))}
                            autoComplete="new-password"
                            onEnter={handleEnter}
                            inputRef={INPUT_REF}
                            showValidation={showValidation}
                        />
                    </form>
                </Col>
            </div>
            <Row>
                <div className="body__password--buttons">
                    <Col>
                        <div className="body__password--button-previous">
                            <ButtonPrevious onClick={steps.toPrevious} />
                        </div>
                    </Col>
                    <Col>
                        <div className="body__password--button-finish">
                            <ButtonNextStep onClick={handleNext} disabled={showValidation && haltProgression} />
                        </div>
                    </Col>
                </div>
            </Row>
        </div>
    );
};

export default ConfirmOnly;
