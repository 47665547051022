import H from 'history';
import React from 'react';

const useRedirectToDashboard = (history: H.History, condition: boolean) => {
    React.useEffect(() => {
        if (!condition) return;

        history.push('/investor');
    }, [history, condition]);
};

export default useRedirectToDashboard;
