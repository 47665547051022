import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { YearlyIncomeStatement } from 'model/income-statement';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import incomeStatementApi from 'services/api/incomeStatementApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { getYearlyIncomeStatementError, getYearlyIncomeStatementRequest, getYearlyIncomeStatementSuccess } from './actions';
import { GetYearlyIncomeStatementTypes } from './types';

function* handleGetYearlyIncomeStatement(action: ReturnType<typeof getYearlyIncomeStatementRequest>) {
    try {
        const result: AxiosResponse<YearlyIncomeStatement> = yield call(incomeStatementApi.getYearlyIncomeStatement, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(getYearlyIncomeStatementError(mapped));
            return;
        }
        yield put(getYearlyIncomeStatementSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getYearlyIncomeStatementError(mapped));
    }
}

function* watchGetYearlyIncomeStatement() {
    yield takeEvery(GetYearlyIncomeStatementTypes.GET_YEARLY_INCOME_STATEMENT_REQUEST, handleGetYearlyIncomeStatement);
}

function* getYearlyIncomeStatementSaga() {
    yield all([fork(watchGetYearlyIncomeStatement)]);
}

export default getYearlyIncomeStatementSaga;
