import { FundSeriesAndInvestor } from 'model/series';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum GetAllSecondaryAccessSeriesTypes {
    GET_ALL_SECONDARY_ACCESS_SERIES_REQUEST = '@@funds/GET_ALL_SECONDARY_ACCESS_SERIES_REQUEST',
    GET_ALL_SECONDARY_ACCESS_SERIES_SUCCESS = '@@funds/GET_ALL_SECONDARY_ACCESS_SERIES_SUCCESS',
    GET_ALL_SECONDARY_ACCESS_SERIES_ERROR = '@@funds/GET_ALL_SECONDARY_ACCESS_SERIES_ERROR',
    GET_ALL_SECONDARY_ACCESS_SERIES_RESET_STATE = '@@funds/GET_ALL_SECONDARY_ACCESS_SERIES_RESET_STATE'
}

export interface GetAllSecondaryAccessSeriesState extends BaseStoreState {
    readonly allSecondaryAccessSeries?: FundSeriesAndInvestor[];
}
