import React from 'react';
import SimpleCategoryBarChart from '../../components/chart/bar-chart/category-bar-chart/simple-category-bar-chart';
import SimpleHorizontalBarChart from '../../components/chart/bar-chart/horizontal-bar-chart/simple-horizontal-bar-chart';
import SimpleStackedBarChart from '../../components/chart/bar-chart/stacked-bar-chart/simple-stacked-bar-chart';
import CustomChartLegend from '../../components/chart/chart-legend/custom-chart-legend';
import GroupedChartToolTip, { ChartGroupData } from '../../components/chart/chart-tooltip/grouped-chart-tooltip';
import UngroupedChartTooTip from '../../components/chart/chart-tooltip/ungrouped-chart-tooltip';
import SimpleDonutChart from '../../components/chart/donut-chart/simple-donut-chart';
import SimpleSplineChart from '../../components/chart/line-chart/spline-chart/simple-spline-chart';
import SimpleYLineChart from '../../components/chart/line-chart/y-line-chart/simple-y-line-chart';

const formatLabel = (x: number): string => {
    return `R$ ${x}`;
};

interface ChartData extends Record<string, number | string> {
    month: string;
    value: number;
    base: number;
    date: string;
    amount: number;
    diff: number;
}

export const ChartSamples = () => {
    const [chartData, setChartData] = React.useState<ChartData[]>([
        { month: 'abr', value: 10, base: 0, date: '25/08', amount: 0, diff: 1000 },
        { month: 'mai', value: 50, base: 0, date: '24/08', amount: 0, diff: 1000 },
        { month: 'jun', value: 40, base: 0, date: '23/08', amount: 0, diff: 1000 },
        { month: 'jul', value: 88, base: 0, date: '22/08', amount: 0, diff: 1000 },
        { month: 'ago', value: 28, base: 0, date: '21/08', amount: 0, diff: 1000 },
        { month: 'set', value: 83, base: 0, date: '18/08', amount: 0, diff: 1000 },
        { month: 'out', value: 22, base: 0, date: '17/08', amount: 0, diff: 1000 },
        { month: 'nov', value: 28, base: 0, date: '16/08', amount: 0, diff: 1000 },
        { month: 'dez', value: 68, base: 0, date: '15/08', amount: 0, diff: 1000 },
        { month: 'jan', value: 21, base: 0, date: '14/08', amount: 0, diff: 1000 }
    ]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setChartData([
                { month: 'abr', value: 709, base: 100, date: '25/08', amount: 600, diff: 400 },
                { month: 'mai', value: 400, base: 120, date: '24/08', amount: 400, diff: 600 },
                { month: 'jun', value: 200, base: 180, date: '23/08', amount: 300, diff: 700 },
                { month: 'jul', value: 653, base: 450, date: '22/08', amount: 400, diff: 600 },
                { month: 'ago', value: 400, base: 400, date: '21/08', amount: 600, diff: 400 },
                { month: 'set', value: 700, base: 150, date: '18/08', amount: 700, diff: 300 },
                { month: 'out', value: 800, base: 500, date: '17/08', amount: 750, diff: 250 },
                { month: 'nov', value: 639, base: 539, date: '16/08', amount: 450, diff: 550 },
                { month: 'dez', value: 300, base: 628, date: '15/08', amount: 500, diff: 500 },
                { month: 'jan', value: 500, base: 115, date: '14/08', amount: 650, diff: 350 }
            ]);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const customizeUngroupedToolTip = (id: string, value: number, color: unknown) => {
        return `
        <div style="display: flex; align-items: center">
            <div style="background-color:${color}; width: 10px; height: 10px; display: inline-block; margin-right: 5px"></div>
            <b style="font-size: 10px">${id}</b>
        </div>
        <hr style="margin: 5px 0" />
        <b>${value}</b>`;
    };

    const customizeGroupedToolTip = (itens: ChartGroupData[]) => {
        const buildItem = (item: ChartGroupData) => `
            <div>
                <div style="background-color:${item.color}; width: 10px; height: 10px; display: inline-block; margin-right: 5px"></div>
                <b style="font-size: 10px">${item.id}</b>
                ${item.value}
            </div>`;

        return `
        <div style="display: flex; align-items: center; flex-direction: column;">
            ${itens.map(buildItem)}
        </div>`;
    };

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50vw' }}>
                    <UngroupedChartTooTip customize={customizeUngroupedToolTip}>
                        {tooltip => (
                            <SimpleCategoryBarChart
                                data={chartData}
                                config={{
                                    height: 320,
                                    labelKey: 'month',
                                    categoriesKeys: ['value', 'base'],
                                    categoryLabelFormat: formatLabel
                                }}
                                tooltipOptions={tooltip}
                            />
                        )}
                    </UngroupedChartTooTip>
                </div>
                <div style={{ width: '50vw' }}>
                    <GroupedChartToolTip customize={customizeGroupedToolTip}>
                        {tooltip => (
                            <SimpleStackedBarChart
                                data={chartData}
                                config={{
                                    height: 320,
                                    labelKey: 'month',
                                    stackKeys: ['base', 'value'],
                                    stackLabelFormat: formatLabel
                                }}
                                tooltipOptions={tooltip}
                            >
                                {(chart, keys) => (
                                    <CustomChartLegend
                                        chart={chart}
                                        keys={keys}
                                        formatKey={key => (key === 'value' ? 'ABC' : 'XYZ')} // map key to desired label
                                    />
                                )}
                            </SimpleStackedBarChart>
                        )}
                    </GroupedChartToolTip>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50vw' }}>
                    <UngroupedChartTooTip customize={customizeUngroupedToolTip}>
                        {tooltip => (
                            <SimpleYLineChart
                                data={chartData}
                                config={{
                                    height: 320,
                                    labelKey: 'month',
                                    yKeys: ['value', 'base'],
                                    yLabelFormat: formatLabel,
                                    pointRadius: 3.5,
                                    pointFocusRadius: 5.5,
                                    colorPatern: ['#fa5454', '#319ff8']
                                }}
                                tooltipOptions={tooltip}
                            />
                        )}
                    </UngroupedChartTooTip>
                </div>
                <div style={{ width: '50vw' }}>
                    <UngroupedChartTooTip customize={customizeUngroupedToolTip}>
                        {tooltip => (
                            <SimpleSplineChart
                                data={chartData}
                                config={{
                                    height: 320,
                                    labelKey: 'month',
                                    yKeys: ['value'],
                                    yLabelFormat: formatLabel,
                                    // pointRadius: 4.5,
                                    // pointFocusRadius: 6
                                }}
                                tooltipOptions={tooltip}
                                //columns={[]}
                            >
                                {(chart, keys) => (
                                    <CustomChartLegend
                                        chart={chart}
                                        keys={['']}
                                        formatKey={key => (key === 'value' ? 'ABC' : '')} // map key to desired label
                                    />
                                )}
                            </SimpleSplineChart>
                        )}
                    </UngroupedChartTooTip>
                </div>
            </div>
            c
            <div style={{ display: 'flex' }}>
                <div style={{ width: '50vw' }}>
                    <GroupedChartToolTip customize={it => customizeGroupedToolTip([{ ...it[0] }])} // showing one first value of group
                    >
                        {tooltip => (
                            <SimpleHorizontalBarChart
                                data={chartData}
                                config={{
                                    height: 320,
                                    labelKey: 'date',
                                    stackKeys: ['amount', 'diff'],
                                    stackLabelFormat: formatLabel,
                                    colorPatern: ['#64b5f7', '#64b5f766']
                                }}
                                tooltipOptions={tooltip}
                            />
                        )}
                    </GroupedChartToolTip>
                </div>
                <div style={{ width: '50vw' }}>
                    <UngroupedChartTooTip customize={customizeUngroupedToolTip}>
                        {tooltip => (
                            <SimpleDonutChart
                                data={[chartData[0]]} // mocking only one value of array (optional)
                                config={{
                                    height: 320,
                                    // labelKey: 'month',
                                    categoriesKeys: ['value', 'base'],
                                    // categoryLabelFormat: formatLabel,
                                    donutWidth: 80,
                                    donutLabelPositionAdjust: 0.85
                                }}
                                tooltipOptions={tooltip}    
                            >
                                {(chart, keys) => (
                                    <CustomChartLegend
                                        chart={chart}
                                        keys={['value','0', '1', '2']}
                                        formatKey={key => (key === 'value' ? 'ABC' : 'XYZ')} // map key to desired label
                                    />
                                )}
                            </SimpleDonutChart>
                        )}
                    </UngroupedChartTooTip>
                </div>
            </div>
        </>
    );
};

export default ChartSamples;
