
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { confirmShareholderInviteReducer } from './confirm/reducer';
import confirmShareholderInviteSaga from './confirm/sagas';
import { ShareholderInviteConfirmState } from './confirm/types';
import { shareholderInviteToConfirmReducer } from './to-confirm/reducer';
import shareholderInviteToConfirmtSaga from './to-confirm/sagas';
import { ShareholderInviteToConfirmState } from './to-confirm/types';

export interface ShareholderInviteState {
    readonly recoverShareholderInvite: ShareholderInviteToConfirmState;
    readonly confirmShareholderInvite: ShareholderInviteConfirmState;
}

export const ShareholderInviteReducer: ReducersMapObject<ShareholderInviteState, AnyAction> = {
    recoverShareholderInvite: shareholderInviteToConfirmReducer,
    confirmShareholderInvite: confirmShareholderInviteReducer
};

export function* shareholderInviteSagas() {
    yield all([
        fork(shareholderInviteToConfirmtSaga),
        fork(confirmShareholderInviteSaga)
    ]);
}
