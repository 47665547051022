import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import useFilterUtils from 'shared/hooks/use-filter-utils';
import HeaderSeriesSelector from '../header-series-selector/header-series-selector';

import './anchor-header.scss';

export enum AnchorTab {
    GENERAL = 'GENERAL',
    PERFORMANCE = 'PERFORMANCE',
    CAPTURE = 'CAPTURE',
    DOCUMENTS = 'DOCUMENTS',
    INCOME_STATEMENT_MONTH = 'INCOME_STATEMENT_MONTH',
    FINANCIAL_STATEMENT_MONTH = 'FINANCIAL_STATEMENT_MONTH',
    ACCOUNT = 'ACCOUNT'
}

export interface AnchorTabMenuProps {
    tab: AnchorTab | undefined;
}

interface TabLinkProps {
    label: string;
    link: string;
    dataCy: string;
    show: boolean;
}

const TabLink: React.FunctionComponent<TabLinkProps> = ({ label, link, children, dataCy, show }): JSX.Element => {
    const { t } = useTranslation();
    const { params } = useFilterUtils();

    if (!show) {
        return <></>;
    }

    return (
        <li>
            <NavLink
                activeClassName="link-is-active"
                to={{
                    pathname: link,
                    search: params
                }}
                data-cy={dataCy}
            >
                {t(label)}
                {children}
            </NavLink>
        </li>
    );
};

export const AnchorBaseTabLinks: Record<AnchorTab, string> = {
    GENERAL: '/investor/general',
    ACCOUNT: '/investor/account',
    CAPTURE: '/investor/raising',
    DOCUMENTS: '/investor/documents',
    PERFORMANCE: '/investor/performance',
    FINANCIAL_STATEMENT_MONTH: '/investor/financial-statement-month',
    INCOME_STATEMENT_MONTH: '/investor/income-statement-month'
};

const TAB_LINK_RECORD: Record<AnchorTab, React.ReactNode> = {
    GENERAL: <TabLink label="anchor.menu.general" link={AnchorBaseTabLinks.GENERAL} dataCy="anchor-tablink#general" key={AnchorBaseTabLinks.GENERAL} show />,
    CAPTURE: <TabLink label="anchor.menu.capture" link={AnchorBaseTabLinks.CAPTURE} dataCy="anchor-tablink#capture" key={AnchorBaseTabLinks.CAPTURE} show />,
    DOCUMENTS: <TabLink label="anchor.menu.documents" link={AnchorBaseTabLinks.DOCUMENTS} dataCy="anchor-tablink#documents" key={AnchorBaseTabLinks.DOCUMENTS} show />,
    ACCOUNT: <TabLink label="anchor.menu.account" link={AnchorBaseTabLinks.ACCOUNT} dataCy="anchor-tablink#account" key={AnchorBaseTabLinks.ACCOUNT} show />,
    PERFORMANCE: <TabLink label="anchor.menu.performance" link={AnchorBaseTabLinks.PERFORMANCE} dataCy="anchor-tablink#performance" key={AnchorBaseTabLinks.PERFORMANCE} show />,
    FINANCIAL_STATEMENT_MONTH: <TabLink label="anchor.menu.financial-statement-month" link={AnchorBaseTabLinks.FINANCIAL_STATEMENT_MONTH} dataCy="anchor-tablink#balance" key={AnchorBaseTabLinks.FINANCIAL_STATEMENT_MONTH} show />,
    INCOME_STATEMENT_MONTH: <TabLink label="anchor.menu.income-statement" link={AnchorBaseTabLinks.INCOME_STATEMENT_MONTH} dataCy="anchor-tablink#dre" key={AnchorBaseTabLinks.INCOME_STATEMENT_MONTH} show />
};

export const AnchorTabMenu = (props: AnchorTabMenuProps) => {
    if (!props.tab) {
        return <></>;
    }

    return (
        <div className="anchor-header__tab-title--container">
            <Container>
                <HeaderSeriesSelector />
                <Row data-cy="anchor-tablink#container">
                    <Col>
                        <div className="anchor-header__tab-menu">
                            <ul className="anchor-header__tab-menu--items">{Object.keys(AnchorTab).map(key => TAB_LINK_RECORD[key])}</ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AnchorTabMenu;
