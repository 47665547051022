import { ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// GetAll SecondaryAccess Series
import { GetAllSecondaryAccessSeriesState } from './get-all-secondary-access-series/types';
import { getAllSecondaryAccessSeriesReducer } from './get-all-secondary-access-series/reducer';
import getAllSecondaryAccessSeriesSaga from './get-all-secondary-access-series/sagas';

export interface SecondaryAccessState {
    allSecondaryAccessSeries: GetAllSecondaryAccessSeriesState;
}

export const SecondaryAccessReducer: ReducersMapObject<SecondaryAccessState> = {
    allSecondaryAccessSeries: getAllSecondaryAccessSeriesReducer,
};

export function* secondaryAccessSagas() {
    yield all([fork(getAllSecondaryAccessSeriesSaga)]);
}
