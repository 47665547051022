import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { FindAllUserSeries } from 'model/series';
import { markError } from 'reducer/application/error/actions';
import { findAllSeriesByUserError, findAllSeriesByUserRequest, findAllSeriesByUserSuccess } from 'reducer/series/find-all-by-user/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import seriesApi from 'services/api/seriesApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { FindAllSeriesByUserActionTypes } from './types';

function* handleFindAllSeriesByUserRequest(action: ReturnType<typeof findAllSeriesByUserRequest>) {
    try {
        const result: AxiosResponse<ReadonlyArray<FindAllUserSeries>> = yield call(seriesApi.findAllByUser);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(findAllSeriesByUserError(mapped));
            return;
        }
        yield put(findAllSeriesByUserSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(findAllSeriesByUserError(mapped));
    }
}

function* watchFindAllSeriesByUser() {
    yield takeEvery(FindAllSeriesByUserActionTypes.FIND_ALL_BY_USER_REQUEST, handleFindAllSeriesByUserRequest);
}

function* findAllSeriesByUserSaga() {
    yield all([fork(watchFindAllSeriesByUser)]);
}

export default findAllSeriesByUserSaga;
