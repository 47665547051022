import { PersonToUpdateProfile } from 'model/personAccount';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum PersonUpdateProfileActionTypes {
    PERSON_UPDATE_PROFILE_REQUEST = '@@personUpdateProfile/PERSON_UPDATE_PROFILE_REQUEST',
    PERSON_UPDATE_PROFILE_SUCCESS = '@@personUpdateProfile/PERSON_UPDATE_PROFILE_SUCCESS',
    PERSON_UPDATE_PROFILE_ERROR = '@@personUpdateProfile/PERSON_UPDATE_PROFILE_ERROR',
    PERSON_UPDATE_PROFILE_RESET_STATE = '@@personUpdateProfile/PERSON_UPDATE_PROFILE_RESET_STATE'
}

export interface PersonUpdateProfileState extends BaseStoreState {
    readonly profileUpdate?: PersonToUpdateProfile;
}
