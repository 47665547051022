import React from 'react';
import { ValidationResult } from '../../shared/util/validation-utils';
import FieldBasic from '../field-basic/field-basic';

export interface InputEmailProps {
    label: string;
    email?: string;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    readOnly?: boolean;
    validate?: (value: string) => ValidationResult;
    autoComplete?: string;
    onEnter?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    onChange?: (value: string) => void;
    showValidation?: boolean;
    externalUpdate?: boolean;
}

export const InputEmail = (props: InputEmailProps) => {
    return (
        <FieldBasic
            label={props.label}
            margin="normal"
            readOnly={props.readOnly}
            value={props.email ?? ''}
            autoFocus={props.autoFocus}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            validate={props.validate}
            autoComplete={props.autoComplete}
            onEnter={props.onEnter}
            onChange={props.onChange}
            showValidation={props.showValidation}
            externalUpdate={props.externalUpdate}
        />
    );
};

export default InputEmail;
