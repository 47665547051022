import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { SharePerformanceActionTypes, SharePerformanceState } from './types';

export const initialState: SharePerformanceState = {
    status: HttpRequestStatus.NOOP,
    performance: undefined,
    error: undefined
};

const reducer: Reducer<SharePerformanceState> = (state = initialState, action): SharePerformanceState => {
    switch (action.type) {
        case SharePerformanceActionTypes.SHARE_PERFORMANCE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, performance: undefined, error: undefined };
        }
        case SharePerformanceActionTypes.SHARE_PERFORMANCE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, performance: action.payload, error: undefined };
        }
        case SharePerformanceActionTypes.SHARE_PERFORMANCE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SharePerformanceActionTypes.SHARE_PERFORMANCE_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as sharePerformanceReducer };
