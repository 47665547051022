import { CityToSearch } from 'model/personAccount';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum SearchCitiesTypes {
    SEARCH_CITIES_REQUEST = '@@search-cities/SEARCH_CITIES_REQUEST',
    SEARCH_CITIES_SUCCESS = '@@search-cities/SEARCH_CITIES_SUCCESS',
    SEARCH_CITIES_ERROR = '@@search-cities/SEARCH_CITIES_ERROR',
    SEARCH_CITIES_RESET_STATE = '@@search-cities/SEARCH_CITIES_RESET_STATE',
}

export interface SearchCitiesState extends BaseStoreState {
    readonly cities?: Page<CityToSearch>;
}
