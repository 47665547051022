import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { AcceptLgpdTermsActionTypes, AcceptLgpdTermsState } from './types';

export const initialState: AcceptLgpdTermsState = {
    status: HttpRequestStatus.NOOP,
    person: undefined,
    error: undefined
};

const reducer: Reducer<AcceptLgpdTermsState> = (state = initialState, action): AcceptLgpdTermsState => {
    switch (action.type) {
        case AcceptLgpdTermsActionTypes.ACCEPT_LGPD_TERMS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, person: undefined, error: undefined };
        }
        case AcceptLgpdTermsActionTypes.ACCEPT_LGPD_TERMS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, person: action.payload, error: undefined };
        }
        case AcceptLgpdTermsActionTypes.ACCEPT_LGPD_TERMS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case AcceptLgpdTermsActionTypes.ACCEPT_LGPD_TERMS_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as acceptLgpdTermsReducer };
