import { AxesOptions, ChartAPI, ChartConfiguration, Data, generate, PointOptions, TooltipOptions } from 'c3';
import { ChartData } from 'components/chart/chart-data';
import React from 'react';
import useUID from '../../../../shared/util/uid-utils';
import { DEFAULT_COLOR_PATTERN } from '../../chart-constants';
import './y-line-chart.scss';

export interface BarChartConfiguration<T extends ChartData, K extends keyof T> {
    height: number;
    labelKey: K;
    yKeys: K[];
    yLabelFormat?: (value: number) => string;
    colorPatern?: string[];
    pointRadius?: number;
    pointFocusRadius?: number;
    // TODO validate unused props
    axisTickY?: number;
}

export interface SimpleYLineChartProps<T extends ChartData, K extends keyof T> {
    data: T[];
    className?: string;
    config: BarChartConfiguration<T, K>;
    children?: (chart: ChartAPI, keys: string[]) => React.ReactNode;
    tooltipOptions?: TooltipOptions;
}

export const SimpleYLineChart = <T extends ChartData, K extends keyof T>(props: SimpleYLineChartProps<T, K>) => {
    const UID = useUID();

    const [chart, setChart] = React.useState<ChartAPI>({} as ChartAPI);
    const [config] = React.useState<BarChartConfiguration<T, K>>(props.config);
    const [initialData] = React.useState<T[]>(props.data);
    const [hasLoaded, setLoaded] = React.useState<boolean>(false);

    React.useEffect(() => {
        const chartData: Data = {
            json: initialData,
            type: 'line',
            keys: {
                x: config.labelKey.toString(),
                value: config.yKeys.map(it => it.toString())
            }
        };
        const axesConfig: AxesOptions = {
            x: {
                type: 'category',
                tick: { outer: false }
            },
            y: {
                tick: {
                    format: config.yLabelFormat,
                    outer: false
                }
            }
        };
        const pointConfig: PointOptions = {
            show: !!config.pointRadius,
            r: config.pointRadius,
            focus: {
                expand: {
                    enabled: !!config.pointFocusRadius,
                    r: config.pointFocusRadius
                }
            }
        };
        const charConfiguration: ChartConfiguration = {
            data: chartData,
            bindto: `#${UID}`,
            size: { height: config.height },
            legend: { hide: true },
            transition: { duration: 400 },
            grid: { y: { show: true }, x: { show: true } },
            tooltip: { ...props.tooltipOptions },
            axis: axesConfig,
            point: pointConfig,
            color: { pattern: config.colorPatern ?? DEFAULT_COLOR_PATTERN }
        };
        const _chart = generate(charConfiguration);
        setChart(_chart);
        setLoaded(true);
    }, [UID, config, initialData, props.tooltipOptions]);

    React.useEffect(() => {
        if (chart.load) {
            chart.load({
                json: props.data,
                keys: {
                    x: config.labelKey.toString(),
                    value: config.yKeys.map(it => it.toString())
                }
            });
        }
    }, [props.data, config, chart]);

    const classes: string = ['y-line-chart', props.className].filter(it => !!it).join(' ');

    return (
        <div className={classes}>
            <div id={UID} />
            {props.children &&
                hasLoaded &&
                props.children(
                    chart,
                    props.config.yKeys.map(it => it.toString())
                )}
        </div>
    );
};
export default SimpleYLineChart;
