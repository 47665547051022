import { all, fork } from '@redux-saga/core/effects';
import { capitalEvolutionReducer } from 'reducer/fund-account/capital-evolution/reducer';
import capitalEvolutionSaga from 'reducer/fund-account/capital-evolution/sagas';
import { CapitalEvolutionState } from 'reducer/fund-account/capital-evolution/types';
import { AnyAction, ReducersMapObject } from 'redux';
import { capitalBalanceReducer } from './capital-balance/reducer';
import capitalBalanceSaga from './capital-balance/sagas';
import { CapitalBalanceState } from './capital-balance/types';
import { fundAccountCashAvailableBalanceReducer } from './cash-available-balance/reducer';
import fundAccountCashAvailableBalanceSaga from './cash-available-balance/sagas';
import { FundAccountCashAvailableBalanceState } from './cash-available-balance/types';
import { fundAccountParticipantCostReducer } from './participant-costs/reducer';
import fundAccountParticipantCostSaga from './participant-costs/sagas';
import { FundAccountParticipantCostState } from './participant-costs/types';
import { fundAccountReceivablesVolumeReducer } from './receivables-volume/reducer';
import fundAccountReceivablesVolumeSaga from './receivables-volume/sagas';
import { FundAccountrReceivablesVolumeState } from './receivables-volume/types';

export interface FundAccountState {
    cashAvailableBalance: FundAccountCashAvailableBalanceState;
    capitalEvolution: CapitalEvolutionState;
    capitalBalance: CapitalBalanceState;
    receivables: FundAccountrReceivablesVolumeState;
    participantCosts: FundAccountParticipantCostState;
}

export const FundAccountReducer: ReducersMapObject<FundAccountState, AnyAction> = {
    cashAvailableBalance: fundAccountCashAvailableBalanceReducer,
    capitalEvolution: capitalEvolutionReducer,
    capitalBalance: capitalBalanceReducer,
    receivables: fundAccountReceivablesVolumeReducer,
    participantCosts: fundAccountParticipantCostReducer
};

export function* fundAccountSagas() {
    yield all([fork(fundAccountCashAvailableBalanceSaga), fork(capitalEvolutionSaga), fork(fundAccountReceivablesVolumeSaga), fork(fundAccountParticipantCostSaga), fork(capitalBalanceSaga)]);
}
