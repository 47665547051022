import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { AuthenticationActionTypes, AuthenticationState } from './types';

export const initialState: AuthenticationState = {
    status: HttpRequestStatus.NOOP,
    token: undefined
};

const reducer: Reducer<AuthenticationState> = (state = initialState, action): AuthenticationState => {
    switch (action.type) {
        case AuthenticationActionTypes.LOGIN_REQUEST:
        case AuthenticationActionTypes.FULL_LOGIN_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING };
        }
        case AuthenticationActionTypes.LOGIN_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, token: action.payload };
        }
        case AuthenticationActionTypes.LOGIN_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case AuthenticationActionTypes.LOGOUT_REQUEST:
        case AuthenticationActionTypes.LOGIN_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as authenticationReducer };
