import { AxiosResponse } from 'axios';
import { FundAccountReceivables } from 'model/fund-account';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fundAccountApi from 'services/api/fundAccountApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { receivablesVolumeError, receivablesVolumeRequest, receivablesVolumeSuccess } from './actions';
import { FundAccountrReceivablesVolumeActionTypes } from './types';

function* handleGetreceivablesVolume(action: ReturnType<typeof receivablesVolumeRequest>) {
    try {
        const result: AxiosResponse<FundAccountReceivables> = yield call(fundAccountApi.getReceivablesFundBalance, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(receivablesVolumeError(mapped));
            return;
        }
        yield put(receivablesVolumeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(receivablesVolumeError(mapped));
    }
}

function* watchGetreceivablesVolume() {
    yield takeEvery(FundAccountrReceivablesVolumeActionTypes.RECEIVABLES_VOLUME_REQUEST, handleGetreceivablesVolume);
}

function* fundAccountReceivablesVolumeSaga() {
    yield all([fork(watchGetreceivablesVolume)]);
}

export default fundAccountReceivablesVolumeSaga;
