import { CapitalEvolution } from 'model/fund-account';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum CapitalEvolutionActionTypes {
    CAPITAL_EVOLUTION_REQUEST = '@@capitalEvolution/CAPITAL_EVOLUTION_REQUEST',
    CAPITAL_EVOLUTION_SUCCESS = '@@capitalEvolution/CAPITAL_EVOLUTION_SUCCESS',
    CAPITAL_EVOLUTION_ERROR = '@@capitalEvolution/CAPITAL_EVOLUTION_ERROR',
    CAPITAL_EVOLUTION_RESET_STATE = '@@capitalEvolution/CAPITAL_EVOLUTION_RESET_STATE'
}

export interface CapitalEvolutionState extends BaseStoreState {
    readonly report?: CapitalEvolution;
}
