import { AxiosResponse } from 'axios';
import { ShareholderInvite, ShareholderInviteConfirmation, ShareholderInviteRequest } from '../../model/shareholder-invite';
import { api } from './api';

export const shareholderInvite = () => {
    const recoverByToken = (token: string): Promise<AxiosResponse<ShareholderInvite>> => {
        return api.get<ShareholderInvite>(`api/shareholder-invites/${token}`);
    };

    const confirmByToken = (request: ShareholderInviteRequest): Promise<AxiosResponse<ShareholderInviteConfirmation>> => {
        return api.patch<ShareholderInviteConfirmation>(`api/shareholder-invites/${request.token}/confirm`, request.invite);
    };

    return {
        recoverByToken,
        confirmByToken
    };
};

export default shareholderInvite();
