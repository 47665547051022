import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonStart from '../../../../components/button-start/button-start';
import { useSteps } from '../invite-steps/invite-steps-context';
import './welcome.scss';

export interface WelcomeProps {
    name: string;
    loading: boolean;
}

export const Welcome = (props: WelcomeProps): JSX.Element => {
    const { t } = useTranslation();
    const steps = useSteps();

    return (
        <div className="body__welcome">
            <Row>
                <Col md="8" style={{ margin: 'auto' }}>
                    <div className="body__welcome--title">
                        <span>{t('accept-invite.welcome.greetings', { name: props.name })}</span>
                        <br />
                        <span>{t('accept-invite.welcome.title')}</span>
                    </div>
                    <div className="body__welcome--text">{t('accept-invite.welcome.platform')}</div>
                    <div className="body__welcome--text">
                        {t('accept-invite.welcome.register.start')}
                        <span>{t('accept-invite.welcome.register.contrast')}</span>
                        {t('accept-invite.welcome.register.middle')}
                        <span>{t('accept-invite.welcome.register.end')}</span>
                    </div>
                    <div className="container__button">
                        <ButtonStart onClick={steps.toNext} disabled={props.loading} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Welcome;
