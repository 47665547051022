import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { numberOfParticipantsReducer } from './number-of-participants/reducer';
import numberOfParticipantsSaga from './number-of-participants/sagas';
import { NumberOfParticipantsState } from './number-of-participants/types';

export interface RaisingState {
    numberOfParticipants: NumberOfParticipantsState;
}

export const RaisingReducer: ReducersMapObject<RaisingState, AnyAction> = {
    numberOfParticipants: numberOfParticipantsReducer
};

// prettier-ignore
export function* raisingSagas() {
    yield all([
        fork(numberOfParticipantsSaga)
    ]);
}
