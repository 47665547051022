import { AxiosResponse } from 'axios';
import { IncomeStatementSubGroupAcquiredVolume } from 'model/income-statement-sub-group';
import LoggingUtils from 'shared/util/logging-utils';
import { api } from './api';

export const incomeStatementSubGroup = () => {
    const getAcquiredVolume = (fundId: string): Promise<AxiosResponse<IncomeStatementSubGroupAcquiredVolume>> => {
        LoggingUtils.debugInfo('test');
        return api.get<IncomeStatementSubGroupAcquiredVolume>(`api/income-statement-sub-groups/funds/${fundId}/acquired-volume`);
    };

    return {
        getAcquiredVolume
    };
};

export default incomeStatementSubGroup();
