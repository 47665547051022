import { Grid } from '@material-ui/core';
import ClearButton from 'components/button-clear/clear-button';
import CloseButton from 'components/button-close/close-button';
import StandardButton from 'components/button-standard/standard-button';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker-input';
import { format } from 'date-fns';
import _ from 'lodash';
import { DatePeriod } from 'model/enums/date-period';
import { ShareholderAccountHistoryRequest } from 'model/shareholder-account';
import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import './account-filter-modal.scss';

interface AccountFilterModalProps {
    filterActive: ShareholderAccountHistoryRequest;
    onClose: () => void;
    handleChangeFilterActive: (value: Partial<ShareholderAccountHistoryRequest>) => void;
}

const AccountFilterModal = (props: AccountFilterModalProps) => {
    const { filterActive, onClose, handleChangeFilterActive } = props;

    const [filterRequest, setFilterRequest] = useState<ShareholderAccountHistoryRequest>(filterActive);

    const handleDatesFilter = (value: Partial<ShareholderAccountHistoryRequest>) => {
        setFilterRequest({
            ...filterRequest,
            ...value
        });
    };

    const handleConfirmFilterRequest = () => {
        handleChangeFilterActive({ ...filterRequest, datePeriod: undefined });
        onClose();
    };

    const handleClearFilterRequest = () => {
        handleChangeFilterActive({ datePeriod: DatePeriod.THREE_MONTHS, startDate: undefined, endDate: undefined });
    };

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    const DatesFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    id="inputDate_filterMyAccount_initialDate"
                    onChange={startDate => handleDatesFilter({ startDate })}
                    value={filterRequest?.startDate ?? ''}
                    maxDate={filterRequest?.endDate ?? format(new Date(), 'yyyy-MM-dd')}
                    placeholder={'account.initial-date'}
                    label={'account.initial-date'}
                    dataCy="account-date#start-date"
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    id="inputDate_filterMyAccount_finalDate"
                    onChange={endDate => handleDatesFilter({ endDate })}
                    value={filterRequest?.endDate ?? ''}
                    minDate={filterRequest?.startDate}
                    maxDate={format(new Date(), 'yyyy-MM-dd')}
                    placeholder={'account.final-date'}
                    label={'account.final-date'}
                    dataCy="account-date#end-date"
                />
            </Grid>
        </>
    );

    const isCleanButtonDisabled: boolean = !filterRequest.startDate && !filterRequest.endDate;
    const isConfirmButtonDisabled: boolean = _.isEqual(filterRequest, filterActive);

    return (
        <Modal isOpen toggle={onClose}>
            <div className="account-filter-modal--container">
                <div className="account-filter-modal--header">
                    <label className="account-filter-modal--header-title"> Período</label>
                    <CloseButton onClick={onClose} />
                </div>
                <Grid container spacing={2}>
                    {DatesFilter}
                </Grid>
                <div className="account-filter-modal--buttons-actions">
                    <ClearButton onClick={handleClearFilterRequest} disabled={isCleanButtonDisabled} />
                    <StandardButton label="entity.action.confirm" onClick={handleConfirmFilterRequest} disabled={isConfirmButtonDisabled} />
                </div>
            </div>
        </Modal>
    );
};

export default AccountFilterModal;
