import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { IncomeStatementSubGroupAcquiredVolume } from 'model/income-statement-sub-group';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import incomeStatementSubGroupApi from 'services/api/incomeStatementSubGroupApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { subGroupAcquiredVolumeError, subGroupAcquiredVolumeRequest, subGroupAcquiredVolumeSuccess } from './actions';
import { IncomeStatementSubGroupAcquiredVolumeActionTypes } from './types';

function* handleGetSubGroupAcquiredVolume(action: ReturnType<typeof subGroupAcquiredVolumeRequest>) {
    try {
        const result: AxiosResponse<IncomeStatementSubGroupAcquiredVolume> = yield call(incomeStatementSubGroupApi.getAcquiredVolume, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(subGroupAcquiredVolumeError(mapped));
            return;
        }
        yield put(subGroupAcquiredVolumeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(subGroupAcquiredVolumeError(mapped));
    }
}

function* watchGetSubGroupAcquiredVolume() {
    yield takeEvery(IncomeStatementSubGroupAcquiredVolumeActionTypes.SUB_GROUP_ACQUIRED_VOLUME_REQUEST, handleGetSubGroupAcquiredVolume);
}

function* incomeStatementSubGroupAcquiredVolumeSaga() {
    yield all([fork(watchGetSubGroupAcquiredVolume)]);
}

export default incomeStatementSubGroupAcquiredVolumeSaga;
