import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from '../../../model/enums/httpStatus';
import { PersonTermsAcceptance } from '../../../model/person';
import { UserAccount } from '../../../model/user';
import accountApi from '../../../services/api/accountApi';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { termsAcceptanceError, termsAcceptanceSuccess } from '../terms-acceptance/actions';
import { userAccountError, userAccountSuccess } from './actions';
import { UserAccountActionTypes } from './types';

function* handleUserAccount() {
    try {
        const result: AxiosResponse<UserAccount> = yield call(accountApi.recoverAccount);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(userAccountError(mapped));
            return;
        }
        yield put(userAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(userAccountError(mapped));
    }
}

function* watchUserAccountRequest() {
    yield takeEvery(UserAccountActionTypes.USER_ACCOUNT_REQUEST, handleUserAccount);
}

function* handleUserAccountWithTerms() {
    try {
        const result: AxiosResponse<UserAccount> = yield call(accountApi.recoverAccount);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(userAccountError(mapped));
            return;
        }
        yield recoverTermsAcceptance();
        yield put(userAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(userAccountError(mapped));
    }
}

function* recoverTermsAcceptance() {
    const result: AxiosResponse<PersonTermsAcceptance> = yield call(accountApi.termsAcceptance);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(termsAcceptanceError(mapped));
            return;
        }
        yield put(termsAcceptanceSuccess(result.data));
}


function* watchUserAccountWithTermsRequest() {
    yield takeEvery(UserAccountActionTypes.USER_ACCOUNT_WITH_TERMS_REQUEST, handleUserAccountWithTerms);
}

function* userAccountSaga() {
    yield all([fork(watchUserAccountRequest), fork(watchUserAccountWithTermsRequest)]);
}

export default userAccountSaga;
