import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { TermsAcceptanceActionTypes, TermsAcceptanceState } from './types';

export const initialState: TermsAcceptanceState = {
  status: HttpRequestStatus.NOOP,
  terms: undefined,
  error: undefined
};

const reducer: Reducer<TermsAcceptanceState> = (state = initialState, action): TermsAcceptanceState => {
  switch (action.type) {
    case TermsAcceptanceActionTypes.TERMS_ACCEPTANCE_REQUEST: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }
    case TermsAcceptanceActionTypes.TERMS_ACCEPTANCE_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, terms: action.payload, error: undefined };
    }
    case TermsAcceptanceActionTypes.TERMS_ACCEPTANCE_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }

    case TermsAcceptanceActionTypes.TERMS_ACCEPTANCE_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as termsAcceptanceReducer };

