import { FundHistory, Portfolio } from 'model/fund';
import { action } from 'typesafe-actions';
import { GiroWebappError } from '../../../model/error';
import { MyFundsActionTypes } from './types';

export const portfolioRequest = () => action(MyFundsActionTypes.PORTFOLIO_REQUEST);
export const portfolioSuccess = (response: Portfolio) => action(MyFundsActionTypes.PORTFOLIO_SUCCESS, response);
export const portfolioError = (error: GiroWebappError) => action(MyFundsActionTypes.PORTFOLIO_ERROR, error);
export const portfolioResetState = () => action(MyFundsActionTypes.PORTFOLIO_RESET_STATE);

export const fundHistoryRequest = () => action(MyFundsActionTypes.FUND_HISTORY_REQUEST);
export const fundHistorySuccess = (response: FundHistory) => action(MyFundsActionTypes.FUND_HISTORY_SUCCESS, response);
export const fundHistoryError = (error: GiroWebappError) => action(MyFundsActionTypes.FUND_HISTORY_ERROR, error);
export const fundHistoryResetState = () => action(MyFundsActionTypes.FUND_HISTORY_RESET_STATE);
