import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { GetAllSecondaryAccessSeriesState, GetAllSecondaryAccessSeriesTypes } from './types';

export const initialState: GetAllSecondaryAccessSeriesState = {
    status: HttpRequestStatus.NOOP,
    allSecondaryAccessSeries: undefined,
    error: undefined
};

const reducer: Reducer<GetAllSecondaryAccessSeriesState> = (state = initialState, action): GetAllSecondaryAccessSeriesState => {
    switch (action.type) {
        case GetAllSecondaryAccessSeriesTypes.GET_ALL_SECONDARY_ACCESS_SERIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, allSecondaryAccessSeries: undefined, error: undefined };
        }
        case GetAllSecondaryAccessSeriesTypes.GET_ALL_SECONDARY_ACCESS_SERIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, allSecondaryAccessSeries: action.payload, error: undefined };
        }
        case GetAllSecondaryAccessSeriesTypes.GET_ALL_SECONDARY_ACCESS_SERIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case GetAllSecondaryAccessSeriesTypes.GET_ALL_SECONDARY_ACCESS_SERIES_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as getAllSecondaryAccessSeriesReducer };
