import { CapitalBalance } from 'model/fund-account';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum CapitalBalanceActionTypes {
    CAPITAL_BALANCE_REQUEST = '@@capitalBalance/CAPITAL_BALANCE_REQUEST',
    CAPITAL_BALANCE_SUCCESS = '@@capitalBalance/CAPITAL_BALANCE_SUCCESS',
    CAPITAL_BALANCE_ERROR = '@@capitalBalance/CAPITAL_BALANCE_ERROR',
    CAPITAL_BALANCE_RESET_STATE = '@@capitalBalance/CAPITAL_BALANCE_RESET_STATE'
}

export interface CapitalBalanceState extends BaseStoreState {
    readonly report?: CapitalBalance;
}
