import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import useFilterUtils from 'shared/hooks/use-filter-utils';
import HeaderSeriesSelector from '../header-series-selector/header-series-selector';

import './participant-header.scss';

export enum ParticipantTab {
    GENERAL = 'GENERAL',
    DOCUMENTS = 'DOCUMENTS',
    ACCOUNT = 'ACCOUNT'
}

export interface ParticipantTabMenuProps {
    tab: ParticipantTab | undefined;
}

interface TabLinkProps {
    label: string;
    link: string;
    dataCy: string;
}

const TabLink: React.FunctionComponent<TabLinkProps> = ({ label, link, children, dataCy }): JSX.Element => {
    const { t } = useTranslation();
    const { params } = useFilterUtils();

    return (
        <NavLink
            activeClassName="link-is-active"
            to={{
                pathname: link,
                search: params
            }}
            data-cy={dataCy}
        >
            {t(label)}
            {children}
        </NavLink>
    );
};

export const ParticipantsBaseTabLinks: Record<ParticipantTab, string> = {
    ACCOUNT: '/investor/account',
    DOCUMENTS: '/investor/documents',
    GENERAL: '/investor/general',
};

const TAB_LINK_RECORD: Record<ParticipantTab, React.ReactNode> = {
    GENERAL: <TabLink label="participant.menu.general" link={ParticipantsBaseTabLinks.GENERAL} dataCy="participant-tablink#general" />,
    DOCUMENTS: <TabLink label="participant.menu.documents" link={ParticipantsBaseTabLinks.DOCUMENTS} dataCy="participant-tablink#documents" />,
    ACCOUNT: <TabLink label="participant.menu.account" link={ParticipantsBaseTabLinks.ACCOUNT} dataCy="participant-tablink#account" />,
};

const ParticipantTabMenu = (props: ParticipantTabMenuProps) => {
    if (!props.tab) {
        return <></>;
    }

    return (
        <div className="participant-header__tab-title--container">
            <Container>
                <HeaderSeriesSelector />
                <Row data-cy="participant-tablink#container">
                    <Col>
                        <div className="participant-header__tab-menu">
                            <ul className="participant-header__tab-menu--items">
                                {Object.keys(ParticipantTab)
                                    .map(key => ParticipantTab[key])
                                    .map(key => (
                                        <li key={key}>{TAB_LINK_RECORD[key]}</li>
                                    ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ParticipantTabMenu;
