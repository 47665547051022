import { AxiosResponse } from 'axios';
import { ShareholderAccountHistory, ShareholderAccountHistoryRequest } from 'model/shareholder-account';
import { api } from './api';

export const shareholderAccount = () => {
    const history = (request: ShareholderAccountHistoryRequest): Promise<AxiosResponse<ShareholderAccountHistory[]>> => {
        const { fundName, startDate, endDate, fundId, seriesId, datePeriod, personId } = request;

        const _requestFilters = [
            fundName && `fundName=${fundName}`,
            fundId && `fundId=${fundId}`,
            startDate && `startDate=${startDate}`,
            endDate && `endDate=${endDate}`,
            seriesId && `seriesId=${seriesId}`,
            datePeriod && `datePeriod=${datePeriod}`,
            personId ? `personId=${personId}` : undefined
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<ShareholderAccountHistory[]>(`api/shareholders/accounts/history?${_requestFilters}`);
    };

    return {
        history
    };
};

export default shareholderAccount();
