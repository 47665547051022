import H from 'history';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { PasswordChange } from 'model/personAccount';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { changePasswordResetState } from 'reducer/shareholder/profile/change-password/actions';
import { personUpdateProfileResetState } from 'reducer/shareholder/profile/update-profile/actions';
import { compose } from 'redux';
import { logoutRequest } from '../../reducer/authentication/actions';
import { useChangePasswordState, usePersonUpdateProfileState, useRootDispatch } from '../../reducer/hooks';
import MessageError from './components/message-error/message-error';
import Message from './components/message/message';
import ProfileDisplay from './components/profile-display/profile-display';
import ProfileEditing from './components/profile-editing/profile-editing';
import { ProfileProvider, useProfileContext, withProfileContext } from './profile-context';
import './profile.scss';

export const Profile = () => {
    const { t } = useTranslation();
    const history: H.History = useHistory();
    const dispatch = useRootDispatch();
    const {
        isEditing,
        messageVisibility,
        activeId,
        setActiveId,
        handleSave,
        handleDisplayChange,
        handleMouseFunction,
        setIsAddingBankAccount,
        setMessageVisibility,
        messageErrorVisibility,
        setMessageErrorVisibility,
        setIsEditing,
        profileUpdateSuccess,
        passwordUpdateSuccess,
        setShowValidation,
        setChangePassword
    } = useProfileContext();

    const { status: updateStatus } = usePersonUpdateProfileState();
    const { status: passwordChangeStatus } = useChangePasswordState();
    const parentRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (profileUpdateSuccess && passwordChangeStatus === HttpRequestStatus.NOOP) {
            setIsAddingBankAccount(false);
            setMessageVisibility(true);
            setIsEditing(false);
            dispatch(personUpdateProfileResetState());
        }
    }, [profileUpdateSuccess, passwordChangeStatus, setIsAddingBankAccount, setMessageVisibility, setIsEditing, dispatch]);

    React.useEffect(() => {
        if (passwordUpdateSuccess && updateStatus === HttpRequestStatus.NOOP) {
            setIsAddingBankAccount(false);
            setMessageVisibility(true);
            setIsEditing(false);
            setChangePassword({} as PasswordChange);
            dispatch(changePasswordResetState());
            setShowValidation(false);
        }
    }, [passwordUpdateSuccess, updateStatus, setIsAddingBankAccount, setMessageVisibility, setIsEditing, setChangePassword, setShowValidation, dispatch]);

    React.useEffect(() => {
        if (passwordUpdateSuccess && profileUpdateSuccess) {
            setIsAddingBankAccount(false);
            setMessageVisibility(true);
            setIsEditing(false);
            setChangePassword({} as PasswordChange);
            dispatch(personUpdateProfileResetState());
            dispatch(changePasswordResetState());
            setShowValidation(false);
        }
    }, [passwordUpdateSuccess, profileUpdateSuccess, setIsAddingBankAccount, setMessageVisibility, setIsEditing, setChangePassword, setShowValidation, dispatch]);

    React.useEffect(() => {
        if (updateStatus === HttpRequestStatus.ERROR || passwordChangeStatus === HttpRequestStatus.ERROR) {
            setMessageErrorVisibility(true);
        }
    }, [updateStatus, passwordChangeStatus, setMessageErrorVisibility, dispatch]);

    React.useEffect(() => {
        return () => {
            dispatch(personUpdateProfileResetState());
            dispatch(changePasswordResetState());
            setMessageErrorVisibility(false);
        };
    }, [setMessageErrorVisibility, dispatch]);

    React.useEffect(() => {
        const currentParentRef = parentRef.current;
        if (currentParentRef) {
            const nodes = Array.from(currentParentRef.querySelectorAll('.mouse-hover-behavior'));
            nodes.forEach(childNode => {
                childNode.addEventListener('mouseover', handleMouseFunction);
            });

            return () => {
                if (currentParentRef) {
                    nodes.forEach(childNode => {
                        if (childNode) {
                            childNode.removeEventListener('mouseover', handleMouseFunction);
                        }
                    });
                }
            };
        }
    }, [parentRef, handleMouseFunction, isEditing]);

    const handleCancelEditing = () => {
        handleDisplayChange(false);
        setChangePassword({} as PasswordChange);
        setShowValidation(false);
        setIsAddingBankAccount(false);
        setMessageErrorVisibility(false);
        dispatch(personUpdateProfileResetState());
        dispatch(changePasswordResetState());
    };

    const handleLogout = () => {
        dispatch(logoutRequest());
        history.push('/');
    };

    return (
        <div id="profile" className="account-profile__items">
            <div className="personal-data__items">
                <div className="personal-data__items--back">
                    <Container>
                        <Row>
                            <ProfileProvider>
                                {isEditing ? (
                                    <>
                                        <Col className="personal-data__items--back__left" md={{ size: '2', offset: '8' }}>
                                            <div
                                                className="license-name__items--cancel"
                                                onClick={() => {
                                                    handleCancelEditing();
                                                }}
                                            >
                                                {t('profile.cancel')}
                                            </div>
                                        </Col>
                                        <Col className="personal-data__items--back__right" md="2">
                                            <div className={`license-name__items--save`} onClick={handleSave}>
                                                {t('profile.save')}
                                            </div>
                                        </Col>
                                    </>
                                ) : (
                                    <Col className="personal-data__items--back__right" md={{ size: '2', offset: '10' }}>
                                        <div onClick={() => handleDisplayChange(true)} className="license-name__items--edit">
                                            {t('profile.edit')}
                                        </div>
                                    </Col>
                                )}
                            </ProfileProvider>
                        </Row>
                    </Container>
                </div>
                <div className="container">
                    <Row className="personal-data__items--container">
                        <Col md="2">
                            <div className="personal-data__items--sidebar">
                                <div className="personal-data__items--sidebar-title">{t('profile.profile')}</div>
                                <a href="#data" onClick={() => setActiveId('data')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'data' ? 'active' : ''}`}>
                                        {t('profile.data.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                {!isEditing && (
                                    <>
                                        <a href="#address" onClick={() => setActiveId('address')}>
                                            <div className={`personal-data__items--sidebar-subtitle ${activeId === 'address' ? 'active' : ''}`}>
                                                {t('profile.address.title')}
                                                <div className="personal-data__items--sidebar-icon" />
                                            </div>
                                        </a>
                                    </>
                                )}
                                <a href="#data-bank" onClick={() => setActiveId('data-bank')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'data-bank' ? 'active' : ''}`}>
                                        {t('profile.data-bank.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <a href="#password" onClick={() => setActiveId('password')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'password' ? 'active' : ''}`}>
                                        {t('profile.password.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <a href="#contact" onClick={e => setActiveId('contact')}>
                                    <div className={`personal-data__items--sidebar-subtitle ${activeId === 'contact' ? 'active' : ''}`}>
                                        {t('profile.contact.title')}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <div className="personal-data__items--sidebar-subtitle" onClick={handleLogout}>
                                    {t('profile.exit')}
                                    <div className="personal-data__items--sidebar-icon" />
                                </div>
                            </div>
                        </Col>
                        <Col md="9" className="data">
                            <div ref={parentRef}>{isEditing ? <ProfileEditing /> : <ProfileDisplay />}</div>

                            {messageVisibility && <Message />}
                            {messageErrorVisibility && <MessageError message={passwordChangeStatus === HttpRequestStatus.ERROR ? 'profile.error.password' : 'profile.error.profile'} />}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default compose(withProfileContext())(Profile);
