import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { Reducer } from 'redux';
import { CapitalBalanceActionTypes, CapitalBalanceState } from './types';

export const initialState: CapitalBalanceState = {
    status: HttpRequestStatus.NOOP,
    report: undefined,
    error: undefined
};

const reducer: Reducer<CapitalBalanceState> = (state = initialState, action): CapitalBalanceState => {
    switch (action.type) {
        case CapitalBalanceActionTypes.CAPITAL_BALANCE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, report: undefined, error: undefined };
        }
        case CapitalBalanceActionTypes.CAPITAL_BALANCE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, report: action.payload, error: undefined };
        }
        case CapitalBalanceActionTypes.CAPITAL_BALANCE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CapitalBalanceActionTypes.CAPITAL_BALANCE_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as capitalBalanceReducer };
