import { NumberOfParticipants } from 'model/raising';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum NumberOfParticipantsActionTypes {
    NUMBER_OF_PARTICIPANTS_REQUEST = '@@NumberOfParticipants/NUMBER_OF_PARTICIPANTS_REQUEST',
    NUMBER_OF_PARTICIPANTS_SUCCESS = '@@NumberOfParticipants/NUMBER_OF_PARTICIPANTS_SUCCESS',
    NUMBER_OF_PARTICIPANTS_ERROR = '@@NumberOfParticipants/NUMBER_OF_PARTICIPANTS_ERROR',
    NUMBER_OF_PARTICIPANTS_RESET_STATE = '@@NumberOfParticipants/NUMBER_OF_PARTICIPANTS_RESET_STATE'
}

export interface NumberOfParticipantsState extends BaseStoreState {
    readonly report?: NumberOfParticipants;
}
