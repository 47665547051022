import { FundAccountCashAvailableBalance } from 'model/fund-account';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum FundAccountCashAvailableBalanceActionTypes {
    CASH_AVAILABLE_BALANCE_REQUEST = '@@fundAccount/CASH_AVAILABLE_BALANCE_REQUEST',
    CASH_AVAILABLE_BALANCE_SUCCESS = '@@fundAccount/CASH_AVAILABLE_BALANCE_SUCCESS',
    CASH_AVAILABLE_BALANCE_ERROR = '@@fundAccount/CASH_AVAILABLE_BALANCE_ERROR',
    CASH_AVAILABLE_BALANCE_RESET_STATE = '@@fundAccount/CASH_AVAILABLE_BALANCE_RESET_STATE'
}

export interface FundAccountCashAvailableBalanceState extends BaseStoreState {
    readonly balance?: FundAccountCashAvailableBalance;
}
