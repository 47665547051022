import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import DynamicDonutChart from 'components/chart/donut-chart/dynamic-donut-chart';
import { Portfolio } from 'model/fund';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FundPortfolio } from 'model/fund';
import { colorArray } from 'components/chart/line-chart/components/chart-components-style';

interface WalletDiskGraphProps {
    portfolio: Portfolio | undefined;
}

const customizeDonutToolTip = (id: string, value: number, color: unknown) => {
    return `
    <div style="display: flex; align-items: center">
        <div style="background-color:${color}; width: 10px; height: 10px; display: inline-block; margin-right: 5px"></div>
        <b style="font-size: 10px">${id}</b>
    </div>
    <hr style="margin: 5px 0" />
    <b>${value} %</b>`;
};

const WalletDiskGraph = (props: WalletDiskGraphProps) => {
    const { portfolio } = props;

    const sortedPortfolio: FundPortfolio[] | undefined = portfolio?.funds.sort((a, b) => b.sharePercentage - a.sharePercentage);

    const { t } = useTranslation();

    return (
        <div className="infos-container--child">
            {sortedPortfolio?.some(it => it.sharePercentage !== 0) ? (
                <UngroupedChartToolTip customize={customizeDonutToolTip}>
                    {tooltip => (
                        <DynamicDonutChart
                            data={sortedPortfolio?.map(it => [it.name, it.sharePercentage])} // mocking only one value of array (optional)
                            config={{
                                height: 225,
                                width: 225,
                                categoriesKeys: ['name', 'sharePercentage'],
                                donutWidth: 55,
                                colorPatern: colorArray,
                                donutLabelPositionAdjust: 0.9
                            }}
                            tooltipOptions={tooltip}
                        >
                            {(chart, keys) => (
                                <CustomChartLegend
                                    chart={chart}
                                    keys={[]}
                                    formatKey={key => (key === 'totalValue' ? 'totalValue' : key)} // map key to desired label
                                />
                            )}
                        </DynamicDonutChart>
                    )}
                </UngroupedChartToolTip>
            ) : (
                <span className="infos-container--child__empty">{t('wallet.not-funds')}</span>
            )}
        </div>
    );
};

export default WalletDiskGraph;
