import { AxiosResponse } from 'axios';
import { FinancialStatementAvailableYearsFilter, FinancialStatementMonth, FinancialStatementMonthFilter, FinancialStatementMonthYearlyFilter, YearlyFinancialStatementMonth } from 'model/financial-statement-month';
import { api } from './api';

const BASE_URL_ADMIN: string = 'api/financial-statement-month';

export const financialStatementMonth = () => {
    const getFinancialStatementMonth = (filter: FinancialStatementMonthFilter): Promise<AxiosResponse<FinancialStatementMonth>> => {
        const _requestFilters = [filter?.issuerId ? `issuerId=${filter.issuerId}` : undefined, filter?.year ? `year=${filter.year}` : undefined, filter?.personId ? `personId=${filter.personId}` : undefined].filter(it => !!it).join('&');

        return api.get<FinancialStatementMonth>(`${BASE_URL_ADMIN}/monthly?${_requestFilters}`);
    };

    const getAvailableYearsByIssuerId = (filter: FinancialStatementAvailableYearsFilter): Promise<AxiosResponse<ReadonlyArray<number>>> => {
        const _requestFilters = [filter?.personId ? `personId=${filter.personId}` : undefined];

        return api.get<ReadonlyArray<number>>(`${BASE_URL_ADMIN}/issuer/${filter.issuerId}/available-years?${_requestFilters}`);
    };

    const getYearlyFinancialStatementMonth = (filter: FinancialStatementMonthYearlyFilter): Promise<AxiosResponse<YearlyFinancialStatementMonth>> => {
        const _requestFilters = [filter?.issuerId ? `issuerId=${filter.issuerId}` : undefined, filter?.personId ? `personId=${filter.personId}` : undefined].filter(it => !!it).join('&');

        return api.get<YearlyFinancialStatementMonth>(`${BASE_URL_ADMIN}/yearly?${_requestFilters}`);
    };

    return {
        getFinancialStatementMonth,
        getAvailableYearsByIssuerId,
        getYearlyFinancialStatementMonth
    };
};

export default financialStatementMonth();
