import FeatureFlag from 'components/feature-flag/feature-flag';
import { UnleashFeatureToggleCaptureFlag } from 'config/unleash/unleash-feature-toggle-flag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import NumberOfParticipantsCard from 'shared/debenture-cards/number-of-participants/number-of-participants-card';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import { useFeatureFlagStatus } from 'shared/hooks/use-feature-flag-status';
import EmptyFundWarning from '../../../components/empty-fund-warning/empty-fund-warning';
import CapitalEvolutionCard from './components/capital-evolution-card';
import CaptureCard from './components/capture-card';
import ParticipantCostsCard from './components/participant-costs';

import './anchor-capture.scss';

const AnchorCapture = () => {
    const { t } = useTranslation();

    const { selectedFundAndSeries } = useFundAndSeriesContext();
    const isCapitalEvolutionEnabled = useFeatureFlagStatus(UnleashFeatureToggleCaptureFlag.CAPITAL_EVOLUTION);

    if (!selectedFundAndSeries?.fundId) {
        return (
            <div className="anchor-capture__items">
                <EmptyFundWarning />
            </div>
        );
    }

    /**
     * @deprecated
     */
    const graphLabels = ['participantes', 'âncoras'];

    const NumberOfParticipantsGraph: JSX.Element = (
        <Card className="anchor-capture__items--card">
            <NumberOfParticipantsCard fundId={selectedFundAndSeries.fundId} />
        </Card>
    );

    const CaptureGraph: JSX.Element = (
        <Card className="anchor-capture__items--card">
            <CaptureCard
                fundId={selectedFundAndSeries.fundId}
                cardTitle={t('anchor.capture.cards.capture.card-title')}
                graphTitles={[t('anchor.capture.cards.capture.graph-title-one'), t('anchor.capture.cards.capture.graph-title-two')]}
                buttons={[t('anchor.capture.cards.capture.button-volume'), t('anchor.capture.cards.capture.button-balance')]}
                graphLabels={graphLabels}
            />
        </Card>
    );

    const CapitalEvolutionGraph: JSX.Element = (
        <Card className="anchor-capture__items--card">
            <CapitalEvolutionCard
                fundId={selectedFundAndSeries.fundId}
                cardTitles={[t('anchor.capture.cards.capital-evolution.title'), t('anchor.capture.cards.capital-evolution.title-two')]}
                graphLabels={graphLabels}
                graphTitle={t('anchor.capture.cards.capital-evolution.graph-title-one')}
                buttons={[t('anchor.capture.cards.capital-evolution.button-twelve-months'), t('anchor.capture.cards.capital-evolution.button-five-years')]}
            />
        </Card>
    );

    const ParticipantCostsGraph: JSX.Element = (
        <Card className="anchor-capture__items--card">
            <ParticipantCostsCard
                cardTitle={t('anchor.capture.cards.participant-costs.card-title')}
                graphLabels={graphLabels[0]}
                graphTitles={[t('anchor.capture.cards.participant-costs.graph-title-one'), t('anchor.capture.cards.participant-costs.graph-title-two')]}
                buttons={[t('anchor.capture.cards.participant-costs.selector-serie-one'), t('anchor.capture.cards.participant-costs.button-consolidated')]}
                fundId={selectedFundAndSeries.fundId}
                personId={selectedFundAndSeries.personId}
            />
        </Card>
    );

    return (
        <div className="anchor-capture__items">
            <Container>
                <Row>
                    <Col md={isCapitalEvolutionEnabled ? '5' : '6'}>
                        <FeatureFlag flagName={UnleashFeatureToggleCaptureFlag.NUMBER_OF_PARTICIPANTS_GRAPH} component={NumberOfParticipantsGraph} />
                        <FeatureFlag flagName={UnleashFeatureToggleCaptureFlag.CAPTURE_GRAPH} component={CaptureGraph} />
                    </Col>
                    <Col md={isCapitalEvolutionEnabled ? '7' : '6'}>
                        <FeatureFlag flagName={UnleashFeatureToggleCaptureFlag.CAPITAL_EVOLUTION} component={CapitalEvolutionGraph} />
                        <FeatureFlag flagName={UnleashFeatureToggleCaptureFlag.PARTICIPANT_COSTS_GRAPH} component={ParticipantCostsGraph} />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AnchorCapture;
