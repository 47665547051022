import React from 'react';
import FormatterUtils from '../formatter-utils';

export type DecimalValue = number | undefined | null;

export interface DecimalFormatterProps {
    value: DecimalValue;
    defaultValue?: number;
}

export const DecimalFormatter = (props: DecimalFormatterProps) => {
    const { value, defaultValue } = props;
    return <>{FormatterUtils.formatNumberDecimal(value, defaultValue)}</>;
};

export const formatDecimal = FormatterUtils.formatNumberDecimal;

export default DecimalFormatter;
