import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { PersonToProfile } from 'model/personAccount';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import accountApi from 'services/api/accountApi';
import { ErrorUtils } from 'shared/error/error-utils';
import {  personUpdateProfileError, personUpdateProfileRequest, personUpdateProfileSuccess } from './actions';
import { PersonUpdateProfileActionTypes } from './types';

function* handlePersonUpdateProfile(action: ReturnType<typeof personUpdateProfileRequest>) {
    try {
        const result: AxiosResponse<PersonToProfile> = yield call(accountApi.updateProfile, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(personUpdateProfileError(mapped));
            return;
        }
        yield put(personUpdateProfileSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(personUpdateProfileError(mapped));
    }
}

function* watchPersonUpdateProfileRequest() {
    yield takeEvery(PersonUpdateProfileActionTypes.PERSON_UPDATE_PROFILE_REQUEST, handlePersonUpdateProfile);
}

function* personUpdateProfileSaga() {
    yield all([fork(watchPersonUpdateProfileRequest)]);
}

export default personUpdateProfileSaga;
