const TYPEFORM_ID = 'typeform-id';
const MODAL_ACTION = 'hasFormBeenClosed';

const saveFormInLocalStorage = (id: string): void => {
    localStorage.setItem(TYPEFORM_ID, JSON.stringify(id));
};

const checkIfFormInAnswered = (id: string): boolean => {
    return JSON.parse(localStorage.getItem(TYPEFORM_ID) ?? '{}') === id;
};

const saveModalActionInSessionStorage = () => {
    sessionStorage.setItem(MODAL_ACTION, JSON.stringify(true));
};

const checkModalAction = (): boolean => {
    return JSON.parse(sessionStorage.getItem(MODAL_ACTION) ?? '{}') === true;
};

export const EmbededFormUtils = {
    saveFormInLocalStorage,
    checkIfFormInAnswered,
    saveModalActionInSessionStorage,
    checkModalAction
};
