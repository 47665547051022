import { forgotPasswordReducer } from 'reducer/account/forgot-password/reducer';
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { acceptLgpdTermsReducer } from './accept-lgpd-terms/reducer';
import acceptLgpdTermsSaga from './accept-lgpd-terms/sagas';
import { AcceptLgpdTermsState } from './accept-lgpd-terms/types';
import { acceptTermsReducer } from './accept-terms/reducer';
import acceptTermsSaga from './accept-terms/sagas';
import { AcceptTermsState } from './accept-terms/types';
import forgotPasswordSaga from './forgot-password/sagas';
import { ForgotPasswordState } from './forgot-password/types';
import { termsAcceptanceReducer } from './terms-acceptance/reducer';
import termsAceeptanceSaga from './terms-acceptance/sagas';
import { TermsAcceptanceState } from './terms-acceptance/types';
import { userAccountReducer } from './user-account/reducer';
import userAccountSaga from './user-account/sagas';
import { UserAccountState } from './user-account/types';

export interface AccountState {
    readonly acceptTerms: AcceptTermsState;
    readonly termsAcceptance: TermsAcceptanceState;
    readonly userAccount: UserAccountState;
    readonly forgotPassword: ForgotPasswordState;
    readonly lgpdTermsAcceptance: AcceptLgpdTermsState;
}

export const AccountReducer: ReducersMapObject<AccountState, AnyAction> = {
    acceptTerms: acceptTermsReducer,
    termsAcceptance: termsAcceptanceReducer,
    forgotPassword: forgotPasswordReducer,
    userAccount: userAccountReducer,
    lgpdTermsAcceptance: acceptLgpdTermsReducer
};

export function* accountSagas() {
    yield all([fork(acceptTermsSaga), fork(termsAceeptanceSaga), fork(forgotPasswordSaga), fork(userAccountSaga), fork(acceptLgpdTermsSaga)]);
}
