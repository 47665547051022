import React from 'react';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import HeaderInvestor from './components/investor-header';
import './header-series-selector.scss';
import HeaderSecondaryAccess from './components/secondary-access-header';
import _ from 'lodash';
import { HeaderVisualizationType } from 'model/enums/header-visualization-type';

const HeaderSeriesSelector = () => {
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    if (_.isNil(selectedFundAndSeries)) return <></>;

    const isSecondaryAccessVisualization: boolean = selectedFundAndSeries?.headerVisualizationType === HeaderVisualizationType.SECONDARY_ACCESS; 

    return isSecondaryAccessVisualization ? <HeaderSecondaryAccess selectedFundAndSeries={selectedFundAndSeries} /> : <HeaderInvestor selectedFundAndSeries={selectedFundAndSeries} />;
};

export default HeaderSeriesSelector;
