import { Tooltip } from '@material-ui/core';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import StandardOutlinedButton from 'components/standard-outlined-button/standard-outlined-button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import SelectAllModal from 'components/select-all-modal/select-all-modal';
import '../header-series-selector.scss';
import { FundSeriesAndInvestor } from 'model/series';

interface HeaderInvestorProps {
    selectedFundAndSeries: FundSeriesAndInvestor;
}

const HeaderInvestor = (props: HeaderInvestorProps) => {
    const [showModal, setShowModal] = useState(false);

    const { selectedFundAndSeries } = props;

    const { t } = useTranslation();

    return (
        <>
            <Row>
                <Col>
                    <div className="header-series-selector--container">
                        <div className="header-series-selector--title">
                            <Tooltip title={selectedFundAndSeries?.fundName ? selectedFundAndSeries?.fundName : ''}>
                                <span>{selectedFundAndSeries?.fundName}</span>
                            </Tooltip>
                        </div>
                        <StandardOutlinedButton label="header-tab.select-series" onClick={() => setShowModal(true)} endIcon={<ImportExportIcon />} />
                    </div>
                </Col>
            </Row>
            <h2 className="header-series-selector--subtitle">{t('header-tab.series', { series: selectedFundAndSeries?.seriesName })}</h2>
            {showModal && <SelectAllModal handleClose={() => setShowModal(false)} />}
        </>
    );
};

export default HeaderInvestor;
