import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { ShareholderAccountHistoryActionTypes, ShareholderAccountHistoryState } from './types';

export const initialState: ShareholderAccountHistoryState = {
    status: HttpRequestStatus.NOOP,
    accounts: undefined,
    error: undefined
};

const reducer: Reducer<ShareholderAccountHistoryState> = (state = initialState, action): ShareholderAccountHistoryState => {
    switch (action.type) {
        case ShareholderAccountHistoryActionTypes.SHAREHOLDER_ACCOUNT_HISTORY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, accounts: undefined, error: undefined };
        }
        case ShareholderAccountHistoryActionTypes.SHAREHOLDER_ACCOUNT_HISTORY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, accounts: action.payload, error: undefined };
        }
        case ShareholderAccountHistoryActionTypes.SHAREHOLDER_ACCOUNT_HISTORY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ShareholderAccountHistoryActionTypes.SHAREHOLDER_ACCOUNT_HISTORY_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as shareholderAccountHistoryReducer };
