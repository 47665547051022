import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { ShareholderAccountHistory } from 'model/shareholder-account';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import shareholderAccountApi from 'services/api/shareholderAccountApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { shareholderAccountHistoryError, shareholderAccountHistoryRequest, shareholderAccountHistorySuccess } from './actions';
import { ShareholderAccountHistoryActionTypes } from './types';

function* handleShareholderAccountHistory(action: ReturnType<typeof shareholderAccountHistoryRequest>) {
    try {
        const result: AxiosResponse<ShareholderAccountHistory[]> = yield call(shareholderAccountApi.history, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(shareholderAccountHistoryError(mapped));
            return;
        }
        yield put(shareholderAccountHistorySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(shareholderAccountHistoryError(mapped));
    }
}

function* watchShareholderAccountHistoryRequest() {
    yield takeEvery(ShareholderAccountHistoryActionTypes.SHAREHOLDER_ACCOUNT_HISTORY_REQUEST, handleShareholderAccountHistory);
}

function* shareholderAccountHistorySaga() {
    yield all([fork(watchShareholderAccountHistoryRequest)]);
}

export default shareholderAccountHistorySaga;
