import clsx from 'clsx';
import CurrencyFormatter from 'components/fomatter/currency/currency-formatter';
import _ from 'lodash';
import { monthValues } from 'model/enums/month-values';
import { IncomeStatementGroupBalances } from 'model/income-statement';
import React from 'react';

interface IncomeStatementMonthRowProps {
    balances: ReadonlyArray<IncomeStatementGroupBalances>;
    className?: string;
}

const IncomeStatementMonthRow = (props: IncomeStatementMonthRowProps) => {
    const { balances, className } = props;

    const filteredBalances = (month: number) => balances.filter(value => value.month === month).find(balance => !_.isNil(balance.value));

    return (
        <>
            {monthValues.map(month => {
                return (
                    <td
                        key={month}
                        className={clsx('page-container--table__row-items', className, {
                            NEGATIVE_VALUES: _.lt(filteredBalances(Number(month))?.value, 0)
                        })}
                    >
                        <span>{!_.isNil(filteredBalances(Number(month))?.value) ? <CurrencyFormatter value={filteredBalances(Number(month))?.value} prefix /> : '-'}</span>
                    </td>
                );
            })}
        </>
    );
};
export default IncomeStatementMonthRow;
