import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../loading/loading';
import './button-next.scss';

export interface ButtonNextProps {
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

export const ButtonNext = (props: ButtonNextProps): JSX.Element => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (!(props.isLoading || props.disabled)) {
            props.onClick();
        }
    };

    return (
        <div className="button__style--second">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                {props.isLoading ? (
                    <div
                    style={{
                        width: '185px'
                    }}
                >
                    <Loading />
                </div>
                ) : (
                    <>
                        {t('accept-invite.buttons.next').toUpperCase()}
                        <InputAdornment position="end">
                            <div className="button--arrow" />
                        </InputAdornment>
                    </>
                )}
            </Button>
        </div>
    );
};

export default ButtonNext;
