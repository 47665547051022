import { Authority } from '../../model/enums/authority';
import { useUserAccountState } from '../../reducer/hooks';
import AuthUtil from '../../services/api/authUtil';

const useHasAnyAuthority = (...authorities: Authority[]): boolean => {
    const { account } = useUserAccountState();
    const token = AuthUtil.decode();
    return (
        !!token?.authorities?.some(role => authorities?.some(it => it === role)) ||
        !!account?.authorities?.some(role => authorities?.some(it => it === role))
    );
};

export default useHasAnyAuthority;
