export const DEFAULT_COLOR_PATTERN: string[] = ['#64b5f7', '#5991bc'];

export const DEFAULT_CHART_TRANSITION_DURATION = 400;

// BAR CHAR
export const DEFAULT_BAR_SPACE = 0.25;
export const DEFAULT_BAR_WITDTH_RATIO = 0.7;

export const DEFAULT_BAR_Y_AXIS_PADDING_TOP = 5;
export const DEFAULT_BAR_Y_AXIS_PADDING_BOTTOM = 5;

export const BAR_CHART_EMPTY_VALUES_MAX = 1000;

// SPLINE CHART
export const DEFAULT_SPLINE_Y_PADDING_BOTTOM = 0;
export const DEFAULT_SPLINE_Y_PADDING_TOP = 15;

export const DEFAULT_SPLINE_POINT_RADIUS = 4.5;
export const DEFAULT_SPLINE_POINT_EXPAND_RADIUS = 6;

export const SPLINE_CHART_EMPTY_VALUES_MAX = 100;
