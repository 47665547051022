import { PersonTermsAcceptance } from '../../../model/person';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum TermsAcceptanceActionTypes {
  TERMS_ACCEPTANCE_REQUEST = '@@terms-acceptance/TERMS_ACCEPTANCE_REQUEST',
  TERMS_ACCEPTANCE_SUCCESS = '@@terms-acceptance/TERMS_ACCEPTANCE_SUCCESS',
  TERMS_ACCEPTANCE_ERROR = '@@terms-acceptance/TERMS_ACCEPTANCE_ERROR',

  TERMS_ACCEPTANCE_RESET_STATE = '@@terms-acceptance/HAS_ACCEPTED_TERTERMS_ACCEPTANCE_RESET_STATEMS_RESET_STATE'
}

export interface TermsAcceptanceState extends BaseStoreState {
  readonly terms?: PersonTermsAcceptance;
}
