import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import BaseTextInput, { BaseTextInputProps } from 'components/inputs/base-text-input';
import React from 'react';
import './simple-search-input.scss';

type Base = Omit<BaseTextInputProps, 'shouldShrinkLabel' | 'inputComponent' | 'readOnly' | 'onChange' | 'onClick' | 'type' | 'changeMapper'>;

export interface BaseSearchInputProps extends Base {
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onChange?: (value: string) => void;
    readOnly?: boolean;
}

export const BaseSearchInput = (props: BaseSearchInputProps) => {
    const { endAdornment, onClick, onChange, disabled, readOnly, label, value, placeholder, id } = props;

    const handleMousedown = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault();

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (disabled) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (onClick) {
                onClick(event);
            }
        }
    };

    return (
        <BaseTextInput
            id={id}
            placeholder={placeholder}
            label={label}
            value={value}
            readOnly={readOnly}
            externalUpdate
            onChange={onChange}
            onClick={handleClick}
            autoComplete="off"
            type="search"
            disabled={disabled}
            endAdornment={
                endAdornment ?? (
                    <InputAdornment position="end">
                        <IconButton onClick={handleClick} onMouseDown={handleMousedown} disabled={disabled}>
                            <Icon>
                                <div className="search--icon" />
                            </Icon>
                        </IconButton>
                    </InputAdornment>
                )
            }
        />
    );
};

export default BaseSearchInput;
