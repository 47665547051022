import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { FundDocumentsToGetAll } from 'model/fund';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fundApi from 'services/api/fundApi';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { myFundDocumentsError, myFundDocumentsRequest, myFundDocumentsSuccess } from './action';
import { MyFundDocumentsTypes } from './types';

function* handleGetMyFundDocuments(_action: ReturnType<typeof myFundDocumentsRequest>) {
    try {
        const result: AxiosResponse<Page<FundDocumentsToGetAll>> = yield call(fundApi.getFundDocuments, _action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(myFundDocumentsError(mapped));
            return;
        }
        yield put(myFundDocumentsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(myFundDocumentsError(mapped));
    }
}

function* watchGetMyFundDocuments() {
    yield takeEvery(MyFundDocumentsTypes.MY_FUND_DOCUMENTS_REQUEST, handleGetMyFundDocuments);
}

function* myFundDocumentsSaga() {
    yield all([fork(watchGetMyFundDocuments)]);
}

export default myFundDocumentsSaga;
