import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import SimpleSplineChart from 'components/chart/line-chart/spline-chart/simple-spline-chart';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { ParticipantCostChartData } from 'model/fund-account';
import { FundAndPersonIdFilter, SeriesOfFund } from 'model/series';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { participantCostRequest, participantCostResetState } from 'reducer/fund-account/participant-costs/actions';
import { useFundAccountParticipantCosts, useNonParticipantSeriesOfFundState, useRootDispatch } from 'reducer/hooks';
import { nonParticipantSeriesOfFundRequest, nonParticipantSeriesOfFundResetState } from 'reducer/series/non-participant-series/actions';

interface ParticipantCostsCardProps {
    cardTitle: string;
    fundId: string | undefined | null;
    personId: string | undefined | null;
    graphLabels: string;
    graphTitles: string[];
    buttons: string[];
}

interface ParticipantCostsGraphProps {
    graphLabels: string;
    graphTitles: string[];
    totalCost: number;
    chartData: ParticipantCostChartData[];
}

const I18N_PREFIX = 'anchor.capture.cards.participant-costs';

const formatLabel = (x: number): string => `${x}%`;
const formatData = (v: number | { valueOf(): number }, _id: string, _i: number, _j: number) => `${v}%`;

const ParticipantCostsLineGraph = (props: ParticipantCostsGraphProps) => {
    const tooltipText = 'Custo: ';

    const formatDate = (value: Date | number): string => {
        if (typeof value === 'number') {
            const date = props.chartData?.length && props.chartData.length >= value ? props.chartData[value]?.month : undefined;
            return moment(date).format('MM/YY');
        }
        return moment(value).format('MM/YY');
    };

    const customizeUngroupedToolTip = (_id: string, value: number, color: unknown, x: number) => {
        return `
            <div class="tooltip--block">
                <div class="tooltip--block-line">
                    <div class="tooltip--block-color" style="background-color:${color};"></div>
                    <b sclass="tooltip--block-text">${moment(props.chartData[x].month).format('MM/YYYY')}</b>
                </div>
                <hr />
                <div class="tooltip--block-two">
                    <span sclass="tooltip--block-text">${tooltipText}<b>${value}%</b></span>
                </div>
            </div>
        `;
    };

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-three">{props.graphTitles[0]}</div>
                <div className="items--card-graph-title-two">
                    <span>{props.graphTitles[1]}</span>
                    <span className="items--card-graph-title-info">{props.totalCost}</span>
                </div>
            </div>
            <UngroupedChartToolTip customize={customizeUngroupedToolTip}>
                {tooltip => (
                    <SimpleSplineChart
                        data={props.chartData}
                        config={{
                            height: 297,
                            labelKey: 'month',
                            yKeys: ['costAmountPercentage'],
                            yLabelFormat: formatLabel,
                            pointRadius: 4.5,
                            pointFocusRadius: 6,
                            categoryFormat: formatDate,
                            dataFormat: formatData
                        }}
                        tooltipOptions={tooltip}
                    >
                        {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={_key => props.graphLabels} />}
                    </SimpleSplineChart>
                )}
            </UngroupedChartToolTip>
        </>
    );
};

const ParticipantCostsCard = (props: ParticipantCostsCardProps) => {
    const [selector, setSelector] = React.useState<boolean>(false);
    const [selectedSeries, setSelectedSeries] = React.useState<SeriesOfFund | undefined>(undefined);
    const [consolidated, setConsolidated] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const { participantCostBySeries, status: costsStatus } = useFundAccountParticipantCosts();
    const { series, status: serieStatus } = useNonParticipantSeriesOfFundState();

    const isLoading: boolean = serieStatus === HttpRequestStatus.ONGOING || costsStatus === HttpRequestStatus.ONGOING;

    const shouldRenderChart: boolean = costsStatus === HttpRequestStatus.SUCCESS && !!participantCostBySeries?.data?.length;

    const dispatch = useRootDispatch();

    React.useEffect(() => {
        if (!props.fundId) return;
        if (!props.personId) return;

        const filter: FundAndPersonIdFilter = {
            fundId: props.fundId,
            personId: props.personId
        }

        dispatch(nonParticipantSeriesOfFundRequest(filter));
    }, [dispatch, props.fundId, props.personId]);

    React.useEffect(() => {
        return () => {
            dispatch(nonParticipantSeriesOfFundResetState());
            dispatch(participantCostResetState());
        };
    }, [dispatch]);

    React.useEffect(() => {
        if (!series) return;

        const firstSeries: SeriesOfFund | undefined = series.find(it => !!it);

        setSelectedSeries(firstSeries);
    }, [series]);

    const queryConsolidated = useCallback(
        (value: boolean) => {
            setConsolidated(value);

            if (!props.fundId || !props.personId || !selectedSeries) return;

            dispatch(
                participantCostRequest({
                    id: props.fundId,
                    seriesId: selectedSeries.id,
                    consolidated: value,
                    personId: props.personId
                })
            );
        },
        [props.fundId, props.personId, selectedSeries, dispatch]
    );

    React.useEffect(() => {
        if (!props.fundId || !selectedSeries) return;

        queryConsolidated(consolidated);
    }, [queryConsolidated, props.fundId, selectedSeries, consolidated]);

    if (!isLoading && !series.length) {
        return (
            <div className="items--card-block">
                <div className="items--card-header">
                    <div className="items--card-header-title">{props.cardTitle}</div>
                </div>
                <div className="items--card-error">{t(`${I18N_PREFIX}.error`)}</div>
            </div>
        );
    }

    return (
        <div className="items--card-block">
            <div className="items--card-header">
                <div className="items--card-header-title">{props.cardTitle}</div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="items--card-header-buttons">
                        <div className={`items--card-selector`} onClick={() => (selector ? setSelector(false) : setSelector(true))}>
                            <div className={`items--card-buttons-third${selector ? '-open' : '-closed'}`}>
                                {selectedSeries?.name}
                                <div className="items--card-buttons-third-arrow" />
                            </div>
                            {selector &&
                                series.map(it => (
                                    <div key={it.id} className="items--card-selector-options">
                                        <div
                                            className="items--card-selector-option"
                                            onClick={() => {
                                                setSelectedSeries(it);
                                            }}
                                        >
                                            {it.name}
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <button className={`items--card-header-buttons${consolidated ? '-active' : '-inactive'}`} onClick={() => setConsolidated(!consolidated)}>
                            {t(`${I18N_PREFIX}.button-consolidated`)}
                        </button>
                    </div>
                )}
            </div>

            {shouldRenderChart && (
                <div className="items--card-graph">
                    <ParticipantCostsLineGraph graphLabels={props.graphLabels} graphTitles={props.graphTitles} totalCost={participantCostBySeries?.yearlyCost ?? 0} chartData={participantCostBySeries?.data ?? []} />
                </div>
            )}
        </div>
    );
};

export default ParticipantCostsCard;
