import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { UserAccountActionTypes, UserAccountState } from './types';

export const initialState: UserAccountState = {
  status: HttpRequestStatus.NOOP,
  account: undefined,
  error: undefined
};

const reducer: Reducer<UserAccountState> = (state = initialState, action): UserAccountState => {
  switch (action.type) {
    case UserAccountActionTypes.USER_ACCOUNT_REQUEST: 
    case UserAccountActionTypes.USER_ACCOUNT_WITH_TERMS_REQUEST: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }
    case UserAccountActionTypes.USER_ACCOUNT_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, account: action.payload, error: undefined };
    }
    case UserAccountActionTypes.USER_ACCOUNT_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }

    case UserAccountActionTypes.USER_ACCOUNT_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as userAccountReducer };

