import ActionButton from 'components/buttons/action-button/action-button';
import SimpleCategoryBarChart from 'components/chart/bar-chart/category-bar-chart/simple-category-bar-chart';
import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import { formatLocalDate, LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import Loading from 'components/loading/loading';
import { FundAccountPeriod } from 'model/enums/fund-account-period';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { FundAccountCashAvailableBalanceChartData } from 'model/fund-account';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { cashAvailableBalanceRequest } from 'reducer/fund-account/cash-available-balance/actions';
import { useFundAccountCashAvailableBalanceState, useRootDispatch } from 'reducer/hooks';
import './cash-available-balance-card.scss';

const CHART_HEIGHT = 262;

interface CashAvailableBalanceCardProps {
    fundId: string | undefined | null;
}

export const CashAvailableBalanceCard = (props: CashAvailableBalanceCardProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const [period, setPeriod] = React.useState<FundAccountPeriod>(FundAccountPeriod.LAST_QUARTER);
    const [selector, setSelector] = React.useState(false);
    const { status, balance } = useFundAccountCashAvailableBalanceState();

    const isLoading = status !== HttpRequestStatus.ERROR && status !== HttpRequestStatus.SUCCESS;
    const hasError = status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (!props.fundId) return;

        dispatch(cashAvailableBalanceRequest({ id: props.fundId, period }));
    }, [dispatch, props.fundId, period]);

    const handleSelector = () => {
        if (isLoading) return;
        setSelector(ps => !ps);
    };

    const handleSelection = (_period: FundAccountPeriod) => {
        setPeriod(_period);
    };

    if (isLoading) {
        return (
            <div className="items--card-block">
                <div className="items--card-header">
                    <div className="items--card-header-title">{t('debenture-cards.cash-available-balance.title')}</div>
                    <div className="items--card-header-buttons" style={{ padding: 0 }}>
                        <CashAvailableBalanceSelection open={selector} period={period} onOpen={handleSelector} onSelect={handleSelection} />
                    </div>
                </div>
                <div className="items--card--loading">
                    <Loading />
                </div>
            </div>
        );
    }

    const handleTryAgain = () => {
        if (!props.fundId) return;

        dispatch(cashAvailableBalanceRequest({ id: props.fundId, period }));
    };

    if (hasError || !balance) {
        return (
            <div className="items--card-block">
                <div className="items--card-header">
                    <div className="items--card-header-title">{t('debenture-cards.cash-available-balance.title')}</div>
                    <div className="items--card-header-buttons" style={{ padding: 0 }}>
                        <CashAvailableBalanceSelection open={selector} period={period} onOpen={handleSelector} onSelect={handleSelection} />
                    </div>
                </div>
                <div className="items--card--error">
                    <div className="anchor-header--error-message">{t('debenture-cards.cash-available-balance.data-recovery-exception')}</div>
                    <ActionButton label={t('global.try-again')} onClick={handleTryAgain} />
                </div>
            </div>
        );
    }

    return (
        <div className="items--card-block">
            <div className="items--card-header">
                <div className="items--card-header-title">{t('debenture-cards.cash-available-balance.title')}</div>
                <div className="items--card-header-buttons" style={{ padding: 0 }}>
                    <CashAvailableBalanceSelection open={selector} period={period} onOpen={handleSelector} onSelect={handleSelection} />
                </div>
            </div>
            <div className="items--card-graph">
                <CashAvailableBalanceChart data={balance.data} period={period} />
            </div>
        </div>
    );
};

interface CashAvailableBalanceSelectionProps {
    open: boolean;
    period: FundAccountPeriod;
    onOpen: () => void;
    onSelect: (period: FundAccountPeriod) => void;
}

const CashAvailableBalanceSelection = (props: CashAvailableBalanceSelectionProps) => {
    const { t } = useTranslation();

    return (
        <div className="items--card-buttons">
            <div className={`items--card-selector`} onClick={props.onOpen}>
                <div className={`items--card-buttons-third${props.open ? '-open' : '-closed'}`}>
                    {t(`debenture-cards.cash-available-balance.period.${props.period}`)}
                    <div className="items--card-buttons-third-arrow" />
                </div>
                {props.open && (
                    <div className="items--card-selector-options">
                        <div className="items--card-selector-option" onClick={() => props.onSelect(FundAccountPeriod.LAST_QUARTER)}>
                            {t(`debenture-cards.cash-available-balance.period.${FundAccountPeriod.LAST_QUARTER}`)}
                        </div>
                        <div className="items--card-selector-option" onClick={() => props.onSelect(FundAccountPeriod.LAST_SEMESTER)}>
                            {t(`debenture-cards.cash-available-balance.period.${FundAccountPeriod.LAST_SEMESTER}`)}
                        </div>{' '}
                        <div className="items--card-selector-option" onClick={() => props.onSelect(FundAccountPeriod.LAST_YEAR)}>
                            {t(`debenture-cards.cash-available-balance.period.${FundAccountPeriod.LAST_YEAR}`)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

interface CashAvailableBalanceChartProps {
    period: FundAccountPeriod;
    data: FundAccountCashAvailableBalanceChartData[];
}

const CashAvailableBalanceChart = (props: CashAvailableBalanceChartProps) => {
    const { t } = useTranslation();

    const formatLabel = (value: number): string => formatCurrency(value, 0, true);
    const formatCategory = (value: Date | number): string => {
        const format = FundAccountPeriod.LAST_YEAR === props.period ? LocalDateFormatType.SHORT_MONTH_NAME : LocalDateFormatType.DAY_AND_SHORT_MONTH_NAME;
        if (typeof value === 'number') {
            const date = props.data?.length && props.data.length >= value ? props.data[value]?.date : undefined;
            return formatLocalDate(date, format);
        }
        return formatLocalDate(value, format);
    };

    const curtomizeTooltip = React.useMemo(() => {
        return (_id: string, value: number, color: unknown, x: number) => {
            const date = props.data?.length && props.data.length >= x ? props.data[x]?.date : undefined;
            return `
            <div class="tooltip--block">
                <div class="tooltip--block-line">
                    <div class="tooltip--block-color" style="background-color:${color};"></div>
                    <b class="tooltip--block-text">${formatLocalDate(date, LocalDateFormatType.DAY_AND_SHORT_MONTH_NAME)}</b>
                </div>
                <hr />
                <div class="tooltip--block-two">
                    <span class="tooltip--block-text">Saldo:</span>
                    <span>${formatCurrency(value, 0, true)}</span>
                </div>
            </div>
        `;
        };
    }, [props.data]);

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-three">{t('debenture-cards.cash-available-balance.chart.title')}</div>
            </div>
            <div style={{ height: `${CHART_HEIGHT}px` }}>
                <UngroupedChartToolTip customize={curtomizeTooltip}>
                    {tooltip => (
                        <SimpleCategoryBarChart
                            data={props.data}
                            config={{
                                height: CHART_HEIGHT,
                                labelKey: 'date',
                                categoryFormat: formatCategory,
                                categoriesKeys: ['balance'],
                                categoryLabelFormat: formatLabel
                            }}
                            tooltipOptions={tooltip}
                        >
                            {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={_key => t('debenture-cards.cash-available-balance.chart.label')} />}
                        </SimpleCategoryBarChart>
                    )}
                </UngroupedChartToolTip>
            </div>
        </>
    );
};

export default CashAvailableBalanceCard;
