import { Tooltip } from '@material-ui/core';
import { formatCurrency } from 'components/fomatter/currency/currency-formatter';
import { SeriesOfFundPortfolio } from 'model/fund';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface WalletSeriesRowProps {
    series: SeriesOfFundPortfolio;
    handleFundAndSeries: (series: SeriesOfFundPortfolio) => void;
    dataCy: string;
}

const WalletSeriesRow = (props: WalletSeriesRowProps) => {
    const { handleFundAndSeries, series, dataCy } = props;

    const { t } = useTranslation();

    return (
        <div className="fund-container--child__fund-content--container">
            <div className="fund-container--child__fund-content--row">
                <Tooltip title={series.name}>
                    <div className="fund-container--child__fund-content--cell">{series.name}</div>
                </Tooltip>
                <div className="fund-container--child__fund-content--dashed">-------------------------------------------------------------------------</div>
                <div className="fund-container--child__fund-content--amount">{formatCurrency(series.totalAmount, 0, true)}</div>
            </div>
            <div onClick={() => handleFundAndSeries(series)} className="wallet__items--button" data-cy={dataCy}>
                {t('wallet.modal-button')}
            </div>
        </div>
    );
};

export default WalletSeriesRow;
