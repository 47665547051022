import { Tooltip } from '@material-ui/core';
import { PopupButton } from '@typeform/embed-react';
import { useVariant } from '@unleash/proxy-client-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks/use-toast';
import { EmbededFormUtils } from 'shared/util/embeded-form-utils';

import './embeded-form.scss';

const I18N_PREFIX = 'embeded-form';

const EmbededForm = () => {
    const [isCurrentFormAnswered, setIsCurrentFormAnswered] = useState<boolean>(false);

    const { t } = useTranslation();
    const { enabled, payload } = useVariant('typeform-satisfaction-survey');
    const { toastSuccess } = useToast();

    const FORM_ID = payload?.value;

    const hasModalBeenClosed: boolean = EmbededFormUtils.checkModalAction();

    useEffect(() => {
        if (EmbededFormUtils.checkIfFormInAnswered(FORM_ID ?? '')) {
            setIsCurrentFormAnswered(true);
            return;
        }

        setIsCurrentFormAnswered(false);
    }, [FORM_ID]);

    if (!FORM_ID || !enabled || isCurrentFormAnswered) {
        return <></>;
    }

    const handleSubmit = () => {
        setIsCurrentFormAnswered(true);
        EmbededFormUtils.saveFormInLocalStorage(FORM_ID);
        toastSuccess(t(`${I18N_PREFIX}.submitted`));
    };

    const onClose = () => {
        EmbededFormUtils.saveModalActionInSessionStorage();
    };

    return (
        <Tooltip title={t(`${I18N_PREFIX}.title`)} placement="left" open arrow>
            <span className="tooltip-wrapper">
                <PopupButton open={hasModalBeenClosed ? undefined : 'load'} id={FORM_ID} className={'embeded-form'} onSubmit={handleSubmit} onClose={onClose} fullScreen autoFocus autoClose />
            </span>
        </Tooltip>
    );
};

export default EmbededForm;
