import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { AcceptTermsActionTypes, AcceptTermsState } from './types';

export const initialState: AcceptTermsState = {
  status: HttpRequestStatus.NOOP,
  person: undefined,
  error: undefined
};

const reducer: Reducer<AcceptTermsState> = (state = initialState, action): AcceptTermsState => {
  switch (action.type) {
    case AcceptTermsActionTypes.ACCEPT_TERMS_REQUEST: {
      return { ...state, status: HttpRequestStatus.ONGOING, person: undefined, error: undefined };
    }
    case AcceptTermsActionTypes.ACCEPT_TERMS_SUCCESS: {
      return { ...state, status: HttpRequestStatus.SUCCESS, person: action.payload, error: undefined };
    }
    case AcceptTermsActionTypes.ACCEPT_TERMS_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload }
    }
    case AcceptTermsActionTypes.ACCEPT_TERMS_RESET_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as acceptTermsReducer };

