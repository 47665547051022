import { SeriesOfFund } from 'model/series';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum NonParticipantOfFundActionTypes {
    NON_PARTICIPANT_SERIES_OF_FUND_REQUEST = '@@seriesOfFund/NON_PARTICIPANT_SERIES_OF_FUND_REQUEST',
    NON_PARTICIPANT_SERIES_OF_FUND_SUCCESS = '@@seriesOfFund/NON_PARTICIPANT_SERIES_OF_FUND_SUCCESS',
    NON_PARTICIPANT_SERIES_OF_FUND_ERROR = '@@seriesOfFund/NON_PARTICIPANT_SERIES_OF_FUND_ERROR',
    NON_PARTICIPANT_OF_FUND_RESET_STATE = '@@seriesOfFund/SERIES_OF_FUND_RESET_STATE'
}

export interface NonParticipantOfFundState extends BaseStoreState {
    readonly series: SeriesOfFund[];
}
