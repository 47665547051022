import { BankAccountToUpdate } from "model/bank-account";
import { Validations, ValidationUtils } from "shared/util/validation-utils";

const BANK_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data-bank.validation.bank.required'
    },

}
export const validateBank = (value: string | undefined) => ValidationUtils.validate(value, BANK_VALIDATION);

const BANK_ACCOUNT_NUMBER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data-bank.validation.account-number.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 16,
        errorMessage: 'profile.data-bank.validation.account-number.max-length'
    }

}
export const validateBankAccountNumber = (value: string | undefined) => ValidationUtils.validate(value, BANK_ACCOUNT_NUMBER_VALIDATION);

const BANK_ACCOUNT_DIGIT_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data-bank.validation.account-digit.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 8,
        errorMessage: 'profile.data-bank.validation.account-digit.max-length'
    }

}
export const validateBankAccountDigit = (value: string | undefined) => ValidationUtils.validate(value, BANK_ACCOUNT_DIGIT_VALIDATION);

const BANK_AGENCY_NUMBER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data-bank.validation.agency-number.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 16,
        errorMessage: 'profile.data-bank.validation.agency-number.max-length'
    }

}
export const validateBankAgencyNumber = (value: string | undefined) => ValidationUtils.validate(value, BANK_AGENCY_NUMBER_VALIDATION);

const BANK_AGENCY_DIGIT_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data-bank.validation.agency-digit.required'
    },
    maxLength: {
        predicate: it => !it?.length || it.length < 8,
        errorMessage: 'profile.data-bank.validation.agency-digit.required'
    }

}
export const validateBankAgencyDigit = (value: string | undefined) => ValidationUtils.validate(value, BANK_AGENCY_DIGIT_VALIDATION);

const BANK_ACCOUNT_TYPE_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: it => !!it,
        errorMessage: 'profile.data-bank.validation.account-type.required'
    },

}
export const validateBankAccountType = (value: string | undefined) => ValidationUtils.validate(value, BANK_ACCOUNT_TYPE_VALIDATION);

export const validateBankProfileUpdate = (value: BankAccountToUpdate | undefined): boolean => {
    if(!value) return false;
    if(!value) return false;

    return (
        validateBank(value.bank?.name).isValid &&
        validateBankAccountNumber(value.accountNumber).isValid && 
        validateBankAccountDigit(value.accountDigit).isValid &&
        validateBankAgencyNumber(value.agencyNumber).isValid &&
        validateBankAgencyDigit(value.agencyDigit).isValid &&
        validateBankAccountType(value.type).isValid
    )
}