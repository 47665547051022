import { PersonSimple } from 'model/person';
import { BaseStoreState } from 'reducer/baseStoreState';

export enum AcceptLgpdTermsActionTypes {
    ACCEPT_LGPD_TERMS_REQUEST = '@@acceptLgpdTerms/ACCEPT_LGPD_TERMS_REQUEST',
    ACCEPT_LGPD_TERMS_SUCCESS = '@@acceptLgpdTerms/ACCEPT_LGPD_TERMS_SUCCESS',
    ACCEPT_LGPD_TERMS_ERROR = '@@acceptLgpdTerms/ACCEPT_LGPD_TERMS_ERROR',
    ACCEPT_LGPD_TERMS_RESET_STATE = '@@acceptLgpdTerms/ACCEPT_LGPD_TERMS_RESET_STATE'
}

export interface AcceptLgpdTermsState extends BaseStoreState {
    readonly person?: PersonSimple;
}
