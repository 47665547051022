import 'bootstrap/dist/css/bootstrap.min.css';
import UnleashProvider from 'config/unleash/unleash-provider';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from 'redux';
import App from './App';
import i18n from './config/i18n';
import './index.css';
import { IRootState } from './reducer';
import configureStore from './reducer/configureStore';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './shared/error/error-boundary';

const initialState = window.INITIAL_REDUX_STATE;

export const store: Store<IRootState> = configureStore(initialState);

export type RootDispatcher = typeof store.dispatch;

const rootEl = document.getElementById('root');

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <UnleashProvider>
                    <App />
                </UnleashProvider>
            </I18nextProvider>
        </Provider>
    </ErrorBoundary>,
    rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
