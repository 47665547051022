import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { FundHistory, MyFund, Portfolio } from '../../../model/fund';
import { BaseStoreState } from '../../baseStoreState';

export type ApiResponse = Record<string, string>;

export enum MyFundsActionTypes {
    MY_FUNDS_REQUEST = '@@myFunds/MY_FUNDS_REQUEST',
    MY_FUNDS_SUCCESS = '@@myFunds/MY_FUNDS_SUCCESS',
    MY_FUNDS_ERROR = '@@myFunds/MY_FUNDS_ERROR',
    MY_FUNDS_RESET_STATE = '@@myFunds/MY_FUNDS_RESET_STATE',

    PORTFOLIO_REQUEST = '@@myFunds/PORTFOLIO_REQUEST',
    PORTFOLIO_SUCCESS = '@@myFunds/PORTFOLIO_SUCCESS',
    PORTFOLIO_ERROR = '@@myFunds/PORTFOLIO_ERROR',
    PORTFOLIO_RESET_STATE = '@@myFunds/PORTFOLIO_RESET_STATE',

    FUND_HISTORY_REQUEST = '@@myFunds/FUND_HISTORY_REQUEST',
    FUND_HISTORY_SUCCESS = '@@myFunds/FUND_HISTORY_SUCCESS',
    FUND_HISTORY_ERROR = '@@myFunds/FUND_HISTORY_ERROR',
    FUND_HISTORY_RESET_STATE = '@@myFunds/FUND_HISTORY_RESET_STATE'
}

export interface MyFundsState extends BaseStoreState {
    readonly funds: MyFund[];
    readonly portfolio?: Portfolio;
    readonly portfolioStatus?: HttpRequestStatus;
    readonly history?: FundHistory;
    readonly historyStatus?: HttpRequestStatus;
}
