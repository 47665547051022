import { AxiosResponse } from 'axios';
import { SeriesOfFund } from 'model/series';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import seriesApi from 'services/api/seriesApi';
import HttpStatus from '../../../model/enums/httpStatus';
import { ErrorUtils } from '../../../shared/error/error-utils';
import { markError } from '../../application/error/actions';
import { nonParticipantSeriesOfFundError, nonParticipantSeriesOfFundRequest, nonParticipantSeriesOfFundSuccess } from './actions';
import { NonParticipantOfFundActionTypes } from './types';

function* handleNonParticipantSeriesOfFundRequest(action: ReturnType<typeof nonParticipantSeriesOfFundRequest>) {
    try {
        const result: AxiosResponse<SeriesOfFund[]> = yield call(seriesApi.getNonParticipantSeriesOfFund, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(nonParticipantSeriesOfFundError(mapped));
            return;
        }
        yield put(nonParticipantSeriesOfFundSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(nonParticipantSeriesOfFundError(mapped));
    }
}

function* watchHandleNonParticipantSeriesOfFundRequest() {
    yield takeEvery(NonParticipantOfFundActionTypes.NON_PARTICIPANT_SERIES_OF_FUND_REQUEST, handleNonParticipantSeriesOfFundRequest);
}

function* nonParticipantSeriesOfFundSaga() {
    yield all([fork(watchHandleNonParticipantSeriesOfFundRequest)]);
}

export default nonParticipantSeriesOfFundSaga;
