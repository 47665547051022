import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from 'reactstrap/lib/Container';
import Loading from '../../components/loading/loading';
import { Authentication } from '../../model/authentication';
import { ErrorConstants, ErrorType, GiroWebappErrorConstants } from '../../model/enums/error-constants';
import { HttpRequestStatus } from '../../model/enums/httpRequestStatus';
import { InviteStatus } from '../../model/enums/invite-status';
import { WhatsAppStatus } from '../../model/enums/whatsapp-status';
import { ErrorHandlingCustomization } from '../../model/error';
import { ShareholderInviteRequest, ShareholderInviteToConfirm } from '../../model/shareholder-invite';
import { customizeErrorHandling, markError } from '../../reducer/application/error/actions';
import { loginRequest } from '../../reducer/authentication/actions';
import {
    useAuthenticationState,
    useRootDispatch,
    useShareholderInviteConfirmState,
    useShareholderInviteToConfirmState
} from '../../reducer/hooks';
import { confirmShareholderInviteRequest } from '../../reducer/shareholder-invite/confirm/actions';
import { shareholderToConfirmRequest } from '../../reducer/shareholder-invite/to-confirm/actions';
import useRedirectToDashboard from '../../shared/hooks/redirect-to-dashboard';
import LoggingUtils from '../../shared/util/logging-utils';
import StringUtils from '../../shared/util/string-utils';
import './accept-invite.scss';
import Contact from './components/contact/contact';
import ContactInformative from './components/contact/contact-informative';
import ContactPhone from './components/contact/contact-phone';
import InviteSteps from './components/invite-steps/invite-steps';
import { StepsEnum } from './components/invite-steps/invite-steps-context';
import InviteSuccess from './components/invite-success/invite-success';
import ConfirmOnly from './components/password/confirm-only';
import PasswordAndConfirm from './components/password/password-and-confirm';
import PasswordOnly from './components/password/password-only';
import TermsOfUseStep from './components/terms-of-use-step/terms-of-use-step';
import Welcome from './components/welcome/welcome';

interface AcceptInviteParams {
    token: string;
}

const useInviteToAcceptRequest = (initialToken: string) => {
    const [token] = React.useState<string>(initialToken);
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        dispatch(shareholderToConfirmRequest(token));
    }, [token, dispatch]);
};

const useInviteToAcceptAwareness = () => {
    const state = useShareholderInviteToConfirmState();
    const isLoading = state.status !== HttpRequestStatus.ERROR && state.status !== HttpRequestStatus.SUCCESS;
    return { isLoading };
};

const useValidateInvite = () => {
    const state = useShareholderInviteToConfirmState();
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        if (state.status !== HttpRequestStatus.SUCCESS) return;

        if (StringUtils.isBlank(state.invite?.email)) {
            dispatch(markError({ message: GiroWebappErrorConstants.INVITE_INVALID }));
        } else if (state.invite?.status && state.invite.status !== InviteStatus.OPEN) {
            type WrongInviteStatus = InviteStatus.CLOSED | InviteStatus.CONFIRMED | InviteStatus.WAITING_CONFIRMATION;
            const inviteStatusRecord: Record<WrongInviteStatus, GiroWebappErrorConstants> = {
                CLOSED: GiroWebappErrorConstants.INVITE_STATUS_CLOSED,
                CONFIRMED: GiroWebappErrorConstants.INVITE_STATUS_CONFIRMED,
                WAITING_CONFIRMATION: GiroWebappErrorConstants.INVITE_STATUS_WAITING_CONFIRMATION
            };
            dispatch(markError({ message: inviteStatusRecord[state.invite.status] }));
        }
    }, [state.status, state.invite, dispatch]);
};

const useErrorConfiguration = () => {
    const dispatch = useRootDispatch();

    React.useEffect(() => {
        const _record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.shareholderInvite.notFound': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood'
                }
            },
            'error.invite.invalid': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood'
                }
            },
            'error.invite.closed': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood'
                }
            },
            'error.invite.confirmed': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood'
                }
            },
            'error.invite.waitingConfirmation': {
                type: ErrorType.BLOCKING,
                action: {
                    label: 'accept-invite.error.understood'
                }
            }
        };
        dispatch(dispatch(customizeErrorHandling({ record: _record })));
    }, [dispatch]);
};

export const AcceptInvite = (): JSX.Element => {
    const { token } = useParams<AcceptInviteParams>();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const { invite } = useShareholderInviteToConfirmState();
    const confirmState = useShareholderInviteConfirmState();
    const authenticationState = useAuthenticationState();

    useInviteToAcceptRequest(token);
    const { isLoading } = useInviteToAcceptAwareness();
    useErrorConfiguration();
    useValidateInvite();
    useRedirectToDashboard(
        history,
        authenticationState.status === HttpRequestStatus.SUCCESS || authenticationState.status === HttpRequestStatus.ERROR
    );

    const [inviteConfirmation, setInviteConfirmation] = React.useState<ShareholderInviteToConfirm>({} as ShareholderInviteToConfirm);
    const [isCompleted, setCompleted] = React.useState<boolean>(false);

    const handleChange = (value: Partial<ShareholderInviteToConfirm>) => {
        setInviteConfirmation({ ...inviteConfirmation, ...value });
    };

    React.useEffect(() => {
        LoggingUtils.debugInfo('confirm', confirmState);
        if (confirmState.status === HttpRequestStatus.SUCCESS && confirmState.invite?.status === InviteStatus.CONFIRMED) {
            setCompleted(true);
        }
    }, [confirmState]);

    if (isLoading) {
        return (
            <div className="page">
                <Container>
                    <Loading />
                </Container>
            </div>
        );
    }

    if (!invite) {
        return (
            <div className="page">
                <Container>
                    <></>
                </Container>
            </div>
        );
    }

    const handleFinish = (accept: boolean) => {
        if (accept) {
            const _confirm = { ...inviteConfirmation, acceptedTerms: accept };
            setInviteConfirmation(_confirm);
            if (isCompleted) {
                setCompleted(false);
            }
            const request: ShareholderInviteRequest = { token, invite: _confirm };
            dispatch(confirmShareholderInviteRequest(request));
        }
    };

    const handleOnStartUse = () => {
        const _username = invite.email;
        const _password = inviteConfirmation.password;
        const authentication: Authentication = { username: _username, password: _password };
        dispatch(loginRequest(authentication));
    };

    const stepsRecord: Record<StepsEnum, React.ReactNode> = {
        WELCOME: <Welcome name={invite.name} loading={isLoading} />,
        PASSWORD_AND_CONFIRM: (
            <PasswordAndConfirm
                username={invite.email}
                password={inviteConfirmation.password ?? ''}
                confirm={inviteConfirmation.confirm ?? ''}
                onChange={handleChange}
            />
        ),
        PASSWORD_ONLY: <PasswordOnly username={invite.email} password={inviteConfirmation.password ?? ''} onChange={handleChange} />,
        CONFIRM_ONLY: (
            <ConfirmOnly
                username={invite.email}
                password={inviteConfirmation.password ?? ''}
                confirm={inviteConfirmation.confirm ?? ''}
                onChange={handleChange}
            />
        ),
        CONTACT: (
            <Contact
                phoneNumber={inviteConfirmation.phoneNumber ?? ''}
                whatsappStatus={inviteConfirmation.whatsAppStatus ?? WhatsAppStatus.NOT_KNOWN}
                onChange={handleChange}
            />
        ),
        CONTACT_INFORMATIVE: (
            <ContactInformative whatsappStatus={inviteConfirmation.whatsAppStatus ?? WhatsAppStatus.NOT_KNOWN} onChange={handleChange} />
        ),
        CONTACT_PHONE: <ContactPhone phoneNumber={inviteConfirmation.phoneNumber ?? ''} onChange={handleChange} />,
        TERMS_OF_USE: <TermsOfUseStep acceptTerms={inviteConfirmation.acceptedTerms ?? false} onFinish={handleFinish} />
    };

    return (
        <div className="page">
            <Container>
                <InviteSteps stepsRecord={stepsRecord} token={token} />
            </Container>
            <InviteSuccess open={isCompleted} onStartUse={handleOnStartUse} />
        </div>
    );
};

export default AcceptInvite;
