import EmptyCollectionAware from 'components/collection/empty-collection-aware';
import LocalDateFormatter, { LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { FundDocumentsRequest } from 'model/fund';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { fundDocumentsDownloadRequest } from 'reducer/fund/download-documents/action';
import { myFundDocumentsRequest } from 'reducer/fund/fund-documents/action';
import { useFundDocumentsState, useRootDispatch } from 'reducer/hooks';
import { LIST_PAGE_SIZE } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import Pagination from 'shared/pagination/pagination';
import EmptyFundWarning from '../../components/empty-fund-warning/empty-fund-warning';
import DashboardHeader, { FundDocumentsSortableProperties } from './components/documents-header/documents-header';
import './documents.scss';

const I18N_PREFIX = 'anchor.documents';

const FundDocuments = () => {
    const [page, setPage] = React.useState<number>(0);
    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const [sortedProperty, setSortedProperty] = React.useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>(undefined);

    const { fundDocuments, status: documentsStatus } = useFundDocumentsState();
    const { selectedFundAndSeries } = useFundAndSeriesContext();

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const sortPageable = useCallback((): FundDocumentsRequest => {
        const _pageable = {
            page,
            size: LIST_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : ''
        };

        const request = { fundId: selectedFundAndSeries?.fundId as string, pageable: _pageable };

        return request;
    }, [sortedProperty, sortOrder, page, selectedFundAndSeries]);

    React.useEffect(() => {
        if (!selectedFundAndSeries?.fundId) return;
        dispatch(myFundDocumentsRequest(sortPageable()));
    }, [sortPageable, dispatch, selectedFundAndSeries]);

    const isLoading = documentsStatus !== HttpRequestStatus.SUCCESS;

    const handleSort = (property: FundDocumentsSortableProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty(undefined);
            setSortOrder(undefined);
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    if (!selectedFundAndSeries?.fundId) {
        return (
            <div className="fund-documents__items">
                <EmptyFundWarning />
            </div>
        );
    }

    return (
        <>
            <main className="fund-documents">
                <section className="fund-documents__container">
                    <div className="fund-documents__table">
                        <table className="page-container--table">
                            <thead>
                                <DashboardHeader documents={fundDocuments?.content ?? []} handleSort={handleSort} sortOrder={sortOrder} sortedProperty={sortedProperty} />
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <Loading isTable />
                                ) : fundDocuments?.content?.length === 0 ? (
                                    <tr>
                                        <td>
                                            <div className="page-container--table--not-found">
                                                <span className="page-container--table--not-found--icon" />
                                                <h1>{t(`${I18N_PREFIX}.empty.title`)}</h1>
                                                <h4>{t(`${I18N_PREFIX}.empty.message`)}</h4>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    <EmptyCollectionAware
                                        collection={fundDocuments?.content ?? []}
                                        isTable
                                        label={{
                                            key: `${I18N_PREFIX}.not-found`
                                        }}
                                    >
                                        {fundDocuments?.content?.map(document => (
                                            <tr className="page-container--table-cell" key={document.id}>
                                                <td className="page-container--table-cell-title">
                                                    <span>{document.file.description ?? '-'}</span>
                                                </td>
                                                <td className="page-container--table-cell-title">
                                                    <span>{document.type ? t(`${I18N_PREFIX}.document-type.${document.type}`) : '-'}</span>
                                                </td>
                                                <td className="page-container--table-cell-title">
                                                    <LocalDateFormatter value={document.createdDate ?? '-'} type={LocalDateFormatType.LONG_FULL_DATE} />
                                                </td>
                                                <td className="page-container--table-cell-icon" onClick={() => dispatch(fundDocumentsDownloadRequest(document.id))}>
                                                    <span className="page-container--table-cell-icon-download" />
                                                </td>
                                            </tr>
                                        ))}
                                    </EmptyCollectionAware>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!isLoading && <Pagination page={page} totalPages={fundDocuments?.totalPages ?? 0} onChange={handlePageChange} />}
                </section>
            </main>
        </>
    );
};

export default FundDocuments;
