import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface ExpandableCellProps {
    className: string;
    cellTitle: string;
    isCollapsedArrow: boolean;
}

const ExpandableFirstCell = (props: ExpandableCellProps) => {
    const { className, cellTitle, isCollapsedArrow } = props;

    return (
        <td className={clsx('page-container--table__sticky', className)}>
            <div className={clsx('page-container--table-icon', isCollapsedArrow ? 'collapsed' : 'expanded')}></div>

            <Tooltip title={cellTitle}>
                <span>{cellTitle}</span>
            </Tooltip>
        </td>
    );
};

export default ExpandableFirstCell;
