import { AxiosResponse } from 'axios';
import {
    CapitalBalance,
    CapitalEvolution,
    FundAccountCapitalBalanceRequest,
    FundAccountCapitalEvolutionRequest,
    FundAccountCashAvailableBalance,
    FundAccountCashAvailableBalanceRequest,
    FundAccountParticipantCost,
    FundAccountReceivables,
    FundAccountrReceivablesVolumeRequest,
    ParticipantCostRequest
} from 'model/fund-account';
import { api } from './api';

export const fundAccount = () => {
    const getCashAvailableBalance = (request: FundAccountCashAvailableBalanceRequest): Promise<AxiosResponse<FundAccountCashAvailableBalance>> => {
        return api.get<FundAccountCashAvailableBalance>(`api/fund-accounts/funds/${request.id}/cash/available-balance?period=${request.period}`);
    };

    const getCapitalEvolution = (request: FundAccountCapitalEvolutionRequest): Promise<AxiosResponse<CapitalEvolution>> => {
        return api.get<CapitalEvolution>(`api/fund-accounts/funds/${request.id}/capital-evolution?period=${request.period}`);
    };

    const getCapitalBalance = (request: FundAccountCapitalBalanceRequest): Promise<AxiosResponse<CapitalBalance>> => {
        return api.get<CapitalBalance>(`api/fund-accounts/funds/${request.id}/capital-balance`);
    };

    const getReceivablesFundBalance = (request: FundAccountrReceivablesVolumeRequest): Promise<AxiosResponse<FundAccountReceivables>> => {
        return api.get<FundAccountReceivables>(`api/fund-accounts/funds/${request.id}/receivables`);
    };

    const getParticipantCosts = (request: ParticipantCostRequest): Promise<AxiosResponse<FundAccountParticipantCost>> => {
        const _requestFilters = [request?.seriesId ? `seriesId=${request.seriesId}` : undefined, request?.consolidated ? `consolidated=${request.consolidated}` : undefined, request?.personId ? `personId=${request.personId}` : undefined].filter(it => !!it).join('&');

        return api.get<FundAccountParticipantCost>(`api/fund-accounts/funds/${request.id}/participants/costs?${_requestFilters}`);
    };

    return {
        getCashAvailableBalance,
        getCapitalEvolution,
        getReceivablesFundBalance,
        getCapitalBalance,
        getParticipantCosts
    };
};

export default fundAccount();
