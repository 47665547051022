import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel as BootstrapCarousel, CarouselCaption, CarouselIndicators, CarouselItem } from 'reactstrap';
import Background1 from '../../../../images/img-background1.jpg';
import Background2 from '../../../../images/img-background2.jpg';
import Background3 from '../../../../images/img-background3.jpg';
import './login-carousel.scss';

export const LoginCarousel = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [animating, setAnimating] = useState<boolean>(false);
    const { t } = useTranslation();

    const items = [
        {
            src: Background1,
            altText: 'Slide 1',
            captionHeader: t('login.carousel.first')
        },
        {
            src: Background2,
            altText: 'Slide 2',
            captionHeader: t('login.carousel.second')
        },
        {
            src: Background3,
            altText: 'Slide 3',
            captionHeader: t('login.carousel.third'),
            captionText: ''
        }
    ];

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map(item => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
                <div className="carousel--item" style={{ backgroundImage: `url(${item.src})` }} />
                <CarouselCaption captionText={item.captionText ?? ''} captionHeader={item.captionHeader ?? ''} />
            </CarouselItem>
        );
    });

    return (
        <div className="carousel--color">
            <BootstrapCarousel activeIndex={activeIndex} next={next} previous={previous} interval={5000}>
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
            </BootstrapCarousel>
        </div>
    );
};

export default LoginCarousel;
