import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/httpStatus';
import { CapitalBalance } from 'model/fund-account';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import fundAccountApi from 'services/api/fundAccountApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { capitalBalanceError, capitalBalanceRequest, capitalBalanceSuccess } from './actions';
import { CapitalBalanceActionTypes } from './types';

function* handleGetCapitalBalance(action: ReturnType<typeof capitalBalanceRequest>) {
    try {
        const result: AxiosResponse<CapitalBalance> = yield call(fundAccountApi.getCapitalBalance, action.payload);

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(capitalBalanceError(mapped));
            return;
        }
        yield put(capitalBalanceSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(capitalBalanceError(mapped));
    }
}

function* watchGetCapitalBalance() {
    yield takeEvery(CapitalBalanceActionTypes.CAPITAL_BALANCE_REQUEST, handleGetCapitalBalance);
}

function* capitalBalanceSaga() {
    yield all([fork(watchGetCapitalBalance)]);
}

export default capitalBalanceSaga;
