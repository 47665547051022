import { AxiosResponse } from 'axios';
import { SeriesPosition } from 'model/share-history';
import { api } from './api';
import { SeriesPositionFilter } from 'model/enums/my-position';

export const shareHistory = () => {
    const getSeriesPosition = (seriesAndPersonId: SeriesPositionFilter): Promise<AxiosResponse<SeriesPosition>> => {
        const _requestFilters = [seriesAndPersonId?.personId? `personId=${seriesAndPersonId.personId}` : undefined];

        return api.get<SeriesPosition>(`api/shares-histories/series/${seriesAndPersonId.seriesId}/my-position?${_requestFilters}`);
    };

    return {
         getSeriesPosition
    };
};

export default shareHistory();
