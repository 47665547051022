import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './wallet-switch.scss';
import { WalletDataVisualization } from 'model/enums/wallet-data-visualization';

const I18N_PREFIX = 'wallet.switch-button';

interface ButtonSwitchProps {
    disabled?: boolean;
    handleSwitch: () => void;
    currentVisualization: WalletDataVisualization;
}

const WalletSwitch = (props: ButtonSwitchProps) => {
    const { disabled, handleSwitch, currentVisualization } = props;

    const { t } = useTranslation();

    return (
        <div className="wallet-switch">
            <div
                className={clsx('wallet-switch--content', {
                    active: currentVisualization === WalletDataVisualization.INVESTOR,
                    disabled
                })}
                onClick={() => {
                    if (currentVisualization === WalletDataVisualization.INVESTOR) return;
                    handleSwitch();
                }}
                aria-hidden="true"
            >
                {t(`${I18N_PREFIX}.${WalletDataVisualization.INVESTOR}`)}
            </div>
            <div
                className={clsx('wallet-switch--content', {
                    active: currentVisualization === WalletDataVisualization.SECONDARY_ACCESS,
                    disabled
                })}
                onClick={() => {
                    if (currentVisualization === WalletDataVisualization.SECONDARY_ACCESS) return;
                    handleSwitch();
                }}
                aria-hidden="true"
            >
                {t(`${I18N_PREFIX}.${WalletDataVisualization.SECONDARY_ACCESS}`)}
            </div>
        </div>
    );
};

export default WalletSwitch;
