import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/httpStatus';
import { ShareholderInviteConfirmation } from 'model/shareholder-invite';
import fundApi from 'services/api/fundApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { fundResourcesSourceError, fundResourcesSourceRequest, fundResourcesSourceSuccess } from './actions';
import { FundResourcesSourceActionTypes } from './types';

function* handleGetFundResourcesSource(action: ReturnType<typeof fundResourcesSourceRequest>) {
    try {
        const result: AxiosResponse<ShareholderInviteConfirmation> = yield call(fundApi.getFundResourcesSource, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroIdhApiError(result);
            yield put(markError(mapped));
            yield put(fundResourcesSourceError(mapped));
            return;
        }
        yield put(fundResourcesSourceSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(fundResourcesSourceError(mapped));
    }
}

function* watchGetFundResourcesSource() {
    yield takeEvery(FundResourcesSourceActionTypes.FUND_RESOURCES_SOURCE_REQUEST, handleGetFundResourcesSource);
}

function* fundResourcesSourceSaga() {
    yield all([fork(watchGetFundResourcesSource)]);
}

export default fundResourcesSourceSaga;
