import { CURRENCY_FIXED_DECIMAL, INTL_CURRENCY, INTL_LOCALE, NUMBER_MAX_DECIMAL, PERCENTAGE_MAX_DECIMAL, UNIT_PRICE_FIXED_DECIMAL } from 'config/constants';

export const CURRENCY_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    minimumFractionDigits: CURRENCY_FIXED_DECIMAL,
    maximumFractionDigits: CURRENCY_FIXED_DECIMAL
});

export const CURRENCY_FORMATTER_PREFIXED = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'currency',
    currency: INTL_CURRENCY,
    minimumFractionDigits: CURRENCY_FIXED_DECIMAL,
    maximumFractionDigits: CURRENCY_FIXED_DECIMAL
});

export const UNIT_PRICE_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    minimumFractionDigits: UNIT_PRICE_FIXED_DECIMAL,
    maximumFractionDigits: UNIT_PRICE_FIXED_DECIMAL
});

export const UNIT_PRICE_FORMATTER_PREFIXED = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'currency',
    currency: INTL_CURRENCY,
    minimumFractionDigits: UNIT_PRICE_FIXED_DECIMAL,
    maximumFractionDigits: UNIT_PRICE_FIXED_DECIMAL
});

export const PERCENTAGE_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: PERCENTAGE_MAX_DECIMAL
});

export const PERCENTAGE_INTEGER_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

export const DECIMAL_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    minimumFractionDigits: 0,
    maximumFractionDigits: NUMBER_MAX_DECIMAL
});
