import React from 'react';
import ActionButton from '../../../components/buttons/action-button/action-button';
import ModalSuccess from '../../../components/modal-success/modal-success';
import './modal-forgot-password.scss';

export interface ModalForgotPasswordProps {
    open: boolean;
    title: string;
    message: string;
    action: string;
    closeModal: () => void;
}

export default (props: ModalForgotPasswordProps) => {
    const { open, title, message, action } = props;

    const handleClose = () => {
        props.closeModal();
    };

    return (
        <ModalSuccess open={open}>
            <div className="content__modal-forgot-password--itens">
                <div className="content__modal-forgot-password--image" />
                <div className="content__modal-forgot-password--title">{title}</div>
                <div className="content__modal-forgot-password--message">{message}</div>
                <ActionButton label={action.toUpperCase()} onClick={handleClose} />
            </div>
        </ModalSuccess>
    );
};
