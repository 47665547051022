import clsx from 'clsx';

import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import React from 'react';
import BaseSearchInput, { BaseSearchInputProps } from './base-search-input';
import './simple-search-input.scss';

type BaseValidation = Omit<ValidInputProps<string>, 'children'>;

export type ValidSearchInputProps = BaseSearchInputProps & BaseValidation;

export const ValidSearchInput = (props: ValidSearchInputProps) => {
    const { validate, showValidation, value, readOnly, label, placeholder, onChange, onClick, id } = props;
    return (
        <ValidInput
            id={id}
            value={value}
            validate={validate}
            showValidation={showValidation}
            className={clsx('search-input', { dirty: value, readonly: readOnly })}
        >
            {({ error }) => (
                <BaseSearchInput
                    id={id}
                    onChange={onChange}
                    onClick={onClick}
                    value={value}
                    label={label}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    error={error}
                />
            )}
        </ValidInput>
    );
};

export default ValidSearchInput;
