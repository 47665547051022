import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './standard-outlined-button.scss';

export interface StandardOutlinedButtonProps {
    onClick?: () => void;
    label: string;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    endIcon?: ReactNode;
}

export const StandardOutlinedButton = (props: StandardOutlinedButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={clsx('button__style--standard-outlined', [`${props.size}`])}>
            <Button variant="contained" onClick={props.onClick} disabled={props.disabled} endIcon={props.endIcon}>
                {t(props.label)}
            </Button>
        </div>
    );
};

export default StandardOutlinedButton;
