import { Reducer } from 'redux';
import { HttpRequestStatus } from '../../../model/enums/httpRequestStatus';
import { FundAccountParticipantCostActionTypes, FundAccountParticipantCostState } from './types';

export const initialState: FundAccountParticipantCostState = {
    status: HttpRequestStatus.NOOP,
    participantCostBySeries: undefined,
    participantCostConsolidated: undefined,
    error: undefined
};

const reducer: Reducer<FundAccountParticipantCostState> = (state = initialState, action): FundAccountParticipantCostState => {
    switch (action.type) {
        case FundAccountParticipantCostActionTypes.PARTICIPANT_COST_REQUEST: {
            return { ...state, status: HttpRequestStatus.ONGOING, participantCostBySeries: undefined, error: undefined };
        }
        case FundAccountParticipantCostActionTypes.PARTICIPANT_COST_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, participantCostBySeries: action.payload, error: undefined };
        }
        case FundAccountParticipantCostActionTypes.PARTICIPANT_COST_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case FundAccountParticipantCostActionTypes.PARTICIPANT_COST_RESET_STATE: {
            return { ...initialState };
        }
        default: {
            return state;
        }
    }
};

export { reducer as fundAccountParticipantCostReducer };
