import ActionButton from 'components/buttons/action-button/action-button';
import SimpleCategoryBarChart from 'components/chart/bar-chart/category-bar-chart/simple-category-bar-chart';
import CustomChartLegend from 'components/chart/chart-legend/custom-chart-legend';
import UngroupedChartToolTip from 'components/chart/chart-tooltip/ungrouped-chart-tooltip';
import { formatLocalDate, LocalDateFormatType } from 'components/fomatter/local-date/local-date-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { NumberOfParticipantsChartData } from 'model/raising';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNumberOfParticipantsState, useRootDispatch } from 'reducer/hooks';
import { numberOfParticipantsRequest } from 'reducer/raising/number-of-participants/actions';
import './number-of-participants-card.scss';

const CHART_HEIGHT = 262;

interface NumberOfParticipantsCardProps {
    fundId: string | undefined | null;
}

export const NumberOfParticipantsCard = (props: NumberOfParticipantsCardProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { status, report } = useNumberOfParticipantsState();

    const isLoading = status !== HttpRequestStatus.ERROR && status !== HttpRequestStatus.SUCCESS;
    const hasError = status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (!props.fundId) return;

        dispatch(numberOfParticipantsRequest(props.fundId));
    }, [dispatch, props.fundId]);

    if (isLoading) {
        return (
            <div className="items--card-block">
                <div className="items--card-header">
                    <div className="items--card-header-title">{t('debenture-cards.number-of-participants.title')}</div>
                </div>
                <div className="items--card--loading">
                    <Loading />
                </div>
            </div>
        );
    }

    const handleTryAgain = () => {
        if (!props.fundId) return;

        dispatch(numberOfParticipantsRequest(props.fundId));
    };

    if (hasError || !report) {
        return (
            <div className="items--card-block">
                <div className="items--card-header">
                    <div>{t('debenture-cards.number-of-participants.title')}</div>
                </div>
                <div className="items--card--error">
                    <div className="anchor-header--error-message">{t('debenture-cards.number-of-participants.data-recovery-exception')}</div>
                    <ActionButton label={t('global.try-again')} onClick={handleTryAgain} />
                </div>
            </div>
        );
    }

    if (!report.data || report.data.length === 0) {
        return (
            <div className="items--card-block">
                <div className="items--card-header">
                    <div className="items--card-header-title">{t('debenture-cards.number-of-participants.title')}</div>
                </div>
                <div className="items--card-error">{t('debenture-cards.number-of-participants.empty-chart')}</div>
            </div>
        );
    }

    return (
        <div className="items--card-block">
            <div className="items--card-header">
                <div className="items--card-header-title">{t('debenture-cards.number-of-participants.title')}</div>
            </div>
            <div className="items--card-graph">
                <NumberOfParticipantChart data={report.data} />
            </div>
        </div>
    );
};

interface NumberOfParticipantsChartProps {
    data: NumberOfParticipantsChartData[];
}

const NumberOfParticipantChart = (props: NumberOfParticipantsChartProps) => {
    const { t } = useTranslation();

    const formatCategory = (value: Date | number): string => {
        if (typeof value === 'number') {
            const date = props.data?.length && props.data.length >= value ? props.data[value]?.date : undefined;
            return formatLocalDate(date, LocalDateFormatType.SHORT_MONTH_NAME);
        }
        return formatLocalDate(value, LocalDateFormatType.SHORT_MONTH_NAME);
    };

    const customizeToolTip = React.useMemo(() => {
        return (_id: string, _value: number, color: unknown, x: number) => {
            const date = props.data?.length && props.data.length >= x ? props.data[x]?.date : undefined;
            const numberOfParticipants = props.data?.length && props.data.length >= x ? props.data[x]?.number : undefined;
            return `
            <div class="tooltip--block">
                <div class="tooltip--block-line">
                    <div class="tooltip--block-color" style="background-color:${color};"></div>
                    <b class="tooltip--block-text">${formatLocalDate(date, LocalDateFormatType.FULL_MONTH_NAME)?.toUpperCase()}</b>
                </div>
                <hr />
                <div class="tooltip--block-two">
                    <span class="tooltip--block-text">
                         ${t('debenture-cards.number-of-participants.chart.tooltip.number-of-participants', { count: numberOfParticipants ?? 0 })}
                    </span>
                </div>
            </div>
        `;
        };
    }, [props.data, t]);

    return (
        <>
            <div className="items--card-graph-title">
                <div className="items--card-graph-title-three">{t('debenture-cards.number-of-participants.chart.title')}</div>
            </div>
            <div style={{ height: `${CHART_HEIGHT}px` }}>
                <UngroupedChartToolTip customize={customizeToolTip}>
                    {tooltip => (
                        <SimpleCategoryBarChart
                            data={props.data}
                            config={{
                                height: CHART_HEIGHT,
                                labelKey: 'date',
                                categoryFormat: formatCategory,
                                categoriesKeys: ['number']
                            }}
                            tooltipOptions={tooltip}
                            ticks={1}
                        >
                            {(chart, keys) => <CustomChartLegend chart={chart} keys={keys} formatKey={_key => t('debenture-cards.number-of-participants.chart.legend.participants')} />}
                        </SimpleCategoryBarChart>
                    )}
                </UngroupedChartToolTip>
            </div>
        </>
    );
};

export default NumberOfParticipantsCard;
