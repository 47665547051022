import { FundResourcesSource } from 'model/fund';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum FundResourcesSourceActionTypes {
    FUND_RESOURCES_SOURCE_REQUEST = '@@fundResourcesSource/FUND_RESOURCES_SOURCE_REQUEST',
    FUND_RESOURCES_SOURCE_SUCCESS = '@@fundResourcesSource/FUND_RESOURCES_SOURCE_SUCCESS',
    FUND_RESOURCES_SOURCE_ERROR = '@@fundResourcesSource/FUND_RESOURCES_SOURCE_ERROR',
    FUND_RESOURCES_SOURCE_RESET_STATE = '@@fundResourcesSource/FUND_RESOURCES_SOURCE_RESET_STATE'
}

export interface FundResourcesSourceState extends BaseStoreState {
    readonly source?: FundResourcesSource;
}
