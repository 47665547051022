import { CloseButton } from 'components/button-close/close-button';
import Loading from 'components/loading/loading';
import _ from 'lodash';
import { HttpRequestStatus } from 'model/enums/httpRequestStatus';
import { SeriesType } from 'model/enums/series-type';
import { FundSeriesAndInvestor } from 'model/series';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { Modal } from 'reactstrap';
import { getAvailableYearsByIssuerIdResetState } from 'reducer/financial-statement-month/get-available-years-by-issuer-id/actions';
import { getAllSeriesPortfolioRequest, getAllSeriesPortfolioResetState } from 'reducer/fund/get-all-series-portfolio/actions';
import { useGetAllSeriesPortfolioState, useRootDispatch, useUserAccountState } from 'reducer/hooks';
import { getIncomeStatementAvailableYearsResetState } from 'reducer/income-statement/get-available-years-by-issuer-id/actions';
import { useFundAndSeriesContext } from 'shared/fund-and-series-selection/fund-and-series-selection-context';
import useFilterUtils from 'shared/hooks/use-filter-utils';
import { ParticipantsBaseTabLinks } from 'shared/menu/participant-header/participant-tab-menu';
import SelectAllCard from './components/select-all-card/select-all-card';

import './select-all-modal.scss';
import { HeaderVisualizationType } from 'model/enums/header-visualization-type';

interface SelectAllModalProps {
    handleClose: () => void;
    baseUrl?: string;
}

const SelectAllModal = (props: SelectAllModalProps) => {
    const { handleClose, baseUrl } = props;
    const { t } = useTranslation();

    const { account } = useUserAccountState();
    const { allSeries, status } = useGetAllSeriesPortfolioState();
    const { buildSearchParams } = useFilterUtils();
    const { handleSelectFundAndSeries } = useFundAndSeriesContext();

    const dispatch = useRootDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();

    const participantTabLinks = _.values(ParticipantsBaseTabLinks);
    const existsByParticipantsTabLinks: boolean = participantTabLinks.includes(url);

    const getUrlToRedirect = useCallback(
        (seriesType: string): string => {
            if (seriesType !== SeriesType.RESULT && !existsByParticipantsTabLinks) {
                return ParticipantsBaseTabLinks.GENERAL;
            }

            return baseUrl ?? url;
        },
        [baseUrl, url, existsByParticipantsTabLinks]
    );

    const handleFundAndSeries = useCallback(
        (fundAndSeries: FundSeriesAndInvestor) => {

            const fundSeriesAndInvestor: FundSeriesAndInvestor = {
                fundId: fundAndSeries.fundId,
                fundName: fundAndSeries.fundName,
                fundType: fundAndSeries.fundType,
                seriesId: fundAndSeries.seriesId,
                seriesName: fundAndSeries.seriesName,
                seriesType: fundAndSeries.seriesType,
                personId: account?.personId,
                personName: account?.personName,
                headerVisualizationType: HeaderVisualizationType.INVESTOR
            };

            handleSelectFundAndSeries(fundSeriesAndInvestor);
            history.push({
                pathname: getUrlToRedirect(fundSeriesAndInvestor.seriesType),
                search: buildSearchParams(fundSeriesAndInvestor)
            });

            dispatch(getAvailableYearsByIssuerIdResetState());
            dispatch(getIncomeStatementAvailableYearsResetState());
            handleClose();
        },
        [handleSelectFundAndSeries, history, account, buildSearchParams, handleClose, getUrlToRedirect, dispatch]
    );

    useEffect(() => {
        dispatch(getAllSeriesPortfolioRequest());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(getAllSeriesPortfolioResetState());
        };
    }, [dispatch]);

    const isLoading = status === HttpRequestStatus.ONGOING;

    return (
        <Modal isOpen size="xl" modalClassName="select-all-modal" toggle={handleClose}>
            <div className="select-all-modal--container">
                <div className="select-all-modal--header">
                    <label className="select-all-modal--header-title">{t('wallet.modal.title')}</label>
                    <CloseButton onClick={handleClose} />
                </div>
            </div>
            <div className="select-all-modal--list">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        {allSeries?.map((it, index) => (
                            <SelectAllCard key={it.seriesId} fundAndSeries={it} action={() => handleFundAndSeries(it)} dataCy={`button#select-series-${index}`} />
                        ))}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default SelectAllModal;
