import React from 'react';
import FormatterUtils from '../formatter-utils';

export type CurrecyValue = number | undefined | null;

export interface CurrencyFormatterProps {
  value: CurrecyValue;
  prefix?: boolean;
  defaultValue?: number;
}

export const CurrencyFormatter = (props: CurrencyFormatterProps) => {
  const { value, defaultValue, prefix } = props;
  return <>{FormatterUtils.formatNumberToCurrency(value, defaultValue, prefix)}</>;
};

export const formatCurrency = FormatterUtils.formatNumberToCurrency;

export default CurrencyFormatter;
