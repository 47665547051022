export type Environment = 'development' | 'staging' | 'production';

const getEnvironment = (): Environment => {
    return process.env.REACT_APP_PROFILE as Environment;
};

const isDevelopmentEnvironment = (): boolean => {
    const currentEnvironment = getEnvironment();
    return currentEnvironment === 'development';
};

const isStagingEnvironment = (): boolean => {
    const currentEnvironment = getEnvironment();
    return currentEnvironment === 'staging';
};

const isProductionEnvironment = (): boolean => {
    const currentEnvironment = getEnvironment();
    return currentEnvironment === 'production';
};

export const EnvironmentUtils = {
    getEnvironment,
    isDevelopmentEnvironment,
    isStagingEnvironment,
    isProductionEnvironment
};
